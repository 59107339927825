import React from "react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import ReactTimeAgo from "react-time-ago";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

const LastSeen = ({ record }) => {
  const date = record?.TimeInQueue ? new Date(record?.TimeInQueue) : null;
  return (
    <>
      {date instanceof Date  ? (
        <div>
          <ReactTimeAgo
            date={date}
            locale="en-US"
            timeStyle="round"
          />
        </div>
      ) : (
        <div>--</div>
      )}
    </>
  );
};

export default LastSeen;
