import React from "react";
import { Button as MuiButton, CircularProgress, Box } from "@mui/material";
// import EmailIcon from "@mui/icons-material/Email";
import { useCreate, useNotify, useRefresh } from "react-admin";
import { useParams } from "react-router-dom";
import ResendIcon from '../../assets/images/resend.svg';
const Revoke = ({ record }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const { id } = useParams();
    const [create, { isLoading, error }] = useCreate();
    const handleRevoke = (value) => {

        const payload = {
            ID: record?.ID,
        };
        create(
            "revoke",
            { data: payload },
            {
                onSuccess: (data) => {
                    notify(`Revoke succesfully`, {
                        type: "success",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    });

                    refresh();
                },
                onError: (e: any) =>
                    notify(`Operation fail:${e.message}`, {
                        type: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }),
            }
        )
    }
    if (isLoading)
        return (
            <>
                <div className="d-flex justify-content-center align-items-center">
                    <CircularProgress size="30px" />
                </div>
            </>
        );
    return (
        <Box display={"flex"} justifyContent={"start"}>
            <MuiButton
                sx={{
                    ".MuiButton-startIcon": { paddingLeft: "10px" },
                    minWidth: "80px",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    textTransform: "capitalize",
                }}
                onClick={handleRevoke}
                // startIcon={<img src={ResendIcon} alt="" />}
                variant="text"
            >
                Revoke
            </MuiButton>
        </Box>
    )
}

export default Revoke