import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store'
// Define a type for the slice state
interface FileUploadApi {
    uploading: boolean
}

// Define the initial state using that type
const initialState: FileUploadApi = {
    uploading: false
}
const FileUploadingSlice = createSlice({
    name: 'fileUploading',
    initialState,
    reducers: {
        // add actions and reducers here
        fileUploadingStart: (state) => {
            state.uploading = true;
        },
        fileUploadingComplete: (state) => {
            state.uploading = false;
        }
    },
});

export const { fileUploadingStart, fileUploadingComplete } = FileUploadingSlice.actions
export default FileUploadingSlice.reducer
