import React from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
// import InputField from "../../../../registration/Inputs/InputField";
// import SelectField from "../../../../registration/Inputs/SelectField";
// import { TiDelete } from "react-icons/ti";
// import InputFieldPhone from "../../../../registration/Inputs/InputFieldPhone";
import { CardHeader } from "@mui/material";
import DeleteContainedBtn from "../../../../common/DeleteContainedBtn";
import InputField2 from "../../../../common/InputComponents/InputField2";
import TextInputPhone from "../../../components/inputComponents/TextInputPhone";
import InputSelectField from "../../../../common/InputComponents/InputSelectField";

const EmergencyContact = (props) => {
  // 
  const { register } = props;
  const { control, watch, setValue, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "emergency_reference.fields", // unique name for your Field Array
    keyName: "arrId", //default to "id", you can change the key name
  });
  const { errors } = useFormState({
    control,
  });

  return (
    <>
      <div className="blue-box a-blue-box pd15 mr-b30">
        {fields.map((field, index) => {
          // 
          if (
            watch(`emergency_reference.fields.${index}.reference_copy`)
              ?.value >= 0
          ) {
            if (
              !getValues(
                `emergency_reference.fields.${index}.reference_copy_status`
              )
            ) {
              /**
               *
               */
              setValue(
                `emergency_reference.fields.${index}`,
                getValues(
                  `reference.fields.${
                    getValues(
                      `emergency_reference.fields.${index}.reference_copy`
                    )?.value
                  }`
                ),
                { shouldValidate: true }
              );
              setValue(
                `emergency_reference.fields.${index}.reference_copy_status`,
                true
              );
            }
          }
          return (
            <div key={field.arrId} style={{ marginTop: "20px" }}>
              <div className="row">
                <div className="col-sm-12 mb-2">
                  {/* <span className="s-no-index float-left">{index + 1}</span>{" "} */}
                  {/* <button
                    type="button"
                    onClick={async () => {
                      remove(index);
                      props?.checkSourceAvailableHandler("emergency");
                    }}
                    title="Delete this Employer"
                    className="float-right btn btn-sm btn-danger"
                  >
                    <TiDelete size={25} /> Delete
                  </button> */}
                  <CardHeader
                    sx={{ padding: 0 }}
                    title={<b className="s-no-index-number">{index + 1}</b>}
                    action={
                      <DeleteContainedBtn
                        onClick={async () => {
                          remove(index);
                          props?.checkSourceAvailableHandler("emergency");
                          props?.handleAPIDebounced();
                        }}
                        title="Delete this Employer"
                      />
                    }
                  />
                </div>
              </div>

              <div className="row">
                {props.referenceList.filter(
                  (item) =>
                    !!item.first_name && !!item.last_name && !!item.email
                ).length > 0 && (
                  <div className="col-sm-12">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                        {watch(
                          `emergency_reference.fields.${index}.first_name`
                        ) === "" && (
                          // <SelectField
                          //   notRequired={true}
                          //   defaultValue={
                          //     watch(
                          //       `emergency_reference.fields.${index}.first_name`
                          //     )
                          //       ? watch(
                          //           `emergency_reference.fields.${index}.first_name`
                          //         ) +
                          //         " " +
                          //         watch(
                          //           `emergency_reference.fields.${index}.last_name`
                          //         )
                          //       : ""
                          //   }
                          //   disable={watch(
                          //     `emergency_reference.fields.${index}.reference_copy_status`
                          //   )}
                          //   options={props.referenceList
                          //     .filter(
                          //       (item) =>
                          //         !!item.first_name &&
                          //         !!item.last_name &&
                          //         !!item.email
                          //     )
                          //     .map((item, index) => {
                          //       const name =
                          //         item.first_name + " " + item.last_name ||
                          //         index;
                          //       return {
                          //         value: index,
                          //         label: name,
                          //       };
                          //     })}
                          //   label="Copy data from reference"
                          //   register={register(
                          //     `emergency_reference.fields.${index}.reference_copy`
                          //   )}
                          // />
                          <InputSelectField
                            label={`Copy data from reference`}
                            source={`emergency_reference.fields.${index}.reference_copy`}
                            options={props.referenceList
                              .filter(
                                (item) =>
                                  !!item.first_name &&
                                  !!item.last_name &&
                                  !!item.email
                              )
                              .map((item, index) => {
                                const name =
                                  item.first_name + " " + item.last_name ||
                                  index;
                                return {
                                  value: index,
                                  label: name,
                                };
                              })}
                            labelAsteriskClass={"color-red"}
                            labelClass={"lable-title"}
                            requiredClass={{
                              fontSize: "70%",
                            }}
                            disable={watch(
                              `emergency_reference.fields.${index}.reference_copy_status`
                            )}
                            notRequired={false}
                            onChangeCallback={() => {
                              props?.handleAPIDebounced();
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                  {/* <InputField
                    label={`First Name`}
                    register={register(
                      `emergency_reference.fields.${index}.first_name`
                    )}
                  /> */}
                  <InputField2
                    label="First Name"
                    source={`emergency_reference.fields.${index}.first_name`}
                    placeholder="First Name"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                    // disable={field?.is_fixed}
                  />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                  {/* <InputField
                    label={`Last Name`}
                    register={register(
                      `emergency_reference.fields.${index}.last_name`
                    )}
                  /> */}
                  <InputField2
                    label="Last Name"
                    source={`emergency_reference.fields.${index}.last_name`}
                    placeholder="Last Name"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                    // disable={field?.is_fixed}
                  />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4">
                  {/* <InputField
                    label={`Email`}
                    register={register(
                      `emergency_reference.fields.${index}.email`
                    )}
                  /> */}
                  <InputField2
                    label="Email"
                    source={`emergency_reference.fields.${index}.email`}
                    placeholder="Email"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                    // disable={field?.is_fixed}
                  />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                  {/* <InputFieldPhone
                    label={`Mobile Number`}
                    register={register(
                      `emergency_reference.fields.${index}.mobile`
                    )}
                    notRequired={true}
                  /> */}
                  <TextInputPhone
                    source={`emergency_reference.fields.${index}.mobile`}
                    label={`Mobile Number`}
                    isCountryCode={`emergency_reference.fields.${index}.country_code`}
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                    widthClass={"w-100"}
                    requiredClass={{
                      fontSize: "70%",
                    }}
                    onChangeCallback={() => {
                      props?.handleAPIDebounced();
                    }}
                    // disabled={field?.is_fixed}
                    required={false}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                  {/* <InputField
                    label={`Relationship to Applicant`}
                    register={register(
                      `emergency_reference.fields.${index}.relationship`
                    )}
                  /> */}
                  <InputField2
                    label="Relationship to Applicant"
                    source={`emergency_reference.fields.${index}.relationship`}
                    placeholder="Relationship to Applicant"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                    // disable={field?.is_fixed}
                  />
                </div>
                {errors && errors[index] && errors[index].number}
              </div>
            </div>
          );
        })}
        <div className="add-text-btn flex1 text-center ps-2 pt-3">
          <button
            type="button"
            onClick={(event) =>
              append({
                id: 0,
                first_name: "",
                last_name: "",
                email: "",
                mobile: "",
              })
            }
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                fill="#2DC3E8"
              />
            </svg>
            &nbsp; Add another emergency contact
          </button>
        </div>
      </div>
    </>
  );
};

export default EmergencyContact;
