import * as React from "react";
import { Box, Button, InputAdornment, ButtonGroup } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import {
  TextInput,
  NullableBooleanInput,
  useListContext,
  CheckboxGroupInput,
  useGetOne,
  SelectInput,
  useGetList,
  Loading,
  useStore,
  useGetIdentity,
  Datagrid,
  TextField,
  FunctionField,
} from "react-admin";
import { useForm, FormProvider, get } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import InputDateField from "../../common/InputComponents/InputDateFieldOld";
import { JSONTree } from "react-json-tree";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import NewApplicationModel from "../NewApplicationModel";
import { useController, Controller } from "react-hook-form";
import SwitchSubscriber from "../SwitchSubscriber";
import { useSelector } from "react-redux";
import CopyUrlModal from "../../Modal/CopyUrlModal";

const style = {
  // position: 'absolute' as 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: 450,
  // bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  // p: 4,
};

// export const AgentList = (props) => {
//   //
//   // debugger;
//   const { Office } = props;
//   const { data, isLoading, error } = useGetList("getagentlist", {
//     filter: { url: Office?.URL, SubscriberID: Office?.SubscriberID },
//   });

//   //
//   //
//   return (
//     <Box component="span">
//       {isLoading && <p>Loading...</p>}
//       {!isLoading && data && (
//         <SelectInput
//           sx={{ width: "100%" }}
//           source="Agent"
//           variant="outlined"
//           choices={data?.map((item) => ({
//             id: item.FullName,
//             name: `${item.FullName}`,
//           }))}
//         />
//       )}
//     </Box>
//   );
// };

// export const FloorPlan = (props) => {
//   const { FloorPlan } = props;
//   const { data, isLoading, error } = useGetList("getfloorlist", {
//     filter: {
//       PropertyID: FloorPlan?.ID,
//       SubscriberID: FloorPlan?.SubscriberID,
//     },
//   });
//   return (
//     <Box component="span">
//       {isLoading && <p>Loading...</p>}
//       {!isLoading && data && (
//         <SelectInput
//           sx={{ width: "100%" }}
//           source="Floor"
//           variant="outlined"
//           choices={data?.map((item) => ({
//             id: item.FloorCode,
//             name: `${item.FloorCode}`,
//           }))}
//         />
//       )}
//     </Box>
//   );
// };

export default function ActionLandloardInbox(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const { id } = useParams();
  const { data: identity } = useGetIdentity();
  const submitBtnRef = React.useRef<HTMLButtonElement>(null);
  const { data, isLoading, error } = useGetOne("getfilterforinbox", {
    id: id || identity?.subscriberid,
  });

  const { displayedFilters, filterValues, setFilters, hideFilter } =
    useListContext();
  // get user role from  global store
  const userRole =
    useSelector((state: any) => state?.users?.role) || identity?.userrole;

  // get application detail data
  // const { data: applicationDetail, isLoading: isLoadingData } = useGetOne(
  //   "getnewapplicationdetail",
  //   {
  //     id: id || identity?.subscriberid,
  //   },
  //   { enabled: id || identity?.subscriberid ? true : false }
  // );
  const form = useForm({
    defaultValues: filterValues,
  });

  // if (!displayedFilters.main) return null;
  const applyFilter = (values) => {
    if (Object.keys(values).length > 0) {
      const filter = {
        ...values,
        end_date: values.end_date
          ? moment(values.end_date).format("MM/DD/yyyy")
          : "",
        start_date: values.start_date
          ? moment(values.start_date).format("MM/DD/yyyy")
          : "",
      };
      setFilters(filter, displayedFilters);
      handleClose();
    }
  };
  const onSubmit = (values: any) => {
    applyFilter({
      ...values,
      app_invitation_type: filterValues?.app_invitation_type || 1,
    });
  };

  const [q, setQ] = React.useState<null | string>(filterValues?.q);

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      form.setValue("q", q);
      if (q != null) {
        applyFilter({
          ...form.getValues(),
          app_invitation_type: filterValues?.app_invitation_type || 1,
        });
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [q]);

  React.useEffect(() => {
    if (filterValues.app_invitation_type) {
      form.reset(filterValues)
      if (!filterValues?.q) {
        setQ(filterValues?.q)
      }
    }
  }, [filterValues])

  // React.useEffect(() => {
  //   if (Object.keys(filterValues).length === 0) {
  //     if (applicationDetail?.ApplicationType?.ATS) {
  //       setFilters({ app_invitation_type: "1" }, displayedFilters);
  //     } else if (applicationDetail?.ApplicationType?.DocUpload) {
  //       setFilters({ app_invitation_type: "2" }, displayedFilters);
  //     } else if (applicationDetail?.ApplicationType?.GuestCard) {
  //       setFilters({ app_invitation_type: "3" }, displayedFilters);
  //     }
  //   }
  // }, [filterValues, applicationDetail]);

  const start_date = form.watch("start_date");

  // const filteredApplicationsStatus = data?.Status?.map((item) => {
  //   let status = item.ApplicationStatus;

  //   // Modify status if it matches "CompletedConditionallyApproved"
  //   if (status === "CompletedConditionallyApproved") {
  //     status = "Completed-Conditionally Approved";
  //   }

  //   // Add additional conditions if needed

  //   return {
  //     ...item,
  //     ApplicationStatus: status,
  //   };
  // }).filter((item) => {
  //   if (
  //     !filterValues.app_invitation_type ||
  //     filterValues.app_invitation_type == 1
  //   ) {
  //     return (
  //       item.ApplicationStatus === "Invited" ||
  //       item.ApplicationStatus === "Application In Progress" ||
  //       item.ApplicationStatus === "Submitted" ||
  //       item.ApplicationStatus === "Screening" ||
  //       item.ApplicationStatus === "Completed-Approved" ||
  //       item.ApplicationStatus === "Completed-Conditionally Approved" ||
  //       item.ApplicationStatus === "Completed-Unacceptable"
  //     );
  //   } else if (filterValues.app_invitation_type == 2) {
  //     return (
  //       item.ApplicationStatus === "Invited" ||
  //       item.ApplicationStatus === "Application In Progress" ||
  //       item.ApplicationStatus === "Review Pending" ||
  //       item.ApplicationStatus === "Verified" ||
  //       item.ApplicationStatus === "Further Documentation Required" ||
  //       item.ApplicationStatus === "Unverifiable"
  //     );
  //   } else {
  //     return item;
  //   }
  // });

  return (
    <>
      <div className="a-top-right-block">
        <div className="cus-search">
          <input
            type="text"
            name=""
            id=""
            value={q || ""}
            placeholder="Search"
            onChange={(e) => setQ(e?.target?.value)}
          />
        </div>
      </div>

    </>
  );
}
