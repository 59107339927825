import './ActionCSS/Action.css'
import { Button, Datagrid, DateField, FunctionField, List, ListBase, ListGuesser, ListToolbar, NumberField, Pagination, Resource, TextField, useListContext, useStore } from 'react-admin';
import FilterReports from './FilterReports';
import FilterChips from '../Ats/SuperAdminInbox/FilterChips';
import ExportSubRevenue from './ExportSubRevenue';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
export const RevenueManagementReportResource = () => {
    return <Resource name="getrevenuemanagementreport" list={RevenueManagement} />
}
const MyList = ({ children, filters, actions, ...props }) => {
    return (
        <ListBase
            {...props}
            perPage={100} // Number of items per page
        >
            <ListToolbar
                filters={filters}
                actions={actions}
            />
            <FilterChips excludeKeys={['app_invitation_type']} />
            {children}
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
            <br />
            <br />
            <br />
            <br />
        </ListBase>
    );
};

// const PostPagination = () => <Pagination rowsPerPageOptions={[100, 200, 300, 400,500,1000]} />;
const RevenueManagement = () => {
    return (
        <>
            <br />
            <br />
            <MyList
                filters={<FilterReports />}
                actions={<ExportSubRevenue />}
                sort={{ field: "ApplicationNumber", order: "DESC" }}
                // filterDefaultValues={{ subscriberID: 1 }}
                children={<DataGrid />}
            />
        </>)
}
const DataGrid = () => {

    return (<Datagrid bulkActionButtons={false} sx={{
        "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
        "& .RaDatagrid-expandIcon": { color: "#fff" },
        "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
        "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
            fontSize: 12,
        },
        "& .RaDatagrid-table": {
            fontSize: 12,
        },
        "& .MuiToggleButton-root.Mui-selected": {
            fontWeight: 700,
        },
    }}>
        {/* <TextField source="id"  label={'App Id'}/> */}
        <TextField source="ApplicationNumber" label={'App Id'} />
        <FunctionField
            sortBy="AppDate"
            label="Date"
            render={(record) => {
                // Format the AppDate using moment.js
                const formattedDate = moment.utc(record.AppDate).format('MM/DD/YYYY');

                return (
                    <>

                        <p>{formattedDate}</p> {/* Display the formatted AppDate */}
                        {/* <p>{record.LandLordPhone}</p> */} {/* Other data rendering if needed */}
                    </>
                );
            }}
        />
        <TextField source="SubscriberName" label={'Subscriber'} />
        {/* <TextField source="SubscriberName" label={'Subscriber'} /> */}
        <FunctionField
            label={"Office/Property"}
            sortBy="OfficeName"
            render={(record) => {
                return <span>{record.OfficeName || record.PropertyName}</span>
            }
            }
        />
        <TextField source='ApplicantName' label={'Applicant'} />
        {/* <TextField source='ApplicationFee' label={'App Fee'}/> */}
        <FunctionField
            source="ApplicationFee"
            label="App Fee"
            sortBy="ApplicationFee"
            render={(record) => {
                return !record.ApplicationFee ? (
                    ""
                ) : (
                    <>
                        <NumericFormat
                            thousandSeparator=","
                            displayType="text"
                            thousandsGroupStyle="thousand"
                            decimalScale={2}
                            prefix="$"
                            fixedDecimalScale
                            value={record.ApplicationFee}
                        />

                    </>
                );
            }}
        />
        {/* <TextField source='SubscriberFee' label={'Subs Fee'}/> */}
        <FunctionField
            source="SubscriberFee"
            label="Sub Fee"
            sortBy="SubscriberFee"
            render={(record) => {
                return !record.SubscriberFee ? (
                    ""
                ) : (
                    <>
                        <NumericFormat
                            thousandSeparator=","
                            displayType="text"
                            thousandsGroupStyle="thousand"
                            decimalScale={2}
                            prefix="$"
                            fixedDecimalScale
                            value={record.SubscriberFee}
                        />

                    </>
                );
            }}
        />
        {/* <TextField source='StripeFee' label={'Tran fee'} /> */}
        <FunctionField
            source="StripeFee"
            label="Tran fee"
            sortBy="StripeFee"
            render={(record) => {
                return !record.StripeFee ? (
                    ""
                ) : (
                    <>
                        <NumericFormat
                            thousandSeparator=","
                            displayType="text"
                            thousandsGroupStyle="thousand"
                            decimalScale={2}
                            prefix="$"
                            fixedDecimalScale
                            value={record.StripeFee}
                        />

                    </>
                );
            }}
        />
           <TextField source="NameOnCard" label={'Card Holder'} />
           <TextField source="TransactionNumber" label={'Transaction No.'} />
        <TextField source="AgentName" label={'Agent'} />
     
        {/* <FunctionField label="Batch date Batch Id." render={(record) => {
                        return (<>
                            <p>{record.BatchDate}</p>
                            <p>{record.BatchNumber}</p>
                        </>)
                    }
                    } />  */}
       
    </Datagrid>)


}
export default RevenueManagementReportResource;