import React, { useContext, useEffect, useState } from "react";
import {
  useCreate,
  useGetIdentity,
  useGetOne,
  useNotify,
  useRefresh,
  useStore,
} from "react-admin";
import Dropzone from "react-dropzone";
import { FaFileUpload } from "react-icons/fa";
import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  fileUploadingComplete,
  fileUploadingStart,
} from "../../../Store/Slice/fileUploadingSlice";
import { RootState } from "../../../Store/store";

import { setUploadPetImageId } from "../../../Store/Slice/applicantDetailsSlice";

export interface uploadDocumentInterface {
  id: number;
  files: any;
  document_id: number;
  source_id: number;
  allowOfferLetter: boolean | null;
}

export default function DragDropDocumentButton({
  trn_id,
  fetchRecord,
  documentId,
  SourceId,
  acceptedFiles = `image/*,application/pdf`,
  disabled = false,
  fileCount = 0,
  invalidateQueries,
  tag,
  label = "File Upload",
  fileError,
  allowOfferLetter = false,
  beforeUploadCallback = () => { },
  ...props
}) {

  //Fix co-applicant issue
  const trnId = trn_id //useSelector((state: RootState) => state.users.userTrnId);
  // TODO : needed to replace
  const dispatch = useDispatch();

  const { identity, isLoading: identityLoading } = useGetIdentity();

  const notify = useNotify();
  const inputRef = React.useRef<HTMLDivElement>(null);
  const [create, { data, isLoading, error }] = useCreate();
  const isOpeningForDashboard = useSelector(
    (state: RootState) => state.app.isOpeningForDashboard
  );
  const applicationStatus = useSelector(
    (state: any) => state?.users?.applicationStatus
  );
  const [disableFlag, setDisabledFlag] = React.useState(false);

  const {
    data: applicantDatasub,
    isLoading: loading,
    refetch,
  } = useGetOne("getApplicantDetails", { id: trnId }, { enabled: !!trnId });

  const [updateApplicant, updateApplicantStatus] = useCreate(
    "updateApplicationStatus",
    {
      data: {
        trn_id: trnId,
        role: identity?.super_user_role,
        status: "5",
        office_property_name: applicantDatasub?.Application_details?.office_property_name,

      },
    },
    {
      onError: (error: any) => {
        notify(`${error.message}`, {
          type: "warning",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onSettled: () => {
        setTimeout(() => {
          dispatch(fileUploadingComplete());
        }, 3000);
      },
    }
  );

  const [updateApplicantInProg, updateApplicantStatusInProg] = useCreate(
    "updateApplicationStatus",
    {
      data: {
        trn_id: trnId,
        role: identity?.super_user_role,
        status: "2",
        office_property_name: applicantDatasub?.Application_details?.office_property_name,


      },
    },
    {
      onError: (error: any) => {
        notify(`${error.message}`, {
          type: "warning",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onSettled: () => {
        setTimeout(() => {
          dispatch(fileUploadingComplete());
        }, 3000);
      },
    }
  );

  const queryClient = useQueryClient();
  const refresh = useRefresh();

  React.useEffect(() => {
    if (fileError) inputRef?.current?.scrollIntoView();
  }, [fileError]);

  const isParentFieldsetDisabled = (element) => {
    let parent = element.parentElement;
    while (parent) {
      if (parent.tagName === 'FIELDSET' && parent.disabled) {
        return true; // A parent fieldset is disabled
      }
      parent = parent.parentElement;
    }
    return false; // No parent fieldset is disabled
  };
  const [fieldSetDisable,setFieldSetDisable]=useState(false);
  useEffect(() => {
    // When the component mounts, check if the input's parent fieldset is disabled
    const inputElement = inputRef.current;
    if (inputElement && isParentFieldsetDisabled(inputElement)) {
     setDisabledFlag(true);
    } else {
      setFieldSetDisable(false);
      console.log('No parent fieldset is disabled.');
    }
  }, []);

  return (
    <>
      <small className="mute print-hide" style={{ fontSize: "10px" }}>
        {label}
      </small>
      <div className="pb-1 print-hide" ref={inputRef}>
        <Dropzone
          // maxFiles={8}
          maxSize={10485760} //10MB in bytes
          multiple={props?.multiple ? false : true}
          disabled={disabled ? disabled : disableFlag ? fieldSetDisable || true : false}
          accept={acceptedFiles}
          onDropRejected={(file: any[]) => {
            for (let f of file) {
              for (let err of f.errors) {
                if (err.code == "file-too-large") {
                  notify('File is larger than 10MB', {
                    type: "warning",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                  return;
                }
              }
            }

            const errorMessage = file[0]?.errors
              .map((item) => item?.message)
              .join(", ");
            notify(errorMessage, {
              type: "warning",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
          }}
          onDropAccepted={(acceptedFiles) => {
            const totalSize = acceptedFiles.reduce(
              (acc, file) => acc + file.size,
              0
            );
            if (totalSize > 10 * 1024 * 1024) {
              notify(`File size exceeds the limit of 10MB`, {
                type: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });
              return;
            }

            if (fileCount + acceptedFiles.length > 8) {
              notify(`error: upload discard max file limit exceed`, {
                type: "warning",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              });

              return;
            }

            notify("File Uploading...", {
              type: "info",
              autoHideDuration: 4000,
              anchorOrigin: { vertical: "top", horizontal: "right" },
            });
            dispatch(fileUploadingStart());
            if (
              identity?.super_user_role != "applicant" &&
              tag == "IncomeUpload"
            )
              beforeUploadCallback();
            setDisabledFlag(true);
            create(
              "fileUpload",
              {
                data: {
                  files: acceptedFiles,
                  trn_id: trnId,
                  document_id: documentId,
                  source_id: SourceId,
                  allowOfferLetter: allowOfferLetter,
                },
              },
              {
                onError: (error: any) => {
                  notify(`${error.message}`, {
                    type: "error",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                  setDisabledFlag(false);
                },
                onSuccess: (data) => {
                  refresh();
                  queryClient.invalidateQueries([`${invalidateQueries}`]);
                  notify("Document Uploaded", {
                    type: "success",
                    anchorOrigin: { vertical: "top", horizontal: "right" },
                  });
                  if (props.from == "petsImage") {
                    dispatch(
                      setUploadPetImageId({
                        index: props?.petIndex,
                        id: data?.records?.id,
                        file: data?.records?.blob_path,
                        type: "UPLOAD",
                      })
                    );
                  }
                  if (props?.handleOnChange) {
                    props?.handleOnChange();
                  }
                  if (applicationStatus >= 3) {
                    if (applicationStatus !== 8 && applicationStatus !== 7) {
                      if (!isOpeningForDashboard)
                         updateApplicant();
                    }
                  }
                  if (applicationStatus == 1) {
                    updateApplicantInProg();
                  }
                  // if (identity?.super_user_role == "Subscriber Admin" && identity?.super_user_role != "applicant")
                  //   handleApplicantSubmit();
                  setTimeout(() => setDisabledFlag(false), 5000);
                },
              }
            );
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              className="docs-upload"
              style={{
                minWidth: "100px",
                background: "#fff",
                maxWidth: "80px",
              }}
            >
              <div className="upload-subwrap" {...getRootProps()}>
                <span>
                  <p>&nbsp;</p>
                  <FaFileUpload color="grey" size={35} />
                </span>
                {isLoading ? (
                  <div className="mt-1 d-flex align-items-center justify-content-center">
                    &nbsp;
                    <div
                      className="spinner-grow spinner-grow-sm"
                      role="status"
                    ></div>
                    <p style={{ fontSize: "10px" }}>&nbsp;Uploading...</p>
                  </div>
                ) : (
                  <>
                    <p className="pt-2 small" style={{ background: "#fff" }}>
                      Drop Files Here Or{" "}
                    </p>
                    <p style={{ background: "#fff" }}>Click to Upload </p>
                  </>
                )}
                <input {...getInputProps()} type="file" name="" id="" />
              </div>
            </div>
          )}
        </Dropzone>

        {fileError && (
          <div
            className="invalid-feedback bg-danger text-white mb-2 d-inline"
            style={{
              display: "block",
              fontSize: "80%",
              marginTop: 14,
              fontWeight: 450,
            }}
          >
            {"File required"}
          </div>
        )}
      </div>
    </>
  );
}
