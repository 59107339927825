import { Resource } from 'ra-core'
import { ListGuesser } from 'ra-ui-materialui'
import React from 'react'
import { GetAuditLogist, GetLogActivityList } from '../../componets/Ats/GetLogActivityList'

function Getloginactivitylogs() {
  return (
    <Resource name='getloginactivitylogs' list={<GetLogActivityList/>} />
  )
}
export function GetAuditLogs() {
  return (
    <Resource name='getsubmittedauditlog' list={<GetAuditLogist/>} />
  )
}

export default Getloginactivitylogs
