import { typesofUsers } from "../config";



export function checkModuleAccess(ModuleData, moduleName, menuName) {

    if (!ModuleData) {
        return false;
    }
    if (!ModuleData[moduleName]) {
        return true;
    }
    const parentMenu = ModuleData['ParentMenus'].filter(item => item != null).find(parent => parent?.MenuName === moduleName);



    // If parent menu is not found or has no access, return false
    if (!parentMenu || parentMenu.Access === false) {
        return false;
    }
    if (ModuleData[moduleName].findIndex(item => item.Access == true) == -1) {
        return false
    }
    if (parentMenu.Access == true && menuName == " ") {
        return true;
    }
    // Find the menu item within the module
    const menu = ModuleData[moduleName].find(item => item?.MenuName === menuName);

    // Return true if the menu item doesn't exist
    if (!menu) {
        return true;
    }

    // Check user access if the menu item exists
    return menu.Access === true;
}
export function isSuperAdmin(accessStatus, userRole) {
  

    if (userRole === typesofUsers.docuverusSuperAdmin || userRole === typesofUsers.applicant)
        return true;


    else
        return accessStatus;
}
