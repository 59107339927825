import { useEffect,useState } from "react";
import {Controller,useController } from "react-hook-form";

const SelectInput = (props) => {
    const { field, fieldState } = useController({ name: props.source });
    const onChangeCallback=props?.onChangeCallback || function(){}
    
    const isPropsStatus = props?.placeholder == undefined ? "Select Application Status":props?.placeholder
   
    const statusName = (props?.placeholder == 1 || props?.placeholder == 13 ) ? {"Verified":13} : (props?.placeholder == 2 || props?.placeholder == 15 ) ? {"Unverifiable":15} : {"Further Documentation Required":14}
    const [statusKey, statusValue] = Object.entries(statusName)[0];
    console.log("typeof",(typeof(props?.placeholder)))
    
    return (
      <Controller
        control={props.control}
        {...field}
        render={() => {
          return (
            <div className={`emp-inputs ${props?.extraClass && props?.extraClass}`}>
              <label className="form-label">{props.label} {props.required && <span>&#42;</span>}</label>
              <select
                {...field}
                onChange={(e)=>{
                  field.onChange(e);
                  onChangeCallback();
                }}
                className={`form-select bg-white ${fieldState.error !== undefined && "red-inc-slect"
                  }`}
                aria-label="Default select example"
              >
               <option value={(typeof(props.placeholder)) == "number" ?statusValue: ""} selected>
                  {(typeof(props.placeholder)) == "number" ?statusKey: isPropsStatus}
                </option>
                {
                  typeof props.placeholder === "number" ? (props?.options
                    ?.filter((check) => check.name !== statusKey)
                    .map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    ))):( props?.options?.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.name}
                      </option>
                    )))
                }
               
                
              </select>
              <div
                className="invalid-feedback"
                style={{ display: "block", fontSize: "70%" }}
              >
                {fieldState.error !== undefined && fieldState.error.message}
              </div>
            </div>
          );
        }}
      />
    );
  };

export default SelectInput;