import React, { useEffect, useState } from "react";
import FloorPlanList from "./FloorPlanList";
import { Resource, useGetIdentity, usePermissions, useStore } from "react-admin";
import UnitsList from "./UnitsList";
import { useLocation } from "react-router-dom";
import { checkModuleAccess, isSuperAdmin } from "../../../Functions/permissions";
import { JSONTree } from "react-json-tree";

const FloorPlanAndUnits = () => {
  const { state } = useLocation();
  const [tab, setTab] = useStore("unitFloorTab");
  const { data: identity } = useGetIdentity();
  const { permissions } = usePermissions();

  useEffect(() => {
    setTab(0);
  }, [])


  let tabsData: any[] = [];
  if (isSuperAdmin(checkModuleAccess(permissions, "Settings", "Floor Plan"), identity?.userrole)) {
    tabsData?.push(
      {
        id: 1,
        tab: "Floor Plan",
        name: "get-floor",
        contextFromTab: "floorPlan",
      }
    )

  }
  if (isSuperAdmin(checkModuleAccess(permissions, "Settings", "Units"), identity?.userrole)) {
    tabsData?.push(
      {
        id: 2,
        tab: "Units",
        name: "get-unit",
        contextFromTab: "units",
      }
    )
  }

  useEffect(() => {
    setTab(0);
  }, [])
  console.log(tabsData, "tabsData1")
  return (
    <div>
      <div className="application-tabs">
        <div className="nav-flexes">
          <nav>
            <div
              className="nav nav-tabs app-nav-tabs"
              id="nav-tab"
              role="tablist"
              autoFocus={(state?.type === "unit" || state?.type === "floor")}

            >

              {tabsData?.map((item, index) => (
                <>
                 <button
                    key={item.id}
                    className={`nav-link ${tab === index ? "active" : ""}`}
                    id={`nav-name-tab0${index}`}
                    data-bs-toggle="tab"
                    data-bs-target={"#nav-name" + index}
                    type="button"
                    role="tab"
                    aria-controls={"nav-name" + index}
                    aria-selected={tab === index}
                    autoFocus={(state?.type === "unit" || state?.type === "floor")}
                    onClick={() => {
                      setTab(index);
                    }}
                  >
                    <div className="name1">
                      <h6>{item?.tab}</h6>
                    </div>
                  </button></>

              ))}
            </div>
          </nav>
        </div>
        <div className="tab-content app-nav-tabcontent" id="nav-tabContent">
          {tabsData?.map((item, index) => (
            <div
              key={index}
              className={`tab-pane fade show ${tab === index ? "active" : ""
                } name1-wrap`}
              id={`nav-name${index}`}
              role="tabpanel"
              aria-labelledby={`nav-name-tab${index}`}
              tabIndex={index}
            >
              {tab === index && (
                <Resource
                  name={item?.name}
                  list={
                    item?.contextFromTab === "units" ? (
                      <UnitsList />
                    ) : (
                      <FloorPlanList />
                    )
                  }
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FloorPlanAndUnits;
