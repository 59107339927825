import React, { createContext, useEffect } from 'react';
import  ListBaseAppInbox from './ListBaseAppInbox';
import { DatagridTable } from './DataGridTable';
import ActionsAdmin from './ActionsAdmin';
import MainFilter from '../SubscriberInbox/MainFilters';
import MainGlobalSearchFilters from './MainGlobalSearchFilters';
import ActionsGlobalSearch from './ActionsGlobalSearch';
import { DataGridTableGlobalSearch } from './DataGridTableGlobalSearch';



const GetAdminGlobalSearchList = () => {
    const defaultFilterValue = { app_invitation_type: "", dashboardOption: 1 }
    return (
        <ListBaseAppInbox enableDashboardGraph={false} actions={<ActionsGlobalSearch/>} filters={<MainGlobalSearchFilters/>} storeKey="GetAdminGlobalSearchList" sort={{ field: 'id', order: 'DESC' }} filterDefaultValues={defaultFilterValue} exporter={false} title={'Global Search'}>

            <DataGridTableGlobalSearch disableSortingAll={true} addressFieldHide={true} rentFieldHide={true} queuedFieldDisplay={false} typeField={true} riFieldHide={true} screeningReport={false} />
        </ListBaseAppInbox>
    )
};

export default GetAdminGlobalSearchList;