import React, { useEffect } from "react";
import save_icon from "../../../assets/images/save_icon.svg";
import {
  useCreate,
  useGetList,
  useNotify,
  useRefresh,
  useStore,
} from "react-admin";
import {useNavigate, useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import ToggleButtons from "../../common/InputComponents/ToggleButtons";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, Button } from "@mui/material";
const ApplicationSettingsDocument = () => {
  const documenttoggleButton = [
    {
      value: "Hide",
      label: "Hide",
      icon: <VisibilityOffOutlinedIcon />,
      borderColor: "#FF0000",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#46a96f1a",
      color: "#FF0000",
    },
    {
      value: "Optional",
      label: "Optional",
      icon: <RemoveRedEyeOutlinedIcon />,
      borderColor: "#8A3324",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#90909033",
      color: "#8A3324",
    },

    {
      value: "Required",
      label: "Required",
      icon: <DoneAllIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  
  const methods = useForm();
  const {id:profileId}= useParams();
  const [docuverusAccordion, setDocuverusAccordion] = React.useState(false);
  const navigate = useNavigate();
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const { data: getdocumentsectionwise, isLoading: loadingdocument } =
    useGetList("getdocumentsectionwise", {
      filter: {
        SubscriberID: switchSubscriberObj?.value,
        ApplicationProfileID: profileId,
      },
    });
  // console.log("Document",getdocumentsectionwise)
  useEffect(() => {
    const formData = getdocumentsectionwise?.reduce((acc, item) => {
      acc[item.ID] = item.FinalValue; // Set the initial value from FinalValue
      return acc;
    }, {});

    methods.reset(formData);
  }, [getdocumentsectionwise, methods]);

  
  const [create, { isLoading, error }] = useCreate();
  const notify = useNotify();

  const refresh = useRefresh();

  // form submit handler
  const onSubmit = (value, e) => {
    e.preventDefault();
    const addData = getdocumentsectionwise?.map((item) => {
      if (value[item.id]) {
        // Return a new object with the updated FinalValue
        return {
          ...item, // Spread the current item properties
          FinalValue: value[item.id], // Update the FinalValue
        };
      }
      return item; // Return the original item if no update is needed
    });
 

    
    create(
      `updateprofiledocumentsettigs`,
      { data:{Questions: addData} },
      {
        onSuccess: (data) => {
          notify(
            `Document settings updated successfully.`,

            {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
          refresh();
          methods?.reset();
          // navigate(-1);
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
  return (
    <>
      <div className="tab-pane fade show" id="tab-documents">
        <div className="auth-form mr-t10">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="info-toggle">
                <div
                  className="toggle-heads"
                  onClick={(event) => {
                    event.preventDefault();
                    setDocuverusAccordion((v) => !v);
                  }}
                >
                  {/* <div className="row align-items-center justify-content-between ">
                    <div className="col">
                      <div className="d-flex align-items-center">
                        <b className="ms-3 me-2" style={{ fontSize: "14px" }}>
                          Document Settings
                        </b>
                      </div>
                    </div>
                  </div> */}

            
                    <div
                      className="card card-body w-100"
                      style={{ paddingTop: 0 }}
                    >
                      <div className="row">
                        <div className="container">
                          {/* .map(item=>({source:item.ID})) */}
                          <div className="row" key={`row`}>
                            {/* Create a row and fill it with the next 4 items */}
                            {!!getdocumentsectionwise &&
                              getdocumentsectionwise
                                ?.map((item) => ({
                                  ...item,
                                  id: item.ID,
                                  [item.ID]: "Optional",
                                }))
                                .map((doc, i) => (
                                  <div
                                    className="col-md-4 col-sm-6 mr-b10 mr-t20"
                                    key={doc.id}
                                  >
                                    {/* <p
                                      className="ms-3 me-2"
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "600",
                                        fontFamily: "Open Sans",
                                      }}
                                    >
                                      {doc.DisplayName}
                                    </p> */}

                                    <ToggleButtons
                                      toggleButton={documenttoggleButton}
                                      label={doc.DisplayName} // Use the 'DisplayName' property
                                      labelClass={"lable-title"}
                                      source={doc.ID}
                                      onClickHandler={(event) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  </div>
                                ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
              <Box
                sx={{
                  height: "60px",
                  background: "#F5FDFF",
                  boxShadow: "0px -1px 12px 0px rgba(0, 0, 0, 0.12)",
                }}
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
                className="sticky-bottom mt-4"
              >
                <Box>
                  <Button
                    sx={{ mx: 1 }}
                    onClick={() => {
                      methods?.reset();
                      navigate(-1);
                    }}
                    variant="outlined"
                    color="secondary"
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    startIcon={<img src={save_icon} alt="save icon" />}
                    sx={{ mx: 1 }}
                    variant="contained"
                    type="submit"
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
};
export default ApplicationSettingsDocument;
