import React, { Fragment, useState, useEffect } from "react";
import MarkInactiveModal from "./MarkInactiveModal";
import MarkActiveModal from "./MarkActiveModal";
import FilterModal from "./FilterModal";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Datagrid,
  FunctionField,
  ListBase,
  Pagination,
  TextField,
  useListContext,
  useStore,
  useGetList,
} from "react-admin";
import {
  Grid,
  Typography,
  TextField as MuiTextField,
  Box,
  Link,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
  Stack,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import { ATS_API_URL } from "../../../config";
import InputFileImport from "../../common/InputFileImport";
import DownloadSampleButton from "../../common/DownloadSampleButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { format } from "date-fns";
import FilterChips from "../../Ats/SuperAdminInbox/FilterChips";


const MyListToolBar = ({ title }) => {
  const {
    displayedFilters,
    filterValues,
    setFilters,
    hideFilter,
    sort    
  } = useListContext();
  const [applicantType, setApplicantType] = useState("All");

  const navigate = useNavigate();
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const [searchValue, setSearchValue] = useState('');
  
  // Debounce the search function
  const debouncedSearch = debounce((value) => {
    // Perform your search logic here
    
  }, 500); // 500ms debounce delay

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value)
    setFilters({ ...filterValues, q_email: value }, []);
  };

  useEffect(() => {
    console.log("filterValues.q_email1",filterValues.q_email)
    if (filterValues.q_email == undefined) {
      console.log("filterValues.q_email2",filterValues.q_email)
      setSearchValue(''); 
    }else {
      console.log("filterValues.q_email3",filterValues.q_email)
      setSearchValue(filterValues.q_email);
    }
  }, [filterValues.q_email]);


  const handleExportData = () => {
    const tokenString = localStorage.getItem("auth");
    let tokenSecret = "";
    if (tokenString) {
      try {
        const token = JSON.parse(tokenString);
        tokenSecret = token?.data?.tokendata || "";
      } catch (error) {
        console.error("Error parsing token:", error);
      }
    }

    const currentTimestamp = Date.now();

    const url = `${ATS_API_URL}/subscriber/exportEmailSettings?subscriberid=${
      switchSubscriberObj?.value
    }&_sort=${sort?.field || ""}&_order=${sort?.order || ""}`;

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `${tokenSecret}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `emailSettings-${currentTimestamp}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => console.error("Error:", error));
  };

  return (
    <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
      {/* Left side with title */}
      <Grid item xs={12} md={6}>
        <div className="app-option-btn" id="a-oprtions">
          <label htmlFor="c4">
            <input
              type="radio"
              onChange={() => {
                setFilters({ ...filterValues, templateApplicableFor: "" }, []);
                console.log(
                  "filterValues.templateApplicableFor",
                  filterValues.templateApplicableFor
                );
              }}
              checked={filterValues.templateApplicableFor == undefined}
              name="a-oprtions"
              id="c4"
            />
            <span>All</span>
          </label>

          <label htmlFor="c1">
            <input
              type="radio"
              onChange={() => {
                setFilters(
                  { ...filterValues, templateApplicableFor: "Applicant" },
                  []
                );
              }}
              checked={filterValues.templateApplicableFor == "Applicant"}
              name="a-oprtions"
              id="c1"
            />
            <span>APPLICANT</span>
          </label>

          <label htmlFor="c2">
            <input
              type="radio"
              onChange={() => {
                setFilters(
                  { ...filterValues, templateApplicableFor: "Subscriber" },
                  []
                );
              }}
              checked={filterValues.templateApplicableFor == "Subscriber"}
              name="a-oprtions"
              id="c2"
            />
            <span>SUBSCRIBER</span>
          </label>

          <label htmlFor="c3">
            <input
              type="radio"
              onChange={() => {
                setFilters(
                  { ...filterValues, templateApplicableFor: "Third Party" },
                  []
                );
              }}
              checked={filterValues.templateApplicableFor == "Third Party"}
              name="a-oprtions"
              id="c3"
            />
            <span>THIRD PARTY</span>
          </label>
        </div>
      </Grid>

      {/* Right side with search input, filter button, and add button */}
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        <div className="group-btn">
          {/* Search Input */}
          <MuiTextField
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            
            value={searchValue}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
          />
          <FilterModal />
          <button
            onClick={handleExportData}
            type="button"
            className="g-line-btn"
            title="Export all data"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_14375_6193)">
                <path
                  d="M8.66667 8.4987H10.6667L8 11.1654L5.33333 8.4987H7.33333V5.83203H8.66667V8.4987ZM10 3.16536H3.33333V13.832H12.6667V5.83203H10V3.16536ZM2 2.49336C2 2.12803 2.298 1.83203 2.666 1.83203H10.6667L14 5.16536V14.494C14.0006 14.5816 13.984 14.6684 13.951 14.7495C13.9181 14.8306 13.8695 14.9045 13.808 14.9668C13.7466 15.0291 13.6734 15.0788 13.5928 15.1128C13.5121 15.1469 13.4255 15.1648 13.338 15.1654H2.662C2.48692 15.1641 2.31934 15.0941 2.19548 14.9704C2.07161 14.8466 2.0014 14.6791 2 14.504V2.49336Z"
                  fill="#2DC3E8"
                />
              </g>
              <defs>
                <clipPath id="clip0_14375_6193">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        </div>
      </Grid>
    </Grid>
  );
};

const MyList = ({ children, toolBar, ...props }) => {
  return (
    <ListBase
      {...props}
      perPage={100} // Number of items per page
    >
      {toolBar}
      <FilterChips excludeKeys={['templateApplicableFor']} />
      {children}
      <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
      <br />
      <br />
      <br />
      <br />
    </ListBase>
  );
};

const ActiveBtn = () => {
  const myStyles = {
    display: "flex",
    width: "79px",
    padding: "8px 11px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    background: "#E6FDF0",
  };
  const myTextStyles = {
    color: "var(--Success, #46A96F)",
    // textAlign: 'center',
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };
  return (
    <div style={myStyles}>
      <div style={myTextStyles}>Active</div>
    </div>
  );
};
const DeActiveBtn = () => {
  const myStyles = {
    display: "flex",
    width: "79px",
    padding: "8px 11px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    background: "#FFF1F1",
  };
  const myTextStyles = {
    color: "var(--Success, #FF4848)",
    // textAlign: 'center',
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };
  return (
    <div style={myStyles}>
      <div style={myTextStyles}>Inactive</div>
    </div>
  );
};

const EmailSettings = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedRecords, setSelectedRecords] = useState<number[]>([]);
  const [headerCheckbox, setHeaderCheckbox] = useState(false);
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  // const {
  //   data: getEmailSettingsDetails,
  //   isLoading: loadingEmailSettingsDetails,
  // } = useGetList(
  //   "email-template-list",
  //   {
  //     filter: {
  //       subscriberID: switchSubscriberObj?.value,
  //     },
  //   },
  //   {
  //     enabled: switchSubscriberObj?.value && id ? true : false,
  //     refetchOnWindowFocus: false,
  //   }
  // );
 // console.log(getEmailSettingsDetails, "getEmailSettingsDetails");

  // const SelectAllHeader = ({ setSelectedRecords }) => {
  //   const { data } = useListContext();
  //   console.log(data, "dataemail");
  //   const [headerCheckbox, setHeaderCheckbox] = useState(false);

  //   const handleHeaderCheckboxChange = (event) => {
  //     const isChecked = event.target.checked;
  //     setHeaderCheckbox(isChecked);
  //     // Select/Deselect all records
  //     const allRecordIds = data.map((record) => record.id);
  //     if (isChecked) {
  //       setSelectedRecords(allRecordIds);
  //     } else {
  //       setSelectedRecords([]);
  //     }
  //   };

  //   return (
  //     <Checkbox
  //       checked={headerCheckbox}
  //       onChange={handleHeaderCheckboxChange}
  //     />
  //   );
  // };

  // const handleCheckboxChange = (recordId: any) => {
  //   console.log("recordId", recordId);
  //   setSelectedRecords((prevSelectedRecords) =>
  //     prevSelectedRecords.includes(recordId)
  //       ? prevSelectedRecords.filter((id) => id !== recordId)
  //       : [...prevSelectedRecords, recordId]
  //   );
  // };

  // const anyCheckboxChecked = selectedRecords.length > 0;

  // useEffect(() => {
  //   if (anyCheckboxChecked) {
  //     console.log("At least one checkbox is checked", selectedRecords);
  //   } else {
  //     console.log("No checkboxes are checked");
  //   }
  // }, [selectedRecords.length]);

  return (
    <MyList
      toolBar={<MyListToolBar title={"Email Settings"} />}
      sort={{ field: "id", order: "DESC" }}
      filter={{ subscriberID: switchSubscriberObj?.value }}
      resource="email-template-list"
      // filter={{ subscriberID: 10145 }}
    >
      {/* {
             selectedRecords.length > 0 &&(
            <>
            <Box sx={{ padding: 2,  display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
            <Stack direction="row" spacing={2}>
            <MarkActiveModal status="InActive" selectedIds={selectedRecords}/>
            <MarkActiveModal status="Active" selectedIds={selectedRecords}/>
          </Stack>
          </Box>
           </>
          )
          } */}

      <UserSetupDataGrid />
    </MyList>
  );
};

const UserSetupDataGrid = () => {
  const navigate = useNavigate();
  const {
    displayedFilters,
    filterValues,
    setFilters,
    hideFilter,
    sort,
  } = useListContext();
  const [selectedRecords, setSelectedRecords] = useState<number[]>([]);
  const [headerCheckbox, setHeaderCheckbox] = useState(false);
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const [selectedAllRecords, setSelectedAllRecords] = useState<number[]>([]);
  const [selectedApplicantRecords, setSelectedApplicantRecords] = useState<
    number[]
  >([]);
  const [selectedSubscriberRecords, setSelectedSubscriberRecords] = useState<
    number[]
  >([]);
  const [selectedThirdPartyRecords, setSelectedThirdPartyRecords] = useState<
    number[]
  >([]);

  const SelectAllHeader = ({ setSelectedRecords }) => {
    const { data } = useListContext();
    const [headerCheckboxAll, setHeaderCheckboxAll] = useState(false);
    const [headerCheckboxApplicant, setHeaderCheckboxApplicant] = useState(false);
    const [headerCheckboxSub, setHeaderCheckboxSub] = useState(false);
    const [headerCheckboxThird, setHeaderCheckboxThird] = useState(false);

   

    const handleHeaderCheckboxChange = (event) => {
      const isChecked = event.target.checked;


      console.log("filterValues.templateApplicableFor",filterValues.templateApplicableFor)
      console.log("isChecked",isChecked)

      if (filterValues.templateApplicableFor === undefined) {
        // Active Tab
        setHeaderCheckboxAll(isChecked);
        const allRecordIds = data.map((record) => record.id);
        if (isChecked) {
          setSelectedAllRecords(allRecordIds);
        } else {
          setSelectedAllRecords([]);
        }
      } else if (filterValues.templateApplicableFor === "Applicant") {
        // All Tab
        setHeaderCheckboxApplicant(isChecked);
        const allRecordIds = data.map((record) => record.id);
        if (isChecked) {
          setSelectedApplicantRecords(allRecordIds);
        } else {
          setSelectedApplicantRecords([]);
        }
      } else if (filterValues.templateApplicableFor === "Subscriber") {
        // All Tab
        setHeaderCheckboxSub(isChecked);
        const allRecordIds = data.map((record) => record.id);
        if (isChecked) {
          setSelectedSubscriberRecords(allRecordIds);
        } else {
          setSelectedSubscriberRecords([]);
        }
      } else if (filterValues.templateApplicableFor === "Third Party") {
        // All Tab
        setHeaderCheckboxThird(isChecked);
        const allRecordIds = data.map((record) => record.id);
        if (isChecked) {
          setSelectedThirdPartyRecords(allRecordIds);
        } else {
          setSelectedThirdPartyRecords([]);
        }
      }
      // You can add similar logic for the Inactive tab if needed.
    };

   
    return (
      <Checkbox
        checked={filterValues.templateApplicableFor == undefined ? headerCheckboxAll : filterValues.templateApplicableFor == "Applicant" ? headerCheckboxApplicant : filterValues.templateApplicableFor == "Subscriber" ? headerCheckboxSub:headerCheckboxThird}
        onChange={handleHeaderCheckboxChange}
      />
    );
  };

  const handleCheckboxChange = (recordId: any) => {
    if (filterValues.templateApplicableFor === undefined) {
      // Active Tab
      setSelectedAllRecords((prevSelectedRecords) =>
        prevSelectedRecords.includes(recordId)
          ? prevSelectedRecords.filter((id) => id !== recordId)
          : [...prevSelectedRecords, recordId]
      );
    } else if (filterValues.templateApplicableFor === "Applicant") {
      // All Tab
      setSelectedApplicantRecords((prevSelectedRecords) =>
        prevSelectedRecords.includes(recordId)
          ? prevSelectedRecords.filter((id) => id !== recordId)
          : [...prevSelectedRecords, recordId]
      );
    } else if (filterValues.templateApplicableFor === "Subscriber") {
      // All Tab
      setSelectedSubscriberRecords((prevSelectedRecords) =>
        prevSelectedRecords.includes(recordId)
          ? prevSelectedRecords.filter((id) => id !== recordId)
          : [...prevSelectedRecords, recordId]
      );
    } else if (filterValues.templateApplicableFor === "Third Party") {
      // All Tab
      setSelectedThirdPartyRecords((prevSelectedRecords) =>
        prevSelectedRecords.includes(recordId)
          ? prevSelectedRecords.filter((id) => id !== recordId)
          : [...prevSelectedRecords, recordId]
      );
    }
    // You can add similar logic for the Inactive tab if needed.
  };

  const anyCheckboxChecked = selectedRecords.length > 0;

  useEffect(() => {
    if (anyCheckboxChecked) {
      console.log("At least one checkbox is checked", selectedRecords);
    } else {
      console.log("No checkboxes are checked");
    }
  }, [selectedRecords.length]);

  return (
    <>
      <Box>
        {filterValues.templateApplicableFor === undefined &&
          selectedAllRecords.length > 0 && (
            <Box
              sx={{
                padding: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Stack direction="row" spacing={2}>
                <MarkActiveModal
                  status="Inactive"
                  selectedIds={selectedAllRecords}
                  setSelectedIds={setSelectedAllRecords}
                />
                <MarkActiveModal
                  status="Active"
                  selectedIds={selectedAllRecords}
                  setSelectedIds={setSelectedAllRecords}
                />
              </Stack>
            </Box>
          )}
        {filterValues.templateApplicableFor === "Applicant" &&
          selectedApplicantRecords.length > 0 && (
            <Box
              sx={{
                padding: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Stack direction="row" spacing={2}>
                <MarkActiveModal
                  status="Inactive"
                  selectedIds={selectedApplicantRecords}
                  setSelectedIds={setSelectedApplicantRecords}
                />
                <MarkActiveModal
                  status="Active"
                  selectedIds={selectedApplicantRecords}
                  setSelectedIds={setSelectedApplicantRecords}
                />
              </Stack>
            </Box>
          )}
        {filterValues.templateApplicableFor === "Subscriber" &&
          selectedSubscriberRecords.length > 0 && (
            <Box
              sx={{
                padding: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Stack direction="row" spacing={2}>
                <MarkActiveModal
                  status="Inactive"
                  selectedIds={selectedSubscriberRecords}
                  setSelectedIds={setSelectedSubscriberRecords}
                />
                <MarkActiveModal
                  status="Active"
                  selectedIds={selectedSubscriberRecords}
                  setSelectedIds={setSelectedSubscriberRecords}
                />
              </Stack>
            </Box>
          )}
        {filterValues.templateApplicableFor === "Third Party" &&
          selectedThirdPartyRecords.length > 0 && (
            <Box
              sx={{
                padding: 2,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 2,
              }}
            >
              <Stack direction="row" spacing={2}>
                <MarkActiveModal
                  status="Inactive"
                  selectedIds={selectedThirdPartyRecords}
                  setSelectedIds={setSelectedThirdPartyRecords}
                />
                <MarkActiveModal
                  status="Active"
                  selectedIds={selectedThirdPartyRecords}
                  setSelectedIds={setSelectedThirdPartyRecords}
                />
              </Stack>
            </Box>
          )}

        <Datagrid
          sx={{
            "& .RaDatagrid-headerCell": {
              fontWeight: "bold",
              fontSize: 12,
              // textAlign: 'center',
            },
            "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
            // "& .MuiTableBody-root.datagrid-body.RaDatagrid-tbody tr td.MuiTableCell-root.MuiTableCell-body:nth-child(4), & .MuiTableBody-root.datagrid-body.RaDatagrid-tbody tr td.MuiTableCell-root.MuiTableCell-body:nth-child(3)":
            //     { padding: "8px" },
          }}
          bulkActionButtons={false}
          isRowSelectable={(record) => true}
        >
          <FunctionField
            render={(record) => (
              <Checkbox
                // checked={selectedRecords.includes(record.id)}
                // onChange={() => handleCheckboxChange(record.id)}
                checked={
                  filterValues.templateApplicableFor === undefined
                    ? selectedAllRecords.includes(record.id)
                    : filterValues.templateApplicableFor === "Applicant"
                    ? selectedApplicantRecords.includes(record.id)
                    : filterValues.templateApplicableFor === "Subscriber"
                    ? selectedSubscriberRecords.includes(record.id)
                    : filterValues.templateApplicableFor === "Third Party"
                    ? selectedThirdPartyRecords.includes(record.id)
                    : false // Handle other tabs similarly
                }
                onChange={() => handleCheckboxChange(record.id)}
              />
            )}
            label={<SelectAllHeader setSelectedRecords={setSelectedRecords} />}
            sx={{ width: "100px" }}
          />
          <TextField
            source="TemplateName"
            label={"Template Task"}
            sx={{
              width: "150px",
              // whiteSpace: "normal",
              // wordBreak: "break-word",
              // overflow: "hidden", // Prevents overflow
              // textOverflow: "ellipsis", // Optional, for adding ellipsis if needed
              // display: "inline-block", // Ensures the text wraps properly within the container
            }}
          />
          <TextField
            source="Subject"
            label={"Subject"}
            sx={{ width: "150px" }}
          />
          <FunctionField
            source="CreatedOn"
            sx={{ width: "150px" }}
            label={"Date Added"}
            render={(record) =>
              record.CreatedOn
                ? format(new Date(record.CreatedOn), "MM/dd/yyyy")
                : ""
            }
          />
          {/* <FunctionField
                      sortByOrder="ASC"
                      sortBy={"Status"}
                      label={"Status"}
                      render={(record) => (
                          <Box display={"flex"} sx={{ width: "80px" }}>
                              {record.Active ? <ActiveBtn /> : <DeActiveBtn />}
                             
                              <IconButton
                                  aria-label="view details"
                                  color="primary"
                                  sx={{
                                      py: 0,
                                  }}
                                  onClick={() =>
                                      navigate(
                                          `/Ats/getEmailSettings/admintemplateDetails/${record?.id}`,
                                          { state: { isAdding: false, thirdPartyAppId: 1 } }
                                      )
                                  }
                                  className="show_on_hover"
                              >
                                  <ArrowForwardIcon />
                              </IconButton>
                          </Box>
                      )}
                      sx={{ width: "150px" }}
                 
                  /> */}

          <FunctionField
            sortByOrder="DESC"
            sortBy="Status"
            label={"Status"}
            render={(record) =>
              record.Active ? <ActiveBtn /> : <DeActiveBtn />
            }
          />
          <FunctionField
            label={""}
            render={(record) => (
              // <Box display={"flex"}>
              <Box display={"flex"} sx={{ width: "20px" }}>
                {/* <CopyLinkHoverDropdown record={record} /> */}
                <IconButton
                  aria-label="view details"
                  color="primary"
                  sx={{
                    py: 0,
                  }}
                  onClick={() =>
                    navigate(
                      `/ats/settings/emailSettings/templateDetails/${record.id}`,
                      { state: { isAdding: false, thirdPartyAppId: 1 } }
                    )
                  }
                  className="show_on_hover"
                >
                  <ArrowForwardIcon />
                </IconButton>
              </Box>
            )}
          />

          {/* Add more fields as needed */}
        </Datagrid>
      </Box>
    </>
  );
};

export default EmailSettings;
