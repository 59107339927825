import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import NumberFormat from "react-number-format";
import { NumericFormat } from "react-number-format";
import { Controller, useFormContext, useController } from "react-hook-form";

const InputCurrencyField = (props) => {
  const { labelClass = "", requiredClass = {} } = props;

  // const { field, fieldState } = useController({ name: props.source });
  //
  const onChangeCallback = props?.handleOnChange || function () {};
  const { control, setValue, getValues } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: props.source,
    control,
  });

  const prefix = " $ ";
  // const [value, setValue] = useState<any>(0);

  // const handleChange = (e) => {
  //   e.preventDefault();
  //   const { value = "" } = e.target;
  //   // const parsedValue = value.replace(/[^\d.]/gi, "");
  //   // setValue(props.source,parsedValue);
  //   parseFloat(value).toFixed(2)
  //   onChangeCallback()
  // };

  // const handleOnBlur = () =>{
  //   setValue(props.source,parseFloat(field.value).toFixed(2)); }

  // React.useEffect(() => {
  //   //
  //   setValue(props.source,Number(field.value));
  // },[])
  const handleValueChange = (valueObj) => {
    let floatValue;
    if (props?.removeDefaultZeroValue) {
      floatValue = valueObj.floatValue; // Added this based on props is true to set 0 value as well.
    } else {
      floatValue = valueObj.floatValue || "";
    }
    if (getValues(props.source) !== valueObj.floatValue) {
       floatValue=(floatValue !== "" && field.value  % 1 !== 0)?parseFloat(floatValue).toFixed(2):floatValue
      setValue(props.source, floatValue);
      onChangeCallback();
    }
  };

  return (
    <>
      <div className="emp-inputs">
        <label
          htmlFor={props.source}
          className={`form-label ${error && "text-danger"} ${labelClass}`}
        >
          {props.label} {props.required && <span className="red">*</span>}
        </label>
        <NumericFormat
          value={field.value}
          className={`form-control cus-input-control ${
            props.required && field.value<0 && "is-warning"
          } ${error && "is-invalid"}`}
          disabled={props.disabled || false}
          prefix={prefix}
          placeholder="$ 0.0"
          allowNegative={props?.allowNegative?(props?.allowNegative):false}
          onValueChange={handleValueChange}
          decimalScale={2}
          thousandSeparator=","
        />
        <div className="invalid-feedback" style={requiredClass}>
          {error && error.message}
        </div>
      </div>
    </>
  );
};

export default InputCurrencyField;
