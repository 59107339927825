import React,{useState} from "react";
import InputMask from "react-input-mask";
import { useFormContext, useController } from "react-hook-form";

function InputMaskField(props) {
  
  const required = props.notRequired === undefined ? true : !props.notRequired;
  // const { name } = props.register;
  const { control, setValue, watch } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: props?.source,
    control,
  });
  const [mask, setMask] = useState(null);
  return (
    <>
      <label
        htmlFor={""}
        className={`form-label lable-title ${
          error !== undefined && "text-danger"
        }`}
      >
        {props.label} {required && <span className="red">*</span>}
      </label>

      <InputMask
        mask={mask}
        max={props?.maxLength ? props.maxLength : ""}
        type={props?.type ? props.type : "text"}
        disabled={props.disable === true ?? "disabled"}
        placeholder={props.placeholder || `${props.label}`}
        className={`form-control cus-input-control ${
          required && !field.value && "is-warning"
        } ${error !== undefined && "is-invalid"}`}
        {...field}
        onBlur={(e) => {
          setMask(null);
          const dependentFun = props.triggerDependent || null;
          if (dependentFun) {
            if (!e.target.value === "") {
              dependentFun(e);
            }
          }
          field.onBlur(e);
        }}
        id={props.source}
        onChange={(e) => {
          field.onChange(e);
          props?.handleOnChange();
          setMask(props.mask);
        }}
      />
      <div className="invalid-feedback" style={{ display: "block", fontSize: "70%" }}>
        {error !== undefined && error.message}
      </div>
    </>
  );
}

export default InputMaskField;
