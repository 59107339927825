import React, { Fragment } from "react";
import CheckIcon from "@mui/icons-material/Check";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button } from "@mui/material";
import { useCreate, useNotify, useRefresh } from "react-admin";
import { useParams } from "react-router";
import { CircularProgress } from "@mui/material";
import { JSONTree } from "react-json-tree";

const MFAPopup = ({ open, setOpen, setValue, status }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const { id } = useParams();
  const [create, { isLoading, error }] = useCreate();

  // const subscriberStatusHandler = () => {
  //   const formData = {
  //     subscriberID: id,
  //     status: status,
  //   };
  //   create(
  //     "subscriber-status",
  //     { data: formData },
  //     {
  //       onSuccess: (data) => {
  //         notify(`Subscriber Status updated successfully.`, {
  //           type: "success",
  //           anchorOrigin: { vertical: "top", horizontal: "right" },
  //         });
  //         refresh();
  //         setValue("twofactorenabled", status == 1 ? 1 : 0);
  //         setOpen(false);
  //       },
  //       onError: (e: any) =>
  //         notify(`Operation fail:${e.message}`, {
  //           type: "error",
  //           anchorOrigin: { vertical: "top", horizontal: "right" },
  //         }),
  //     }
  //   );
  // };

  const dialogTitle = (
    <DialogTitle color="error" id="alert-dialog-title">
      <InfoIcon /> Are you sure?
    </DialogTitle>
  );

  const dialogContent = (
    <DialogContent>
      <p className="mr-b20">
        Are you sure you want to {status == 1 ? "activate" : "deactivate"} MFA for the
        user?
      </p>
      {isLoading ? (
        <div className="d-flex justify-content-end align-items-center mw-100">
          <CircularProgress size="20px" />
          <h5
            style={{
              fontSize: "12px",
              color: "#303030",
              fontFamily: "OpenSans-Regular",
            }}
          >
            &nbsp; &nbsp; Please wait...
          </h5>
        </div>
      ) : (
        <Box display={"flex"} justifyContent={"end"}>
          <Button
            sx={{ mx: 1 }}
            onClick={() => {
              setValue("twofactorenabled", status == 0 ? 1 : 0);
              setOpen(false);
            }}
            variant="outlined"
            color="secondary"
            type="button"
            disabled={isLoading}
          >
            Cancel
          </Button>
         
          <Button
            startIcon={<CheckIcon />}
            sx={{ mx: 1 }}
            variant="contained"
            type="submit"
            onClick={() => {
            setValue("twofactorenabled", status == 0 ? 0 : 1);
              setOpen(false);
            }}
            disabled={isLoading}
          >
            {status == 1 ? "activate" : "deactivate"} MFA
          </Button>
        </Box>
      )}
    </DialogContent>
  );
  return (
    <Fragment>
      <Dialog
        fullWidth
        open={open}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            // Prevent closing or perform custom logic here
            return false;
          }
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dialogTitle && dialogTitle}
        {dialogContent && dialogContent}
      </Dialog>
    </Fragment>
  );
};

export default MFAPopup;
