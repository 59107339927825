import React, { useState, useEffect } from 'react';
import { Tabs, Tab, Box, Typography } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CircularProgress } from "@mui/material";
import {
    useGetList, Button, useStore, useUpdate, useNotify,
    useRefresh, useGetIdentity
} from 'react-admin';
import { JSONTree } from 'react-json-tree';
// import ToggleButtons from '../../componets/common/InputComponents/ToggleButtons';import ToggleButtons from '../../common/InputComponents/ToggleButtons';
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import ToggleButtons from '../../common/InputComponents/ToggleButtons';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Button as MUIButton } from "@mui/material";
import { method } from 'lodash';
import InputField from '../../common/InputComponents/InputField';
import InputField2 from '../../common/InputComponents/InputField2';
import { ATS_API_URL } from '../../../config';

const ThirdPartyUserPermission = () => {
    const [value, setValue] = useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const methods = useForm();
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    const navigate = useNavigate();
    const {
        data: getrolePermission,
        isLoading: loadingrole,
    } = useGetList(
        "get-role-permission-third-party",
        {
            filter: {
                subscriberid: switchSubscriberObj?.value,


            },
        },
    );


    useEffect(() => {
        if (getrolePermission?.[0]?.RoleName) {
            setSelectedTab(getrolePermission[0].RoleName);
        }
    }, [getrolePermission]);
    const [selectedTab, setSelectedTab] = useState();
    console.log(getrolePermission?.[0]?.RoleName, "selectedTab");
    const [isLoadingExport, setIsLoading] = useState(false);

    return (

        <section className="main-sec">

            <div className="home_content">
                <div className='d-flex justify-content-between'
                    style={{ fontWeight: "600", fontSize: "18px" }}
                >
                    <p>
                    Landlord/Third Party Permissions

                    </p>


                </div>
                <div className="application-tabs">
                    <div className="nav-flexes" style={{ marginTop: "40px", fontSize: "12px", fontWeight: "700", fontFamily: "Open Sans", lineHeight: "18.5px" }}>
                        <nav>
                            <div
                                className="nav nav-tabs app-nav-tabs"
                                id="nav-tab"
                                role="tablist"
                            >

                                {getrolePermission && getrolePermission?.length > 0 && getrolePermission?.map((item, index) => {

                                    return (
                                        <button
                                            type="button"
                                            className={`nav-link ${index === 0 ? "active" : ""}`}
                                            data-bs-toggle="tab"
                                            data-bs-target={`#tab-${item.ID}`}
                                            onClick={() => setSelectedTab(item.RoleName)} // Set role name when clicked
                                        >
                                            {item.RoleName}
                                        </button>)
                                })}
                            </div>
                        </nav>
                    </div>
                    <div className="tab-content app-nav-tabcontent " id="nav-tabContent" style={{ marginTop: "10px", backgroundColor: "#f8f8f8" }} >
                        {getrolePermission && getrolePermission.length > 0 && getrolePermission.map((item, index) => {

                            return (

                                <div className={`tab-pane fade show ${index === 0 ? "active" : ""}`} id={`tab-${item.ID}`}>


                                    <TabsBody item={item} index={index} />

                                </div>


                            )
                        })}

                    </div>

                </div>
            </div>
        </section>

    );
};

const toggleButton = [
    {
        value: false,
        label: "Denied",
        icon: <ClearIcon />,
        borderColor: "#FF4848",
        backgroundColor: "#FFF1F1",
        boxShadowColor: "#FFF1F1",
        color: "#FF4848",
    },
    {
        value: true,
        label: "Access",
        icon: <CheckIcon />,
        borderColor: "#46A96F",
        backgroundColor: "#EBFFF3",
        boxShadowColor: "#46a96f1a",
        color: "#46A96F",
    },
];
const TabsBody = ({ item, index }) => {
    const { rolename } = useParams();
    const methods = useForm();
    const navigate = useNavigate();
    const [expandIdSection, setExpandIdSection] = React.useState(true);
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    const { data: getpermissions } = useGetList('getpermissions', { filter: { 'subscriberid': switchSubscriberObj?.value, 'role': item.RoleName } });
    const collasableData = getpermissions?.filter(item => item.ParentMenuName == null);
    const childCollasableData = getpermissions?.filter(item => item.ParentMenuName != null);

    // const allAccessFalse = childCollasableData?.every(item => item.Access === false);

    // let collasableData = getpermissions?.filter(item => item.ParentMenuName == null);

    // If all child menus have Access false, set the parent menu's Access to false
    // if (allAccessFalse) {
    //     const collasableData1 = collasableData?.map(parentItem => {
    //         return {
    //             ...parentItem?.MenuName,
    //             Access: false // Set parent menu's Access to false
    //         };

    //     });
    //     console.log(collasableData1,"1111")
    // }

    const notify = useNotify();
    const refresh = useRefresh();
    const [update, { data, isLoading, error }] = useUpdate();
    const {
        data: getrolePermission,
        isLoading: loadingrole,
    } = useGetList(
        "get-role-permission",
        {
            filter: {
                subscriberid: switchSubscriberObj?.value,


            },
        },
    );




    const onSubmit = (value) => {
        console.log(value, "beforefunction")

        const addData = {
            subscriberid: switchSubscriberObj?.value,
            rolename: item?.RoleName,
            applicantrole: item?.ApplicantRole === true ? 1 : 0,
            active: item?.Active === true ? 1 : 0,
            menulist:
                // Object.keys(value).map(key => ({
                //     [key]: value[key] === true // Or keep it as value[key] if you want the exact boolean
                // }))
                Object.keys(value).map(key => {
                    if (key === "applicantrole") {
                        return null; // Skip "applicantrole"
                    }
                    return { [key]: value[key] === true }; // Keep true/false mapping
                }).filter(item => item !== null && !item?.hasOwnProperty('undefined'))

        };
        console.log(addData, "afterfunction")
        update(
            `update-permission`,
            { data: addData },
            {
                onSuccess: (data) => {
                    notify(

                        `Permission Updated Successfully.`,

                        {
                            type: "success",
                            anchorOrigin: { vertical: "top", horizontal: "right" },
                        }
                    );
                    refresh();
                    // methods?.reset();
                    // navigate(-1);
                },
                onError: (e: any) =>
                    notify(`Operation fail:${e.message}`, {
                        type: "error",
                        anchorOrigin: { vertical: "top", horizontal: "right" },
                    }),
            }
        );
    };
    useEffect(() => {
        if (getpermissions) {
            const permissionData = getpermissions.map(item => {
                return { [item.MenuID]: item.Access }; // Dynamic key
            });

            // Assuming methods.reset expects an object, not an array
            methods.reset(Object.assign({}, ...permissionData));
        }
    }, [getpermissions]);


    return (<>

        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
                {/* <JSONTree data={methods.watch()} /> */}
                {/* <JSONTree data={item} /> */}
                {/* <div className="col-sm-12 col-md-4 col-lg-4 mr-b20" >

                    <YesNoRadioButton
                        required={true}
                        label="Applicants can apply with this role?"
                        source={`applicantrole`}
                    />
                </div> */}
                {collasableData && collasableData.length > 0 && collasableData.filter(item => item.MenuID != null).map((item, index) => {
                    return <>

                        {/* <JSONTree data={methods.watch()} />  */}
                        <div className="info-toggle">
                            <div className="toggle-heads ">
                                <div className="row align-items-center justify-content-between  ">
                                    <div className="col">
                                        <div className="d-flex align-items-center">
                                            <b
                                                className="ms-3 me-2"
                                                style={{ fontSize: "18px", fontWeight: "700", fontFamily: "Open Sans" }}
                                            >
                                                {item.MenuName}
                                            </b>
                                        </div>
                                    </div>

                                    <div className="col text-end m-1">
                                        <ToggleButtons
                                            toggleButton={toggleButton}
                                            // label="Status"
                                            labelClass={"lable-title"}
                                            notRequired={true}
                                            source={String(item.MenuID)}
                                        />
                                    </div>
                                </div>

                                {methods.watch(String(item.MenuID)) == true && <div
                                    className={` ${!expandIdSection ? "d-none" : "d-display"
                                        } card-body cust-card-appl`}
                                >

                                    <CollasableFormData formInputArr={childCollasableData?.filter(i => i.ParentMenuName == item.MenuName)} item={item} index={index} />

                                </div>}

                            </div>
                        </div>
                    </>
                })}
                <Box

                    display={"flex"}
                    justifyContent={"end"}
                    alignItems={"center"}
                    className="mt-4"
                    minHeight="10vh"
                >
                    <Box>

                        <MUIButton
                            startIcon={!isLoading && <CheckIcon />} // Conditionally render the startIcon
                            sx={{ mx: 1 }}
                            variant="contained"
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? (
                                <CircularProgress size="20px" sx={{ color: "white" }} /> // Show loader when isLoading is true
                            ) : (
                                <span>Update</span> // Show text when isLoading is false
                            )}
                        </MUIButton>
                    </Box>
                </Box>

            </form></FormProvider>
    </>)
}


const CollasableFormData = ({ item, index, formInputArr }) => {
    return <>
        <div className='row'>
            {/* <JSONTree data={formInputArr} /> */}
            {formInputArr && formInputArr.length > 0 && formInputArr.map((item, index) => {
                return <>
                    <div className='col-3'>
                        <div
                            className="card card-body w-100"
                            style={{ paddingTop: 0 }}
                        >
                            {/* <JSONTree data={item} /> */}
                            <>
                                <ToggleButtons
                                    toggleButton={toggleButton}
                                    label={item?.MenuName}
                                    labelClass={"lable-title"}
                                    source={String(item?.MenuID)}
                                    notRequired={true}
                                />
                            </>
                        </div>
                    </div>
                </>
            })
            }
        </div>
    </>
}



export default ThirdPartyUserPermission;
