import React, { Fragment, useState, useEffect } from "react";

import { Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { MdOutlineUploadFile } from "react-icons/md";
import {
    Datagrid,
    FunctionField,
    ListBase,
    Pagination,
    TextField,
    useListContext,
    useStore,
    useGetList,
    Resource,
    usePermissions
} from "react-admin";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Box,
    Link,
    ToggleButton,
    ToggleButtonGroup,
    Checkbox,
    Stack
} from "@mui/material";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import { ATS_API_URL } from "../../config";
import InputFileImport from "../common/InputFileImport";
import DownloadSampleButton from "../common/DownloadSampleButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { format } from 'date-fns';

import FilterModal from "../manageSubscribers/FilterModal";


import { JSONTree } from "react-json-tree";

import CopyLinkHoverDropdownUserSetup from "../Settings/UserSetup/CopyLinkHoverDropdownUserSetup";
import MarkAsActiveAdmin from "./MarkAsActiveAdmin";
import { checkModuleAccess } from "../../Functions/permissions";



const MyListToolBar = ({ title }) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
        useListContext();
    const [applicantType, setApplicantType] = useState("All")
    const navigate = useNavigate();
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    console.log(switchSubscriberObj?.value, "switchSubscriberObj")
    // Debounce the search function
    const debouncedSearch = debounce((value) => {

        // Perform your search logic here
        setFilters({ ...filterValues, searchcode: value }, []);
    }, 500); // 500ms debounce delay
    useEffect(() => {
        // Set default filter value if not already set
        if (!filterValues.activecode) {
            setFilters({ ...filterValues }, []);
        }
    }, [filterValues, setFilters]);
    const handleSearchChange = (event) => {

        const value = event.target.value;
        debouncedSearch(value);
    };


    const handleExportData = () => {
        const tokenString = localStorage.getItem('auth')
        let tokenSecret = ''
        if (tokenString) {
            try {
                const token = JSON.parse(tokenString);
                tokenSecret = token?.data?.tokendata || '';
            } catch (error) {
                console.error('Error parsing token:', error);
            }
        }


        const currentTimestamp = Date.now();

        const url = `${ATS_API_URL}/admin/exportdocuverususers`;
        // ?subscriberid=${switchSubscriberObj?.value
        //     }&UserType=${filterValues.UserType}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `${tokenSecret}`
            }
        }).then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `AdminUsers.xlsx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Error:', error));
    }

const {permissions} =usePermissions();


    return (
        <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
            {/* Left side with title */}
            <Grid item xs={12} md={6}>
                <div className="app-option-btn" id="a-oprtions">
                    <label htmlFor="c1">
                        <input
                            type="radio"
                            onChange={() => {
                                setFilters({ ...filterValues, activecode: "" }, []);
                            }}
                            checked={
                                filterValues.activecode == undefined
                            }
                            // defaultChecked
                            name="a-oprtions"
                            id="c1"
                        />
                        <span>ALL</span>

                    </label>


                    <label htmlFor="c2">
                        <input
                            type="radio"
                            onChange={() => {
                                setFilters({ ...filterValues, activecode: "1" }, []);
                            }}
                            checked={
                                filterValues.activecode == "1"
                            }
                            name="a-oprtions"
                            id="c2"
                        />
                        <span>ACTIVE</span>
                    </label>


                    <label htmlFor="c3">
                        <input
                            type="radio"
                            onChange={() => {
                                setFilters({ ...filterValues, activecode: "0" }, []);
                            }}
                            checked={
                                filterValues.activecode == "0"
                            }
                            name="a-oprtions"
                            id="c3"
                        />
                        <span>INACTIVE</span>
                    </label>



                </div>



            </Grid>

            {/* Right side with search input, filter button, and add button */}
            <Grid
                item
                xs={12}
                md={6}
                container
                justifyContent="flex-end"
                alignItems="center"
            >
                <div className="group-btn">
                    {/* Search Input */}
                    <MuiTextField
                        placeholder="search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        defaultValue={filterValues.searchcode}
                        onChange={handleSearchChange}
                        variant="outlined"
                        size="small"
                    />
                    {/* <FilterModal /> */}

                    <button
                        onClick={handleExportData}
                        type="button"
                        className="g-line-btn"
                        title="Export all data"
                    >
                        <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_14375_6193)">
                                <path
                                    d="M8.66667 8.4987H10.6667L8 11.1654L5.33333 8.4987H7.33333V5.83203H8.66667V8.4987ZM10 3.16536H3.33333V13.832H12.6667V5.83203H10V3.16536ZM2 2.49336C2 2.12803 2.298 1.83203 2.666 1.83203H10.6667L14 5.16536V14.494C14.0006 14.5816 13.984 14.6684 13.951 14.7495C13.9181 14.8306 13.8695 14.9045 13.808 14.9668C13.7466 15.0291 13.6734 15.0788 13.5928 15.1128C13.5121 15.1469 13.4255 15.1648 13.338 15.1654H2.662C2.48692 15.1641 2.31934 15.0941 2.19548 14.9704C2.07161 14.8466 2.0014 14.6791 2 14.504V2.49336Z"
                                    fill="#2DC3E8"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_14375_6193">
                                    <rect
                                        width="16"
                                        height="16"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </button>

                    {/* <InputFileImport style={{ width: '400px' }} startIcon={true} buttonText={"CSV"} importType={"UserSetup"} /> */}

                    {checkModuleAccess(permissions, "Other Permissions", "Edit Users") &&  <button
                        type="button"
                        onClick={() =>
                            navigate(`/ats/manageUsers/ManageUsers/addNewAdminUser`, {
                                state: { isAdding: true },
                            })
                        }
                        className="blue-btn px-4"
                    >
                        <svg
                            className="mr-r10"
                            width="10"
                            height="11"
                            viewBox="0 0 10 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.33337 4.83333V0.833334H5.66671V4.83333H9.66671V6.16667H5.66671V10.1667H4.33337V6.16667H0.333374V4.83333H4.33337Z"
                                fill="white"
                            />
                        </svg>
                        User
                    </button>}

                </div>
            </Grid>
        </Grid>
    );
};


const MyList = ({ children, toolBar, ...props }) => {
    return (
        <ListBase
            {...props}
            perPage={100} // Number of items per page
        >
            {toolBar}
            {children}
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
            <br />
            <br />
            <br />
            <br />
        </ListBase>
    );
};




const ActiveBtn = () => {
    const myStyles = {
        display: "flex",
        width: "79px",
        padding: "8px 11px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "4px",
        background: "#E6FDF0",
    };
    const myTextStyles = {
        color: "var(--Success, #46A96F)",
        // textAlign: 'center',
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
    };
    return (
        <div style={myStyles}>
            <div style={myTextStyles}>Active</div>
        </div>
    );
};
const DeActiveBtn = () => {
    const myStyles = {
        display: "flex",
        width: "79px",
        padding: "8px 11px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "4px",
        background: "#FFF1F1",
    };
    const myTextStyles = {
        color: "var(--Success, #FF4848)",
        // textAlign: 'center',
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
    };
    return (
        <div style={myStyles}>
            <div style={myTextStyles}>Inactive</div>
        </div>
    );
};



const ManageUsers = (props) => {

    const navigate = useNavigate();
    const { id } = useParams();

    const location = useLocation();

    const params = useParams();
    const getlocalStorageItem: any = localStorage.getItem(
        "stateRecord" + params?.applicationId
    );
    let locationState = JSON.parse(getlocalStorageItem);
    const data = location.state || locationState;

    const [selectedRecords, setSelectedRecords] = useState<number[]>([]);
    const [headerCheckbox, setHeaderCheckbox] = useState(false);
    const [switchSubscriberObj] = useStore("switchSubscriberName");

    return (

        <MyList

            toolBar={<MyListToolBar title={"User Setup"} />}
            sort={{ field: "id", order: "ASC" }}

            filter={{
                SubscriberId: switchSubscriberObj?.value,

            }}
            resource="user-setup-admin"

        >



            <UserSetupDataGrid />

        </MyList>
    );

};


const UserSetupDataGrid = () => {
    const navigate = useNavigate();
    const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
        useListContext();
    const [selectedRecords, setSelectedRecords] = useState<number[]>([]);
    const [headerCheckbox, setHeaderCheckbox] = useState(false);
    console.log(filterValues, "filterValues")
    const [activeTab, setActiveTab] = useState('Active');
    const [selectedActiveRecords, setSelectedActiveRecords] = useState<number[]>([]);
    const [selectedAllRecords, setSelectedAllRecords] = useState<number[]>([]);
    const [selectedInactiveRecords, setSelectedInactiveRecords] = useState<number[]>([]);
    const SelectAllHeader = ({ setSelectedRecords }) => {

        const { data } = useListContext();
        const [headerCheckbox, setHeaderCheckbox] = useState(false);
        console.log(data, "datauser")


        const handleHeaderCheckboxChange = (event) => {
            const isChecked = event.target.checked;
             if (filterValues.activecode === undefined) { // All Tab
                setHeaderCheckbox(isChecked);
                const allRecordIds = data.map(record => record.Id);
                if (isChecked) {
                    setSelectedAllRecords(allRecordIds);
                } else {
                    setSelectedAllRecords([]);
                }
            }
            else if (filterValues.activecode === "1") { // Active Tab
                setHeaderCheckbox(isChecked);
                const allRecordIds = data.map(record => record.Id);
                if (isChecked) {
                    setSelectedActiveRecords(allRecordIds);
                } else {
                    setSelectedActiveRecords([]);
                }
            } else if (filterValues.activecode === "0") { // All Tab
                setHeaderCheckbox(isChecked);
                const allRecordIds = data.map(record => record.Id);
                if (isChecked) {
                    setSelectedInactiveRecords(allRecordIds);
                } else {
                    setSelectedInactiveRecords([]);
                }
            }
           
            // You can add similar logic for the Inactive tab if needed.
        };


        return (
            <Checkbox
                checked={headerCheckbox}
                onChange={handleHeaderCheckboxChange}
            />
        );
    };



    const handleCheckboxChange = (recordId: any) => {
        if (filterValues.activecode === undefined) { // All Tab
            setSelectedAllRecords(prevSelectedRecords =>
                prevSelectedRecords.includes(recordId)
                    ? prevSelectedRecords.filter(id => id !== recordId)
                    : [...prevSelectedRecords, recordId]
            );
        }
        else if (filterValues.activecode === "1") { // Active Tab
            setSelectedActiveRecords(prevSelectedRecords =>
                prevSelectedRecords.includes(recordId)
                    ? prevSelectedRecords.filter(id => id !== recordId)
                    : [...prevSelectedRecords, recordId]
            );
        } else if (filterValues.activecode === "0") { // All Tab
            setSelectedInactiveRecords(prevSelectedRecords =>
                prevSelectedRecords.includes(recordId)
                    ? prevSelectedRecords.filter(id => id !== recordId)
                    : [...prevSelectedRecords, recordId]
            );
        }
       
        // You can add similar logic for the Inactive tab if needed.
    };


    const anyCheckboxChecked = selectedRecords.length > 0;
    useEffect(() => {

        if (anyCheckboxChecked) {
            console.log('At least one checkbox is checked', selectedRecords);
        } else {
            console.log('No checkboxes are checked');
        }
    }, [selectedRecords.length]);
    return (

        <>
            <Box>
            {filterValues.activecode === undefined && selectedAllRecords.length > 0 && (
                    <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                        <Stack direction="row" spacing={2}>
                            <MarkAsActiveAdmin  status1="Inactive" status="Deactivate" selectedIds={selectedAllRecords}  setSelectedIds={setSelectedAllRecords}  />
                            <MarkAsActiveAdmin status1="Active" status="Activate" selectedIds={selectedAllRecords}  setSelectedIds={setSelectedAllRecords} />
                        </Stack>
                    </Box>
                )}
                {filterValues.activecode === "1" && selectedActiveRecords.length > 0 && (
                    <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                        <Stack direction="row" spacing={2}>
                            <MarkAsActiveAdmin status1="Inactive" status="Deactivate" selectedIds={selectedActiveRecords} setSelectedIds={setSelectedActiveRecords}/>
                            <MarkAsActiveAdmin status1="Active" status="Activate" selectedIds={selectedActiveRecords} setSelectedIds={setSelectedActiveRecords}/>
                        </Stack>
                    </Box>
                )}
                {filterValues.activecode === "0" && selectedInactiveRecords.length > 0 && (
                    <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                        <Stack direction="row" spacing={2}>
                            <MarkAsActiveAdmin status1="Inactive" status="Deactivate" selectedIds={selectedInactiveRecords} setSelectedIds={setSelectedInactiveRecords}/>
                            <MarkAsActiveAdmin status1="Active" status="Activate" selectedIds={selectedInactiveRecords} setSelectedIds={setSelectedInactiveRecords} />
                        </Stack>
                    </Box>
                )}
              

                <Datagrid
                    sx={{
                        "& .RaDatagrid-headerCell": {
                            fontWeight: "bold",
                            fontSize: 12,
                            // textAlign: 'center', // Center the text in header cells
                        },
                        "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
                        // "& .MuiTableCell-root": {
                        //     padding: "8px", // Adjust padding for all cells
                        //     textAlign: 'center', // Center the text in all cells
                        // },
                    }}
                    bulkActionButtons={false}
                    isRowSelectable={record => true}
                >
                    <FunctionField
                        render={record => (
                            <Checkbox
                                checked={
                                     filterValues.activecode === undefined
                                    ? selectedAllRecords.includes(record.Id)
                                   : filterValues.activecode === "1"
                                        ? selectedActiveRecords.includes(record.Id)
                                        : filterValues.activecode === "0"
                                            ? selectedInactiveRecords.includes(record.Id)
                                           
                                                : false // Handle other tabs similarly
                                }
                                onChange={() => handleCheckboxChange(record.Id)}
                            // checked={selectedRecords.includes(record.Id)}
                            // onChange={() => handleCheckboxChange(record.Id)}
                            />
                        )}
                        label={<SelectAllHeader setSelectedRecords={setSelectedRecords} />}
                        sx={{ width: "100px" }} // Set a fixed width
                    />
                    {/* <TextField source="Name" label="Name"  sx={{
        width: "200px",
        whiteSpace: "normal",
        wordBreak: "break-word",
    }} /> */}
                    <TextField
                        source="Name"
                        label="Name"
                        sx={{
                            width: "150px",
                            whiteSpace: "normal",
                            wordBreak: "break-word",
                            overflow: "hidden", // Prevents overflow
                            textOverflow: "ellipsis", // Optional, for adding ellipsis if needed
                            display: "inline-block", // Ensures the text wraps properly within the container
                        }}
                    />

                    <TextField source="Email" sx={{ width: "150px" }} />
                    <TextField source="Role" sx={{ width: "150px" }} />
                    <TextField source="PhoneNumber" label="Mobile" sx={{ width: "150px" }} />
                    {/* <FunctionField
                        sortByOrder="ASC"
                        sortBy="Status"
                        label="Status"
                        render={(record) => (
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                {parseInt(record.Active) ? <ActiveBtn /> : <DeActiveBtn />}
                                <IconButton
                                    aria-label="view details"
                                    color="primary"
                                    onClick={() => navigate(`/ats/manageUsers/ManageUsers/updateNewAdminUser/${record?.Id}`, { state: { isAdding: false } })}
                                    className="show_on_hover"
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Box>
                        )}
                        sx={{ width: "150px" }} // Set a fixed width
                    /> */}
                    <FunctionField
                        sortByOrder="DESC"
                        sortBy="Status"
                        label={"Status"}
                        render={(record) =>
                            parseInt(record.Active) ? <ActiveBtn /> : <DeActiveBtn />
                        }
                    />
                    <FunctionField
                        label={""}
                        render={(record) => (
                            // <Box display={"flex"}>
                            <Box display={"flex"} sx={{ width: "20px"}}>
                                {/* <CopyLinkHoverDropdown record={record} /> */}
                                <IconButton
                                    aria-label="view details"
                                    color="primary"
                                    sx={{
                                        py: 0,
                                    }}
                                    onClick={() => navigate(`/ats/manageUsers/ManageUsers/updateNewAdminUser/${record?.Id}`, { state: { isAdding: false } })}
                                    className="show_on_hover"
                                >
                                    <ArrowForwardIcon />
                                </IconButton>
                            </Box>
                        )}
                        
                    />
                </Datagrid>
            </Box>

        </>
    );
}
export default ManageUsers;
