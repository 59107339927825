import React from "react";
import { Controller, useFormContext, useController } from "react-hook-form";
import "../../common/InputComponents/custom.scss";
const StripeAccountRadioButton = (props) => {
  const required = props.notRequired === undefined ? true : !props.notRequired;
  const { name } = props.register;
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <>
      <Controller
        control={props.control}
        name={name}
        render={({ field }) => {
          return (
            <>
              <div className="i-a-block">
                <h5 className={`${error !== undefined && "text-danger"}`}>
                  {props.label} {required && <span className="red">*</span>}
                </h5>
                <div className="d-flex">
                  <div>
                    <input
                      type="radio"
                      disabled={
                        props.disable
                          ? props.disable
                          : props.subscriberDisable
                          ? props.subscriberDisable
                          : false
                      }
                      id={`${name}subscriber`}
                      {...field}
                      checked={
                        field.value && field.value.toLowerCase() == "subscriber"
                      }
                      value="Subscriber"
                      className="is-hidden"
                      onChange={(e) => {
                        field.onChange(e);
                        props?.handleOnChange(e);
                      }}
                    />
                    <label
                      htmlFor={`${name}subscriber`}
                      className={`ms-1 btn1 btn1--yes ${
                        required && !field.value && "backgroundCRD"
                      } ${error !== undefined && "backgroundCRD"}`}
                    >
                      Subscriber
                    </label>
                  </div>
                  <div
                    onClick={() => {
                      props?.onClick();
                    }}
                  >
                    <input
                      type="radio"
                      id={`${name}${props?.secondButtonValue}`}
                      disabled={
                        props.disable
                          ? props.disable
                          : props.propertyDisable
                          ? props.propertyDisable
                          : false
                      }
                      checked={
                        field.value &&
                        field.value.toLowerCase() ==
                          props?.secondButtonValue?.toLowerCase()
                      }
                      {...field}
                      value={props?.secondButtonValue}
                      className="is-hidden"
                      onChange={(e) => {
                        field.onChange(e);
                        props?.handleOnChange(e);
                      }}
                    />
                    <label
                      htmlFor={`${name}${props?.secondButtonValue}`}
                      className={`ms-1 btn1 btn1--no 
                    ${required && !field.value && "backgroundCRD"}
                    
                    ${error !== undefined && "backgroundCRD"}`}
                    >
                      {props?.secondButtonValue}
                    </label>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      />
      <div
        className="invalid-feedback"
        style={{ display: "block", fontSize: "70%" }}
      >
        {error && error.message}
      </div>
    </>
  );
};

export default StripeAccountRadioButton;
