import React, { useState } from 'react';
import UndoIcon from '@mui/icons-material/Undo';
import Button from '@mui/material/Button';
import { Confirm, useRefresh, useNotify, useUpdate, useDataProvider } from 'react-admin';
import { useMutation } from 'react-query';
import { JSONTree } from 'react-json-tree';

const CustomUndoButton = ({ record, resource, title }) => {
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);
    const [update, { isLoading, error }] = useUpdate();
    const refresh = useRefresh()
    const notify = useNotify();
    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = async () => {
        update(
            'deletedDocument',
            { id: record?.id, data: { ...record }, previousData: record },{
                onError:error=>{
                    const message:any=error;
                     notify(`${message.message}`, { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
                },
                onSettled:()=>{
                    handleDialogClose()
                },
                onSuccess:(data)=>{
                    refresh();
                }
            }
        )
        // .then(_ => {
        //     refresh();
        // }).catch(err => {
        //     
        //     // refresh();
        // }).finally(() => handleDialogClose())
    };

    return (
        <>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={title}
                content={`Are you sure you want to Restore this item?`}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
            <Button
                size="small"
                variant='contained'
                startIcon={<UndoIcon />}
                disabled={isLoading}
                onClick={() => handleClick()}

            >Restore</Button>
        </>
    );
}

export default CustomUndoButton;