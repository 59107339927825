import React from "react";
import { useCreate, useNotify } from "react-admin";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";

const ShowDocumentStatus = ({
  label,
  trn_id: trnId,
  documentId,
  SourceId,
  value,
  src,
  blockStyle,
  invalidateQueries,
  className,
  textStyle,
  customWidth = 155,
  disable = false,
}) => {
  const [loading, setLoading] = React.useState(false);
  // fix the co-applicant issue
  const trn_id = trnId //useSelector((state: RootState) => state.users.userTrnId);
  // const dataProvider = useDataProvider();
  const notify = useNotify();
  const queryClient = useQueryClient();
  const [create, status] = useCreate();
  // const { mutate, isLoading, isSuccess } = useMutation('changeDocumentStatus', (data: any) => dataProvider.updateDocumentStatus({
  //   trn_id: data.trn_id,
  //   document_id: data.documentId,
  //   source_id: data.SourceId,
  //   status: data.value,
  // }),
  //   {
  //     onSuccess: (data) => {
  //       queryClient.invalidateQueries([`${invalidateQueries}`])
  //       notify("Document status updated", { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
  //     },
  //     onError: (data) => {
  //       alert("error")
  //     },
  //     onMutate: () => {
  //       notify('Changing Status...', { type: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
  //     }
  //   })

  const handleChange = (e) => {
    create(
      "saveVerificationStatus",
      {
        data: {
          trn_id,
          document_id: documentId,
          source_id: SourceId,
          status: e.target.value,
        },
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries([`${invalidateQueries}`]);
          notify("Document status updated", { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
        },
        onError: (data) => {
          alert("error");
        },
        // onMutate: () => {
        //   notify('Changing Status...', { type: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
        // }
      }
    );
    // mutate({
    //   trn_id,
    //   documentId,
    //   SourceId,
    //   value: e.target.value
    // })
  };
  const invalidClass = "bg-danger text-white text-center";
  const validClass = "bg-success text-white text-center";
  const notSureClass = "bg-warning text-dark text-center";

  return (
    <div
      className={`p-1 block text-center rounded ${
        value == 1
          ? validClass
          : value == 2
          ? invalidClass
          : value == 3
          ? notSureClass
          : notSureClass
      }`}
      style={{ width: customWidth }}
    >
      <strong style={{ fontSize: "12px", lineHeight: "16px",marginRight:"8px" }} className="mb-1">
        {label}
      </strong>
      {value == 2 && (
           <div style={{ marginRight: "10px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="16"
          fill="currentColor"
          className="bi bi-exclamation-circle-fill"
          viewBox="0 0 17 14"
        >
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
        </svg>
        </div>
      )}
      {value == 3 && (
           <div style={{ marginRight: "10px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="16"
          fill="currentColor"
          className="bi bi-exclamation-triangle-fill"
          viewBox="0 0 17 14"
        >
          {" "}
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </svg>
        </div>
      )}
      {value == null && (
           <div style={{ marginRight: "10px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="16"
          fill="currentColor"
          className="bi bi-exclamation-triangle-fill"
          viewBox="0 0 17 14"
        >
          {" "}
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </svg>
        </div>
      )}
      {value == 1 && (
          <div style={{ marginRight: "10px" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="16"
          fill="currentColor"
          className="bi bi-check-circle-fill"
          viewBox="0 0 17 14"
        >
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </svg>
        </div>
      )}
      {disable && (
        <p style={{ fontSize: "12px"}}>
          {value == 1
            ? "Valid "
            : value == 2
            ? "Unverifiable"
            : value == 3
            ? "Verification Required"
            : "NA"}
        </p>
      )}
      {!disable && (
        <select
        
          disabled={disable}
          className={`cus-select-status  ${
            value == 1
              ? validClass
              : value == 2
              ? invalidClass
              : value == 3
              ? notSureClass
              : notSureClass
          }`}
        
          onChange={(e) => handleChange(e)}
          value={value ? Number(value) : ""}
        >
          <option selected disabled value="">
            NA
          </option>
          <option value={1}> Valid</option>
          <option value={2}>Unverifiable</option>
          <option value={3}>Verification Required</option>
        </select>
      )}
    </div>
  );
};

export default ShowDocumentStatus;
