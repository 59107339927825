import React from 'react'
import ArchiveIcon from '@mui/icons-material/Archive';
import { Box, Button, IconButton, InputAdornment } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextInput, required, useUpdate, useNotify, useRefresh, NumberInput, useGetOne, useGetList, useGetIdentity, usePermissions } from 'react-admin';
import { useForm, FormProvider, get } from 'react-hook-form';
import { Button as MuiButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from "react-router-dom";
import { Update } from '@mui/icons-material';
import CloseIcon from "@mui/icons-material/Close";
import { JSONTree } from 'react-json-tree';
import InputDateField from '../common/InputComponents/InputDateFieldOld';
import InputCurrencyField from '../DocumentAnalysis/components/inputComponents/InputCurrencyFiled';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import InputTextArea from '../common/InputComponents/InputTextArea';
import LoadingSpinner from '../common/loadingSpinner';
import moment from "moment";
import InputField2 from '../common/InputComponents/InputField2';
import InputSelectField from '../common/InputComponents/InputSelectField';
import { inherits } from 'util';
import { typesofUsers } from '../../config';
import { checkModuleAccess } from '../../Functions/permissions';
import { useSelector } from 'react-redux';

export default function AddressRentEditForProperty(props) {
    const valueRecord=props?.record;
    const { id: parmasId } = useParams();
    const id = parmasId || props.record.SubscriberID;
    const notify = useNotify();
    const refresh = useRefresh();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { data: applicantData } = useGetList(
        "get_subscriber_inbox_detials",
        {
            filter: {
                ApplicationNumber: props?.record?.id,
                subscriberid: props?.record?.SubscriberID,
            },
        }
    );
    const validationSchema = Yup.lazy((value) =>
        Yup.object().shape({

            // floor: Yup.object()
            //     .shape({
            //         value: Yup.string().required("Floor is required!").nullable(),
            //         label: Yup.string().nullable(),
            //     })
            //     .nullable()
            //     .required("Floor is required!")
            //     .typeError("required"),
            // unit: Yup.object()
            //     .shape({
            //         value: Yup.string().required("Desired state is required!").nullable(),
            //         label: Yup.string().nullable(),
            //     })
            //     .nullable()
            //     .required("Unit is required!")
            //     .typeError("required"),
            rent: Yup.string()
                .matches(/^(\d*[0-9])|^$/, "Only Numbers Allowed")
            // .min(1, "Greater than 0 value required")
            // .nullable()
            // .test("rent", "Rent is required!", async (value) => {
            //     return value == null || parseInt(value) > 0 ? true : false;
            // }),

        })
    );

    // filter a data
    const form = useForm<any>({
        defaultValues: {
            floor: { label: "", value: "" },


            unit: { label: "", value: "" },

            rent: props.record.AppliedForRent,

        },

        resolver: yupResolver(validationSchema),
    });

    const fieldValue = form.watch("floor");
    
    const methods = form;

    let filterData: any = [];
    if (fieldValue !== null) {
      let filter = applicantData?.filter(
        (item) => item.FloorID == fieldValue.value
      )[0];
      filterData = filter;
    }
    const { data: unitData } = useGetList(
        "getunitlist",
        {
            filter: {
                PropertyID:  props?.record?.PropertyID,
                SubscriberID:  props?.record?.SubscriberID,
            },
        },
        { enabled:  props?.record?.PropertyID &&  props?.record?.SubscriberID ? true : false }
    );
 
  
    const { data: floorData } = useGetList(
        "getfloorlist",
        {
            filter: {
                PropertyID: props?.record?.PropertyID,
                SubscriberID:  props?.record?.SubscriberID,
            },
        },
        { enabled:  props?.record?.PropertyID &&  props?.record?.SubscriberID ? true : false }
    );



    // form validation schema 
  
   
    
    React.useEffect(() => {
        if (props?.record && floorData && unitData) {
            const selectedFloor = floorData
                ?.filter((item) => item.ID == props.record.FloorID)
                ?.map((item) => ({
                    label: item.FloorCode,
                    value: item.ID,
                }));  // Get the first matching element

            const selectedUnit = unitData
                ?.filter((item) => item.ID == props.record.UnitID)
                .map((item) => ({
                    label: item.UnitName,
                    value: item.ID,
                }))[0];  // Get the first matching element

            // Set the values only when they exist
            if (selectedFloor) methods.setValue('floor', selectedFloor);
            if (selectedUnit) methods.setValue('unit', selectedUnit);
        }
    }, [props?.record, floorData, unitData, methods]);


    const [update, { data, isLoading }] = useUpdate();

    // form on onsubmit handler
    const onSubmit = (value) => {

        const sendData = {

            rent: value?.rent,
            floor_id: parseInt(value?.floor?.value),
            unit_id: parseInt(value?.unit?.value),
        }

        update('editapplicationdetail', { id: props.record.id, data: sendData }, {
            onSuccess: (d) => {
                notify(`Data Updated`, { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
                refresh();
                handleClose();
            }, onError: (e: any) => notify(`Operation fail:${e.message}`, { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        })
    }
    const { data: identity } = useGetIdentity();
    const { permissions } = usePermissions();
    const userRole =
      useSelector((state: any) => state?.users?.role) || identity?.userrole;
    return (
        <>
            <EditIcon onClick={handleOpen} color='primary' fontSize='small' style={{ fontSize: props?.fontSize ? props?.fontSize : "inherits" }} />

            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    Edit
                </DialogTitle>
                <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 6,
                  top: 1,

                  color: (theme) => theme.palette.grey[900],
                }}
              >
                {/* <span style={{ fontSize: "12px" }}>Dismiss</span> */}
                <CloseIcon style={{ fontSize: "12px" }} />
              </IconButton>
                <DialogContent>
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)}>
                            {/* <JSONTree data={floorData?.data}/>  */}
                            <fieldset disabled={userRole == typesofUsers.docuverusAdmin && !checkModuleAccess(permissions, "Dashboard", "Edit Application")}>
                            <div className="row">
                                <div className="col-12 mr-b10">
                                    <InputSelectField
                                        label="Floor"
                                        source={`floor`}
                                        options={
                                            floorData?.map((item) => ({
                                                label: item.FloorCode,
                                                value: item.ID,
                                            }))
                                        }
                                        labelAsteriskClass={"color-red"}
                                        labelClass={"lable-title"}
                                        requiredClass={{
                                            fontSize: "70%",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mr-b10">
                                    <InputSelectField
                                        label="Unit"
                                        source={`unit`}
                                        options={
                                            unitData?.map((item) => ({
                                                label: item.UnitName,
                                                value: item.ID,
                                            }))

                                        }
                                        labelAsteriskClass={"color-red"}
                                        labelClass={"lable-title"}
                                        requiredClass={{
                                            fontSize: "70%",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 mr-b10">
                                    <InputCurrencyField label="Rent" source={`rent`} placeholder="Rent" required={true} labelClass={"lable-title"} requiredClass={{ fontSize: "70%" }} />
                                </div>
                            </div>

                            <Box display={'flex'} justifyContent={'end'}>
                                <Button sx={{ mx: 1 }} onClick={() => { handleClose(); }} variant='outlined' color='secondary' type='button' >
                                    Cancel
                                </Button>

                                <Button startIcon={<EditIcon />} sx={{ mx: 1 }} variant='contained' type='submit'>
                                    Update
                                </Button>
                            </Box>
                            </fieldset>
                        </form>
                    </FormProvider>
                </DialogContent>

            </Dialog>

        </>
    )
}
