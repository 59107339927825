import React, { useEffect, useState } from "react";
import { Button, useGetList, useGetOne, useCreate } from "react-admin";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import parse from "html-react-parser";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import {
  Loading,
  // useCreate,
  // useGetList,
  useNotify,
  useRefresh,
  useStore,
  useGetIdentity
} from "react-admin";

import { CircularProgress } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import InputCheckBoxField from "../../componets/common/InputComponents/InputCheckBoxField";
import { JSONTree } from "react-json-tree";
import ESignature from "../../componets/common/eSignature/eSignature";
import SignaturePadComponent from "../../componets/common/SignaturePadComponent";
import { ErrorMessage } from "@hookform/error-message";

const TermsConditionLandLoardSetup = (props) => {

  const Identity = useGetIdentity();
  const [signatureFlag, setSignatureFlag] = useState(false);
  const [signatureImage, setSignatureImage] = useState("");
  const [signatureFont, setSignatureFont] = useState("");
  const [signatureText, setSignatureText] = useState("");
  const [signatureType, setSignatureType] = useState("pad");
  const [isEmptySignature, setIsEmptySignature] = useState(false);
  const [signatureEnd, setSignatureEnd] = useState(false);
  const [signatureTyped, setSignatureTyped] = useState(false);

  console.log(Identity?.data?.subscriberid, "id1234")
  // const id=AuthForId?.data?.subscriberid;
  const { data, isLoading: isLoadingData } = useGetOne("getTermsConditionLandlord", {

    id: 2,

  });
  // console.log(data?.Description, "data6")
  const [create, { isLoading, error }] = useCreate();
  const defaultFormValues = {
    signatureImage: "",
    agreeterms: false,
    TermsConditions: ""
  };
  const schema = yup.object().shape({
    agreeterms: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions")
      .required("You must accept the terms and conditions"),
    signatureImage: yup.string().required("Signature is required"),


  });
  const notify = useNotify();
  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  useEffect(() => {
    methods.setValue('TermsConditions', data?.Description);
  }, [data?.Description])
  const { setError, clearErrors } = methods;
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    console.log("datasubmit", data);

    const sendData = {
      ...data,
      agreeterms: Number(data.agreeterms),
    };
    // console.log("datasendData", sendData);
    create("updatelandlordtandc", { data: sendData }, {
      onSuccess: (data) => {
        notify(`Terms and Conditions updated successfully.`, {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        localStorage.setItem("TermAndConditionVefificationStatus", "1");
        navigate('/landloard/landLoardInbox', { replace: true });
      }
    });
  };



  return (
    <div
      className="container"
      style={{
        marginTop: "50px",
      }}
    >
      <Card>

        <CardContent>

          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>

            <b>Terms And Conditions</b>

          </Typography>
          <hr />

          {data?.Description ? parse(data?.Description) : ""}
          <div className="auth-form mr-t30">
            {/* {signatureImage && <img src={signatureImage} alt="signature" />} */}
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="form-group cus-input-group mr-b20">
                  <InputCheckBoxField
                    notRequired={false}
                    label={"I Accept the terms and conditions"}
                    name={`agreeterms`}


                  />
                  <p style={{ color: 'red' }}><ErrorMessage errors={methods.formState.errors} name="agreeterms" /></p>
                </div>

                {/* <JSONTree data={{signatureImage, signatureText, signatureFont, signatureType,setSignatureTyped}} /> */}
                <SignaturePadComponent setSignatureImage={methods.setValue} />
                <br />
                <br />

                <p style={{ color: 'red' }}><ErrorMessage errors={methods.formState.errors} name="signatureImage" /></p>
                {/* <ESignature
                  cancelOperation={(data) => setSignatureFlag(false)}
                  submit={(data) => {
                    // console.log("Check TYPE", data);
                    setSignatureImage(data);
                    setSignatureFlag(false);
                  }}
                  signatureText={signatureText}
                  setSignatureText={setSignatureText}
                  signatureEnd={signatureEnd}
                  setSignatureEnd={setSignatureEnd}
                  signatureTyped={signatureTyped}
                  setSignatureTyped={setSignatureTyped}
                  signatureFont={signatureFont}
                  setSignatureFont={setSignatureFont}
                  signatureType={signatureType}
                  setSignatureType={setSignatureType}
                  setSignatureImage={setSignatureImage} /> */}
                {isLoading ? (
                  <div className="static-d-info d-flex justify-content-center align-items-center mw-100">
                    <CircularProgress size="20px" />
                    <h5>&nbsp; &nbsp; Please wait...</h5>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    {/* <JSONTree data={methods.watch()} /> */}
                    {/* <JSONTree data={methods.formState.errors} /> */}
                    {/*  disabled={(!methods.formState.isValid)}  */}
                    <button type="submit" className="l-btn-forgetPsd" style={{ backgroundColor: "rgb(45, 195, 232)", border: "rgb(45, 195, 232)" }}>
                      Submit
                    </button>
                  </div>
                )}
              </form></FormProvider>

          </div>
        </CardContent>
      </Card>

    </div>
  );
};

export default TermsConditionLandLoardSetup;