import React, { useEffect, useState } from "react";

import SideBar from "../../pages/Sidebar/sidebar";
import { FormProvider, useForm } from "react-hook-form";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import Grid from "@mui/material/Grid";
import "../../assets/styles/style.css";
import InputSelectField from "../../componets/common/InputComponents/InputSelectField";
import { Card, CardActions, CardContent, CardHeader } from "@mui/material";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import save_icon from "../../assets/images/save_icon.svg";
import QuillTextEditor from "../common/InputComponents/TextEditorWithDataField/QuillTextEditor";

import crossIcon from "../../assets/images/crossIcon.svg";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
} from "@mui/material";
import Header from "../../componets/common/Header/Header";
import YesNoRadioButton from "../../componets/common/InputComponents/YesNoRadioButton";
import InputField from "../../componets/common/InputComponents/InputField";
import { useLogin, useNotify } from "react-admin";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import ToggleButtons from "../../componets/common/InputComponents/ToggleButtons";
import TextEditor from "../../componets/common/InputComponents/TextEditor";
import InputDateField from "../../componets/common/InputComponents/InputDateFieldOld";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useParams } from "react-router";
import {
  Loading,
  useCreate,
  useGetList,
  useRefresh,
  useStore,
  useUpdate,
  useGetIdentity,
  useGetOne,
} from "react-admin";
import formValidation from "../../yupFormValidationRules/formValidator";
import { JSONTree } from "react-json-tree";
import { method } from "lodash";
import moment from "moment";
const UpdateNotification = (props) => {
  // const login = useLogin();
  const notify = useNotify();
  const { states } = props;
  const navigate = useNavigate();
  const refresh = useRefresh();
  const { data: dataField } = useGetList("GETDataFiled");
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const { id } = useParams();


  const validationSchema = yup.lazy((value) =>
    yup.object().shape({
      Active: formValidation.StringRequiredValidation,
    })
  );


  const { data: getStates, isLoading: isLoadingStates } = useGetOne(
    "getStates",
    {
      id: 1,
    },
    { refetchOnWindowFocus: false }
  );

  const {
    data: getSystemNotification,
    isLoading: loadinggetSystemNotification,
  } = useGetList(
    "getSystemNotification",
    {
      filter: {
        subscriberID: switchSubscriberObj?.value,
        id: [id],
        isDeleted: [id]
      },
    }
   
  );



  const toggleButton = [
    {
      value: "Inactive",
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: "Active",
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  const [propertyFilter, setPropertyFilter] = useState([]);

  const {
    data: getCollectiveListOfPropertyAndOffice,
    isLoading: officelistIsLoading,
    error: officelistError,
  } = useGetList(
    "getCollectiveListOfPropertyAndOffice",
    {
      filter: propertyFilter,
    },
    { enabled: propertyFilter?.length > 0 }
  );


  useEffect(() => {
    let data = getSystemNotification && getSystemNotification[0];
    console.log("data12", data?.Active);
    const getFormData: any = {
      Template: data?.Template,
      TemplateName: data?.TemplateName,
      StartDate: data?.StartDate,
      StartTime: data?.StartTime,
      EndTime: data?.EndTime,
      EndDate: data?.EndDate,
      Active: data?.Active == 1 ? "Active" : "Inactive",
      SubscriberID: subscriberlist
        ?.map((item) => ({
          label: item.value,
          value: item.id,
        }))
        .filter(
          (item) => data?.SubscriberID?.split(",")?.includes(item.value)
        ),
      //data?.SubscriberID.value,
      VisibleTo: data?.VisibleTo?.split(",").map((item) => ({
        label: item,
        value: item,
      })),
      // PropertyOrOffice: data?.PropertyOrOffice?.split(',').map((item) => ({
      //   label: item,
      //   value: item,
      // })),

      States: getStates?.data
        ?.map((item) => ({
          label: item.StateShortCode + " - " + item.StateName,
          value: item.id,
        }))
        .filter((item) => data?.States?.split(",")?.includes(item.label)),
      // SubscriberID:data?.SubscriberID,
      // VisibleTo:data?.VisibleTo,
      // States:data?.States
      // SubscriberID: data?.SubscriberID?.map((item) => item.data),
      // PropertyOrOffice: data?.PropertyOrOffice?.map((item) => item.data),
      // States: data?.States?.map((item) => item.data),
      isDeleted: 0
    };
    if (getSystemNotification && getSystemNotification[0] && data) {
      if (!methods.getValues("TemplateName")) {
        setPropertyFilter(data?.SubscriberID?.split(','))

        methods.reset(getFormData);
      }
    }
    console.log("getformdata", getFormData)
  }, [getSystemNotification && getSystemNotification[0]]);

  useEffect(() => {
    let data = getSystemNotification && getSystemNotification[0];
    const PropertyOrOffice = getCollectiveListOfPropertyAndOffice
      ?.map((item) => ({
        value: item?.ID,
        label: item.SubscriberPropertyName || item.SubscriberOfficeName,
      }))
      .filter(
        (item) => (data?.PropertyOrOffice?.split(","))?.includes(item.value)
      )
   
    if (!methods.getValues("PropertyOrOffice"))
      methods.setValue("PropertyOrOffice", PropertyOrOffice)

  }, [getCollectiveListOfPropertyAndOffice, getSystemNotification])
  const [create, { isLoading, error }] = useCreate();

  const onSubmit = (value, e) => {
    e.preventDefault();
    console.log(value);
    let data = getSystemNotification && getSystemNotification[0];
    console.log("Value", data);
    create(
      "addSystemNotification",
      {
        data: {
          //  ...value,

          id: data?.id,
          Template: value?.Template,
          TemplateName: value?.TemplateName,
          StartDate: value?.StartDate,
          EndDate: value?.EndDate,
          StartTime: value?.StartTime,
          EndTime: value?.EndTime,
          Active: value?.Active == "Active" ? 1 : 0,
          SubscriberID: value?.SubscriberID?.map((item) => item.value),
          VisibleTo: value?.VisibleTo?.map((item) => item.value),
          PropertyOrOffice: value?.PropertyOrOffice?.map((item) => item.value),
          States: value?.States?.map((item) => item.label),
          isDeleted: 0

        
        },
      },

      {
        onSuccess: (data) => {
          notify(`Notification updated successfully.`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          methods?.reset();
          navigate(-1);
          refresh();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
  const {
    data: subscriberlist,
    isLoading: subscriberlistIsLoading,
    error: subscriberlistError,
  } = useGetList("getsubscribers", {});
  const SubscriberIDValue = methods.watch<any>("SubscriberID");
  const start_date = methods.watch("StartDate");
  return (
    <>
      <div className="home_content">
        <div className="row">
          <div className="col-12 d-flex">
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Typography sx={{ fontWeight: "bold" }}>
                <Typography
                  color="textPrimary"
                  style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.38)" }}
                  onClick={() => navigate(-1)}
                >
                  System Notifications
                </Typography>
              </Typography>
              <Typography color="textPrimary" style={{ fontWeight: "bold" }}>
                System Notifications Details
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="form">
          <div className="auth-form mr-t30">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputField2
                      label="Notification Name"
                      // register={methods.register("email")}
                      source={`TemplateName`}
                      placeholder="Enter template name here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <ToggleButtons
                      toggleButton={toggleButton}
                      label="Status"
                      labelClass={"lable-title"}
                      source={`Active`}
                      onClickHandler={(event) => event.stopPropagation()}
                    />
                  </Grid>
                </Grid>{" "}
                <br />
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputDateField
                      source={"StartDate"}
                      label="Start Date"
                      labelClass={"lable-title"}
                      minDate={new Date()}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField2
                      label="Start Time"
                      type="time"
                      source={`StartTime`}
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      placeholder={undefined}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputDateField
                      source={"EndDate"}
                      label="End Date"
                      labelClass={"lable-title"}
                      minDate={start_date ? moment(start_date).toDate() : new Date()}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <InputField2
                      label="End Time"
                      type="time"
                      source={`EndTime`}
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      placeholder={undefined}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    {/* <p style={{paddingTop:10}}>Subscribers</p> */}

                    {subscriberlist && (
                      <InputSelectField
                        onChangeCallback={(value) => {
                          methods.setValue('SubscriberID', value);
                      
                          setPropertyFilter(value.map((item) => item.value));
                          if (value.length === 0) {
                            methods.setValue("PropertyOrOffice", []);  // Clear "Properties/Offices" field
                          }
                        }
                      }
                        label="Subscribers"
                        options={subscriberlist?.map((item) => ({
                          label: item.value,
                          value: item.id,
                        }))}
                        source={"SubscriberID"}
                        required={false}
                        stopAutoFocus={true}
                        multiple
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                     {getCollectiveListOfPropertyAndOffice && SubscriberIDValue &&(
                      <InputSelectField
                        onChangeCallback={() => {
                          //  setFilters({
                          //     ...filterValues,
                          //    office: form.getValues('office').value,  // Update the search_by filter with the new search value
                          // },[])
                          // resetForm();
                        }}
                        label="Properties/Offices"
                        options={getCollectiveListOfPropertyAndOffice?.map((item) => {
                          return {
                            value: item?.ID,
                            label: item?.SubscriberPropertyName || item.SubscriberOfficeName,
                          };
                        })}
                        source={"PropertyOrOffice"}
                        required={false}
                        stopAutoFocus={true}
                        multiple
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={4}>
                    <InputSelectField
                      label="States"
                      source={`States`}
                      required={false}
                      options={getStates?.data?.map((item) => ({
                        value: item?.id,
                        label: item.StateShortCode + " - " + item.StateName,
                      }))}
                      multiple
                      stopAutoFocus={true}
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
                    <InputSelectField
                      label="Visible To"
                      source="VisibleTo"
                      required={false}
                      options={[
                        { label: "All Real Estate", value: "realEstate" },
                        { label: "All ATS", value: "ats" },
                        { label: "All Doc-Upload", value: "docUpload" },
                        { label: "All Guestcard", value: "guestcard" },
                      ]}
                      multiple
                      stopAutoFocus={true}
                    />
                  </Grid> */}
                </Grid>
                <br />
                <div className="row d-flex">
                  <div className="col-sm-11 mr-b20">
                    <label htmlFor="quillTextEditor" className="form-label">
                      Text
                    </label>
                    <QuillTextEditor
                      dataFieldOptions={dataField}
                      source={"Template"}
                      control={methods.control}
                    />
                  </div>
                </div>
                <Box
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
                className="mt-4"
                minHeight="10vh"
                // position={"fixed"}
                // bottom={70}
                // right={30}
                >
                  <Box>
                    <Button
                      sx={{ mx: 1 }}
                      onClick={() => {
                        methods?.reset();
                        navigate(-1);
                      }}
                      variant="outlined"
                      color="secondary"
                      type="button"
                    >
                      Cancel
                    </Button>
                    <Button
                      startIcon={<img src={save_icon} alt="save icon" />}
                      sx={{ mx: 1 }}
                      variant="contained"
                      type="submit"
                    >
                      Update
                    </Button>
                  </Box>
                </Box>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateNotification;
