import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Card } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Datagrid,
  FunctionField,
  NumberField,
  useCreate,
  useGetList,
  useGetOne,
  useNotify,
  useRefresh,
  useStore,
  useUpdate,
} from "react-admin";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";
import DocumentAnalysisShow from "../../componets/DocumentAnalysis";
import AlertDialogTable from "../../componets/DocuploadTableComponent/AlertDialogTable";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import "../../App.css";
import ToggleButtons from "../../componets/common/InputComponents/ToggleButtons";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormLabel,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Backdrop,
  Typography,
  Button as MuiButton,
} from "@mui/material";
import { RaDatagrid_headerCell } from "../../componets/common/inlineStyle";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import InputSelectField from "../../componets/common/InputComponents/InputSelectField";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { JSONTree } from "react-json-tree";
import moment from "moment";
// import formValidation from "../../../yupFormValidationRules/formValidator";
import formValidation from "../../yupFormValidationRules/formValidator";
import ApplicationSettingsDocument from "../../componets/Settings/ApplicationProfileSettings/ApplicationSettingsDocument";
import ApplicationSettingsCosigner from "../../componets/Settings/ApplicationProfileSettings/ApplicationSettingsCosigner";
import ApplicationSettingsCoapplicant from "../../componets/Settings/ApplicationProfileSettings/ApplicationSettingsCoapplicant";
import ApplicationSettingsApplicant from "../../componets/Settings/ApplicationProfileSettings/ApplicationSettingsApplicant";
import { useFieldArray } from 'react-hook-form';
import { Delete, Save } from "@mui/icons-material";
import ApplicationSettingsPreAuth from "../../componets/Settings/ApplicationProfileSettings/ApplicationSettingsPreAuth";

const ApplicationSettingsChild = ({ tabClick }) => {
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [switchSubscriberObj] = useStore("switchSubscriberName");

  const { data: getapplicationprofilelist, isLoading } = useGetList(
    "getapplicationprofilelist"
  );

  const { id } = useParams();
  const {
    data: getapplicationprofiledetails,
    isLoading: getapplicationprofiledetailsIsLoading,
    error: getapplicationprofiledetailsError,
  } = useGetOne(
    "getapplicationprofiledetails",
    {
      id: [id],
    },
    { refetchOnWindowFocus: false }
  );
  console.log(id, getapplicationprofiledetails?.profiledetails?.[0], "getapplicationprofiledetails77")
  const ProfileTab = {
    Identification: "Documents Upload",
    Registration: "Registration",
    ContextualQuestions: "Contextual",
    IncomeBankingQuestions: "Income & Banking",
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };
  const require = [
    {
      value: "require",
      label: "require",
      icon: <DoneAllIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];

  const threetoggleButton = [
    {
      value: "Hide",
      label: "hide",
      icon: <VisibilityOffOutlinedIcon />,
      borderColor: "#FF0000",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#90909033",
      color: "#FF0000",
    },
    {
      value: "Optional",
      label: "Optional",
      icon: <RemoveRedEyeOutlinedIcon />,
      borderColor: "#8A3324",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#46a96f1a",
      color: "#8A3324",
    },
    {
      value: "require",
      label: "require",
      icon: <DoneAllIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const location = useLocation();

  const documenttoggleButton = [
    {
      value: "Hide",
      label: "Hide",
      icon: <VisibilityOffOutlinedIcon />,
      borderColor: "#FF0000",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#46a96f1a",
      color: "#FF0000",
    },
    {
      value: "Optional",
      label: "optional",
      icon: <RemoveRedEyeOutlinedIcon />,
      borderColor: "#8A3324",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#90909033",
      color: "#8A3324",
    },

    {
      value: "require",
      label: "require",
      icon: <DoneAllIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  useEffect(() => {
    setValue(getapplicationprofiledetails?.profiledetails?.[0]?.PreAuthTab == "No" ? ProfileTab?.Identification : "1")
  }, [getapplicationprofiledetails])

  const [value, setValue] = React.useState(location?.state?.formData?.PreAuthTab == "No" ? ProfileTab?.Identification : "1");

  return (
    <TabContext value={value}>

      {/* <JSONTree data={location.state.formData.PreAuthTab}/> */}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginTop: "10px",
        }}
      >
        <TabList
          onChange={handleChange}
          style={{
            width: "100%",
          }}
        >
          {getapplicationprofiledetails?.profiledetails?.[0]?.PreAuthTab != "No" && <Tab label="Pre-authorization Questionnaire" value="1" />}
          <Tab label="Registration" value={ProfileTab?.Registration} />
          <Tab label="Identification" value={ProfileTab?.Identification} />

          <Tab label="Contexual" value={ProfileTab?.ContextualQuestions} />
          <Tab label="Income & Banking" value={encodeURIComponent(ProfileTab?.IncomeBankingQuestions)} />

        </TabList>
      </Box>

      {getapplicationprofiledetails?.profiledetails?.[0]?.PreAuthTab != "No" && <TabPanel value="1">
        <ApplicationSettingsPreAuth tabClick={tabClick} />
      </TabPanel>}

      <TabPanel value={ProfileTab?.Identification}>
        <ApplicationSettingsApplicant tabClick={tabClick} currentProfileTab={value} />
      </TabPanel>

      <TabPanel value={ProfileTab?.Registration}><ApplicationSettingsApplicant tabClick={tabClick} currentProfileTab={value} /></TabPanel>
      <TabPanel value={ProfileTab?.ContextualQuestions}><ApplicationSettingsApplicant tabClick={tabClick} currentProfileTab={value} /></TabPanel>
      <TabPanel value={encodeURIComponent(ProfileTab?.IncomeBankingQuestions)}><ApplicationSettingsApplicant tabClick={tabClick} currentProfileTab={value} /></TabPanel>

    </TabContext>
  );
};

export default ApplicationSettingsChild;
