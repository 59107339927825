import React, { useCallback, useEffect, useState } from "react";
// import CustomRadioButton from "../../../registration/Inputs/customRadioButton";
import { useFormContext } from "react-hook-form";
// import InputField from "../../../registration/Inputs/InputField";
// import InputFieldArray from "./InputFieldArray";
// import InputDateField from "../../../registration/Inputs/InputDateField";
// import Alias from "./alias/alias";
import { useDispatch, useSelector } from "react-redux";
// import { sideNavAction } from "../../../../store/identification/sideMenuSlice";
import { AiOutlineArrowDown } from "react-icons/ai";
import { AccordionDetails, Button } from "@mui/material";
import debounce from "lodash.debounce";
import { ImCross } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import InputField from "../../common/InputComponents/InputField";
import InputFieldArray from "./InputFieldArray";
import InputDateField from "../../common/InputComponents/InputDateFieldOld";
import CustomRadioButton from "../../common/InputComponents/CustomRadioButton";
import Alias from "./Alias";
import InputField2 from "../../common/InputComponents/InputField2";
import { JSONTree } from "react-json-tree";

const PersonalDetailSection = (props) => {
  // 
  const navigate = useNavigate();
  //   const useWindowWidth = useSelector(
  //     (state) => state.identification.sideMenuSlice
  //   );
  const dispatch = useDispatch();
  const { register, watch, getValues, setValue, formState } = useFormContext();
  const [yearsOld, setYearsOld] = useState(false);
  const [nextSection, setNextSection] = useState(false);
  const [deleteAlias, setDeleteAlias] = useState(false);
  const [showAnotherPhone, setShowAnotherPhone] = useState(
    JSON.stringify(watch(`applicant.otherPhoneCaption`)) ? true : false
  );

  // highlight-starts
  // const debouncedSave = useCallback(
  //   debounce(
  //     (nextValue) =>
  //       (async () => {
  //         setNextSection(await props?.checkValidation("personalDetails", false));
  //       })(),
  //     1000
  //   ),
  //   [] // will be created only once initially
  // );
  // highlight-ends

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // debouncedSave(name);
      if (name === "applicant.alias_status" && type === "change") {
        if (watch("applicant.alias_status") === "Yes") {
          //   dispatch(sideNavAction.isaliasrequired("required"));
          setValue("applicant.Alias", [{ fname: "", lname: "" }]);
        }
        if (watch("applicant.alias_status") === "No") {
          //   dispatch(sideNavAction.isaliasrequired("notRequired"));
        }
      }
      if (name === "applicant.availableToUpload_id" && type === "change") {
        if (watch("applicant.availableToUpload_id") === "Yes") {
          //   dispatch(sideNavAction.isproofofidentityrequired("required"));
        }
        if (watch("applicant.availableToUpload_id") === "No") {
          //   dispatch(sideNavAction.isproofofidentityrequired("notRequired"));
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [getValues, setValue, watch, dispatch]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // 
      if (type === "change") {
        if (
          watch(`applicant.first_name`) === "" ||
          watch(`applicant.first_name`) === null
        ) {
        }
        if (
          watch(`applicant.last_name`) === "" ||
          watch(`applicant.last_name`) === null
        ) {
        }
        if (watch(`applicant.DOB`) === "" || watch(`applicant.DOB`) === null) {
        }
      }
      if (value.applicant?.otherPhoneNumber) {
        setShowAnotherPhone(true);
      }
    });
    return () => subscription.unsubscribe();
  }, [getValues, setValue, watch, formState]);

  return (
    <>
      <AccordionDetails>
        <div className="pd-form">
          <div>
            <div className="row form-inputs">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="form-group cus-input-group mr-b30">
                  {/* <InputField
                    label="First Name"
                    register={register("applicant.first_name")}
                  /> */}
                  <InputField2
                    label="First Name"
                    source={`applicant.first_name`}
                    placeholder="First Name"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="form-group cus-input-group mr-b30">
                  {/* <InputField
                    notRequired={true}
                    label="Middle Name"
                    register={register("applicant.middle_name")}
                  /> */}
                  <InputField2
                    label="Middle Name"
                    source={`applicant.middle_name`}
                    placeholder="Middle Name"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                    notRequired={true}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="form-group cus-input-group mr-b30">
                  {/* <InputField
                    label="Last Name"
                    register={register("applicant.last_name")}
                  /> */}
                  <InputField2
                    label="Last Name"
                    source={`applicant.last_name`}
                    placeholder="Last Name"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                  />
                </div>
              </div>
              <div className="row a-blue-box">
                <div className="col-sm-12 col-md-8 col-lg-8 blue-in-box d-flex a-blue-box">
                  <div
                    style={{
                      flex: 1,
                    }}
                    className="register-form-group"
                  >
                    <div
                      id="mobile"
                      //   tabindex="0"
                      className="form-group cus-input-group register-mobile-input"
                    >
                      <InputFieldArray
                        showNumberType={false}
                        addMultipleNumber={false}
                        register={register}
                        rigesterKey={"mobile"}
                        handleAPIDebounced={props?.handleAPIDebounced}
                      />
                    </div>
                  </div>
                  <div className="mt-4" style={{ flex: 0.5 }}>
                    {!showAnotherPhone && (
                      <div className=" ms-2 ">
                        <button
                          className="add-num-number"
                          type="button"
                          onClick={() => {
                            setValue(`applicant.otherPhoneCaption`, null);
                            setValue(`applicant.otherPhoneNumber`, null);
                            setShowAnotherPhone(true);
                          }}
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                              fill="#2DC3E8"
                            />
                          </svg>
                          &nbsp;
                          <span
                          // className={`${
                          //   useWindowWidth.windowWidth < 766 ? "font-10" : ""
                          // }`}
                          >
                            Add another number
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4 col-lg-4 d-flex justify-content-center align-items-center  ml-auto">
                  <div
                  // className={`${
                  //   useWindowWidth.windowWidth < 766
                  //     ? "padding-left-zero padding-right-zero  form-group col-sm-12 cus-input-group mr-b30"
                  //     : "form-group col-sm-12 cus-input-group mr-b60  "
                  // }`}
                  >
                    {/* <InputField
                      disable={true}
                      label="Email"
                      register={register("applicant.Email")}
                    /> */}
                    <InputField2
                      label="Email"
                      source={`applicant.Email`}
                      placeholder="Email"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                      disable={true}
                    />
                  </div>
                </div>
              </div>

              {showAnotherPhone && (
                <div className="row a-blue-box">
                  <div className="col-sm-12 col-md-8 col-lg-8 blue-in-box d-flex ">
                    <div style={{ flex: 1 }} className="register-form-group">
                      <div
                        id="mobile"
                        // tabindex="0"
                        className="form-group cus-input-group register-mobile-input"
                      >
                        <InputFieldArray
                          showNumberType={true}
                          addMultipleNumber={false}
                          register={register}
                          rigesterKey={"mobile"}
                          handleAPIDebounced={props?.handleAPIDebounced}
                        />
                      </div>
                    </div>
                    <div style={{ flex: 0.4 }} className="ms-4 mt-4">
                      <ImCross
                        className="cursor-pointer"
                        size={15}
                        fill={"#2DC3E8"}
                        onClick={() => {
                          setValue(`applicant.otherPhoneCaption`, null);
                          setValue(`applicant.otherPhoneNumber`, null);
                          setShowAnotherPhone(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="w-100"></div>
              <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="form-group cus-input-group mr-b30">
                  {/* <InputDateField
                    type="date"
                    notRequired={false}
                    // maxDate={moment().subtract(18, 'years').toDate()}
                    // minDate={moment().subtract(99, 'years').toDate()}
                    label="Date Of Birth"
                    placeholder="Enter Date"
                    register={register("applicant.DOB")}
                  /> */}
                  <InputDateField
                    source={"applicant.DOB"}
                    maxDate={moment().subtract(18, 'years').toDate()}
                     minDate={moment().subtract(99, 'years').toDate()}
                    label="Date Of Birth"
                    labelClass={"lable-title"}
                    onChangeCallback={() => {
                      props?.handleAPIDebounced();
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12">
                <div
                //   className={`${
                //     useWindowWidth.windowWidth < 766 ? "mt-2" : ""
                //   }`}
                >
                  <div className="form-group cus-input-group mr-b30">
                    <CustomRadioButton
                      disable={
                        watch("applicant.Alias")?.length > 0 ? true : false
                      }
                      onClick={() => {
                        if (watch(`applicant.Alias`)?.length > 0) {
                          setDeleteAlias(true);
                        }
                      }}
                      label="Have you ever used any other name(s) during the
                                past 5 years?"
                      register={register("applicant.alias_status")}
                    />
                  </div>
                </div>
              </div>
              {watch("applicant.alias_status") === "Yes" && (
                <>
                  <Alias
                    register={register}
                    handleAPIDebounced={props?.handleAPIDebounced}
                  />
                </>
              )}
            </div>
          </div>
          <Dialog
            open={deleteAlias}
            onClose={() => {
              setDeleteAlias(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`Are you sure want to delete
           ?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Warning! Once deleted cannot revert back .
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button
                className="btn btn-info text-light"
                onClick={() => {
                  setDeleteAlias(false);
                }}
              >
                No
              </button>
              <button
                className="btn btn-info text-light"
                onClick={() => {
                  setValue("applicant.Alias", []);
                  setValue("applicant.alias_status", "No");
                  setDeleteAlias(false);
                  props?.handleAPIDebounced();
                }}
              >
                Yes
              </button>
            </DialogActions>
          </Dialog>
        </div>
        {nextSection && (
          <Button
            onClick={() => {
              navigate("#poi");
            }}
            variant="contained"
            endIcon={<AiOutlineArrowDown />}
          >
            Next Tab
          </Button>
        )}
      </AccordionDetails>
    </>
  );
};

export default PersonalDetailSection;