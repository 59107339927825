import React, { Fragment, useState, useEffect } from "react";
// import MarkInactiveModal from "./MarkInactiveModal";
// import MarkActiveModal from "./MarkActiveModal";
// import FilterModal from "./FilterModal";
import { Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
    Datagrid,
    FunctionField,
    ListBase,
    Pagination,
    TextField,
    useListContext,
    useStore,
    useGetList,
    Resource,
    DateField
} from "react-admin";
import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Box,
    Link,
    ToggleButton,
    ToggleButtonGroup,
    Checkbox,
    Stack
} from "@mui/material";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import { ATS_API_URL } from "../../../config";
import InputFileImport from "../../common/InputFileImport";
import DownloadSampleButton from "../../common/DownloadSampleButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import { format } from 'date-fns';
import FilterModal from "../EmailSettings/FilterModal";
import CopyLinkHoverDropdown from "../PropertySetup/CopyLinkHoverDropdown";
import { JSONTree } from "react-json-tree";
import { ArrowBack } from "@mui/icons-material";



const MyListToolBar = ({ title }) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
        useListContext();
    const [applicantType, setApplicantType] = useState("All")
    const navigate = useNavigate();
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    console.log(switchSubscriberObj?.value, "switchSubscriberObj")
    // Debounce the search function
    const debouncedSearch = debounce((value) => {
        // Perform your search logic here
        setFilters({ ...filterValues, searchcode: value }, []);
    }, 500); // 500ms debounce delay

    const handleSearchChange = (event) => {
        const value = event.target.value;
        debouncedSearch(value);
    };


    const handleExportData = () => {
        const tokenString = localStorage.getItem('auth')
        let tokenSecret = ''
        if (tokenString) {
            try {
                const token = JSON.parse(tokenString);
                tokenSecret = token?.data?.tokendata || '';
            } catch (error) {
                console.error('Error parsing token:', error);
            }
        }


        const currentTimestamp = Date.now();

        const url = `${ATS_API_URL}/subscriber/exportEmailSettings?subscriberid=${switchSubscriberObj?.value
            }&_sort=${sort?.field || ""}&_order=${sort?.order || ""}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `${tokenSecret}`
            }
        }).then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `emailSettings-${currentTimestamp}.xlsx`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Error:', error));
    }




    return (
        <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
            {/* Left side with title */}
            <Grid item xs={12} md={6}>

                <Typography sx={{ fontWeight: "bold" }}>
                    <button
                        title="Go Back"
                        style={{ color: "#2cc0e4" }}
                        className="btn btn-link"
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBack />
                    </button>

                    <span>User Logs</span>

                </Typography>


            </Grid>

            {/* Right side with search input, filter button, and add button */}
            <Grid
                item
                xs={12}
                md={6}
                container
                justifyContent="flex-end"
                alignItems="center"
            >
                <div className="group-btn">
                    {/* Search Input */}
                    <MuiTextField
                        placeholder="search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        defaultValue={filterValues.searchcode}
                        onChange={handleSearchChange}
                        variant="outlined"
                        size="small"
                    />
                    <FilterModal />

                    {/* <button
                        onClick={handleExportData}
                        type="button"
                        className="g-line-btn"
                        title="Export all data"
                    >
                        <svg
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g clip-path="url(#clip0_14375_6193)">
                                <path
                                    d="M8.66667 8.4987H10.6667L8 11.1654L5.33333 8.4987H7.33333V5.83203H8.66667V8.4987ZM10 3.16536H3.33333V13.832H12.6667V5.83203H10V3.16536ZM2 2.49336C2 2.12803 2.298 1.83203 2.666 1.83203H10.6667L14 5.16536V14.494C14.0006 14.5816 13.984 14.6684 13.951 14.7495C13.9181 14.8306 13.8695 14.9045 13.808 14.9668C13.7466 15.0291 13.6734 15.0788 13.5928 15.1128C13.5121 15.1469 13.4255 15.1648 13.338 15.1654H2.662C2.48692 15.1641 2.31934 15.0941 2.19548 14.9704C2.07161 14.8466 2.0014 14.6791 2 14.504V2.49336Z"
                                    fill="#2DC3E8"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_14375_6193">
                                    <rect
                                        width="16"
                                        height="16"
                                        fill="white"
                                        transform="translate(0 0.5)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </button> */}

                    {/* <InputFileImport style={{ width: '400px' }} buttonText={"Import CSV"} importType={"Hierarchy"} /> */}

                    {/* <button
                        type="button"
                        onClick={() =>
                            navigate(`/ats/settings/usersetup/adduser`, {
                                state: { isAdding: true, thirdPartyAppId: 1 },
                            })
                        }
                        className="blue-btn px-4"
                    >
                        <svg
                            className="mr-r10"
                            width="10"
                            height="11"
                            viewBox="0 0 10 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M4.33337 4.83333V0.833334H5.66671V4.83333H9.66671V6.16667H5.66671V10.1667H4.33337V6.16667H0.333374V4.83333H4.33337Z"
                                fill="white"
                            />
                        </svg>
                        User
                    </button> */}

                </div>
            </Grid>
        </Grid>
    );
};


const MyList = ({ children, toolBar, ...props }) => {
    return (
        <ListBase
            {...props}
            perPage={100} // Number of items per page
        >
            {toolBar}
            {children}
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
            <br />
            <br />
            <br />
            <br />
        </ListBase>
    );
};




const ActiveBtn = () => {
    const myStyles = {
        display: "flex",
        width: "79px",
        padding: "8px 11px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "4px",
        background: "#E6FDF0",
    };
    const myTextStyles = {
        color: "var(--Success, #46A96F)",
        // textAlign: 'center',
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
    };
    return (
        <div style={myStyles}>
            <div style={myTextStyles}>Active</div>
        </div>
    );
};
const DeActiveBtn = () => {
    const myStyles = {
        display: "flex",
        width: "79px",
        padding: "8px 11px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "4px",
        background: "#FFF1F1",
    };
    const myTextStyles = {
        color: "var(--Success, #FF4848)",
        // textAlign: 'center',
        fontFamily: "Open Sans",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "normal",
    };
    return (
        <div style={myStyles}>
            <div style={myTextStyles}>Inactive</div>
        </div>
    );
};



const UserLogs = () => {
    const navigate = useNavigate();
    const { id: subscriberid } = useParams();
    console.log(subscriberid, "subscriberid");
    const location = useLocation();

    const params = useParams();
    console.log("params", params);
    const getlocalStorageItem: any = localStorage.getItem(
        "stateRecord" + params?.applicationId
    );
    let locationState = JSON.parse(getlocalStorageItem);
    const data = location.state || locationState;

    const [selectedRecords, setSelectedRecords] = useState<number[]>([]);
    const [headerCheckbox, setHeaderCheckbox] = useState(false);
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
        useListContext();
    // const {
    //     data: getusersetup,
    //     isLoading: loadingEmailSettingsDetails,
    // } = useGetList(
    //     "user-setup", {
    //     filter: {
    //         SubscriberId: switchSubscriberObj?.value,
    //         UserType: filterValues?.UserType,
    //     },
    // }


    // );


    const SelectAllHeader = ({ setSelectedRecords }) => {
        const { data } = useListContext();
        const [headerCheckbox, setHeaderCheckbox] = useState(false);

        const handleHeaderCheckboxChange = (event) => {
            const isChecked = event.target.checked;
            setHeaderCheckbox(isChecked);
            // Select/Deselect all records
            const allRecordIds = data.map(record => record.id);
            if (isChecked) {
                setSelectedRecords(allRecordIds);
            } else {
                setSelectedRecords([]);
            }
        };

        return (
            <Checkbox
                checked={headerCheckbox}
                onChange={handleHeaderCheckboxChange}
            />
        );
    };




    const handleCheckboxChange = (recordId: any) => {
        console.log("recordId", recordId)
        setSelectedRecords(prevSelectedRecords =>
            prevSelectedRecords.includes(recordId)
                ? prevSelectedRecords.filter(id => id !== recordId)
                : [...prevSelectedRecords, recordId]
        );
    };

    const anyCheckboxChecked = selectedRecords.length > 0;
    useEffect(() => {

        if (anyCheckboxChecked) {
            console.log('At least one checkbox is checked', selectedRecords);
        } else {
            console.log('No checkboxes are checked');
        }
    }, [selectedRecords.length]);
    // const per_size= params.pagination.page;

    return (

        <MyList
            toolBar={<MyListToolBar title={"User Setup"} />}
            sort={{ field: "id", order: "DESC" }}
        // filter={{ page_size:200 }}
        // filter={{ subscriberID: 10145 }}

        >

            {
                selectedRecords.length > 0 && (
                    <>
                        <Box sx={{ padding: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                            <Stack direction="row" spacing={2}>
                                {/* <MarkActiveModal status="InActive" selectedIds={selectedRecords}/>
            <MarkActiveModal status="Active" selectedIds={selectedRecords}/> */}
                            </Stack>
                        </Box>
                    </>
                )
            }


            <Datagrid
                sx={{
                    "& .RaDatagrid-headerCell": {
                        fontWeight: "bold",
                        fontSize: 12,
                    },
                    "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
                    "& .MuiTableBody-root.datagrid-body.RaDatagrid-tbody tr td.MuiTableCell-root.MuiTableCell-body:nth-child(4), & .MuiTableBody-root.datagrid-body.RaDatagrid-tbody tr td.MuiTableCell-root.MuiTableCell-body:nth-child(3)":
                        { width: "200px" },
                }}
                bulkActionButtons={false}
                isRowSelectable={record => true}

            >

                {/* <FunctionField
                    render={record => <Checkbox
                        checked={selectedRecords.includes(record.id)}
                        onChange={() => handleCheckboxChange(record.id)} />}
                    label={<SelectAllHeader setSelectedRecords={setSelectedRecords} />}
                /> */}

                {/* <FunctionField
                    source="Officeproperty"
                    label="Office/Property"
                    render={(record) =>
                        record.Officeproperty


                    }
                /> */}

                {/* <FunctionField sortBy="Email"
                    label={"Email"}
                    render={(record) =>
                        record?.Email
                    }
                /> */}
                 <TextField source="Ipaddress" label={'Ip address'}/>
                <TextField source="Device" />
                <TextField source="OS" />
                <TextField source="Browser" label={'browser'} />
                <TextField source="Location"  />
                <DateField source="Lastlogindate" label="LAST LOGIN" locales="en-US" /> 
                {/* <TextField source="Telephone" /> */}
                {/* <FunctionField source="Mobile" label={"Mobile No"} render={(record) => record.Mobile} /> */}


                {/* <FunctionField sortBy="OtherPhone"
                    label={"Other Phone"}
                    render={(record) =>
                        record?.OtherPhone
                    }
                /> */}
                {/* 
                <FunctionField sortBy="HierarchyName"
                    label={"Hierarchy"}
                    render={(record) =>
                        record?.HierarchyName
                    }
                /> */}







                {/* Add more fields as needed */}
            </Datagrid>
        </MyList>
    );

};

export default UserLogs;
