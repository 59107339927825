import React, { useEffect } from "react";
import { useFormContext, useController } from "react-hook-form";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";

const MultiEmailInput = ({
  labelRequired = true,
  label,
  source,
  notRequired = false,
  minLength = 0,
  maxLength = 0,
  disable = false,
  placeholder,
  handleOnChange = (e) => {},
  labelClass = "lable_title",
  additionalInputClass = "",
}) => {
  const required = !notRequired;
  const [focused, setFocused] = React.useState(false);
  const { control, setValue, watch } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: source,
    control,
  });

  useEffect(() => {
    const element = document.querySelector(".react-multi-email");
    element?.classList?.remove("focused"); // Remove a class focused when focused input
  }, [focused]);

  return (
    <div className="emp-inputs form-label multi_email">
      {label !== "" && labelRequired && (
        <label
          className={`${labelClass} ${error && "text-danger"}`}
          htmlFor="inputs"
        >
          {label} {!notRequired && <span>&#42;</span>}
        </label>
      )}
      <ReactMultiEmail
        placeholder={placeholder || `${label}`}
        {...field}
        emails={field.value}
        onChange={(_emails: string[]) => {
          field.onChange(_emails);
          handleOnChange(_emails);
        }}
        // autoFocus={true}
        onFocus={() => {
          setFocused(!focused);
        }}
        // onBlur={() => setFocused(false)}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
        className={`form-control cus-input-control ${
          (required && !field.value) || (required && field?.value?.length === 0)
            ? "is-warning"
            : ""
        } ${error !== undefined && "is-invalid"} ${additionalInputClass}`}
      />
      <div
        className="invalid-feedback"
        style={{ display: "block", fontSize: "70%" }}
      >
        {error !== undefined && error.message}
      </div>
    </div>
  );
};

export default MultiEmailInput;
