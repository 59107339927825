import React, { useState } from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
import { TiDelete } from "react-icons/ti";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CardHeader } from "@mui/material";
import DeleteContainedBtn from "../../../../common/DeleteContainedBtn";
import InputField2 from "../../../../common/InputComponents/InputField2";
import { useMutation } from "react-query";
import InputCheckBoxField from "../../../../common/InputComponents/InputCheckBoxField";
import DragDropDocumentButton from "../../../components/DragDropDocumentButton";
import { dataProvidersAts } from "../../../../../dataproviders/DataProvidersAts";
import { useNotify } from "react-admin";
import FilePreview from "../../../../common/FilePreview";
import CustomPreviewUpload from "../../../../common/CustomPreviewUpload";

const Pets = (props) => {
  const dispatch = useDispatch();
  const { register } = props;
  const { control, setValue, watch, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "have_pet.fields", // unique name for your Field Array
    keyName: "arrId",
    //default to "id", you can change the key name
  });
  const { errors } = useFormState({
    control,
  });
  const [open, setOpen] = useState(false);
  const [fieldId, setFieldId] = useState(null);
  const notify = useNotify();
  const { mutate, isLoading, isSuccess } = useMutation(
    "tag",
    (id) => dataProvidersAts.deleteDocument(id),
    {
      onSuccess: (data) => {
        // setConfirmDelete(false);
        // queryClient.invalidateQueries([`${invalidateQueries}`]);
        notify("Document deleted successfully...", { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
      },
      onError: (data) => {
        // setConfirmDelete(false);
        alert("Fail deleting document");
      },
      onMutate: () => {
        notify("Document is Deleting...", { type: "info", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
      },
    }
  );

  // const [deleteDocument, result] = useDeleteDocumentMutation();
  // const [deleteDocument, result] = deleteDocument();
  const trn_id = useSelector((state: any) => state?.users?.userTrnId);
  const petImageId = useSelector((state: any) => state.applicantDetails);
  const [filePreview, setFilePreview] = useState({
    pet_file_id: "",
    pet_img_filePath: "",
    index:"",
  });
  
  const deleteDocumentHandler = (id, type) => {
    // mutate(id);
    // deleteDocument(id).then((response) => {
    //   if (response.data.success === true) {
    //     dispatch(
    //       sideNavAction.uploadPetImageId({
    //         index: fieldId,
    //         id: id,
    //         type: type,
    //       })
    //     );
    //     // setShowPetImage(false);
    //   }
    // });
  };

  return (
    <>
      {fields?.map((field, index) => (
        <div key={field.arrId}>
          <div className="row">
            <div className="col-sm-12 mb-2 ">
              {/* <span className="s-no-index float-left ">{index + 1}</span>{" "} */}
              {/* <button
                type="button"
                onClick={async () => {
                  remove(index);
                  props?.checkSourceAvailableHandler("pet");
                }}
                title="Delete this Employer"
                className="float-right btn btn-sm btn-danger"
              >
                <TiDelete size={25} /> Delete
              </button> */}
              <CardHeader
                sx={{ padding: 0 }}
                title={<b className="s-no-index-number">{index + 1}</b>}
                action={
                  <DeleteContainedBtn
                    onClick={async () => {
                      remove(index);
                      props?.checkSourceAvailableHandler("pet");
                      props?.handleAPIDebounced();
                    }}
                    title="Delete this Employer"
                  />
                }
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
              {/* <InputField
                label={`Pet Type`}
                register={register(`have_pet.fields.${index}.pet_type`)}
              /> */}
              <InputField2
                label="Pet Type"
                source={`have_pet.fields.${index}.pet_type`}
                placeholder="Pet Type"
                labelClass={"lable-title"}
                additionalInputClass={"cus-control"}
                handleOnChange={() => {
                  props?.handleAPIDebounced();
                }}
              />
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
              {/* <InputField
                label={`Breed`}
                register={register(`have_pet.fields.${index}.pet_breed`)}
              /> */}
              <InputField2
                label="Breed"
                source={`have_pet.fields.${index}.pet_breed`}
                placeholder="Breed"
                labelClass={"lable-title"}
                additionalInputClass={"cus-control"}
                handleOnChange={() => {
                  props?.handleAPIDebounced();
                }}
              />
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
              {/* <InputField
                type={"tel"}
                notRequired={true}
                label={`Weight`}
                register={register(`have_pet.fields.${index}.pet_weight`)}
              /> */}
              <InputField2
                type={"tel"}
                label="Weight"
                source={`have_pet.fields.${index}.pet_weight`}
                placeholder="Weight"
                labelClass={"lable-title"}
                additionalInputClass={"cus-control"}
                handleOnChange={() => {
                  props?.handleAPIDebounced();
                }}
                notRequired={true}
              />
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
              {/* <InputField
                notRequired={true}
                label={`Pet Name`}
                register={register(`have_pet.fields.${index}.pet_name`)}
              /> */}
              <InputField2
                label="Pet Name"
                source={`have_pet.fields.${index}.pet_name`}
                placeholder="Pet Name"
                labelClass={"lable-title"}
                additionalInputClass={"cus-control"}
                handleOnChange={() => {
                  props?.handleAPIDebounced();
                }}
                notRequired={true}
              />
            </div>

            <div className="col-sm-6 col-md-4 col-lg-4 mr-t30">
              <small>Is the pet been Sprayed / Neurated?</small>
              <div
                // className={`${
                //   useWindowWidth.windowWidth < 766
                //     ? "d-flex  align-items-center "
                //     : "d-flex  align-items-center "
                // }`}
                callName="d-flex  align-items-center "
              >
                <label htmlFor="YES">
                  <small>Yes</small>
                  <input
                    className="mr-l10 "
                    checked={
                      watch(`have_pet.fields.${index}.pet_Spayedneutered`) ==
                      "YES"
                    }
                    type={"radio"}
                    value={"YES"}
                    onChange={(e) => {
                      setValue(
                        `have_pet.fields.${index}.pet_Spayedneutered`,
                        "YES"
                      );
                      props?.handleAPIDebounced();
                    }}
                  />
                </label>
                <label htmlFor="NO">
                  <small className="mr-l10">No</small>
                  <input
                    className="mr-l10"
                    checked={
                      watch(`have_pet.fields.${index}.pet_Spayedneutered`) ==
                      "NO"
                    }
                    type={"radio"}
                    value={"NO"}
                    onChange={(e) => {
                      setValue(
                        `have_pet.fields.${index}.pet_Spayedneutered`,
                        "NO"
                      );
                      props?.handleAPIDebounced();
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mr-t30">
              <InputCheckBoxField
                checked={watch(`have_pet.fields.${index}.service_animal`)}
                notRequired={true}
                label={"Check the box if service animal"}
                // register={register(`have_pet.fields.${index}.service_animal`)}
                name={`have_pet.fields.${index}.service_animal`}
                handleOnChange={() => {
                  props?.handleAPIDebounced();
                }}
              />
            </div>
            {/* file uplode and preview  section start*/}
            <div className="col-12">
            <div
              className={`row mb-2 sb-blue`}
            >
              <div
                className={
                  filePreview.pet_file_id !== ""
                    ? "col-md-8 col-sm-12 "
                    : "col-md-12 col-sm-12 "
                }
              >
                <div className="sb-blue m-1">
                  <div className="blue-flex">
                    <div className="row align-items-center print-hide">
                      <div
                        className="col-sm-12 col-md-10"
                        style={{
                          fontSize: "11px",
                          color: "#606060",
                          // paddingLeft: "16px",
                        }}
                      >
                        <div className="d-flex align-items-center ">
                          <strong>Image / Documentation of pet:</strong>
                        </div>
                        <div className="d-flex align-items-center ">
                          <strong>(You can add a picture and/or documentation about your pet here)</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row"> </div>
                  <div className="col-md-12">
                    <div className="d-flex flex-wrap mt-2">
                      <div className="">
                        <DragDropDocumentButton
                          SourceId={0}
                          documentId={14}
                          fetchRecord={() => {}}
                          acceptedFiles={`image/*,application/pdf`}
                          invalidateQueries={"otherDoc"}
                          tag={`fileOprations_other_income`}
                          trn_id={trn_id}
                          fileError={false}
                          petIndex={index}  // passing index for pet images store
                          handleOnChange={() => props?.handleAPIDebounced()} // passing this to call onsubmit after uplode a pet images
                          from="petsImage"
                        />
                      </div>
                      <div className="d-flex">
                        {/* {otherDocData?.other_documents.map((doc) => ( */}
                      {petImageId?.uploadPetImageId[index]?.id && (
                          <div
                            className="px-1"
                          >
                            <FilePreview
                              doc_id={getValues(`have_pet.fields.${index}.file_id`) || petImageId?.uploadPetImageId[index]?.id}
                              img={getValues(`have_pet.fields.${index}.file_image`) || petImageId?.uploadPetImageId[index]?.file}
                              tag={"delete_document_pet_document"}
                              invalidateQueries={"otherDocData"}
                              file={getValues(`have_pet.fields.${index}.file`)}
                              handleClick={() =>
                                setFilePreview({
                                  ...filePreview,
                                  pet_file_id: getValues(`have_pet.fields.${index}.file_id`) || petImageId?.uploadPetImageId[index]?.id,
                                  pet_img_filePath: getValues(`have_pet.fields.${index}.file_image`) || petImageId?.uploadPetImageId[index]?.file,
                                  index: index,
                                })
                              }
                              petIndex={index}  // passing index for pet images delete from store
                              handleOnChange={() => props?.handleAPIDebounced()} // passing this to call onsubmit after uplode a pet images
                            />
                          </div>
                        )}
                        {/* ))} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {filePreview.pet_file_id !== "" && filePreview.index == index && (
                <div className="col-md-4 col-sm-12 mt-2 ">
                  <CustomPreviewUpload
                    filePath={filePreview.pet_img_filePath}
                  />
                </div>
              )}
            </div>
            </div>
            {/* file uplode and preview  section end*/}

            <div className="d-flex"></div>
            {errors && errors[index] && errors[index].number}
          </div>
          <br />
          <br />
          <br />
        </div>
      ))}
      {/* fields.length && */}

      <>
        <div className="add-text-btn flex1 text-center ps-2 pt-3 ">
          <button
            type="button"
            onClick={(event) =>
              append({
                id: 0,
                pet_type: "",
                pet_breed: "",
                pet_weight: "",
                pet_name: "",
                pet_Spayedneutered: "YES",
                service_animal: false,
              })
            }
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                fill="#2DC3E8"
              />
            </svg>
            &nbsp; Add another Pet or Service Animal
          </button>
        </div>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Are you sure want to delete document ?`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Warning! Once deleted cannot revert back .
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              No
            </Button>
            <Button
              onClick={() => {
                console.warn(watch(`have_pet.fields.${fieldId}`));
                if (watch(`have_pet.fields.${fieldId}.file_id`)) {
                  // 
                  deleteDocumentHandler(
                    watch(`have_pet.fields.${fieldId}.file_id`),
                    "DELETE"
                  );
                } else {
                  // 
                  deleteDocumentHandler(
                    // getPetId.uploadPetImageId[`${fieldId}`]?.id,
                    props?.data?.have_pet?.fields[`${fieldId}`]?.id,
                    "DELETE"
                  );
                }
                setTimeout(() => {
                  setOpen(false);
                }, 200);
                props?.handleAPIDebounced();
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};

export default Pets;
