import React from "react";
import { useFormContext, useController } from "react-hook-form";
import { TextInput } from "react-admin";

const InputField2 = ({ labelRequired = true, label, source, notRequired = false, minLength = 0, maxLength = 0, type = 'text', disable = false, placeholder,handleOnChange=(e)=>{}, labelClass = "lable_title", additionalInputClass = "" }) => {
  const required = !notRequired;
  const { control, setValue, watch } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: source,
    control,
  });

  return (

    <div className="emp-inputs form-label">
      { label !== '' && labelRequired && <label className={`${labelClass} ${error && "text-danger"}`} htmlFor="inputs">{label} {!notRequired && <span>&#42;</span>}</label> }
      <input
        type={type ? type : "text"}
        disabled={disable === true ?? "disabled"}
        placeholder={placeholder || `${label}`}
        className={`form-control cus-input-control ${required && !field.value && "is-warning"
          } ${error !== undefined && "is-invalid"} ${additionalInputClass}`}
        {...field}
        onBlur={(e) => {
          field.onBlur();
        }}
        value={field.value || ""}
        id={source}
        onChange={e=>{
          field.onChange(e);
          handleOnChange(e)
        }}
        autoComplete={"nope"} // Added to prevent browser cache email issue.
      />
      <div
        className="invalid-feedback"
        style={{ display: "block", fontSize: "70%" }}
      >
        {/* {fieldState.error !== undefined && fieldState.error.message} */}
        {error !== undefined && error.message}
      </div>
    </div>
  );
};

export default InputField2;
