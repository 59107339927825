import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/DocuVerusLogoorg.png";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
// import { toast, ToastContainer } from "react-toastify";
import { Outlet, Link, useNavigate, Navigate } from "react-router-dom";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import { Button, useCreate, useNotify, useRefresh } from "react-admin";
import { CircularProgress } from "@mui/material";
import { useLogin, Notification } from "react-admin";
import { SOFTWARE_VERSION, typesofUsers } from "../../config";
import { JSONTree } from "react-json-tree";

const MfaLogin = ({username}) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(" ");
  const [create, { isLoading, error }] = useCreate();
  const [emailMsg, setEmailMsg] = useState("");

  const schema = yup.object().shape({
    otp: yup
      .number()
      .typeError("Verification  Code is required!")
      .required("Verification  Code is required!"),
  });

  // Initial form values
  const defaultFormValues = {
    otp: "",
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  useEffect(() => {
    document.title = "Docuverus";
  }, []);

  const sendEmail = () => {
    
    create('mfa_SendMfaEmailOtp', { data: {username: username} }, {
      onSuccess: (data) => {
        // debugger;
        setEmailMsg(data?.records?.email)
        notify(`${data?.message}`, {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onError: (e: any) => {
        notify(`Operation fail: ${e.message}`, {
          type: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    })
  }

  const sendSMS = () => {
    create('mfa_sendmfasmsotp', { data: { username: username } }, {
      onSuccess: (data) => {
        setEmailMsg(data?.records?.sms)
        notify(`${data?.message}`, {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onError: (e: any) => {
        notify(`Operation fail: ${e.message}`, {
          type: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    })
  }

  useEffect(() => {
    create('mfa_sendmfasmsotp', { data: { username: username } }, {
      onSuccess: (data) => {
        setEmailMsg(data?.records?.email)
        notify(`${data?.message}`, {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onError: (e: any) => {
        notify(`Operation fail: ${e.message}`, {
          type: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    })
  }, [])
  const userTypes = {
    subscribers: [
      typesofUsers?.subscriberUser,
      typesofUsers?.subscriberAdmin,
      typesofUsers?.agent,
      typesofUsers?.manager,
      typesofUsers?.admin,
    ],
    admins: [
      typesofUsers?.docuverusSuperAdmin,
      typesofUsers?.superAdmin,
      typesofUsers?.docuverusAdmin,
    ],
    applicants: [
      typesofUsers?.applicant,
      typesofUsers?.coApplicant,
      typesofUsers?.coSigner,
    ],
    thirdPartyAgent: [typesofUsers?.landlordThirdPartyAgent],
  };
  const onSubmit = (data) => {
    const sendData = {
      username: username,
      otp: data?.otp,
    };
    create(
      "mfa_VerifyOtp",
      { data: sendData },
      {
        onSuccess: (data) => {
          if (userTypes.subscribers.includes(data?.records?.userrole)) {
            localStorage.setItem('auth', JSON.stringify({ ...data.records, data: { ...data.records, subscriberView: true } }));
            // window.location.replace('/ats')
            // return { redirectTo: "/Ats" };
          }
          if (userTypes.admins.includes(data?.records?.userrole)) {
            localStorage.setItem('auth', JSON.stringify({ ...data.records, data: { ...data.records, subscriberView: false } }));
            // return { redirectTo: "/Ats" };
            
          }
          // localStorage.setItem('auth')
          notify(`${data?.message}`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          setErrorMessage("");
          methods?.reset();
          // window.location.replace('/ats')
          navigate('/ATS',{replace:true})
        },
        onError: (e: any) => {
          // notify(`Operation fail: ${e.message}`, {
          //   type: "error",
          //   anchorOrigin: { vertical: "top", horizontal: "right" },
          // });
          setErrorMessage(`${e.message}`);
        },
      }
    );
  };
  return (
    <section className="login-wrap">
      <div className="login-cell">
        <div className="login-block">
          <div className="l-logo-block text-center">
            <img src={Logo} alt="Docuverus" title="Docuverus" />
          </div><br />
          <p style={{ color: "green", textAlign: 'center', fontSize: '12px', fontWeight: 'bold' }}>{emailMsg}</p>
          {errorMessage && <p style={{ color: "red", textAlign: 'center', fontSize: '12px', fontWeight: 'bold' }} >{errorMessage}</p>}
          <div className="d-flex justify-content-center align-items-center">
            <button type="button" style={{ fontSize: '14px' }} onClick={sendSMS} className="btn btn-link">
              Resend OTP via SMS
            </button>
            <button type="button" style={{ fontSize: '14px' }} onClick={sendEmail} className="btn btn-link">
              Resend OTP via Email
            </button>
          </div>
          <div className="auth-form mr-t30">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="form-group cus-input-group mr-b20">
                  <InputField2
                    label="Verification Code"
                    source={`otp`}
                    type="password"
                    placeholder="Enter Verification Code"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                  />
                </div>
                {isLoading ? (
                  <div className="static-d-info d-flex justify-content-center align-items-center mw-100">
                    <CircularProgress size="20px" />
                    <h5>&nbsp; &nbsp; Please wait...</h5>
                  </div>
                ) : (
                  <>

                    <div className="d-flex justify-content-center align-items-center">
                      <button type="submit" className="l-btn-forgetPsd">
                        Verify OTP
                      </button>
                    </div>
                  </>

                )}
              </form>
              <br />
              <div className="w-full p-t-3 p-b-5 text-right">
                <Link to="/" className="txt1" style={{ color: "5555FF" }}>
                  Back to Login
                </Link>
              </div>
            </FormProvider>
          </div>

          <div className="l-foot-links mr-t20">
              <ul className="mx-auto">
                <li>
                  {/* <a href={ATS_DOCS_URL + "atss/DataPolicy/Index"} target="_blank"> */}
                  <a href="/atss/DataPolicy" target="_blank">
                    Data Policy
                  </a>
                </li>

                <li>
                  <a href="/atss/TermsOfUse" target="_blank">
                    Terms of Use
                  </a>
                </li>
                <li>
                  Version {SOFTWARE_VERSION}
                </li>
              </ul>
            </div>
        </div>
      </div>
    </section>
  );
};

export default MfaLogin;
