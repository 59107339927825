import React, { useEffect } from "react";
import "../../App.css";
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import { useGetIdentity, useGetList, useStore } from "react-admin";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import TermsAndConditionForm from "./TermsAndCondtionForm";

import QuillTextEditor from "../../componets/common/InputComponents/TextEditorWithDataField/QuillTextEditor";
import RenderQuillText from "../../componets/Settings/EmailSettings/RenderQuillText";
import 'quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import { JSONTree } from "react-json-tree";
// const dataField = {
//   "Docuverus_Link": "{docuverus.link}",
//   "Docupload_Link": "{docupload.link}",
//   "Docuverus_Phone_Number": "{docuverus.phonenumber}",
//   "Docuverus_Support_Email": "{docuverus.supportemail}",
//   "Docuverus_Subscriber_Support_Email": "{docuverus.subscribersupportemail}",
//   "Docuverus_User": "{docuverus.user}",
//   "Docuverus_Password": "{docuverus.password}",
//   "Docuverus_User_Name": "{docuverus.user.name}",
//   "Subscriber_User": "{subscriber.user}",
//   "Subscriber_Password": "{subscriber.password}",
//   "Subscriber_Company_Name": "{subscriber.companyname}",
//   "Subscriber_Email": "{subscriber.email}",
//   "Subscriber_Contact_Person": "{subscriber.contactperson}",
//   "Subscriber_Phone_Number": "{subscriber.phonenumber}",
//   "Subscriber_Expiration_Date": "{subscriber.expirationdate}",
//   "Subscriber_User_Name": "{subscriber.user.name}",
//   "Subscriber_Fee": "{subscriber.fee}",
//   "Applicant_Name": "{applicant.name}",
//   "Applicant_Street_Address": "{applicant.street.address}",
//   "Applicant_State": "{applicant.state}",
//   "Applicant_City": "{applicant.city}",
//   "Applicant_ZipCode": "{applicant.zipcode}",
//   "Applicant_Email": "{applicant.email}",
//   "Application_Number": "{application.number}",
//   "Application_SecretKey": "{application.secretkey}",
//   "Application_Start_Date": "{application.start.date}",
//   "Application_Completion_Date": "{application.completion.date}",
//   "Application_Property": "{application.property}",
//   "Application_Property_Address": "{application.property.address}",
//   "Application_Property_URL": "{application.property.url}",
//   "Application_Property_Email": "{application.property.email}",
//   "Application_Property_Telephone": "{application.property.telephone}",
//   "Application_Originator_Name": "{application.originator.name}",
//   "Application_Originator_Email": "{application.originator.email}",
//   "Application_Fee": "{application.fee}",
//   "Application_Card_Number": "{application.cardnumber}",
//   "Application_Invitee_Name": "{application.invitee.name}",
//   "Application_Invitee_Username": "{application.invitee.username}",
//   "Application_Invitee_Password": "{application.invitee.password}",
//   "Application_Screening_Company": "{application.screening.company}",
//   "CoApplicant_or_Guarantor_Name": "{coapplicant.guarantor.name}",
//   "App_Coapp_Cosign_Role": "{app.coapp.cosign.role}",
//   "Coapplicant_Grid": "{coapplicant.grid}",
//   "Application_Primary_Applicant_Name": "{application.primaryapplicantname}",
//   "User_Name": "{user.name}",
//   "Password": "{password}",
//   "Landlord_or_ThirdpartyAgent_Name": "{landlord.thirdpartyagent.name}",
//   "Landlord_or_ThirdpartyAgent_Email": "{landlord.thirdpartyagent.email}",
//   "Landlord_or_ThirdpartyAgent": "{landlord.thirdparty}",
//   "Landlord_Type_Key_Factor": "{landlord.type.key.factor}",
//   "Application_Screening_Credit_Score": "{Application.Screening.Credit.Score}",
//   "CurrentDate": "{currentdate}",
//   "Landlord_Approval_Condition": "{landlord.approval.condition}",
//   "Landlord_Approval_Reason": "{landlord.approval.reason}",
//   "Screening_Background_Link": "{screening.background.link}",
//   "Applicant_Consent_Link": "{applicant.consent.link}",
//   "ResetPasswordURL": "{resetPswdURL}",
//   "CoApplicantEmails": "{co.applicant.email}",
//   "CoSignerEmails": "{co.signer.email}",
//   "Docuverus_Landlord_Name": "{docuverus.llname}",
//   "Docuverus_Pproperty_County": "{docuverus.propcounty}",
//   "OldEmail": "{Old.email}",
//   "NewEmail": "{New.email}"
// }
const TermsandCondtions = () => {
  const [expandIdSection, setExpandIdSection] = React.useState(true);
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const { data: identity }: any = useGetIdentity();
  const id = switchSubscriberObj?.value || identity?.subscriberid;
  const { data, isLoading } = useGetList("gettermsandcondition", { filter: { 'SubscriberID': id } })
  const { data:dataField } = useGetList("GETDataFiled")

  const sortedAscDataFieldOptions = dataField;
  sortedAscDataFieldOptions?.sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <section className="main-sec">
        <div className="home_content">

          <div
            style={{ fontWeight: "600", fontSize: "18px" }}
          >
            <p>
              Terms & Conditions

            </p>
          </div>
          <div className="application-tabs">
            <div className="nav-flexes" style={{ marginTop: "40px",fontSize: "12px",fontWeight:"700",fontFamily:"Open Sans",lineHeight:"18.5px"  }}>
              <nav>
                <div
                  className="nav nav-tabs app-nav-tabs"
                  id="nav-tab"
                  role="tablist"
                >
                  {data && data.length > 0 && data.map((item, index) => {
                    return (
                      <button
                        type="button"
                        className={`nav-link ${index === 0 ? "active" : ""}`}
                        data-bs-toggle="tab"
                        data-bs-target={`#tab-${item.id}`}
                      >
                        {item.Type} <InfoIcon style={{ height: "12px", width: "12px", color: "orange" }} />
                      </button>
                    )
                  })}

                </div>
              </nav>
            </div>
            <div className="tab-content app-nav-tabcontent " id="nav-tabContent" style={{ marginTop: "10px", backgroundColor: "#f8f8f8",width:"100%" }} >
              {data && data.length > 0 && data.map((item, index) => {
                const template=item?.DocuverusDescription;
                return (
                  <div className={`tab-pane fade show ${index === 0 ? "active" : ""}`} id={`tab-${item.id}`}>
                    <div className="info-toggle">
                      <div className="toggle-heads ">
                        <div className="row align-items-center justify-content-between  ">
                          <div className="col">
                            <div className="d-flex align-items-center">
                              <b
                                className="ms-3 me-2"
                                style={{ fontSize: "18px",fontWeight:"700",fontFamily:"Open Sans" }}
                              >
                                Docuverus
                              </b>
                            </div>
                          </div>

                          <div className="col text-end m-1">
                            <IconButton
                              className="print-hide"
                              onClick={() => setExpandIdSection((v) => !v)}
                              color="primary"
                            >
                              {!expandIdSection ? <AddIcon /> : <RemoveIcon />}
                            </IconButton>
                          </div>
                        </div>

                        <div
                          className={` ${!expandIdSection ? "d-none" : "d-display"
                            } card-body cust-card-appl`}
                        >

                          <div
                            className="card card-body w-60"
                            // style={{ paddingTop: 0 ,width:"100%",maxWidth:"1000px"}}
                          >
                            <>
                              {/* {parse(item.DocuverusDescription)} */}
                              {/* <JSONTree data={template}/> */}
                              <RenderQuillText text={template}/>
                            </>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className="auth-form w-60">
                      <TermsAndConditionForm DataFrom={item} dataFieldOptions={sortedAscDataFieldOptions} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

    </>
  )
}
export default TermsandCondtions

