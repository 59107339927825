import React, { createContext, useEffect } from 'react';
import {
    ListBase,
    ListToolbar,
    Pagination, Title,
} from 'react-admin';
import { Card } from '@mui/material';
import AtsDashboard from '../../../AtsDashboard';
import FilterChips from './FilterChips';
import { JSONTree } from 'react-json-tree';

const ListBaseAppInbox = ({ children, actions, filters, title, enableDashboardGraph = true, ...props }) => {
    // const { children, title,actions ,filters,filter,...rest} = props;
    const filterExludeList = ['app_invitation_type', 'dashboardOption', 'inbox_type'];
    return (
        <ListBase perPage={100}  {...props}>
           
            <Title title={title} />
            <ListToolbar
                filters={filters}
                actions={actions}
            />
            {/* <TopMenu /> */}
            {enableDashboardGraph && <AtsDashboard isAdmin={true} />}
            {!enableDashboardGraph && <br />}
            <FilterChips excludeKeys={filterExludeList} />
            <div style={{marginTop:'5px'}}>
            {/* <Card> */}
                {children}
            {/* </Card> */}
            </div>
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
            <br />
            <br />
            <br />
            <br />
        </ListBase>
    )
};

export default ListBaseAppInbox;