import moment from "moment";
import React from "react";
import { JSONTree } from "react-json-tree";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { Tabs, Tab, Box, Typography } from '@mui/material';
import { checkModuleAccess, isSuperAdmin } from "../../Functions/permissions";
import { useDataProvider, useGetIdentity, usePermissions } from "react-admin";
import YesNoRadioButton from "../common/InputComponents/YesNoRadioButton";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/store";
import { useForm, FormProvider } from "react-hook-form"; // Import FormProvider and useForm

const ViewSecondaryAppView = () => {
  const location = useLocation();
  const state = location?.state;
  const navigate = useNavigate();

  // Create a form context using useForm
  const methods = useForm();

  return (
    <FormProvider {...methods}>
      <section className="main-sec">
        <div className="home_content">
          <div className="row">
            <div className="col-12 d-flex">
              <Breadcrumbs aria-label="breadcrumb">
                <Typography sx={{ fontWeight: "bold" }}>
                  <button
                    title="Go Back"
                    style={{ color: "#2cc0e4" }}
                    className="btn btn-link"
                    onClick={() => navigate(-1)}
                  >
                    <ArrowBack />
                  </button>
                  <span>View Secondary App</span>
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <div className="application-tabs">
            <div className="nav-flexes" style={{ marginTop: "30px" }}>


              <div>
                {state?.getSecondaryApp?.map((question) => (
                  question.display && (
                    <div key={question.ProfileTabFieldID}>

                      <YesNoRadioButton
                        key={question.ProfileTabFieldID}
                        required={question.required}
                        label={question.Question}
                        source={`response_${question.ProfileTabFieldID}`}
                        defaultValue={question?.response}
                        disable={true}



                      />
                    </div>
                  )
                ))}

              </div>

            </div>
          </div>
        </div>
      </section>
    </FormProvider>
  );
};

export default ViewSecondaryAppView;
