import React, { useRef, useState } from "react";
import "../../../pages/Sidebar/sidebar.css";
import "../../../assets/styles/application_det.css";
import "../../../assets/styles/style.css";
import "../../../pages/Sidebar/sidebar.css";
import "../../../assets/styles/application_det.css";
import "../../../assets/styles/style.css";
// import { useController } from "react-hook-form";
import {
  SaveButton,
  SimpleForm,
  useNotify,
  // useRefresh,
  // useTranslate,
  Toolbar,
  useDataProvider,
  useRefresh,
  // RichTextField,
} from "react-admin";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import TextInput from "../components/inputComponents/TextInput";
import TextInputPhone from "../components/inputComponents/TextInputPhone";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import LoadingSpinner from "../../common/loadingSpinner";
import { useForm, FormProvider, get } from "react-hook-form";

const NameChangeModal = ({
  trn_id,
  first_name,
  last_name,
  mobile,
  email,
  country_code,
  fetchRecord,
  handleClose,
  open,
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = React.useState(false);
  const [mno, setmno] = React.useState(mobile);
  const refresh = useRefresh();
  const schema = yup.object({
    first_name: yup
      .string()
      .max(75, "maximum 75 characters is required")
      .required("required")
      
      .min(2, "minimum 2 characters is required")
      .typeError("required"),
    last_name: yup
      .string()
      .max(75, "maximum 75 characters is required")
      .required("required")
      
      .min(2, "minimum 2 characters is required")
      .typeError("required"),
    mobile: yup
      .string()
      .typeError("required")
      .required("required")
      // .min(10, "invalid"),
      .test("rent", "Min 10 digits are required", (value) => {
        let temp: any = value?.toString().replace(/\D/g, "");
        return temp?.length > 10 ? true : false;
      }),
    email: yup
      .string()
      .typeError("required")
      .max(75, "maximum 75 characters is required")
      .required("required")
      .email("Invalid email format")
      .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
  });

  // form initial states
  const form = useForm({
    defaultValues: {
      first_name: first_name,
      last_name: last_name,
      mobile: country_code + String(mobile),
      email: email,
      country_code: country_code,
    },
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    form?.reset({
      first_name: first_name,
      last_name: last_name,
      mobile: country_code + String(mobile),
      email: email,
      country_code: country_code,
    });
  }, [first_name, last_name, mobile, email, country_code, open]);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const payload = {
        trn_id: trn_id,
        first_name: values.first_name,
        last_name: values.last_name,
        mobile: values?.mobile.split(values?.country_code)[1],
        email: values.email,
        country_code: values.country_code,
        subUrl: "/applicant/updateApplicantDetails",
      };
      //
      //
      await dataProvider.update("other_sections", {
        id: trn_id,
        data: payload,
        previousData: values,
      });
      fetchRecord();
      notify(`Applicant details updated Successfully`, {
        type: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
      closeBtnRef?.current?.click();
      refresh();
      handleClose();
    } catch (e) {
      notify(`Error: fail updating applicant deatils comment`, {
        type: "warning",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    } finally {
      setLoading(false);
      // setShowDialog(false);
      // fetchRecord();
    }
  };

  const closeBtnRef: any = React.useRef();

  return (
    <>
      {/* <EditIcon onClick={handleOpen} color="primary" fontSize="small" /> */}
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        sx={{ "& .MuiDialog-paperFullWidth ": { maxWidth: "964px" } }}
      >
        <DialogTitle id="alert-dialog-title">Edit</DialogTitle>
        <DialogContent>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(handleSubmit)}>
              <div className="custom-body w-100">
                <div className="row add-md-form">
                  <div className="col-sm-6">
                    {" "}
                    <TextInput
                      source="first_name"
                      label="First Name"
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextInput
                      source="last_name"
                      label="Last Name"
                      type="text"
                      placeholder=""
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextInputPhone
                      source="mobile"
                      label="Mobile"
                      //  countryCode={country_code}
                      // mobileno={mno}
                      // setmno={(val)=>setmno(val)}
                      // setCountryCode={(code)=>setCountryCode(code)}
                      widthClass={"w-100"}
                      // required={false}
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextInput
                      source="email"
                      label="Email"
                      type="text"
                      placeholder=""
                    />{" "}
                  </div>
                </div>
              </div>
              <PostAddDocuverusCommentToolbar handleClose={handleClose} />
            </form>
          </FormProvider>
          {/* </SimpleForm> */}
        </DialogContent>
      </Dialog>
      {loading ? <LoadingSpinner /> : ""}
    </>
  );
};
function PostAddDocuverusCommentToolbar({ handleClose, ...props }) {
  return (
    <Toolbar
      {...props}
      style={{
        backgroundColor: "white",
        justifyContent: "flex-end",
        paddingRight: 8,
      }}
    >
      <Button
        sx={{ mx: 1 }}
        onClick={() => {
          handleClose();
        }}
        variant="outlined"
        color="primary"
        type="button"
      >
        Cancel
      </Button>

      <Button
        startIcon={<EditIcon />}
        sx={{ mx: 1 }}
        variant="contained"
        type="submit"
      >
        Update
      </Button>
    </Toolbar>
  );
}

export default NameChangeModal;
