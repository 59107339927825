import React from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Box, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useUpdate, useNotify, useRefresh, useGetOne } from "react-admin";
import { useForm, FormProvider, get } from "react-hook-form";
import { Button as MuiButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useParams } from "react-router-dom";
import InputField2 from "../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import LoadingSpinner from "../common/loadingSpinner";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CancelAppSvg from "../../assets/images/CancelApp.svg";

const CancelApp = (props) => {
  const { id } = useParams();
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  //call userId from redux store
  const aspNetUserId = useSelector((state: any) => state?.users?.aspNetUserId);

  // call get Ip address data
  const { data: getIPAddress } = useGetOne(
    "getipaddress",
    { id: 1 },
    {
      enabled: open,
    }
  );
  const ipAddress = getIPAddress?.IPv4;

  // form validation schema
  const validationSchema = Yup.lazy((value) =>
    Yup.object().shape({
      reason: Yup.string()
        .trim("Reason is required!")
        .max(5000, "maximum 5000 characters is required")
        .required("Reason is required!"),
    })
  );

  // form initial states
  const form = useForm({
    defaultValues: {
      reason: "",
      UserID: aspNetUserId,
    },
    resolver: yupResolver(validationSchema),
  });

  // Destructuring form
  const { register, reset } = form;

  const [update, { data, isLoading, error }] = useUpdate();
  // form submit handler
  const onSubmit = (value) => {
    const sendData = { IP: ipAddress, ...value };
    update(
      "cancelapp",
      { id: props.record.id, data: sendData },
      {
        onSuccess: (d) => {
          notify(
            `Application Number ${d.ApplicationNumber} has been Cancelled`,
            { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }}
          );
          refresh();
          handleClose();
          reset();
          if (props?.navigateUser) {
            navigate(-1);
          }
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }}),
      }
    );
  };
  return (
    <>
      {props.buttonCancelApp ? (
        <button
          type="button"
          className=""
          data-bs-toggle="modal"
          data-bs-target="#archieve"
          onClick={handleOpen}
        >
          <img src={CancelAppSvg} alt="" />
          <span>Cancel App</span>
        </button>
      ) : (
        <MuiButton
          onClick={handleOpen}
          startIcon={
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3334 2.99998H13.6667V4.33331H12.3334V13C12.3334 13.1768 12.2631 13.3464 12.1381 13.4714C12.0131 13.5964 11.8435 13.6666 11.6667 13.6666H2.33337C2.15656 13.6666 1.98699 13.5964 1.86197 13.4714C1.73695 13.3464 1.66671 13.1768 1.66671 13V4.33331H0.333374V2.99998H3.66671V0.99998C3.66671 0.823169 3.73695 0.653599 3.86197 0.528575C3.98699 0.403551 4.15656 0.333313 4.33337 0.333313H9.66671C9.84352 0.333313 10.0131 0.403551 10.1381 0.528575C10.2631 0.653599 10.3334 0.823169 10.3334 0.99998V2.99998ZM11 4.33331H3.00004V12.3333H11V4.33331ZM7.94271 8.33331L9.12137 9.51198L8.17871 10.4546L7.00004 9.27598L5.82137 10.4546L4.87871 9.51198L6.05737 8.33331L4.87871 7.15465L5.82137 6.21198L7.00004 7.39065L8.17871 6.21198L9.12137 7.15465L7.94271 8.33331ZM5.00004 1.66665V2.99998H9.00004V1.66665H5.00004Z"
                fill="#2DC3E8"
              />
            </svg>
          }
          variant="text"
          sx={{textTransform:"capitalize"}}
        >
          Cancel App
        </MuiButton>
      )}
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle color="error" id="alert-dialog-title">
          <InfoIcon /> Are you sure?
        </DialogTitle>
        <DialogContent>
          <p>Are you sure you want to cancel the application?</p>
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-12 mr-b10 mt-3">
                  <InputField2
                    label="Reason"
                    source={`reason`}
                    placeholder="Enter Reason for Cancelling Application"
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                  />
                </div>
              </div>
              <Box display={"flex"} justifyContent={"end"}>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    handleClose();
                    reset();
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={<ArchiveIcon />}
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                >
                  Cancel Application
                </Button>
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
      {isLoading ? <LoadingSpinner /> : ""}
    </>
  );
};

export default CancelApp;
