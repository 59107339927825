import React from "react";
import { Button, useGetList, useGetOne, useCreate } from "react-admin";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import parse from "html-react-parser";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  Loading,
  // useCreate,
  // useGetList,
  useNotify,
  useRefresh,
  useStore,
  useGetIdentity
} from "react-admin";

import { CircularProgress } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import InputCheckBoxField from "../../componets/common/InputComponents/InputCheckBoxField";
import { JSONTree } from "react-json-tree";
import QuillTextEditor from "../../componets/common/InputComponents/TextEditorWithDataField/QuillTextEditor";
import RenderQuillText from "../../componets/Settings/EmailSettings/RenderQuillText";
import 'quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
const TermsAndConditionForUserSetup = (props) => {
 
  const Identity = useGetIdentity();

  console.log(Identity?.data?.subscriberid, "id1234")
  // const id=AuthForId?.data?.subscriberid;
  const { data, isLoading: isLoadingData } = useGetOne("getTermsCondition", {

    id: 2,

  });
  console.log(data?.Description, "data6")
  const [create, { isLoading, error }] = useCreate();
  const defaultFormValues = {

    agreeterms: false,
  };
  const schema = yup.object().shape({
    agreeterms: yup
      .boolean()
      .oneOf([true], "You must accept the terms and conditions")
      .required("You must accept the terms and conditions"),


  });
  const notify = useNotify();
  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { setError, clearErrors } = methods;
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    console.log("datasubmit", data);

    const sendData = {
      agreeterms: Number(data.agreeterms),
    };
    console.log("datasendData", sendData);
    create("submittermsandconditions", { data: sendData }, {
      onSuccess: (data) => {
        notify(`Terms and Conditions updated successfully.`, {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
        localStorage.setItem("TermAndConditionVefificationStatus","1");
         navigate('/Ats');
      }
    });
  };

 
  return (
    <div
      className="container"
      style={{
        marginTop: "50px",
      }}
    >
      <Card>

        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <b>Terms And Conditions</b>

          </Typography>
          <hr />

          {/* {data?.Description ? parse(data?.Description) : ""} */}
          <RenderQuillText text={data?.Description}/>
          <div className="auth-form mr-t30">

            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="form-group cus-input-group mr-b20">
                  <InputCheckBoxField
                    notRequired={false}
                    label={"I Accept the terms and conditions"}
                    name={`agreeterms`}


                  />
                </div>
                {isLoading ? (
                  <div className="static-d-info d-flex justify-content-center align-items-center mw-100">
                    <CircularProgress size="20px" />
                    <h5>&nbsp; &nbsp; Please wait...</h5>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <button type="submit" className="l-btn-forgetPsd" disabled={!methods.formState.isValid} style={{ backgroundColor: !methods.formState.isValid ? "lightgrey" : "rgb(45, 195, 232)", border: !methods.formState.isValid ? "lightgrey" : "rgb(45, 195, 232)" }}>
                      Submit
                    </button>
                  </div>
                )}
              </form></FormProvider>
          </div>
        </CardContent>
      </Card>

    </div>
  );
};

export default TermsAndConditionForUserSetup;