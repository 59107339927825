import { Controller, useFormContext, useController } from "react-hook-form";

const InputCheckboxGroupField = ({ source, options: options }) => {
  const { control } = useFormContext();
  return (
    <>
      <Controller
        name={source}
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <>
            {options.map((option) => (
              <label key={option.value}>
                <input
                  type="checkbox"
                  value={option.value}
                  onChange={(e) => {
                    const { value } = e.target;
                    const updatedValue = field.value.includes(value)
                      ? field.value.filter((item) => item !== value)
                      : [...field.value, value];
                    field.onChange(updatedValue);
                  }}
                  checked={field.value.includes(option.value)}
                />&nbsp;
                {option.label}
              </label>
            ))}{" "}
          </>
        )}
      />
    </>
  );
};

export default InputCheckboxGroupField;
