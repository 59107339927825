import React from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useFormContext, useController } from "react-hook-form";

const TextEditor = ({
  label,
  source,
  //   placeholder,
  notRequired = false,
  labelRequired = true,
  labelClass
}) => {
  const { control, setValue, watch } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: source,
    control,
  });

  return (
    <div className="text_editor">
      <div className="emp-inputs form-label">
        {label !== "" && labelRequired && (
          <>
            <label
              className={`${labelClass} ${error && "text-danger"}`}
              htmlFor="inputs"
            >
              {label} {!notRequired && <span>&#42;</span>}
            </label>
            <br />
          </>
        )}
      </div>
      <ReactQuill
        theme={"snow"} // bubble,core
        onChange={(html) => {
          field.onChange(html);
        }}
        value={field?.value}
        modules={TextEditor.modules}
        formats={TextEditor.formats}
        bounds={".app"}
        // placeholder={placeholder}
      />
      <div className="emp-inputs form-label">
        <div
          className="invalid-feedback"
          style={{ display: "block", fontSize: "70%" }}
        >
          {error !== undefined && error.message}
        </div>
      </div>
    </div>
  );
};

TextEditor.propTypes = {
  placeholder: PropTypes.string,
};

TextEditor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

TextEditor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export default TextEditor;
