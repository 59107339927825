import React, { forwardRef, useState } from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import "./index.scss";
import ReactDatePicker from "react-datepicker";
import moment from "moment-timezone";
import InputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";
import { JSONTree } from "react-json-tree";
const ExampleCustomInput = forwardRef((inputFun, ref) => (
  <InputMask
    mask={"99/99/9999"}
    placeholder="mm/dd/yyyy"
    {...inputFun}
    inputRef={ref}
  />
));
 
const InputDateField = ({
  source,
  notRequired = false,
  label = "",
  disabled = false,
  placeholder = "",
  onChangeCallback = () => {},
  labelClass = "lable_title",
  ...props
}) => {
  const { control, watch, setValue } = useFormContext();
  const required = !notRequired;
  // Calculate the minimum date as today's date minus 100 years.
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 100);
 
  const formatDate = (date) => {
    if (!date) return "";
    console.log("input44566", date);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
 
  const parseDate = (str) => {
    try {
      if (!str) return null;
      // Check if the date is in the format mm/dd/yyyy
      const dateParts = str?.split("/");
      if (dateParts.length === 3) {
        const [month, day, year] = dateParts;
        return new Date(year, month - 1, day);
      }
 
      // Check if the date is in the ISO 8601 format
      // Check if the date is in the ISO 8601 format
      const isoDate = new Date(str);
      if (!isNaN(isoDate)) {
        const month = String(isoDate.getUTCMonth() + 1).padStart(2, "0");
        const day = String(isoDate.getUTCDate()).padStart(2, "0");
        const year = isoDate.getUTCFullYear();
        return new Date(year, month - 1, day);
      }
 
      return null; // Handle invalid date strings
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  // function autoCorrectDate(dateValue) {
  //   const parts = dateValue.split('/');
  //   const currentYear = new Date().getFullYear();
 
  //   if (parts.length === 1 && parts[0].length === 1) {
  //     return `0${parts[0]}/01/${currentYear}`;
  //   } else if (parts.length === 1 && parts[0].length === 2) {
  //     return `${parts[0]}/01/${currentYear}`;
  //   } else if (parts.length === 2 && parts[1].length === 1) {
  //     return `${parts[0]}/0${parts[1]}/${currentYear}`;
  //   } else if (parts.length === 2 && parts[1].length === 2) {
  //     return `${parts[0]}/${parts[1]}/${currentYear}`;
  //   }
  //   return dateValue;
  // }
 
  const autoCorrectDate = (input) => {
    console.log("input4555", input);
    const parts = input.split(/\D+/).filter((part) => part !== "");
 
    let month, day, year;
    const currentYear = new Date().getFullYear();
 
    if (parts.length === 1) {
      month = parts[0].padStart(2, "0");
      day = "01";
      year = currentYear.toString();
    } else if (parts.length === 2) {
      month = parts[0].padStart(2, "0");
      day = parts[1].padStart(2, "0");
      year = currentYear.toString();
    } else if (parts.length >= 3) {
      month = parts[0].padStart(2, "0");
      day = parts[1].padStart(2, "0");
      year = parts[2].padStart(4, currentYear.toString()).slice(0, 4);
    } else {
      return { formattedDate: input, valid: false };
    }
 
    const monthNumber = parseInt(month);
    const dayNumber = parseInt(day);
    const yearNumber = parseInt(year);
 
    if (
      monthNumber < 1 ||
      monthNumber > 12 ||
      dayNumber < 1 ||
      yearNumber < 1000 ||
      yearNumber > 9999
    ) {
      return { formattedDate: input, valid: false };
    }
 
    const maxDay = new Date(yearNumber, monthNumber, 0).getDate();
    if (dayNumber > maxDay) {
      return { formattedDate: input, valid: false };
    }
 
    const formattedDate = `${monthNumber
      .toString()
      .padStart(2, "0")}/${dayNumber
      .toString()
      .padStart(2, "0")}/${yearNumber}`;
    return { formattedDate, valid: true };
  };
 
  const isValidDate = (date) => {
    if (!date || isNaN(date.getTime())) return false;
    return date >= minDate;
  };
 
  const handleDateValidation = (inputValue, onChange, setValue, source) => {
    const { formattedDate, valid } = autoCorrectDate(inputValue);
    const parsedDate = parseDate(formattedDate);
    console.log("Input Value:", inputValue);
    console.log("Formatted Date:", formattedDate);
    console.log("Valid:", valid);
    console.log("source:", source);
    console.log("setValue:", setValue);
    console.log("onChange:", onChange);
 
    if (valid && isValidDate(parsedDate)) {
      console.log("Setting value:", formattedDate);
      setValue(source, formattedDate, { shouldValidate: true });
      onChange(formattedDate);
     
    } else {
      console.log("Invalid date, clearing value");
      setValue(source, "");
      onChange("");
    }
  };
 
  const [openCalender, SetOpenCalender] = useState(false);
  return (
    <>
      <Controller
        control={control}
        name={source}
        render={({ field, fieldState }) => {
          const error = fieldState.error !== undefined;
          const { onChange, value, ref, ...rest } = field;
 
          return (
            <div className="emp-inputs">
              {label !== "" && (
                <label
                  htmlFor="select_box"
                  className={`${labelClass} ${error && "text-danger"}`}
                >
                  {label} {required && <span>&#42;</span>}
                </label>
              )}
              <ReactDatePicker
                ref={(elem) => {
                  !props?.stopAutoFocus && elem && ref(elem.input);
                }}
                key={source}
                open={openCalender}
                // onBlur={() => SetOpenCalender(false)}
                enableTabLoop={false}
                selected={parseDate(value)}
                placeholderText="MM/DD/YYYY"
                disabled={disabled}
                // onKeyDown={function (event) {
                //   if (event?.keyCode == 9 || event?.which === 9) {
                //     // alert('tab')
                //    // SetOpenCalender(false)
                //     // auto correct date if date partially type and tab
                //     // console.log(event.target.value)
                //     // console.log(autoCorrectDate(event.target.value))
                //     // //  event.target.value=autoCorrectDate(event.target.value);
                //     // setTimeout(()=>{
                //     //   onChange(autoCorrectDate(event.target.value));
                //     // },1000)
                //     // setValue(source,autoCorrectDate(event.target.value),{shouldDirty:true});
                //     // debugger;
                //     //  onChange(formatDate(new Date(autoCorrectDate(event.target.value))))
 
                //   }
                // }}
                onChange={(date) => {
                  onChange(formatDate(date));
                  onChangeCallback();
                  SetOpenCalender(false);
                }}
                onBlur={(e) => {
                  console.log("blur 3")
                  handleDateValidation(e.target.value, onChange, setValue, source);
                  SetOpenCalender(false);
                 
 
                }}
 
                onKeyDown={(e) => {
                  console.log("e.which", e.which);
 
                  if (e.key === "Enter" || e.which === 9) {
                    if (e.which === 9) {
                     // e.preventDefault();
 
                      const enterEvent = new KeyboardEvent("keydown", {
                        key: "Enter",
                        code: "Enter",
                        keyCode: 13,
                        which: 13,
                        bubbles: true,
                      });
                       e.target.dispatchEvent(enterEvent);
                      // const tabEventTwo = new KeyboardEvent("keydown", {
                      //   key: "Tab",
                      //   code: "Tab",
                      //   keyCode: 9,
                      //   which: 9,
                      //   bubbles: true,
                      // });
                      // e.target.dispatchEvent(tabEventTwo);
                    }
 
                    handleDateValidation(
                      e.target.value,
                      onChange,
                      setValue,
                      source,
                     
                    );
                    SetOpenCalender(false);
 
                   
                  }
                }}
                // onClickOutside={() => {
                //   console.log("blur 4")
                //   SetOpenCalender(false);
                // }}
                // onBlur={(event) => {
                //   const correctedDate = autoCorrectDate(event.target.value.replace(/\D/g, ''));
                //   const parsedDate = parseDate(correctedDate);
                //   setValue(source, correctedDate, { shouldValidate: true });
                //   if (isValidDate(parsedDate)) {
                //     onChange(correctedDate);
                //   } else {
                //     onChange("");
 
                //   }
                // }}
                // customInput={<ExampleCustomInput />}
                onFocus={() => {
                  SetOpenCalender(true);
                }}
                // onClickOutside={() => SetOpenCalender(false)}
                showYearDropdown
                dateFormat="MM/dd/yyyy"
                className={`form-control cus-input-control ${
                  required && !field.value && "is-warning"
                } ${error && "is-invalid"}`}
                maxDate={props?.maxDate ? props?.maxDate : null}
                minDate={props?.minDate ? props?.minDate : minDate}
              />
              <div
                className="invalid-feedback"
                style={{ display: "block", fontSize: "70%" }}
              >
                {error !== undefined && fieldState?.error?.message}
              </div>
            </div>
          );
        }}
      />
    </>
  );
};
 
export default InputDateField;