import React, { useEffect, useState } from 'react';
import { useListContext, Button, BulkDeleteButton, useUnselect, useNotify, useCreate, useRefresh, useStore } from 'react-admin';
import MergeIcon from '@mui/icons-material/Merge';
import { Confirm } from 'react-admin';

const CustomBulkMergeButton = () => {
    const { selectedIds, data, resource } = useListContext();
    const [firstSelectedApp, setFirstSelectedApp] = useStore('firstSelectedApp');
    const unselect = useUnselect(resource);
    const notify = useNotify();
    const refresh = useRefresh();
    const [create, { isLoading, error }] = useCreate('mergeApplications');
    const [selectedApplications, setSelectedApplications] = useState<any>([]);

    // Write logic to store the first selected application 
    React.useEffect(() => {
        if (selectedIds.length > 0 && firstSelectedApp === undefined) {
            const firstApp = data.find(app => app.id === selectedIds[0]);
            setFirstSelectedApp(firstApp);
        }
        if (selectedIds.length === 0) {
            setFirstSelectedApp(undefined);
        }
    }, [selectedIds]);

    useEffect(() => {
        const selectedApplications = selectedIds.map(id => {
            const app = data.find(app => app.id === id);
            return {
                id: id,
                address: app?.AppliedForAddress,
                Property: app?.Property,
                SubscriberName: app?.SubscriberName,
                Subscribertype: app?.Subscribertype,
                ApplicationType: app?.ApplicationType,
                ApplicationStatus: app?.ApplicationStatus,
                AppliedForRent: app?.AppliedForRent,
                Agent:app?.Agent
            };
        });

        setSelectedApplications(selectedApplications);
    }, [firstSelectedApp, selectedIds]);
    const mismatchingApps = selectedApplications.filter(app => {
        const mismatches: string[] = [];
    
        if (app?.Agent !== firstSelectedApp?.Agent && app?.Agent != null && firstSelectedApp?.Agent != null) {
            mismatches.push('Agent');
        }
        if (app?.address !== firstSelectedApp?.AppliedForAddress && app?.address != null && firstSelectedApp?.AppliedForAddress != null) {
            mismatches.push('Address');
        }
        if (app?.Property !== firstSelectedApp?.Property && app?.Property != null && firstSelectedApp?.Property != null) {
            mismatches.push('Property');
        }
        if (app?.SubscriberName !== firstSelectedApp?.SubscriberName && app?.SubscriberName != null && firstSelectedApp?.SubscriberName != null) {
            mismatches.push('SubscriberName');
        }
        if (app?.Subscribertype !== firstSelectedApp?.Subscribertype && app?.Subscribertype != null && firstSelectedApp?.Subscribertype != null) {
            mismatches.push('Subscribertype');
        }
        if (app?.ApplicationType !== firstSelectedApp?.ApplicationType && app?.ApplicationType != null && firstSelectedApp?.ApplicationType != null) {
            mismatches.push('ApplicationType');
        }
        if (app?.AppliedForRent !== firstSelectedApp?.AppliedForRent && app?.AppliedForRent != null && firstSelectedApp?.AppliedForRent != null) {
            mismatches.push('AppliedForRent');
        }
    
        // Attach the mismatches array to each app if any mismatches exist
        if (mismatches.length > 0) {
            (app as any).mismatches = mismatches; // Add mismatches to the app object
            return true; // Keep the app in the filtered result
        }
        
        return false; // Exclude the app if there are no mismatches
    });

    const handleMerge = () => {

        if (mismatchingApps.length > 0) {
            const mismatchingIds = mismatchingApps.map(app => app.id);

            notify(`Merge failed for Application(s) with IDs: ${mismatchingIds.join(', ')}. Please check the following mismatched fields: Agent, Property, SubscriberName, Subscribertype from the first selected Application.`,
                { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            unselect(mismatchingIds); // Deselect the non-matching item
        } else {
            // Implement your merging logic here
            create('mergeApplications', { data: selectedIds }, {
                onSuccess: ({ data }) => {
                    notify(`Applications merged successfully.`, { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    setOpen(false)
                    unselect(selectedIds);
                    setFirstSelectedApp(undefined);
                    refresh();
                },
                onError: (error: any) => {
                    notify(`Error: ${error.message}`, { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                }
            });
        }
    };

    React.useEffect(() => {
        if (selectedIds.length > 8) {
            notify('You can only select up to 8 items at a time.', { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            unselect(selectedIds.slice(8)); // Deselect any items beyond the first 8
        }
    }, [selectedIds]);

    React.useEffect(() => {
        if (firstSelectedApp) {
            // if the first selected application is not in "Submitted", "Invited" and 'Application In Progress' status, deselect all items
            if (firstSelectedApp?.ApplicationStatus !== 'Submitted' && firstSelectedApp?.ApplicationStatus !== 'Invited' && firstSelectedApp?.ApplicationStatus !== 'Application In Progress') {
                notify('Only applications with status "Submitted", "Invited" and "In progress" can be merged.', { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                unselect(selectedIds); // Deselect all items
                setFirstSelectedApp(undefined);
                
            }
        }
    }, [firstSelectedApp , selectedIds]);

    React.useEffect(() => {
        // notify user what data missing in selected applications
        if (mismatchingApps.length > 0) {
            const mismatchingIds = mismatchingApps.map(app => app.id);
            const mismatchMessages = mismatchingApps.map(app => {
                const mismatches = app.mismatches.join(', '); 
                return `Mismatched fields: ${mismatches}`;
            });
            notify(`Merge failed for Application(s) with IDs: ${mismatchingIds.join(', ')}. Please check the following mismatched fields: Agent, Property, SubscriberName, Subscribertype from the first selected Application.`,
                { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            unselect(mismatchingIds); // Deselect the non-matching item
        }

    }, [mismatchingApps]);
    const acceptedStatus = ['Invited', 'Application In Progress'];
    React.useEffect(() => {
        const selectedApplicationsExpectZeroIndex = selectedApplications.slice(1).filter(app => app.ApplicationStatus !== undefined);
        // selectedApplicationsExpectZeroIndex skips for udentified ApplicationStatus
        const isMismatchingAppAccepted = selectedApplicationsExpectZeroIndex.every(app => acceptedStatus.includes(app.ApplicationStatus));
        const isMismatchingAccepted = selectedApplicationsExpectZeroIndex.filter(app => !acceptedStatus.includes(app.ApplicationStatus))
        const isMismatchingAcceptedIds = isMismatchingAccepted.map(app => app.id);
        const isMismatchingAcceptedStatus = isMismatchingAccepted.map(app => app.ApplicationStatus);
        if (!isMismatchingAppAccepted) {
            notify(`Applications with Status ${isMismatchingAcceptedStatus.join(', ')} cannot be merged`, { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            unselect(isMismatchingAcceptedIds); // Deselect the accepted items
        }
    }, [selectedApplications]);
    const [open, setOpen] = useState(false);
    const handleDialogClose = () => setOpen(false);
    return (
        <>
            <Button
                variant='contained'
                label="Merge Selected"
                onClick={() => setOpen(true)}
                style={{ padding: '0.5rem', background: selectedIds.length < 2 || mismatchingApps.length > 0 ? 'lightgrey' : '' }}
                disabled={selectedIds.length < 2 || mismatchingApps.length > 0}
                
            >
                <MergeIcon />
            </Button>
            <Confirm
                isOpen={open}

                title={`Are you sure you want to merge the selected applications?`}
                content="You wont be able to undo this later!"
                onConfirm={handleMerge}
                onClose={handleDialogClose}
               
                
            />
        </>
    );
};

export default CustomBulkMergeButton;