import React, { Fragment } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Button } from "@mui/material";

const AlertForStripeModal = ({ open, setOpen, type }) => {
  const dialogTitle = (
    <DialogTitle color="error" id="alert-dialog-title">
      <InfoIcon /> Please add a stripe account for {type} before selecting an{" "}
      {type} account.
    </DialogTitle>
  );

  const dialogContent = (
    <DialogContent>
      <Box display={"flex"} justifyContent={"end"}>
        <Button
          sx={{ mx: 1 }}
          variant="outlined"
          color="secondary"
          type="button"
          onClick={() => {
            setOpen(false);
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          Close
        </Button>
      </Box>
    </DialogContent>
  );
  return (
    <Fragment>
      <Dialog
        fullWidth
        open={open}
        onClose={(event, reason) => {
          if (reason === "backdropClick") {
            // Prevent closing or perform custom logic here
            return false;
          }
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {dialogTitle && dialogTitle}
        {dialogContent && dialogContent}
      </Dialog>
    </Fragment>
  );
};

export default AlertForStripeModal;
