import React, { createContext, useContext, useState, ReactNode } from 'react';

interface UserRoleContextProps {
  role: string;
  setRole: (role: string) => void;
}

const UserRoleContext = createContext<UserRoleContextProps | undefined>(undefined);
interface UserRoleProviderProps {
  children: ReactNode;
  defaultRole: string;
}
export const UserRoleProvider = ({ children,defaultRole }: UserRoleProviderProps) => {
  const [role, setRole] = useState(defaultRole);

  return (
    <UserRoleContext.Provider value={{ role, setRole }}>
      {children}
    </UserRoleContext.Provider>
  );
};

export const useUserRole = () => {
  const context = useContext(UserRoleContext);
  if (!context) {
    throw new Error('useUserRole must be used within a UserRoleProvider');
  }
  return context;
};