import React, { Fragment, useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useLocation } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import InputField2 from "../../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import ToggleButtons from "../../common/InputComponents/ToggleButtons";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button } from "@mui/material";
import GoogleAutoComplete from "../../common/InputComponents/GoogleAutocomplete";
import {
  useCreate,
  useGetIdentity,
  useGetList,
  useGetOne,
  useNotify,
  useRefresh,
  useStore,
} from "react-admin";
import InputSelectField from "../../common/InputComponents/InputSelectField";
import { useSelector } from "react-redux";
import TextInputPhone from "../../DocumentAnalysis/components/inputComponents/TextInputPhone";
import FilePreview2 from "../../common/InputComponents/FilePreview2";
import InputFileUpload from "../../common/InputComponents/InputFileUpload";
import { useParams } from "react-router-dom";
import MultiEmailInput from "../../common/InputComponents/MultiEmailInput";
import { IntegrationRuleType } from "./PropertyDetails";
import InputMaskField from "../../common/InputComponents/InputMaskFieid";
import formValidation from "../../../yupFormValidationRules/formValidator";
import InputCurrencyField from "../../DocumentAnalysis/components/inputComponents/InputCurrencyFiled";

const file_type = ["image/jpg", "image/jpeg", "image/png"];
const file_size = 4000 * 1024;

const AddNewProperty = () => {
  const navigate = useNavigate();
  const notify = useNotify();
  const refresh = useRefresh();
  const toggleButton = [
    {
      value: 0,
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: 1,
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const docUploadToggleButton = [
    {
      value: 0,
      label: "Disabled",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: 1,
      label: "Enabled",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const guestCardToggleButton = [
    {
      value: 0,
      label: "Disabled",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: 1,
      label: "Enabled",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const { identity } = useGetIdentity();
  const { id } = useParams();
  const { state } = useLocation();
  const [getThirdPartyAppsModifiedData, setGetThirdPartyAppsModifiedData] =
    useState<any>([]);
  // state for set a flag if data is enable/null of third Party Integration.
  const [isThirdPartyIntegration, setIsThirdPartyIntegration] = useState(false);
  // get Identification Data
  const { data: getStates, isLoading: isLoadingData } = useGetOne(
    "getStates",
    {
      id: 1,
    },
    { refetchOnWindowFocus: false }
  );

  // get Identification Data
  const { data: getApplicationProfile } = useGetOne(
    "application-profile",
    {
      id: switchSubscriberObj?.value || identity?.subscriberid,
    },
    {
      refetchOnWindowFocus: false,
      enabled: (switchSubscriberObj?.value ||identity?.subscriberid) ? true : false,
    }
  );
  // get getHierarchyLevel Data
  const { data: getHierarchyLevel } = useGetList(
    "hierarchy-levels-list",
    {
      filter: {
        subscriberID: switchSubscriberObj?.value || identity?.subscriberid,
      },
    },
    {
      refetchOnWindowFocus: false,
      enabled: (switchSubscriberObj?.value ||identity?.subscriberid)? true : false,
    }
  );
  // get Identification Data
  const { data: getLeaseTemplate } = useGetOne(
    "lease-template",
    {
      id: switchSubscriberObj?.value,
    },
    {
      refetchOnWindowFocus: false,
      enabled: switchSubscriberObj?.value ? true : false,
    }
  );

  // get getThirdPartyApps Data
  const { data: getThirdPartyApps } = useGetOne(
    "get-third-party-apps",
    {
      id: switchSubscriberObj?.value,
    },
    {
      refetchOnWindowFocus: false,
      enabled: switchSubscriberObj?.value ? true : false,
    }
  );

  // get get-subscriber-configurations Data
  const { data: getSubscriberConfigurations } = useGetOne(
    "get-subscriber-configurations",
    {
      id: switchSubscriberObj?.value,
    },
    {
      refetchOnWindowFocus: false,
      enabled: switchSubscriberObj?.value ? true : false,
    }
  );

  // form validation schema
  const validationSchema = yup.lazy((value) =>
    yup.object().shape({
      property_name: formValidation.name,
      email: yup
      .array()
      .of(yup.string())
      .test("emails", "Required!", (value: any) => {
        return value?.length > 0 ? true : false;
      }),
      status: formValidation.StringRequiredValidation,
      street_address: formValidation.streetAddress,
      zip_code: formValidation.zipCode,
      city: formValidation.city,
      state: formValidation.validationForDropDown,
      telephone: formValidation.mobileNo,
      fax:formValidation.mobileNoNotRequired,
      // fax: yup
      //   .string()
      //   // .matches(
      //   //   /\^\+[0-9]{1,3}\([0-9]{3}\)[0-9]{7}$/,
      //   //   "Fax number is not valid"
      //   // )
      //   .nullable()
      //   .notRequired()
      //   .test(
      //     "pattern",
      //     "Please enter a value matching the pattern",
      //     (value: any) => {
      //       if (value === "" || value === null) {
      //         return true;
      //       }
      //       const regex = /\(\d{3}\) \d{3}-\d{4}/;
      //       return regex.test(value);
      //     }
      //   ),
      application_profile: formValidation.validationForDropDown,
      hierarchy_level: yup
        .object()
        .shape({
          value: yup.string().nullable(),
          label: yup.string().nullable(),
        })
        .nullable(),
      // lease_template: yup
      //   .object()
      //   .shape({
      //     value: yup.string().required("Required!").nullable(),
      //     label: yup.string().nullable(),
      //   })
      //   .nullable()
      //   .required("Required!"),
      select_integration_rule_type:
        value?.third_party_integration &&
        value?.third_party_integration !== null &&
        value?.third_party_integration?.value !== null &&
        formValidation.validationForDropDown,
      property_ID:
        value?.third_party_integration &&
        value?.third_party_integration !== null &&
        value?.third_party_integration?.value !== null &&
        yup.string().nullable().required("Required!"),
      docUpload:
        getSubscriberConfigurations?.records?.DocUpload === 1 &&
        value?.third_party_integration &&
        value?.third_party_integration !== null &&
        value?.third_party_integration?.value !== null &&
        yup.string().required("Required!").typeError("Required!"),
      guestCard:
        getSubscriberConfigurations?.records?.GuestCard === 1 &&
        value?.third_party_integration &&
        value?.third_party_integration !== null &&
        value?.third_party_integration?.value !== null &&
        yup.string().required("Required!").typeError("Required!"),
      file:
        value?.file &&
        value?.file?.type != undefined &&
        yup
          .mixed()
          .nullable()
          // .required("Required!")
          .test(
            "file_size",
            "File size exceeds 4Mb",
            (value) => !value || (value && value.size <= file_size)
          )
          .test(
            "file_type",
            "Supported file format is image files only.",
            (value) => !value || (value && file_type.includes(value?.type))
          ),
    })
  );

  // Initial form values
  const defaultFormValues = {
    property_name: "",
    email: [],
    status: 1,
    street_address: "",
    zip_code: "",
    city: "",
    state: "",
    telephone: "",
    fax: "",
    application_profile: {value:undefined,label:""},
    screeningFee: "",
    subscriberFee: "",
    applicationFee: "",
    processingFee: "",
    hierarchy_level: "",
    lease_template: "",
    addressSearch: "",
    third_party_integration: {value: null, label: ""},
    select_integration_rule_type: "",
    property_ID: "",
    docUpload: 0,
    guestCard: 0,
    file: null,
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const [create, { isLoading, error }] = useCreate();

  // form submit handler
  const onSubmit = (value) => {
    const formData: any = new FormData();
    formData.append("subscriberID", switchSubscriberObj?.value);
    formData.append(
      state?.type === "Office" ? "officeName" : "propertyName",
      value?.property_name
    );
    formData.append("email", value?.email?.join(", "));
    formData.append("active", value?.status);
    formData.append("streetAddress", value?.street_address);
    formData.append("zipCode", value?.zip_code.replace("-",""));
    formData.append("city", value?.city);
    formData.append("state", value?.state ? JSON.stringify(value?.state) : "");
    formData.append("telephone", value?.telephone);
    formData.append("fax", value?.fax);
    formData.append(
      "applicationProfileID",
      value?.application_profile
        ? JSON.stringify(value?.application_profile)
        : ""
    );
    formData.append("processingFee",value?.processingFee);
    formData.append("screeningFee",value?.screeningFee);
    formData.append("subscriberFee",value?.subscriberFee);
    formData.append("applicationFee",value?.applicationFee);
    formData.append(
      "hierarchyID",
      value?.hierarchy_level ? JSON.stringify(value?.hierarchy_level) : ""
    );
    formData.append(
      "leaseTemplateID",
      value?.lease_template ? JSON.stringify(value?.lease_template) : ""
    );
    formData.append(
      "thirdPartyApp",
      value?.third_party_integration
        ? value?.third_party_integration?.value !== null
          ? JSON.stringify(value?.third_party_integration)
          : ""
        : ""
    );
    formData.append(
      "integrationRuleID",
      value?.third_party_integration &&
        value?.third_party_integration?.value !== null
        ? value?.select_integration_rule_type
          ? JSON.stringify(value?.select_integration_rule_type)
          : ""
        : ""
    );
    formData.append(
      "thirdPartyPropertyID",
      value?.third_party_integration &&
        value?.third_party_integration?.value !== null
        ? value?.property_ID
          ? value?.property_ID
          : ""
        : ""
    );
    formData.append(
      "enableGuestCard",
      getSubscriberConfigurations?.records?.GuestCard === 1 &&
        value?.third_party_integration &&
        value?.third_party_integration?.value !== null
        ? value?.guestCard
          ? value?.guestCard
          : 0
        : 0
    );
    formData.append(
      "enableDocUpload",
      getSubscriberConfigurations?.records?.DocUpload === 1 &&
        value?.third_party_integration &&
        value?.third_party_integration?.value !== null
        ? value?.docUpload
          ? value?.docUpload
          : 0
        : 0
    );
    formData.append(
      "file",
      value?.file && value?.file?.type != undefined ? value?.file : ""
    );
    create(
      state?.type === "Office" ? "add-office" : "add-property",
      { data: formData },
      {
        onSuccess: (data) => {
          notify(
            `${
              state?.type === "Office" ? "Office" : "Property"
            } created successfully.`,
            {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
          refresh();
          methods?.reset();
          navigate(-1);
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };

  useEffect(() => {
    document.title =
      state?.type === "Office"
        ? "Office Setup - Add New Office"
        : "Property Setup - Add New Property";
  }, []);

  useEffect(() => {
    if (getThirdPartyApps?.data?.length > 0) {
      const additionalObject = {
        appID: null,
        appName: "None",
        subscriberAppID: null,
      };
      const modifiedData = [...getThirdPartyApps?.data, additionalObject];
      setGetThirdPartyAppsModifiedData(modifiedData);
    }
  }, [getThirdPartyApps?.data]);

  // Function to determine the flag value
  const hasAnyStatusOne = (objects) => {
    // Use the some method for concise and efficient checking
    return objects ? objects?.some((object) => object?.status === 1) : false;
  };

  useEffect(() => {
    if (getSubscriberConfigurations?.records) {
      let thirdPartyIntegration;
      if (
        typeof getSubscriberConfigurations?.records?.ThirdPartyIntegration !==
        "object"
      ) {
        thirdPartyIntegration = JSON?.parse(
          getSubscriberConfigurations?.records?.ThirdPartyIntegration
        );
      } else {
        thirdPartyIntegration =
          getSubscriberConfigurations?.records?.ThirdPartyIntegration;
      }
      setIsThirdPartyIntegration(hasAnyStatusOne(thirdPartyIntegration));
      methods?.setValue("screeningFee", getSubscriberConfigurations?.records?.ScreeningFee);
      methods?.setValue("subscriberFee", getSubscriberConfigurations?.records?.SubscriberFee);
      methods?.setValue("applicationFee", methods?.watch('application_profile')?.value!==undefined? (getApplicationProfile?.data?.filter(item=>item?.ID === methods?.watch('application_profile')?.value)[0]?.ApplicationFee) :getSubscriberConfigurations?.records?.ApplicationFee);
      methods?.setValue("processingFee", getSubscriberConfigurations?.records?.ProcessingFee);
      const defaultProfile = getApplicationProfile?.data?.filter((item) => item?.ProfileName === "Default Application Profile")[0];
      methods?.setValue("application_profile",{value:defaultProfile?.ID,label:defaultProfile?.ProfileName});
    }
  }, [getSubscriberConfigurations?.records, getApplicationProfile?.data]);
 useEffect(()=>{
  if(methods.watch("zip_code")?.length>5 && methods?.watch("zip_code")?.slice(5, 6)!=="-")
    methods?.setValue("zip_code", methods?.watch("zip_code")?.slice(0, 5)+"-"+methods?.watch("zip_code")?.slice(5));
 },[methods?.watch("zip_code")])

 useEffect(()=>{
  methods?.setValue("applicationFee",methods?.watch('application_profile')?.value!==undefined? (getApplicationProfile?.data?.filter(item=>item?.ID === methods?.watch('application_profile')?.value)[0]?.ApplicationFee) :getSubscriberConfigurations?.records?.ApplicationFee)
},[methods?.watch('application_profile')])

const screeningFeeWatch = methods?.watch(`screeningFee`);
const subscriberFeeWatch = methods?.watch(`subscriberFee`);
const applicationFeeWatch = methods?.watch(`applicationFee`);
useEffect(()=>{
  methods?.setValue("processingFee",`${((+applicationFeeWatch)-(+subscriberFeeWatch)-(+screeningFeeWatch))}`)
}, [screeningFeeWatch, subscriberFeeWatch, applicationFeeWatch]);

  return (
    <Fragment>
      <div className="home_content">
        <div className="row">
          <div className="col-12 d-flex">
            <Breadcrumbs aria-label="breadcrumb">
              <Typography sx={{ fontWeight: "bold" }}>
                <button
                  title="Go Back"
                  style={{ color: "#2cc0e4" }}
                  className="btn btn-link"
                  // onClick={() => navigate("/ats/settings/propertySetup")}
                  onClick={() => navigate(-1)}
                >
                  <ArrowBack />
                </button>
                <span>
                  Add New {state?.type === "Office" ? "Office" : "Property"}
                </span>
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label={
                    state?.type === "Office" ? "Office Name" : "Property Name"
                  }
                  source={`property_name`}
                  placeholder={`Enter ${
                    state?.type === "Office" ? "Office" : "Property"
                  } Name`}
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <MultiEmailInput
                  label="Email"
                  source={`email`}
                  placeholder="Enter Email"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <ToggleButtons
                  toggleButton={toggleButton}
                  label="Status"
                  labelClass={"lable-title"}
                  source={`status`}
                />
              </div>
              <div className="col-sm-12 mr-b20">
                <GoogleAutoComplete
                  notRequired={true}
                  groupFieldName={{
                    street: `street_address`,
                    city: `city`,
                    state: `state`,
                    zip: `zip_code`,
                    state_data: getStates?.data?.map((item) => ({
                      value: item?.id,
                      label: item?.StateShortCode + " - " + item?.StateName,
                    })),
                  }}
                  register={methods?.register(`addressSearch`)}
                  label={"Search  Address"}
                  labelClass={"lable-title"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Street Address"
                  source={`street_address`}
                  placeholder="Enter Street Address"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="City"
                  source={`city`}
                  placeholder="Enter City"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`State`}
                  source={`state`}
                  options={getStates?.data?.map((item) => ({
                    value: item?.id,
                    label: item.StateShortCode + " - " + item.StateName,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputField2
                  label="Zip Code"
                  source={`zip_code`}
                  placeholder="Enter Zip Code"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  handleOnChange={(event)=>{
                    // console.log(event.target.value.length>5,"zip");
                    // if(event.target.value.length>5)
                    //   methods?.setValue("zip_code", methods.watch("zip_code").slice(0, 5)+"-"+methods.watch("zip_code").slice(0, 5));
                  }
                }
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <TextInputPhone
                  source={`telephone`}
                  label="Telephone"
                  isCountryCode={`country_code`}
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  widthClass={"w-100"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
              <TextInputPhone
                  source={`fax`}
                  label="Fax"
                  isCountryCode={`country_code`}
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  widthClass={"w-100"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  required={false}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`Application Profile`}
                  source={`application_profile`}
                  options={getApplicationProfile?.data?.map((item) => ({
                    value: item?.ID,
                    label: item?.ProfileName,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Application Fee"
                  source={`applicationFee`}
                  required={true}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    methods?.trigger(`applicationFee`);
                  }}
                  removeDefaultZeroValue={true}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Screening Fee"
                  source={`screeningFee`}
                  required={true}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    methods?.trigger(`screeningFee`);
                  }}
                  removeDefaultZeroValue={true}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Subscriber Fee"
                  source={`subscriberFee`}
                  required={true}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    methods?.trigger(`subscriberFee`);
                  }}
                  removeDefaultZeroValue={true}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputCurrencyField
                  label="Processing Fee"
                  source={`processingFee`}
                  required={false}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  handleOnChange={() => {
                    methods?.trigger(`processingFee`);
                  }}
                  disabled={true}
                  allowNegative={true}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`Hierarchy Level`}
                  source={`hierarchy_level`}
                  options={getHierarchyLevel?.flatMap((item) => (
                    item?.Active?
                    {
                    value: item?.id,
                    label: item?.HierarchyName,
                  }
                  :[]
                  ))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  required={false}
                />
              </div>
              {/* <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <InputSelectField
                  label={`Lease Template`}
                  source={`lease_template`}
                  options={getLeaseTemplate?.data?.map((item) => ({
                    value: item?.ID,
                    label: item?.LeaseName,
                  }))}
                  labelAsteriskClass={"color-red"}
                  labelClass={"lable-title"}
                  requiredClass={{
                    fontSize: "70%",
                  }}
                  required={false}
                />
              </div> */}
              {/* <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <div className="d-flex">
                  <ToggleButtons
                    toggleButton={docUploadToggleButton}
                    label="DocUpload"
                    labelClass={"lable-title"}
                    source={`docUpload`}
                  />
                  <div className="mx-4">
                    <ToggleButtons
                      toggleButton={guestCardToggleButton}
                      label="GuestCard"
                      labelClass={"lable-title"}
                      source={`guestCard`}
                    />
                  </div>
                </div>
              </div> */}
              {/* <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <ToggleButtons
                  toggleButton={docUploadToggleButton}
                  label="DocUpload"
                  labelClass={"lable-title"}
                  source={`docUpload`}
                />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                <ToggleButtons
                  toggleButton={guestCardToggleButton}
                  label="GuestCard"
                  labelClass={"lable-title"}
                  source={`guestCard`}
                />
              </div> */}
            </div>
            {(getSubscriberConfigurations?.records?.DocUpload === 1 ||
              getSubscriberConfigurations?.records?.GuestCard === 1) &&
              isThirdPartyIntegration && (
                <div className="row">
                  <div className="col-sm-12 mr-b30">
                    <div
                      style={{
                        border: "1px solid rgba(0, 0, 0, 0.16)",
                        borderRadius: "4px",
                        padding: "10px 16px 16px 16px",
                      }}
                    >
                      <div className="mb-3">
                        <label className="lable-title">
                          PMS Integration Details
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                          <InputSelectField
                            label={`Select PMS`}
                            source={`third_party_integration`}
                            options={getThirdPartyAppsModifiedData?.map(
                              (item) => ({
                                value: item?.appID,
                                label: item?.appName,
                              })
                            )}
                            labelAsteriskClass={"color-red"}
                            labelClass={"lable-title"}
                            requiredClass={{
                              fontSize: "70%",
                            }}
                            onChangeCallback={() => {
                              methods?.trigger(`select_integration_rule_type`);
                              methods?.trigger(`property_ID`);
                            }}
                            required={false}
                          />
                        </div>
                        <IntegrationRuleType // component for Select integration rule type field from property details
                          methods={methods}
                          switchSubscriberObj={switchSubscriberObj}
                        />
                        {methods?.watch(`third_party_integration`) &&
                        methods?.watch(`third_party_integration`).value  !== null ? (
                          <>
                          <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                            <InputField2
                              label="Property ID"
                              source={`property_ID`}
                              placeholder="Enter Property ID"
                              labelClass={"lable-title"}
                              additionalInputClass={"cus-control"}
                              notRequired={
                                methods?.watch(`third_party_integration`) &&
                                methods?.watch(`third_party_integration`).value !==
                                  null
                                  ? true
                                  : false
                              }
                            />
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-4 mr-b20">
                            <div className="d-flex">
                              {getSubscriberConfigurations?.records
                                ?.DocUpload === 1 && (
                                <ToggleButtons
                                  toggleButton={docUploadToggleButton}
                                  label="DocUpload"
                                  labelClass={"lable-title"}
                                  source={`docUpload`}
                                />
                              )}
                              {getSubscriberConfigurations?.records
                                ?.GuestCard === 1 && (
                                <div
                                  className={
                                    getSubscriberConfigurations?.records
                                      ?.DocUpload === 1
                                      ? "mx-4"
                                      : ""
                                  }
                                >
                                  <ToggleButtons
                                    toggleButton={guestCardToggleButton}
                                    label="GuestCard"
                                    labelClass={"lable-title"}
                                    source={`guestCard`}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            <div className="row mr-b50">
              <div className="col-12 mr-b20">
                <div
                  className="p-3"
                  style={{ background: "#f5fdff", borderRadius: "8px" }}
                >
                  <div className="row">
                    <div className="col-sm-12 col-md-2 col-lg-2 d-flex align-items-center">
                      <label className="lable-title">
                        {state?.type === "Office"
                          ? "Office Logo"
                          : "Property Logo"}
                      </label>
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-4">
                      <div className="d-flex align-items-center">
                        {methods?.watch(`file`) !== null &&
                          !methods?.formState?.errors?.file && (
                            <FilePreview2
                              img={methods?.watch(`file`)}
                              isPdf={false}
                              isfileName={false}
                              isDeletabel={false}
                            />
                          )}
                        {/* <div className="mx-3">
                          <InputFileUpload
                            source={`file`}
                            acceptedFiles={"image/*"}
                            buttonText={
                              methods?.watch(`file`) !== null &&
                              !methods?.formState?.errors?.file
                                ? `Change`
                                : `Upload`
                            }
                          />
                        </div> */}
                        {(methods?.watch(`file`) !== null &&
                              !methods?.formState?.errors?.file)?
                        <div className="mx-3">
                          <Button 
                           color='error'
                           startIcon={<DeleteIcon />}
                           onClick={()=>{
                            methods?.setValue(`file`,null);
                           }}
                          >Delete</Button>
                        </div>:
                        <div className="mx-3">
                          <InputFileUpload
                            source={`file`}
                            acceptedFiles={"image/*"}
                            buttonText={`Upload`}
                          />
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Box
             display={"flex"}
             justifyContent={"end"}
             alignItems={"center"}
             className="mt-4"
             minHeight="10vh"
            >
              <Box>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {
                    refresh();
                    methods?.reset();
                    navigate(-1);
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  startIcon={<CheckIcon />}
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                  disabled={isLoading}
                >
                  Create {state?.type === "Office" ? "Office" : "Property"}
                </Button>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </div>
    </Fragment>
  );
};

export default AddNewProperty;
