import React, { useEffect } from "react";
import ToggleButtons from "../../componets/common/InputComponents/ToggleButtons";
import "../../App.css";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Box, Button } from "@mui/material";
import save_icon from "../../assets/images/save_icon.svg";
import { useCreate, useGetIdentity, useGetList, useNotify } from "react-admin";
import QuillTextEditor from "../../componets/common/InputComponents/TextEditorWithDataField/QuillTextEditor";
import { useNavigate } from "react-router-dom";


const TermsAndConditionForm = ({ DataFrom, dataFieldOptions }) => {
    // Component logic goes here
    const [create, { isLoading, data }] = useCreate();
    const sortedAscDataFieldOptions = dataFieldOptions;
    sortedAscDataFieldOptions?.sort((a, b) => {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    });
    const navigate = useNavigate();
    const [docuverusAccordion, setDocuverusAccordion] = React.useState(false);
    const schema = yup.object().shape({
        Description: yup.string().required("Required").test(
            'not-only-br', // Name of the test
            'Required', // Error message
            value => value !== '<p><br></p>' // Test function
        ),
    });

    const defaultFormValues = {

        Description: "",
        TermsConditionType: ''
    };

    const methods = useForm({
        defaultValues: defaultFormValues,
        resolver: yupResolver(schema),
        mode: "onBlur",
    });
    const notify = useNotify();

    const onSubmit = (data) => {
        notify("Updating Terms and Conditions Please wait a moment", { type: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        create("addupdatetermsandcondition_admin", { data: data });
    };
    useEffect(() => {
        if (data) {
            notify("Terms and Conditions Updated Successfully", { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
    }, [data]);
    useEffect(() => {
        if (DataFrom) {
            methods.setValue('Description', DataFrom?.Description || '');
            methods.setValue('TermsConditionType', DataFrom?.TermsConditionType);
        }
    }, [DataFrom]);
    const toggleButton = [
        {
            value: "false",
            label: "Inactive",
            icon: <ClearIcon />,
            borderColor: "#909090",
            backgroundColor: "#EEE",
            boxShadowColor: "#90909033",
            color: "#606060",
        },
        {
            value: "true",
            label: "Active",
            icon: <CheckIcon />,
            borderColor: "#46A96F",
            backgroundColor: "#EBFFF3",
            boxShadowColor: "#46a96f1a",
            color: "#46A96F",
        },
    ];

    return (
        <div>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-t30">
                    {" "}
                    <div className="info-toggle">
                        <div
                            className="toggle-heads"
                            onClick={(event) => {
                                event.preventDefault();
                                setDocuverusAccordion((v) => !v);
                            }}
                        >
                            <div className="row align-items-center justify-content-between ">
                                <div className="col">
                                    <div className="d-flex align-items-center">
                                        <b
                                            className="ms-3 me-2"
                                            style={{ fontSize: "18px", fontWeight: "700", fontFamily: "Open Sans" }}
                                        >
                                            Docuverus
                                        </b>

                                    </div>
                                </div>

                                <br />
                                <br />
                                <br />
                            </div>
                            {/*  ${!docuverusAccordion ? "d-none" : "d-display"
                              } */}
                            <div
                                className={` card-body cust-card-appl`}
                            >
                                <div
                                    className="card card-body w-100"
                                    style={{ paddingTop: 0 }}
                                >
                                    <br />
                                    <div className="row">
                                        <div className="col-sm-12 mr-b20">
                                            <QuillTextEditor dataFieldOptions={sortedAscDataFieldOptions} source={'Description'} control={methods.control} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Box
                        display={"flex"}
                        justifyContent={"end"}
                        alignItems={"center"}
                        className="mt-4"
                        minHeight="10vh"
                    >
                        <Box>

                            <Button
                                startIcon={<img src={save_icon} alt="save icon" />}
                                sx={{ mx: 1 }}
                                variant="contained"
                                type="submit"
                                disabled={isLoading}
                            >
                                Update
                            </Button>
                        </Box>
                    </Box>
                </form>
            </FormProvider>
        </div>
    );
};

export default TermsAndConditionForm;