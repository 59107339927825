import React, { ChangeEvent } from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import InputSwitchField from "./InputSwitchField";

const BarChartGraph = ({ records, setIsDay }) => {
  const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const data = labels.map((item, index) => ({
    name: item,
    new_app: records?.count[index]?.Usercount || 0,
  }));

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIsDay(checked ? 1 : 0);
  };

  return (
    <div className="n-app-graph">
      <ResponsiveContainer width="100%" height={200}>
        <div>
          <div className="d-flex justify-content-between">
            <div className="active_users_count">
              <h5 className="invisible d-inline">
                {records?.active ? records?.active : 0}
              </h5>
              {/*hidden above h5 for adjust style*/}
              <small className="location_users">Location wise users</small>
              <h5>
                {records?.active ? records?.active : 0}
                <small>Presently Active Users</small>
              </h5>
            </div>
            <div className="toggle_switch is_checked">
              <InputSwitchField
                labelRight="Day"
                labelLeft="Hour"
                onChange={handleChange}
              />
            </div>
          </div>
          <ResponsiveContainer width="100%" height={150}>
            <BarChart data={data}>
              <Bar
                type="monotone"
                name="Active Users"
                dataKey="new_app"
                fill="#8884d8"
              />
              <XAxis dataKey="name" />
              <Tooltip />
              <Legend verticalAlign="top" height={25} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartGraph;
