import React, { useState } from 'react';
import Dialog from "@mui/material/Dialog";
import { FunctionField, TextField, useGetList, Datagrid, useList, ListContextProvider, useNotify, useGetOne, useGetIdentity, useStore, usePermissions } from 'react-admin';
import { DialogContent, Box, DialogTitle } from "@mui/material";
import { MdOutlineFileCopy } from "react-icons/md";
import DataGridCustom from '../DataGrid/DataGridCustom';
import copyTextToClipboard from '../../Functions/copytoclipboard';
import { JSONTree } from 'react-json-tree';
import { useParams } from "react-router-dom";
import { REACT_APPLICANT } from '../../config';
function CopyUrlModal({ dataSubscriberInfo }) {
    // const [switchSubscriber] = useStore('switchSubscriber');
    const switchSubscriber =localStorage.getItem("RaStore.switchSubscriber")
    const [open, setopen] = useState(false);
    const handleChange = (event) => {
        setSelected(event.target.value);
    };
    const getSelectedId = (selected) => {
        switch (selected) {
            case 'user':
                return 1
            case 'property':
                return 1
            case 'office':
                return 2
            case 'floor':
                return 2
            case 'unit':
                return 3
            default:
                return 1
        }
    }

    const { id: urlId } = useParams();
    const [selected, setSelected] = useState(
        dataSubscriberInfo?.CompanyType == "Real Estate" ? "user" : "property"
    );
    const { data, isLoading } = useGetList(
        "getcopyurl",
        {
            filter: { Realestate: dataSubscriberInfo?.CompanyType === "Real Estate" ? 1 : 2, urltype: getSelectedId(selected) || 1, subscriberID: (switchSubscriber === "true") ? urlId : null },
        },
        { enabled: open }
    );
    // get id for application id
    const { data: identity }: any = useGetIdentity();
    // const {subscriberid:id}= identity;
    const id = identity?.subscriberid || urlId;

    const { data: applicationDetail, isLoading: isLoadingData } = useGetOne(
        "getnewapplicationdetail",
        {
            id,
        },
        { enabled: open && id ? true : false }
    );


    const listContext = useList({ data, isLoading });
    const notify = useNotify();
    const notifyUser = async (funcRes, url) => {
        if (await funcRes(url))
            notify(`Url copied to clipboard!`, { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
        else
            notify(`Url fail copied to clipboard!`, { type: 'warning', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
    }
    return (<>
        <button onClick={() => setopen(true)} type="button" className="g-line-btn" title='Copy URL'>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="225"
                height="225"
                viewBox="0 0 225 225"
                version="1.1"
            >
                <path
                    d="M 48.307 77.077 C 35.637 83.349, 25.953 104.028, 28.838 118.651 C 31.568 132.485, 41.909 146.552, 51.185 149.050 C 53.561 149.689, 62.232 149.999, 72.539 149.813 L 89.894 149.500 93.538 146 C 95.542 144.075, 98.497 139.912, 100.105 136.750 L 103.029 131 80.975 131 C 60.822 131, 58.579 130.826, 54.961 128.980 C 45.658 124.234, 42.365 111.091, 48.251 102.197 C 53.272 94.610, 55.707 94, 80.975 94 L 103.029 94 100.124 88.246 C 96.949 81.955, 92.792 77.257, 89.185 75.882 C 87.912 75.397, 79.137 75, 69.685 75.001 C 54.306 75.001, 52.059 75.220, 48.307 77.077 M 136 75.702 C 132.916 76.820, 127.724 82.687, 124.895 88.250 L 121.971 94 144.025 94 C 164.178 94, 166.421 94.174, 170.039 96.020 C 179.342 100.766, 182.635 113.909, 176.749 122.803 C 171.728 130.390, 169.293 131, 144.025 131 L 121.971 131 124.895 136.750 C 126.503 139.912, 129.459 144.075, 131.462 146 L 135.106 149.500 153.608 149.812 C 170.755 150.102, 172.430 149.970, 176.474 148.013 C 189.281 141.813, 199.093 121.045, 196.146 106.372 C 193.595 93.671, 185.335 81.277, 176.603 77.050 C 172.807 75.212, 170.563 75.008, 154.934 75.079 C 145.345 75.122, 136.825 75.403, 136 75.702 M 80 104.294 C 74.694 106.978, 73.643 115.555, 78.123 119.613 C 80.054 121.361, 82.453 121.521, 110.794 121.792 C 143.967 122.109, 145.993 121.847, 148.547 116.909 C 150.312 113.496, 150.361 111.887, 148.805 108.472 C 146.433 103.266, 144.643 102.999, 112.300 103.015 C 90.287 103.026, 81.847 103.360, 80 104.294"
                    fill="#909090"
                />
            </svg>
        </button>
        <Dialog open={open} onClose={() => setopen(false)}>
            <DialogContent style={{minWidth:250}} className="dialog_content_filters">
                <Box >
                    <div
                        className="button-container"
                        style={{
                            textAlign: "center",
                        }}
                    >
                        {dataSubscriberInfo?.CompanyType == "Real Estate" ? (
                            <div className="app-option-btn" id="a-oprtions">
                                <label>
                                    <input
                                        type="radio"
                                        name="a-options"
                                        id="user"
                                        value="user"
                                        checked={selected == 'user' ? true : false}
                                        onChange={handleChange}
                                    />
                                    <span>User URL</span>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="a-options"
                                        id="office"
                                        value="office"
                                        checked={selected === "office" ? true : false}
                                        onChange={handleChange}
                                    />
                                    <span>Office URL</span>
                                </label>
                            </div>
                        ) : (
                            <div className="app-option-btn" id="a-oprtions">
                                <label>
                                    <input
                                        type="radio"
                                        name="a-options"
                                        id="property"
                                        value="property"
                                        checked={selected == 'property' ? true : false}
                                        onChange={handleChange}
                                    />
                                    <span>Property URL</span>
                                </label>

                                <label>
                                    <input
                                        type="radio"
                                        name="a-options"
                                        id="floor"
                                        value="floor"
                                        checked={selected === "floor" ? true : false}
                                        onChange={handleChange}
                                    />
                                    <span>Floor URL</span>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="a-options"
                                        id="unit"
                                        value="unit"
                                        checked={selected === "unit" ? true : false}
                                        onChange={handleChange}
                                    />
                                    <span>Unit URL</span>
                                </label>
                                {/* )} */}
                            </div>
                        )}
                    </div>
                    <br />
                    <div id='copyurl'>
                    <ListContextProvider value={listContext} >
                        <DataGridCustom>
                            <TextField source="NAME" />
                            {applicationDetail?.ApplicationType?.ATS && <FunctionField label={'Applications'} render={(record) => {
                                console.log(record?.URL);
                                return record?.URL && <button type="button" onClick={() => notifyUser(copyTextToClipboard, REACT_APPLICANT +'/'+ record?.URL)} className="g-line-btn">
                                    <MdOutlineFileCopy size={18} />
                                </button>
                            }} />}
                            {applicationDetail?.ApplicationType?.DocUpload && <FunctionField label={'Docupload'} render={(record) => {
                                return record?.DocUploadURL && <button type="button" onClick={() => notifyUser(copyTextToClipboard, `${window.location.protocol}//${window.location.host}/atss/cp/` + record?.DocUploadURL)} className="g-line-btn">
                                    <MdOutlineFileCopy size={18} />
                                </button>
                            }} />}
                            {applicationDetail?.ApplicationType?.GuestCard && <FunctionField label={'GuestCard'} render={(record) => {
                                return record?.GuestCardURL && <button type="button" onClick={() => notifyUser(copyTextToClipboard, `${window.location.protocol}//${window.location.host}/atss/cp/` + record?.GuestCardURL)} className="g-line-btn">
                                    <MdOutlineFileCopy size={18} />
                                </button>
                            }} />}
                        </DataGridCustom>
                    </ListContextProvider>
                    </div>
                </Box>
            </DialogContent>
        </Dialog>
    </>
    );
}

export default CopyUrlModal;