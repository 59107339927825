import React, { useState, useEffect } from "react";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { Controller, useController, useFormContext } from "react-hook-form";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { ADDRESS_API_KEY } from "../../../config";

const getAddressObject = (address_components) => {
  var ShouldBeComponent = {
    home: ["street_number"],
    postal_code: ["postal_code"],
    street: ["street_address", "route"],
    region: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
    ],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4",
    ],
    country: ["country"],
  };

  var address = {
    home: "",
    postal_code: "",
    street: "",
    region: "",
    city: "",
    country: "",
  };
  address_components.forEach((component) => {
    for (var shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === "country") {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });
  return address;
};

const GoogleAutoComplete = (props) => {
  // 
  const { name } = props.register;
  const { control, setValue, watch } = useFormContext();
  const required = props.notRequired === undefined ? true : !props.notRequired;
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const { onChange, ...rest } = field;
  const {labelClass = "form-label", handleOnChange=()=>{}} = props;
  return (
    <>
      <label
        htmlFor={name}
        className={`${labelClass} ${error !== undefined && "text-danger"}`}
      >
        {props.label} {required && <span className="red">*</span>}
      </label>
      <Autocomplete
        apiKey={ADDRESS_API_KEY}
        className={`form-control cus-input-control`}
        onPlaceSelected={(place, inputRef, autocomplete) => {
          onChange(place);
          const address = getAddressObject(
            place.address_components
          );
          setValue(props.groupFieldName.street, `${address.home} ${address.street}`, {
            shouldValidate: true,
          });
          setValue(props.groupFieldName.city, address.city, {
            shouldValidate: true,
          });
          if (props.groupFieldName.home)
            setValue(props.groupFieldName.home, address.home, {
              shouldValidate: true,
            });
          if (props.groupFieldName.zip)
            setValue(props.groupFieldName.zip, address.postal_code, {
              shouldValidate: true,
            });
          const getSelected = props.groupFieldName?.state_data?.filter(
            (item) => {
              return item.label.includes(address.region);
            }
          );
          if (getSelected?.length) {
            setValue(props.groupFieldName.state, getSelected[0], {
              shouldValidate: true,
            });
          }
          handleOnChange();
        }
        }
        placeholder={props.label}
        options={{
          types: ["address"],
          componentRestrictions: { country: "us" }
        }}
      />
      <div className="invalid-feedback">
        {error !== undefined && error.message}
      </div>
    </>
  );
};

export default GoogleAutoComplete;
