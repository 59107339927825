import { format } from "date-fns"
import { BooleanField, CreateButton, Datagrid, DateField, FunctionField, List, ListBase, ListGuesser, ListToolbar, NumberField, Pagination, Resource, TextField, useGetOne, useListContext, useNotify, useStore } from "react-admin"

import {
  Grid,
  Typography,
  TextField as MuiTextField,
  Box,
  Link,
  ToggleButton,
  ToggleButtonGroup,
  Checkbox,
  Stack,
  Button as MuiButton
} from "@mui/material";
import { useNavigate ,useParams} from "react-router-dom";

import PDF from "./../../../../assets/images/Frame (5).png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import FilterChips from "../../Ats/SuperAdminInbox/FilterChips";
import { debounce } from "lodash";
import ApplicationsettingLogo from "../../../assets/images/Frame (10).png";
import AdverseFilter from "../../DocumentAnalysis/components/AdverseLetter/AdverseFilter";
import ApplicationFilter from "./ApplicationFilter";
export const AplicationProfile = () => {
  return <Resource name="getapplicationprofilelist" list={GetapplicationprofilelistList} />
}

const bgStyle = (condition: string) => {
  let style = {};
  switch (condition) {
    case "CompletedConditionallyApproved":
      style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
      break;
    case "NA":
      style = { backgroundColor: "#E9E9E9", color: "#909090" };
      break;
    case "Completed-Unacceptable":
      style = { backgroundColor: "#F8ECE2", color: "#FF4848" };
      break;
    case "Completed-Approved":
      style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
      break;
    case "Invited":
      style = { backgroundColor: "#E9E9E9", color: "#909090" };
      break;
    case " In Progress":
      style = { backgroundColor: "#DDF8FF", color: "#1CA2C2" };
      break;
    case "Submitted":
    case "Review Pending":
      style = { backgroundColor: "#B0EFFF", color: "#127790" };
      break;
    // case "Screening":
    case "Completed":
    case "Valid":
    case "Active":
    case "1":
    // case "Completed-Approved":
    case "Verified":
      style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
      break;
    case "Cancelled":
    case "Archived":
    // case "Completed-Unacceptable":
    case "Invalid":
    case "Inactive":
    case "0":
    case "Unverifiable":
      style = { backgroundColor: "#FFEEEE", color: "#FF4848" };
      break;
    case "Completed-Conditionally Approved":
    case "CompletedConditionallyApproved":
    case "Further Documentation Required":
    case "Verification Required":
    case "Further Doc Req.":
      style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
      break;
    default:
      style = {};
  }
  return style;
};
const MyListToolBar = () => {
  const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
    useListContext();


  const [switchSubscriberObj] = useStore("switchSubscriberName");
  // Debounce the search function
  const debouncedSearch = debounce((value) => {
    // Perform your search logic here
    setFilters({ ...filterValues, q: value }, []);
  }, 500); // 500ms debounce delay

  const handleSearchChange = (event) => {
    const value = event.target.value;
    debouncedSearch(value);
  };
  const navigate = useNavigate();

  return (
    <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
      {/* Left side with title */}
      <Grid item xs={12} md={6}>

        <p style={{ font: "Open Sans", fontWeight: 600, fontSize: '18px' }}>Application Profile</p>



      </Grid>

      {/* Right side with search input, filter button, and add button */}
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        <div className="group-btn">
          {/* Search Input */}
          <MuiTextField
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            defaultValue={filterValues.q}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
          />
          <ApplicationFilter />

          <button
            type="button"
            onClick={() =>
              navigate(`/ats/settings/applicationProfile/create`, {
                state: { isAdding: true },
              })
            }
            className="blue-btn px-4"
          >
            <svg
              className="mr-r10"
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.33337 4.83333V0.833334H5.66671V4.83333H9.66671V6.16667H5.66671V10.1667H4.33337V6.16667H0.333374V4.83333H4.33337Z"
                fill="white"
              />
            </svg>
            Application Profile
          </button>




        </div>
      </Grid>
    </Grid>
  );
};
const MyList = ({ children, toolBar, ...props }) => {
  return (
    <ListBase
      {...props}
      perPage={100} // Number of items per page
    >
      {toolBar}
      <FilterChips excludeKeys={['app_invitation_type', 'status']} />
      {children}

      <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
      <br />
      <br />
      <br />
      <br />
    </ListBase>
  );
};

export const GetapplicationprofilelistList = () => {
  const navigate = useNavigate();

  return (

    <MyList
      toolBar={<MyListToolBar />}
      sort={{ field: "id", order: "DESC" }}

    >
      <Datagrid bulkActionButtons={false} sx={{
        "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
        "& .RaDatagrid-expandIcon": { color: "#fff" },
        "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
        "& .RaDatagrid-headerCell": {
          fontWeight: "bold",
          fontSize: 12,
        },
        "& .RaDatagrid-table": {
          fontSize: 12,
        },
        "& .MuiToggleButton-root.Mui-selected": {
          fontWeight: 700,
        },
      }}>

        <TextField source="Profile Name" />
        <TextField source="Application Fee" />
        <FunctionField
          label={"Status"}
          source=" Status"
          render={(record) => {
            return (
              <MuiButton
                sx={{ width: "80%" }}
                variant="contained"
                disableElevation={true}
                style={bgStyle(record["Status"])}
              >
                {record["Status"] == "1" ? "Active" : "Inactive"}
              </MuiButton>
            );
          }}
        />
        {/* <FunctionField
  label={""}
  source="Status"
  render={(record) => {
    return record["Status"] === "Active" ? (
      <span style={{ 
          color: "grey",
          fontFamily: "Open Sans",
          fontWeight: 400,
          fontSize: "12px",
          lineHeight: "16.34px"
       }}>
        Default
        </span>
    ) : (
      ""
    );
  }}
/> */}
        <FunctionField render={(record) => (record.DefaultProfile == 1 ? "Default Profile" : "")} />
        <FunctionField
          label={""}
          render={(record) => (
            // <Box display={"flex"}>
            <Box display={"flex"} sx={{ width: "80px" }}>
              {/* <CopyLinkHoverDropdown record={record} /> */}
              <IconButton
                aria-label="view details"
                color="primary"
                sx={{
                  py: 0,
                }}
                onClick={() =>
                 
                  {
                     console.log(record,"getapplicationprofiledetails")
                    navigate(`/ats/settings/applicationProfile/${record?.id}/applicationSettings`, {
                      state: { isAdding: true , formData:record},
                    })
                }
                }
                className="show_on_hover"
              >
              {/* <ApplicationsettingLogo/> */}
              <img src={ApplicationsettingLogo}/>
              </IconButton>
              <IconButton
                aria-label="view details"
                color="primary"
                sx={{
                  py: 0,
                }}
                onClick={() =>
                  navigate(
                    `/ats/settings/applicationProfile/edit/${record.id}`,
                    //   { state: { isAdding: false, thirdPartyAppId: 1 } }
                  )
                }
                className="show_on_hover"
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          )}
        />
      </Datagrid>
    </MyList>
  )
}

export default AplicationProfile;