import React, { Suspense, useEffect, useRef, useState } from "react";
import "./App.css";
import {
  Admin,
  BooleanField,
  CustomRoutes,
  Datagrid,
  DateField,
  defaultTheme,
  FunctionField,
  List,
  ListGuesser,
  NumberField,
  Resource,
  TextField,
  useDataProvider,
  useGetIdentity,
  useGetList,
  useGetOne,
  usePermissions,
  useStore,
} from "react-admin";
import {
  Outlet,
  Route,
  useNavigate,
  useNavigation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { dataProvidersAts } from "./dataproviders/DataProvidersAts";
import Inbox from "./Container/ATS/Inbox";
import MyLayout from "./componets/MyLayout";
import "./assets/styles/style.css";
import ApplicantDetailEdit from "./componets/Ats/ApplicantDetailEdit";
import { Provider, useDispatch, useSelector } from "react-redux";
import store, { RootState } from "./Store/store";
import {
  setToken,
  setUserRole,
  setaspNetUserId,
} from "./Store/Slice/usersSlice";
import { JSONTree } from "react-json-tree";
import InboxAdmin, {
  Getglobalsearch,
} from "./Container/ATS/InboxAdmin";
import SubscriberAdminUsage from "./Container/ATS/SubscriberAdminUsage";
import Login from "./Container/Login/Login";
import authProvider from "./authProviders/authProviders";
import Getloginactivitylogs, {
  GetAuditLogs,
} from "./Container/ATS/Getloginactivitylogs";
import Getarchivedcancelled from "./Container/ATS/Getarchivedcancelled";
import GetAdminGlobalSearch from "./componets/Ats/GetAdmininboxGlobalList";
import DocumentView from "./componets/Ats/DocumentView";
import ForgotPassword from "./Container/Login/ForgotPassword";
import DataPrivacy from "./Container/Login/DataPrivacy";
import TermsOfUse from "./Container/Login/TermOfUse";
import ApplicationRegistration from "./componets/ApplicationRegistration/ApplicationRegistration";
import ResetPassword from "./Container/Login/ResetPassword";
import ProfileDetails from "./Container/ATS/ProfileDetails";
import ApplicationSettings from "./Container/ATS/ApplicationSettings";
import AddNewProfile from "./Container/ATS/AddNewProfile";
import EmailSettings from "./componets/Settings/EmailSettings/EmailSettings";
import TemplateDetails from "./componets/Settings/EmailSettings/TemplateDetails";
import SubscriberTemplateDetails from "./componets/Settings/EmailSettings/SubscriberTemplateDetails";
import { MyList } from "./componets/Ats/GetAdmininboxList";
import { DatagridTable } from "./componets/Ats/GetsubscriberinboxList";
import PropertyList from "./componets/Settings/PropertySetup/PropertyList";
import AddNewProperty from "./componets/Settings/PropertySetup/AddNewProperty";
import PropertyDetails from "./componets/Settings/PropertySetup/PropertyDetails";
import AddAndUpdateFloor from "./componets/Settings/PropertySetup/AddAndUpdateFloor";
import AddAndUpdateUnit from "./componets/Settings/PropertySetup/AddAndUpdateUnit";
import ManageSubscribersList from "./componets/manageSubscribers/ManageSubscribersList";
// import AddNewSubscriber from "./componets/manageSubscribers/AddNewSubscriber";
import { ATS_DOCS_URL, SOFTWARE_VERSION, typesofUsers } from "./config";
import MfaLogin from "./Container/Login/MfaLogin";
import AddorUpdateSubscriber from "./componets/manageSubscribers/AddorUpdateSubscriber";
import EntrataList from "./componets/Settings/IntegrationSettings/Entrata/EntrataList";
import AddorUpdateIntegrationSettings from "./componets/Settings/IntegrationSettings/Entrata/AddorUpdateIntegrationSettings";
import YardiList from "./componets/Settings/IntegrationSettings/Yardi/YardiList";
import HierarchyLevelList from "./componets/Settings/HierarchyLevel/HierarchyLevelList";
import AddorUpdateHierarchyLevel from "./componets/Settings/HierarchyLevel/AddorUpdateHierarchyLevel";
import UpdateStripeAccount from "./componets/Settings/PropertySetup/UpdateStripeAccount"
import ErrorIcon from "@mui/icons-material/Report"
import { useUserRole } from "./hooks/useUserRoleHook";
import TermsandCondtions from "./Container/TermsandCondtions/TermsandCondtions";
import TermsandCondtionsAdmin, { TermsandCondtionsAdmin_Landoard, TermsandCondtionsAdmin_Privacy, TermsandCondtionsAdmin_Subscriber, TermsandCondtionsAdmin_termsofuse } from "./Container/TermsandCondtionsAdmin/TermsandCondtions";
import UpdateTemplateDetails from "./componets/Settings/EmailSettings/UpdateTemplateDetails";
import AdminEmailSettings from "./Container/ATS/AdminEmailSettings";
import UpdateAdminTemplateDetail from "./Container/ATS/UpdateAdminTemplateDetail";
import { UserRoleProvider } from "./ContextApi/UserRoleContext";
import UserSetup from "./componets/Settings/UserSetup/UserSetup";
import AddOrUpdateUser from "./componets/Settings/UserSetup/AddOrUpdateUser";
import UpdateUser from "./componets/Settings/UserSetup/UpdateUser";
import UserLogs from "./componets/Settings/UserSetup/UserLogs";
import ManageUsers from "./componets/manageUsers/ManageUsers";
import AddUserForAdmin from "./componets/manageUsers/AddUserForAdmin";
import UpdateUserForAdmin from "./componets/manageUsers/UpdateUserForAdmin";

import TempPassWordResetUserSetup from "./Container/ATS/TempPassWordResetUserSetup";
import TermsAndConditionForUserSetup from "./Container/ATS/TermsConditionUserSetup";
// import { getverificationmanagementreportResourcedv } from "./componets/AdminManagementReport/VerificationReport";
// import { ScreeningManagementReportResourcedv } from "./componets/AdminManagementReport/ScreeningManagement";
// import { getrevenuemanagementreportResourcedv } from "./componets/AdminManagementReport/AdminRevenueManagement";
// import { getleadmanagementreportResourcedv } from "./componets/AdminManagementReport/AdminLeadManagement";
import { LeadManagementReportResource } from "./componets/SubscriberManagementReport/LeadMangement";
import RevenueManagementReportResource from "./componets/SubscriberManagementReport/RevenueManagement";
import { DocuploadManagementReportResource } from "./componets/SubscriberManagementReport/DocuploadManagement";
import AdminLeadManagement from "./Container/ATS/AdminLeadManagement";
import AdminScreeningManagement from "./Container/ATS/AdminScreeningManagement";
import AdminRevenueManagement from "./Container/ATS/AdminRevenueManagement";
import AdminVerificationManagement from "./Container/ATS/AdminVerificationReport";

// import AdminLeadManagement from "./componets/AdminManagementReport/AdminLeadManagement";

import ForgotUsername from "./Container/Login/ForgotUsername";
import TermsConditionLandLoardSetup from "./Container/ATS/TermsConditionLandLoardSetup";
import InboxLandLoard from "./Container/ATS/InboxLandLoard";
import MyLayoutLandLoard from "./componets/MyLayoutLandLoard";
import { GetListLandLoardList } from "./componets/Ats/LandLoard/GetListLandLoardList";
import ViewAdverseLetter from "./componets/Ats/LandLoard/ViewAdverseLetter";
import authProviderLandLoard from "./authProviders/authProvidersLandLoard";

const theme = {
  ...defaultTheme,
  sidebar: {
    width: 240, // The default value is 240
    closedWidth: 66, // The default value is 55
  },
  appbar: {
    // root: {
    //   backgroundColor: 'red'
    // }
  },
  typography: {
    fontFamily: ["'Open Sans'", " sans-serif"].join(","),
    fontSize: 12,
    fontWeight: 400,
    LineHeight: 16,
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#2cc0e4",
      contrastText: "#fff",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#0066ff",
      main: "#4BD5E7",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#fff",
    },
    error: {
      main: "#ff4848",
      contrastText: "#fff",
    },
    warning: {
      main: "#f9bb04",
      contrastText: "#fff",
    },
    success: {
      main: "#46a96f",
      contrastText: "#fff",
    },
    custom: "#fff",
  },
  components: {
    ...defaultTheme.components,
    MuiTextField: {
      defaultProps: {
        variant: "outlined" as const,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: "outlined" as const,
      },
    },
  },
};

// const TokenCheck = ({ isAdmin = false }) => {
//   const [searchParams, setSearchParams] = useSearchParams();
//   const [checkToken, setCheckToken] = useState<String | null>();
//   const token = searchParams.get("token");
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { id } = useParams();
//   useEffect(() => {
//     setCheckToken(token);
//   }, []);
//   useEffect(() => {
//     dispatch(setToken(checkToken));
//     sessionStorage.setItem("token", `${checkToken}`);
//     if (checkToken) {
//       if (!isAdmin) {
//         // localStorage.clear();
//         navigate("/Ats/Subscriber/Inbox/" + id, { replace: true });
//       } else {
//         // localStorage.clear();
//         navigate("/Ats/Admin/SubscriberUsageList", { replace: true });
//       }
//     }
//   }, [checkToken]);
//   return (
//     <>
//       <JSONTree data={checkToken} />
//       <h2>No token Found</h2>
//     </>
//   );
// };

// const TokenValid = (props) => {
//   const { data } = useGetIdentity();
//   // const token = sessionStorage.getItem('token');
//   const dispatch = useDispatch();
//   // const { data, isLoading, isError } = useGetOne('getTokkenDetails', { id: token }, { refetchOnWindowFocus: false });
//   // if (!isError && !isLoading) {
//   dispatch(setaspNetUserId(data?.userid));
//   dispatch(setUserRole(data?.userrole));
//   // const { permissions } = usePermissions();
//   // const [switchSubscriberObj] = useStore("switchSubscriberName");
//   // dispatch(setToken(token));
//   // }
//   const navigate = useNavigate();
//   useEffect(() => {
//     if (data?.userrole == "Applicant") {
//       if (data.redirectUrl) navigate(data.redirectUrl, { replace: true });
//     }
//   }, [data]);
//   // if (!isError && !isLoading)

//   if (data?.userrole != "Applicant" && data?.userrole) return <Outlet />;
//   else return <></>;
// };

export const SubscriberSwitchValidation = (props) => {
  // let { isLoading: isLoadingPermissions, permissions } = usePermissions();
  const [switchSubscriberObj, setSwitchSubscriberObj] = useStore("switchSubscriberName");
  const { data } = useGetIdentity();
  const { Component } = props;
  const navigate = useNavigate();
  // const prevPermissionsRef = useRef(permissions);
  useEffect(() => {
    // if (!isLoadingPermissions && prevPermissionsRef !== permissions) {
    if (props.isAdmin && localStorage.getItem("RaStore.switchSubscriber") === "true") {
      navigate(`/ats/Subscriber/Inbox/${switchSubscriberObj?.value}`, { replace: true })
      // localStorage.setItem("RaStore.switchSubscriber", JSON.stringify(true))
    }
    else if (!props.isAdmin && localStorage.getItem("RaStore.switchSubscriber") === "false") {
      setSwitchSubscriberObj({});
      navigate("/ats", { replace: true });
      // localStorage.setItem("RaStore.switchSubscriber", JSON.stringify(false))
    }
    //   }

    // prevPermissionsRef.current = permissions;
  }, [data, props])
  return <Component type={props?.type} />
}

// const Dashbaord = () => {
//   const { data } = useGetIdentity();
//   // const [switchSubscriber, setSwitchSubscriber] = useStore("switchSubscriber");
//   // // useEffect(() => {
//   // //   if (switchSubscriber) {
//   // //     setSwitchSubscriber(false);
//   // //   }
//   // // }, [switchSubscriber]);
//   const navigate = useNavigate();
//   const [switchSubscriberObj, setSwitchSubscriberObj] = useStore("switchSubscriberName");
//   useEffect(() => {
//     if (data?.userrole == "Applicant") {
//       if (data.redirectUrl) navigate(data.redirectUrl, { replace: true });
//     }
//     if (data?.userrole === "Docuverus Super Admin" && localStorage.getItem("RaStore.switchSubscriber") === null) {
//       localStorage.setItem("RaStore.switchSubscriber", JSON.stringify(false))
//     }
//     if ((data?.userrole === "Agent" || data?.userrole === "Manager") && localStorage.getItem("RaStore.switchSubscriber") === null) {
//       localStorage.setItem("RaStore.switchSubscriber", JSON.stringify(true))
//     };
//     if (data?.userrole === "Admin") {
//       setSwitchSubscriberObj({ value: data?.subscriberid, label: data?.fullName, companyType: data?.subscribertype });
//     }
//   }, [data])

//   const userRole = useUserRole();
//   // if (!isError && !isLoading)

//   if (data?.userrole != "Applicant" && data?.userrole) {
//     if (data?.subscriberView) return <Inbox />;
//     return <InboxAdmin />;
//   }
//   // return <></>;
//   // if(userRole == typesofUsers.docuverusSuperAdmin) return <InboxAdmin />
//   return <InboxLandLoard />
// };

const Test = () => <p>Testing....</p>

const AtsAppLandloard = () => {
  // const dataProvider = useDataProvider();
  // const { data, isLoading, error } = useGetList('getapplicationgraph');
  return (
    <Provider store={store}>
      <Admin
        theme={theme}
        // dashboard={Dashbaord}
        layout={MyLayoutLandLoard}
        authProvider={authProviderLandLoard}
        requireAuth
        loginPage={Login}
        basename="/landloard"
        dataProvider={dataProvidersAts}
      >
        <Resource name="landLoardInbox" list={GetListLandLoardList} />
        <CustomRoutes noLayout>
          <Route path="/TermsAndConditionForUserLandLoard" >
            <Route path="/TermsAndConditionForUserLandLoard" element={<TermsConditionLandLoardSetup />} />
          </Route>
          <Route path="/TempForgotPassword" element={<TempPassWordResetUserSetup />} />
        </CustomRoutes>
        <CustomRoutes>
          <Route
            path="/landLoardInbox/View/:id/edit/:applicationId/:appType"
            element={<UserRoleProvider defaultRole=""><ApplicantDetailEdit /></UserRoleProvider>}
          />
          <Route
            path="/landLoardInbox/adverseletter"
            element={<ViewAdverseLetter />}
          />
          <Route path="/docview" element={<DocumentView />} />
         
        </CustomRoutes>
        {/* <CustomRoutes noLayout>
          <Route path="/Landloard" >
            <Route path="/Landloard/TermsAndConditionForUserLandLoard" element={<TermsConditionLandLoardSetup />} />
          </Route>
        </CustomRoutes>
        <CustomRoutes noLayout>
          <Route path="/TempForgotPassword" element={<TempPassWordResetUserSetup />} />
          <Route path="/TermsAndConditionForUserSetup" element={<TermsAndConditionForUserSetup />} />
        </CustomRoutes> */}
        {/* Resource defined */}
        {/* <Route path="/ForgotPassword" element={<ForgotPassword />} /> */}
      </Admin>
    </Provider>
  );
};

// export const PublicRoutes = () => {
//   return (
//     <Provider store={store}>
//       <Admin
//         theme={theme}
//         dashboard={Dashbaord}
//         layout={MyLayout}
//         basename="/atss"
//         dataProvider={dataProvidersAts}
//       >
//         {/* <CustomRoutes noLayout>
//           <Route path="/Landloard" >
//             <Route path="/Landloard/TermsAndConditionForUserLandLoard" element={<TermsConditionLandLoardSetup />} />
//           </Route>
//         </CustomRoutes> */}


//         <CustomRoutes noLayout>
//           <Route path="/ForgotPassword" element={<ForgotPassword />} />

//           {/* <Route path='/profiledetails' element={<ProfileDetails/>}/> */}
//           <Route
//             path="/applicationsettings"
//             element={<ApplicationSettings />}
//           />
//           <Route path="/DataPolicy" element={<DataPrivacy />} />
//           <Route path="/TermsOfUse" element={<TermsOfUse />} />
//           <Route path="/cp/*" element={<ApplicationRegistration />} />
//           <Route path="/resetPassword/:id" element={<ResetPassword />} />
//           <Route path="/ForgotPassword" element={<ForgotPassword />} />
//           <Route path="/ForgotUsername" element={<ForgotUsername />} />
//           <Route path="/DataPolicy" element={<DataPrivacy />} />
//           <Route path="/TermsOfUse" element={<TermsOfUse />} />
//           <Route path="/cp/*" element={<ApplicationRegistration />} />
//           <Route path="/resetPassword/:id" element={<ResetPassword />} />
//           {/* <Route path="/mfa" element={<MfaLogin />} /> */}
//         </CustomRoutes>
//         {/* Resource defined */}
//       </Admin>
//     </Provider>
//   );
// };

export default AtsAppLandloard;
