import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {
  Button,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import DeleteIcon from '@mui/icons-material/Delete';
import save_icon from '../../assets/images/save_icon.svg';
import {
  Loading,
  useCreate,
  useGetList,
  useNotify,
  useRefresh,
} from "react-admin";
import { useNavigate, useParams } from "react-router";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import { CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import InputSelectField from "../common/InputComponents/InputSelectField";
import ToggleButtons from "../common/InputComponents/ToggleButtons";
import InputFileUpload from "../common/InputComponents/InputFileUpload";
import FilePreview2 from "../common/InputComponents/FilePreview2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";

const AdminUpdateResource = () => {
  const toggleButton = [
    {
      value: "Inactive",
      label: "Inactive",
      icon: <ClearIcon />,
      borderColor: "#909090",
      backgroundColor: "#EEE",
      boxShadowColor: "#90909033",
      color: "#606060",
    },
    {
      value: "Active",
      label: "Active",
      icon: <CheckIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];
  const schema = yup.object().shape({
    Name: yup.string().required("required"),
    Active: yup.string().required("required"),
    Type: yup.object().shape({
      value: yup.string().required("required"),
    }).test('is-not-empty', 'Type is required', (value) => {
      if (value && Object.keys(value).length === 0) {
        return false;
      }
      return true;
    }).nullable(),

    Url: yup.string()
      .when('Type', {
        is: (Type) => Type?.value === "Video",
        then: yup.string()
          .required('Required')
          .nullable()
          .url('Enter a valid URL').typeError("Required"),
        otherwise: yup.string().nullable().notRequired(), // Field not required for other types, but no null
      }),
    file: yup.mixed().when('Type', {
      is: (Type) => Type?.value !== "Video",
      then: yup
        .mixed()
        .nullable()
        .required('File is required'),

      otherwise: yup.mixed().nullable().notRequired(),
    }),




  });
  const notify = useNotify();
  const navigate = useNavigate();
  const refresh = useRefresh();
  const methods = useForm({
    defaultValues: {
      Name: "",
      Type: { value: '', label: '' }, // Default to "Video" to show URL initially
      Url: null,
      ThumbNail: null,
      Active: "Active",
      file: null
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const [create, { isLoading, error }] = useCreate();
  const { id } = useParams();
  
  const {
    data: getAdminResources,
    isLoading: loadinggetAdminResources,
  } = useGetList(
    "getAdminResources",
    {
      filter: {

        id: [id],

      },
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    let data = getAdminResources && getAdminResources[0];
    // 
    const getFormData: any = {
      Name: data?.Name,
      file: data?.Url,
      Url: data?.Url,
      Active: data?.Active == 1 ? "Active" : "Inactive",
      ThumbNail: data?.ThumbNail,
      // Type: data?.Type,
      Type: {
        value: data?.Type, // Ensure Type is an object
        label: data?.Type,
      },
    };
    if (!methods.getValues("Name"))
      methods.reset(getFormData);
  }, [getAdminResources]);
  const { watch, setValue, formState } = methods;
  const selectedType = watch("Type");

  // form submit handler
  const onSubmit = (value) => {
    const formData: any = new FormData();
    formData.append("Name", value?.Name);
    formData.append("ID", id);
    formData.append("Url", value?.Url);
    formData.append("Active", value?.Active == "Active" ? 1 : 0);
    // if (value.ThumbNail) {
    formData.append("ThumbNail", value.ThumbNail);
    // }
    formData.append("Type", value?.Type?.value);
    formData.append(
      "file",
      value?.file 
    );
    create(
      `addEditAdminResource`,
      { data: formData },
      {
        onSuccess: (data) => {
          notify(
            `Resource updated successfully.`,

            {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
          refresh();
          // methods?.reset();
          // navigate(-1);
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );

  };

  return (
    <>
      <div className="home_content">
        <div className="row">
          <div className="col-12 d-flex">
            <Breadcrumbs aria-label="breadcrumb" separator=">">
              <Typography sx={{ fontWeight: "bold" }}>
                <Typography
                  color="textPrimary"
                  style={{ fontWeight: "bold", color: "rgba(0, 0, 0, 0.38)" }}
                  onClick={() => navigate(-1)}
                >
                  Manage Resources
                </Typography>
              </Typography>
              <Typography color="textPrimary" style={{ fontWeight: "bold" }}>
                Edit Resource
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        <div className="form">
          <div className="auth-form mr-t30">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={3} columns={12}>
                  <Grid item xs={8}>
                    <InputField2
                      label="Name"
                      // register={methods.register("email")}
                      source={`Name`}
                      placeholder="Enter Resource name here"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <ToggleButtons
                      toggleButton={toggleButton}
                      label="Status"
                      labelClass={"lable-title"}
                      source={`Active`}
                      onClickHandler={(event) => event.stopPropagation()}
                    />
                  </Grid>
                </Grid>
                <br />

                <Grid container spacing={3} columns={12}>
                  <Grid item xs={5}>
                    <InputSelectField
                      label="Type"
                      source="Type"
                      disable={(methods?.watch("file") !== null)}
                      options={[
                        { label: "Video", value: "Video" },
                        { label: "Document", value: "Document" },
                        { label: "Image", value: "Image" },

                      ]}

                      stopAutoFocus={true}

                    />
                  </Grid>
                  <Grid item xs={5}>
                    {selectedType && selectedType["value"] ? (
                      selectedType["value"] === "Video" ? (
                        <>
                          {methods?.watch("Url") !== null && !methods?.formState?.errors?.Url ? (
                            <div className="d-flex">
                              <div style={{ minWidth: '200px' }}>
                                <InputField2
                                  label="URL"
                                  source="Url"
                                  placeholder="Enter URL here"
                                  labelClass="lable-title"
                                  additionalInputClass="cus-control"
                                />
                              </div>
                              <div className="my-4">
                                <Button
                                  color="error"
                                  startIcon={<DeleteIcon />}
                                  onClick={() => {
                                    methods?.setValue("Url", null);
                                    methods?.setValue("file", null);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <InputField2
                              label="URL"
                              source="Url"
                              placeholder="Enter URL here"
                              labelClass="lable-title"
                              additionalInputClass="cus-control"
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {!!methods?.watch("file") && !methods?.formState?.errors?.file ? (
                            <div className="">
                              <FilePreview2
                                img={methods?.watch("file")}
                                isPdf={methods?.watch("Type").value === "Document"}
                                isDeletabel={false}
                                isfileName={false}
                              />
                              <div className="mx-3">
                                <Button
                                  color="error"
                                  startIcon={<DeleteIcon />}
                                  onClick={() => {
                                    methods?.setValue('Url', null);
                                    methods?.setValue("file", null);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <div className="my-4">
                              <InputFileUpload
                                source="file"
                                acceptedFiles={
                                  selectedType["value"] === "Image"
                                    ? "image/*"
                                    : "application/pdf"
                                }
                                buttonText={`Upload ${selectedType["value"]}`}
                              />
                            </div>
                          )}
                        </>
                      )
                    ) : (
                      <></>
                    )}
                  </Grid>


                </Grid>
                <br /><br />

                <div className="row mr-b20">
                  <div className="col-12 mr-b20">
                    <div
                      className="p-3"
                      style={{ background: "#f5fdff", borderRadius: "8px" }}
                    >
                      <div className="row">
                        <div className="col-sm-12 col-md-2 col-lg-2 d-flex align-items-center">
                          <label className="lable-title">ThumbNail</label>
                        </div>
                        <div className="col-sm-12 col-md-8 col-lg-4">
                          <div className="d-flex align-items-center">
                            {methods?.watch(`ThumbNail`) !== null &&
                              !methods?.formState?.errors?.ThumbNail && (
                                <FilePreview2
                                  img={methods?.watch(`ThumbNail`)}
                                  isPdf={false}
                                  isDeletabel={false}
                                  isfileName={false}
                                  source={"ThumbNail"}
                                />
                              )}
                            {(methods?.watch(`ThumbNail`) !== null &&
                              !methods?.formState?.errors?.ThumbNail) ?
                              <div className="mx-3">
                                <Button
                                  color='error'
                                  startIcon={<DeleteIcon />}
                                  onClick={() => {
                                    methods?.setValue(`ThumbNail`, null);
                                  }}
                                >Delete</Button>
                              </div> :
                              <div className="mx-3">
                                <InputFileUpload
                                  source={`ThumbNail`}
                                  acceptedFiles={"image/*"}
                                  buttonText={`Upload`}
                                />
                              </div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <br /><br />
                <Box
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"center"}
                  className="mt-4"
                  minHeight="10vh"
                  position={"fixed"}
                  bottom={70}
                  right={30}
                >
                  <Box>
                    <Button
                      sx={{ mx: 1 }}
                      onClick={() => {
                        methods?.reset();
                        navigate(-1);
                      }}
                      variant="outlined"
                      color="secondary"
                      type="button"
                    >
                      Cancel
                    </Button>
                    <Button
                      startIcon={isLoading ? "" :<img src={save_icon} alt="save icon" />}
                      sx={{ mx: 1 }}
                      variant="contained"
                      type="submit"
                    >
                      {isLoading ? 
                      (<CircularProgress size="20px" sx={{ color: "white" }} /> // Show loader when isLoading is true
                      ) : (
                        <span>Update</span>
                      )}
                  

                    </Button>
                  </Box>
                </Box>
              </form>
            </FormProvider>
          </div>
        </div>

      </div>
    </>
  )
}
export default AdminUpdateResource;