import { configureStore } from '@reduxjs/toolkit';
import applicationSettingSlice from './Slice/applicationSettingSlice';
import fileUploadingSlice from './Slice/fileUploadingSlice';
import usersSlice from './Slice/usersSlice';
import appInvitationTypeSlice from './Slice/appInvitationTypeSlice';
import applicantDetailsSlice from './Slice/applicantDetailsSlice';

const store = configureStore({
    reducer: {
        // add reducers here
        fileUpload: fileUploadingSlice,
        users: usersSlice,
        app: applicationSettingSlice,
        appInvitationType: appInvitationTypeSlice,
        applicantDetails : applicantDetailsSlice,
    },
});

export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch