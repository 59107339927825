import React from 'react'
import ArchiveIcon from '@mui/icons-material/Archive';
import { Box, Button, InputAdornment } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useGetOne, SelectInput, useGetList, useUpdate, useNotify, useRefresh } from 'react-admin';
import { useForm, FormProvider, get } from 'react-hook-form';
import { Button as MuiButton } from '@mui/material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import {
    useParams,
} from "react-router-dom";
import Transfer from "../../assets/images/Transfer.svg";
import InputSelectField from '../common/InputComponents/InputSelectField';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import LoadingSpinner from '../common/loadingSpinner';

export const AgentList = (props) => {
    const { Office,selected } = props;
    const { data, isLoading, error } = useGetList('getagentlist', { filter: { url: Office?.URL, SubscriberID: Office?.SubscriberID } });
    return (<Box component="span">
        {isLoading && <p>Loading...</p>}
            <div className="row">
                <div className="col-12 mr-b30">
                    {!isLoading && data &&
                        <InputSelectField
                        label="Agent"
                        source={`Agent`}
                        options={data?.filter(item=>item.FullName!=selected).map((item) => ({
                            label: item.FullName,
                            value: item.ASPNetUserID,
                        }))}
                        labelAsteriskClass={"color-red"}
                        labelClass={"lable-title"}
                        requiredClass={{
                            fontSize: "70%",
                        }}
                        />
                        }
                </div>
            </div>
    </Box>);
}

export default function TransferApp(props) {
    const notify = useNotify();
    const refresh = useRefresh();
    const { id:paramsId } = useParams();
    const id = paramsId || props.record.SubscriberID;
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { data: getFilterData, isLoading: getFilterLoading, error: getFilterError } = useGetOne(
        'getfilterforinbox', { id: id }
    );
    
    // call get Ip address data
    const {data: getIPAddress} = useGetOne("getipaddress",{id : 1}, {
        enabled: open,
      });
    const ipAddress = getIPAddress?.IPv4;    

    // form validation schema 
    const validationSchema = Yup.lazy((value) =>
    Yup.object().shape({
        Agent: Yup.object().nullable().required("Agent is required!"),
        })
    );

    // form initial states
    const form = useForm({
        defaultValues: {
            'Office': props.record.Property,
            Agent : null,
        },
        resolver: yupResolver(validationSchema),
    });

    // Destructuring form
    const { register, reset } = form;

    const [update, { data, isLoading, error }] = useUpdate();
    // form submit handler
    const onSubmit = (value) => {
        update('tranferapp', { id: props.record.id, data: { AssignedAgentID: value.Agent.value , IP :ipAddress } }, {
            onSuccess: (d) => {
                notify(`Application Number ${d.ApplicationNumber} has been Transfer`, { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
                refresh();
                handleClose();
                reset();
            }, onError: (e: any) => notify(`Operation fail:${e.message}`, { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
        })
    }
    return (
        <>  
            {props.buttonTransfer ? (
                <button
                  type="button"
                  className=""
                  data-bs-toggle="modal"
                  data-bs-target="#transfer"
                  onClick={handleOpen}
                >
                  <img src={Transfer} alt="" />
                  <span>Transfer</span>
                </button>
            ) : (
                <MuiButton onClick={handleOpen} startIcon={<PersonAddAltIcon />} variant='text' sx={{textTransform:"capitalize"}}>Transfer</MuiButton>
            )}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Transfer Application
                </DialogTitle>
                <DialogContent style={{minHeight:300}}>
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)}>
                            {form.watch()?.Office && <AgentList selected={props.record.Agent} Office={getFilterData?.Offices?.filter(item => item.OfficeName == form.watch().Office)[0]} />}
                            <Box display={'flex'} justifyContent={'end'}>
                                <Button sx={{ mx: 1 }} onClick={() => {handleClose(); reset();}} variant='outlined' color='secondary' type='button' >
                                    Cancel
                                </Button>
                                <Button startIcon={<ArchiveIcon />} sx={{ mx: 1 }} variant='contained' type='submit'>
                                    Transfer
                                </Button>
                            </Box>
                        </form>
                    </FormProvider>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
            {isLoading ? <LoadingSpinner /> : ""}
        </>
    )
}
