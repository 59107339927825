import React from 'react'
import ArchiveIcon from '@mui/icons-material/Archive';
import { Box, Button, InputAdornment } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextInput, required, useUpdate, useNotify, useRefresh } from 'react-admin';
import { useForm, FormProvider, get } from 'react-hook-form';
import { Button as MuiButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useParams } from "react-router-dom";
import archieve from "../../assets/images/archieve.svg";
import InputField2 from "../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import LoadingSpinner from '../common/loadingSpinner';
import { useNavigate } from 'react-router-dom'

export default function Archive(props) {
    const { id } = useParams();
    
    const subscriberId=id || props.record.SubscriberID
    const notify = useNotify();
    const refresh = useRefresh();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate()

    // form validation schema 
    const validationSchema = Yup.lazy((value) =>
    Yup.object().shape({
        Reason: Yup.string().trim("Reason is required!").required("Reason is required!").max(5000, "maximum 5000 characters is required"),
        })
    );

    // form initial states
    const form = useForm({
        defaultValues: {
            Reason: '',
            SubscriberID: subscriberId
        },
        resolver: yupResolver(validationSchema),
    });

    // Destructuring form
    const { register, reset } = form;

    const [update, { data, isLoading, error }] = useUpdate();
    // form submit handler
    const onSubmit = (value) => {
        update('archive', { id: props.record.id, data: value }, {
            onSuccess: (d) => {
                notify(`Application Number ${d.ApplicationNumber} has been Archived`, { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
                refresh();
                handleClose();
                reset();
                if(props.buttonArchieve){
                    navigate(-1)
                }
            }, onError: (e: any) => notify(`Operation fail:${e.message}`, { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
        })
    }
    return (
        <>
        {props.buttonArchieve ? (
            <button
              type="button"
              className=""
              data-bs-toggle="modal"
              data-bs-target="#archieve"
              onClick={handleOpen}
            >
              <img src={archieve} alt="" />
              <span>Archive</span>
            </button>
        ) : (
            <MuiButton onClick={handleOpen} startIcon={<ArchiveIcon />} variant='text' sx={{textTransform:"capitalize"}}>Archive</MuiButton>
        )}
            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle color='error' id="alert-dialog-title">
                    <InfoIcon /> Are you sure?
                </DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to archive the applications? <br/>
                        You can find this later from Settings &gt; Archive.</p>
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-12 mr-b10 mt-3">
                                <InputField2
                                    label="Reason"
                                    source={`Reason`}
                                    placeholder="Enter Reason for Archiving Application"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                    />
                                </div>
                            </div>
                            <Box display={'flex'} justifyContent={'end'}>
                                <Button sx={{ mx: 1 }} onClick={() => {handleClose(); reset();}} variant='outlined' color='secondary' type='button' >
                                    Cancel
                                </Button>
                                <Button startIcon={<ArchiveIcon />} sx={{ mx: 1 }} variant='contained' type='submit'>
                                Archive
                                </Button>

                            </Box>
                        </form>
                    </FormProvider>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
            {isLoading ? <LoadingSpinner /> : ""}
        </>
    )
}
