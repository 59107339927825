import React, { Suspense, useContext, useEffect, useState } from 'react';
import '../../App.css';
import { Admin, BooleanField, Button, ChipField, Confirm, CustomRoutes, Datagrid, DateField, defaultTheme, DeleteButton, FunctionField, List, ListContextProvider, ListGuesser, NumberField, Resource, SearchInput, SimpleForm, TextField, TextInput, useDataProvider, useDelete, useGetList, useGetOne, useList, useListContext, useNotify, useRecordContext, useRefresh } from 'react-admin';
import { Box, ToggleButton, ToggleButtonGroup, Button as MuiButton } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import { TableHead, TableRow, TableCell, Checkbox } from '@mui/material';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { DatagridBody, RecordContextProvider } from 'react-admin';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import FolderIcon from '@mui/icons-material/Folder';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { RootState } from "../../Store/store";
import {
    useParams,
    useNavigate,
} from "react-router-dom";
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { JSONTree } from 'react-json-tree';
import { ATS_API_URL, ATS_DOCS_URL } from '../../config';
import { BiSolidFilePdf } from 'react-icons/bi';
import { useSelector } from 'react-redux';


const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody />} />;

const MyDatagridRow = (props) => {
    const { record, id, children } = props;
    return (
        <RecordContextProvider value={record}>
            <TableRow className={props.className}>
                {React.Children.map(children, field => {
                    if (field)
                        if (!field.props?.hidden)
                            return (
                                <TableCell padding='none' data-label={field?.props?.label || field?.props?.source} sx={{ p: 1 }} key={`${id}-${field?.props?.source}`}>
                                    {field}
                                </TableCell>
                            )
                })}
            </TableRow>
        </RecordContextProvider>
    )
};


const DatagridTableHeaderCell = ({ child, data }) => {
    const isNew =
        (child?.props?.source === "ID" && data[0]?.NewTagID) ||
        (child?.props?.source === "Income" && data[0]?.NewTagIncome) ||
        (child?.props?.source === "Banking" && data[0]?.NewTagBanking) ||
        (child?.props?.source === "Other" && data[0]?.NewTagOther);

    return <div className='badge-cell'> {child.props.label || child.props.source}
        {isNew ? <em>New</em> : <></>}
    </div>
}


const DatagridHeader = (props) => {
    const { children, data } = props;
    return (
        <TableHead sx={{ backgroundColor: '#FFFFFF', border: '0.5px solid #E0E0E0' }}>
            <TableRow>
                {React.Children.map(children, child => {
                    if (child)
                        if (!child.props?.hidden)
                            return (
                                <TableCell padding="none" sx={{ p: 1 }} key={child?.props?.source}>
                                    <DatagridTableHeaderCell child={child} data={data} />
                                </TableCell>
                            )
                })}

            </TableRow>
        </TableHead>
    )
};

const DataGridCustom = ({ children }) => {
    return (
        <MyDatagrid header={<DatagridHeader />}
            sx={{

                '& .RaDatagrid-rowEven': { backgroundColor: "#F9F9F9" },
                '& .RaDatagrid-expandIcon': { color: '#fff' },
                '& .RaDatagrid-expandIconCell': { backgroundColor: "primary.main" },
                '& .RaDatagrid-headerCell': {
                    fontWeight: 'bold',
                    fontSize: 12
                },
                '& .RaDatagrid-table': {
                    fontSize: 12
                },
                '& .MuiToggleButton-root.Mui-selected': {
                    fontWeight: 700
                },
                // '& th.MuiTableCell-root.MuiTableCell-head': {
                //     textAlign: "center"
                // },
                '& th:nth-child(1).MuiTableCell-root.MuiTableCell-head,th:nth-child(2).MuiTableCell-root.MuiTableCell-head': {
                    textAlign: "left"
                },
                '& .MuiTableRow-root.RaDatagrid-row': {
                    verticalAlign: "top"
                }
            }}>
            {children}
        </MyDatagrid>
    );
};

export default DataGridCustom;