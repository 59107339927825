import React, { useEffect, useState } from 'react';
import { SearchInput, useGetIdentity, useGetOne, useListContext,
    usePermissions,
} from 'react-admin';
import { ToggleButton, ToggleButtonGroup, } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import {
    useParams,
} from "react-router-dom";
import { DatagridTable } from './GetsubscriberinboxList';
import ListBaseSubscriber from './ListBaseSubscriber';
import ActionSubscriberInbox from './ActionSubscriberInbox';
import MainFiltersArchieve from './MainFiltersArchieve';
import { checkModuleAccess, isSuperAdmin } from '../../../Functions/permissions';

export const ToogleFilterInboxType = (props) => {

    const { setFilters, filterValues, displayedFilters } = useListContext();
    useEffect(() => {
        if (!filterValues.app_invitation_type) {
            setFilters({ 'app_invitation_type': "1" }, ['app_invitation_type'])
        }
    }, [])

    return (
        <>
            {/* <JSONTree data={filterValues}/> */}
            <ToggleButtonGroup
                sx={{ paddingBottom: 0.5, '& .Mui-selected': { fontWeight: 700 } }}
                size="small"
                value={filterValues.app_invitation_type}
                color="primary"
                exclusive
                onChange={(event: React.MouseEvent<HTMLElement>,
                    newAlignment: string) => {
                    if (newAlignment) {
                        setFilters({ 'app_invitation_type': newAlignment }, ['app_invitation_type'])
                    }
                }}
                aria-label="Inbox"
            >
                <ToggleButton value={"1"} aria-label="Application">
                    Application
                </ToggleButton>
                <ToggleButton value={"2"} aria-label="Docupload">
                    Docupload
                </ToggleButton>
                <ToggleButton value={"3"} aria-label="Docupload">
                    Guest Card
                </ToggleButton>
            </ToggleButtonGroup>
        </>)
};

export const FilterListInbox = (props) => {
    return <>
        <IconButton sx={{
            border: '1px solid',
            borderRadius: ' 5px',
            padding: '6px',
            marginLeft: '-10px',
            marginBottom: '4.4px'
        }} size='medium' aria-label="delete">
            <FilterListIcon />
        </IconButton>
    </>
}

export const filters = [
    <ToogleFilterInboxType source="app_invitation_type" alwaysOn />,
    <SearchInput variant="outlined" sx={{ backgroundColor: '#fff' }} placeholder="Search" InputLabelProps={{
        shrink: true,
    }} source="q" alwaysOn />,
];

export default function BasicPopover() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton color='warning' style={{ color: '#F9BB04' }} size='small' aria-label="info" aria-describedby={id} onClick={handleClick}>
                <InfoIcon style={{ fontSize: '14' }} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>This is the Rent to Income Ratio.</Typography>
            </Popover>
        </div>
    );
}

export const ArchiveCancelledList = () => {
    const { data: identity } = useGetIdentity();
    const { id } = useParams();
    const { permissions } = usePermissions();
    const hasATS = isSuperAdmin(checkModuleAccess(permissions, "Settings", "Archived"), identity?.userrole);

    const hasDocUpload = isSuperAdmin(checkModuleAccess(permissions, "Settings", "Archive Docupload"), identity?.userrole);

    const hasGuestCard = isSuperAdmin(checkModuleAccess(permissions, "Settings", "Archive Guestcard"), identity?.userrole);

    // Dynamically set the default filter value based on the available options
    const { data: applicationDetail, isLoading: isLoadingData } = useGetOne(
        "getnewapplicationdetail",
        {
          id: id || identity?.subscriberid,
        },
        { enabled: id || identity?.subscriberid ? true : false, refetchOnWindowFocus:false }
      );
      const [defaultFilterValue,setDefaultFilterValue]=useState({
        app_invitation_type: 0,
        subscriberid: id || identity?.subscriberid
      })
      useEffect(()=>{
        if (applicationDetail?.ApplicationType?.ATS && hasATS) {
          // defaultAppType = 1;
          setDefaultFilterValue(v=>({...v,app_invitation_type:1}));
        } else if (applicationDetail?.ApplicationType?.DocUpload && hasDocUpload) {
          setDefaultFilterValue(v=>({...v,app_invitation_type:2}));
        } else if (applicationDetail?.ApplicationType?.GuestCard && hasGuestCard) {
          setDefaultFilterValue(v=>({...v,app_invitation_type:3}));
        }
      },[applicationDetail,hasATS,hasDocUpload,hasGuestCard])
     
      if(isLoadingData && defaultFilterValue.app_invitation_type==0){
        return <></>
      }
      
    return (
        <ListBaseSubscriber sort={{ field: 'id', order: 'DESC' }} filters={<MainFiltersArchieve applicationDetail={applicationDetail} />} actions={<ActionSubscriberInbox />} enableDashboardGraph={false} filterDefaultValues={defaultFilterValue} exporter={false} title={'Inbox'}>
            <DatagridTable />
        </ListBaseSubscriber>
    )
};
