function copyTextToClipboard(text) {
    if (navigator.clipboard) {
        // Use the Async Clipboard API if available
       return  navigator.clipboard.writeText(text)
            .then(() => {
                
                return true;
            })
            .catch((err) => {
                return false;
                console.error("Unable to copy text to clipboard: ", err);
            });
    } else {
        // Create a new text area element to hold the text to be copied
        const textArea = document.createElement("textarea");

        // Set the text content to the provided text
        textArea.value = text;

        // Append the text area to the DOM
        document.body.appendChild(textArea);

        // Select the text in the text area
        textArea.select();

        try {
            // Attempt to copy the selected text to the clipboard
            document.execCommand("copy");

            // Clean up: remove the temporary text area
            document.body.removeChild(textArea);

            // You can provide some feedback to the user here if needed
            return true;
        } catch (err) {
            console.error("Unable to copy text to clipboard: ", err);
            return false
        }
    }
}

export default copyTextToClipboard;

// Example usage:
//   const textToCopy = "This is the text to copy to the clipboard.";
//   copyTextToClipboard(textToCopy);