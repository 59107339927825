import React from "react";
import { useFieldArray, useFormContext, useFormState } from "react-hook-form";
// import InputCheckBoxField from "../../../../registration/Inputs/InputCheckBoxField";
// import InputDateField from "../../../../registration/Inputs/InputDateField";
// import InputField from "../../../../registration/Inputs/InputField";
import { TiDelete } from "react-icons/ti";
import DeleteContainedBtn from "../../../../common/DeleteContainedBtn";
import InputDateField from "../../../../common/InputComponents/InputDateFieldOld";
import InputField2 from "../../../../common/InputComponents/InputField2";
import { CardHeader } from "@mui/material";

const CoOccupant = (props) => {
  const { register } = props;
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "co_occupants.fields", // unique name for your Field Array
    keyName: "co_occupants_id", // default to "id", you can change the key name
  });
  const { errors } = useFormState({
    control,
  });

  return (
    <>
      {fields?.map((field, index) => (
        <div key={field.co_occupants_id}>
          <div className="blue-box pd15 mr-t20 a-blue-box">
            <div className="row">
              <div className="col-sm-12 mb-2">
                {/* <span className="s-no-index float-left">{index + 1}</span>{" "} */}
                {/* <button
                  type="button"
                  onClick={() => {
                    remove(index);
                    props?.checkSourceAvailableHandler("cooccupant");
                  }}
                  title="Delete this Employer"
                  className="float-right btn btn-sm btn-danger"
                >
                  <TiDelete size={25} /> Delete
                </button> */}
                <CardHeader
                  sx={{ padding: 0 }}
                  title={<b className="s-no-index-number">{index + 1}</b>}
                  action={
                    <DeleteContainedBtn
                      onClick={() => {
                        remove(index);
                        props?.checkSourceAvailableHandler("cooccupant");
                        props?.handleAPIDebounced();
                      }}
                      title="Delete this Employer"
                    />
                  }
                />
              </div>
            </div>
            <div className=" row">
              <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                {/* <InputField
                  label={`First Name`}
                  register={register(`co_occupants.fields.${index}.first_name`)}
                /> */}
                <InputField2
                  label="First Name"
                  source={`co_occupants.fields.${index}.first_name`}
                  placeholder="First Name"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  handleOnChange={() => {
                    props?.handleAPIDebounced();
                  }}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                {/* <InputField
                  label={`Last Name`}
                  register={register(`co_occupants.fields.${index}.last_name`)}
                /> */}
                <InputField2
                  label="Last Name"
                  source={`co_occupants.fields.${index}.last_name`}
                  placeholder="Last Name"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  handleOnChange={() => {
                    props?.handleAPIDebounced();
                  }}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                {/* <InputDateField
                  maxDate={new Date()}
                  type="date"
                  label="Date Of Birth"
                  placeholder="Enter Date"
                  register={register(`co_occupants.fields.${index}.dob`)}
                /> */}
                <InputDateField
                  source={`co_occupants.fields.${index}.dob`}
                  label="Date Of Birth"
                  labelClass={"lable-title"}
                  onChangeCallback={() => {
                    props?.handleAPIDebounced();
                  }}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                {/* <InputField
                  label={`Relationship`}
                  register={register(
                    `co_occupants.fields.${index}.relationship`
                  )}
                /> */}
                <InputField2
                  label="Relationship"
                  source={`co_occupants.fields.${index}.relationship`}
                  placeholder="Relationship"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  handleOnChange={() => {
                    props?.handleAPIDebounced();
                  }}
                />
              </div>

              <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                {/* <InputField
                  type="tel"
                  maxLength={4}
                  label={`Last 4 digits of SSN`}
                  register={register(`co_occupants.fields.${index}.ssn`)}
                  notRequired={true}
                /> */}
                <InputField2
                  label="Last 4 digits of SSN"
                  source={`co_occupants.fields.${index}.ssn`}
                  placeholder="Last 4 digits of SSN"
                  labelClass={"lable-title"}
                  additionalInputClass={"cus-control"}
                  handleOnChange={() => {
                    props?.handleAPIDebounced();
                  }}
                  notRequired={true}
                />
              </div>

              {errors && errors[index] && errors[index].number}
            </div>
          </div>
        </div>
      ))}
      <div className="blue-box pd15">
        <div className="add-text-btn flex1 text-center ps-2 pt-3">
          <button
            type="button"
            onClick={(event) =>
              append({
                id: "0",
                first_name: "",
                last_name: "",
                dob: "",
                ssn: "",
                relationship: "",
              })
            }
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                fill="#2DC3E8"
              />
            </svg>
            &nbsp; Add another Co-Occupant under age 18
          </button>
        </div>
      </div>
    </>
  );
};

export default CoOccupant;
