import Box from "@mui/material/Box";
import { useGetIdentity, useLogout } from "react-admin";
import * as html2pdf from 'html2pdf.js';
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/store";
import { JsonToTable } from "react-json-to-table";
import { JSONTree } from "react-json-tree";
import { ATS_API_URL } from "../../config";
import { useNavigate } from 'react-router-dom'
import GoogleTranslate from "../common/GoogleTranslate";
const ApplicatonHeader = ({ role, subscriberId, subscriberData, applicantName = "" }) => {

  const [isPdfLoading, setLoading] = useState(false)
  // const { identity, isLoading: identityLoading } = useGetIdentity();
  const isGuestCard = useSelector((state:RootState)=>state.app.isGuestCard);
  const userRole = useSelector((state: RootState) => state.users.role);
  // function printPage() {
    
  //   setLoading(true);

  //   const eleArray = document.querySelectorAll<HTMLElement>('.print-hide');

  //   eleArray.forEach(e => e.style.display = 'none');

  //   let body = document.body
  //   let html = document.documentElement
  //   const content = document.body;
  //   let height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)
  //   let heightCM = height / 35.35
  //   let opt = {
  //     margin:       0,
  //     filename:     'Docupload-PDF-View.pdf',
  //     enableLinks:  false,
  //     image:        { type: 'jpeg', quality: 0.98 },
  //     html2canvas:  { scale: 2, dpi: 400, useCORS: true, letterRendering: true},
  //     jsPDF:        { orientation: 'portrait', unit: 'cm', format: [heightCM, 30] },
  //     pagebreak:    { mode: ['avoid-all', 'css', 'legacy'] },
  //   };
    
  //   html2pdf().set(opt).from(content).output('dataurlnewwindow',"View PDF Application").then( res => {
  //     eleArray.forEach(e => e.style.display = 'block');
  //     setLoading(false);
  //     // window.location.reload();
  //   });
  // }
  const authJson=localStorage.getItem('auth')||'';
  const navigate = useNavigate();
  const { data } = useGetIdentity();
  // const logout = useLogout();
  return (
    <div>
      <h6 className="px-2"> {subscriberId && <img src={`https://uatapiml.dvapply.com/users/logo/${subscriberId}`} alt="" style={{ width: 35, height: 30 }} />} {subscriberData?.CompanyName}</h6>
      <div className='d-flex flex-sm-column flex-md-row align-item-center justify-content-between'>
       
        <div className="p-2 order-sm-1 order-md-1"><h6>Proof Documents for <strong>{applicantName}</strong></h6></div> 
        {/* <div className="p-2 order-sm-3 order-md-3"> <GoogleTranslate/></div> */}
        {data?.redirectUrl && <button className="p-2 order-sm-2 order-md-2 btn btn-sm btn-secondary print-hide" onClick={()=>{ localStorage.removeItem('auth'); navigate('/ats')}}>Logout</button> }      
        {/* {userRole !== "applicant" && !isGuestCard &&<Box className="order-sm-1 order-md-3" component={"span"}>
          <button type="button" className="btn btn-sm btn-outline-danger print-hide" onClick={()=>window.open(`${ATS_API_URL}/applicant/document/printpdf?url=${window.location.href+`?auth=`+encodeURIComponent(authJson)}`, "_blank")}> 
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-file-pdf-fill" viewBox="0 0 16 16">
              <path d="M5.523 10.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.035 21.035 0 0 0 .5-1.05 11.96 11.96 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.888 3.888 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 4.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
              <path fillRule="evenodd" d="M4 0h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm.165 11.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.64 11.64 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.707 19.707 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z" />
            </svg> Save/Print Result
          </button>
        </Box>} */}
      </div>
    </div>
  );
};

export default ApplicatonHeader;
