import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import {
    Edit,
    TextInput,
    useGetIdentity,
    useGetList,
    useCreate,
    useGetOne,
    Resource,
    useNotify,
    NumberField,
    FunctionField,
    Datagrid,
    useListContext,
    useRecordContext,
  } from "react-admin";

const useCustomActions=()=>{
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteddocs, setdeleteddocs] = useState(false);
    const [open, setOpen] = useState(false);
    const [openRentalDecision, setOpenRentalDecision] = useState(false);
    const [openPrelimReport, setOpenPrelimReport] = useState(false);
    const [openLease, setOpenLease] = useState(false);
    const [value, setValue] = useState(false);
    // const encodedData: any = searchParams.get("data");
    const { id, appType } = useParams();
    const record = useRecordContext();

    const { register, handleSubmit, reset, watch } = useForm({
      defaultValues: {
        ApplicationNumber: record?.id,
        Notice: "0",
        IsEmail: "0",
      },
    });
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
  
    const handleCloseMenu = () => {
      setAnchorEl(null);
    };
    const handleopendeleteddocs = () => {
        setdeleteddocs(true);
      };
      const handleclosedeleteddocs = () => {
        setdeleteddocs(false);
      };
      const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    
      const handleRentalDecision = () => {
        setOpenRentalDecision(true);
      };
    
      const handleCloseRentalDecision = () => {
        setOpenRentalDecision(false);
      };
    
      const handlePrelimReport = () => {
        setOpenPrelimReport(true);
      };
      const handleClosePrelimReport = () => {
        setOpenPrelimReport(false);
      };
    
      const handleOpenLease = () => {
        setOpenLease(true);
      };
      const handleCloseLease = () => {
        setOpenLease(false);
      };
      
 
  return{

    //export
    handleCloseLease,
    handleOpenLease,
    handlePrelimReport,
    handleClosePrelimReport,
    handleRentalDecision,
    handleCloseRentalDecision,
    handleopendeleteddocs,
    handleclosedeleteddocs,
    handleCloseMenu,
    handleClose,
    handleOpenMenu,
    handleOpen,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    anchorEl,
    deleteddocs,
    open,
    openRentalDecision,
    openPrelimReport,
    openLease,
    value,id,appType
  
    

  }
}
 
export default useCustomActions;