import React from 'react'
import { ListGuesser, Resource } from 'react-admin'
import { ArchiveCancelledList } from '../../componets/Ats/SubscriberInbox/ArchiveCancelledList'
// import { ArchiveCancelledList } from '../../componets/Ats/ArchiveCancelledList'

export default function Getarchivedcancelled() {
    return (
        <Resource name='getarchivedcancelled' list={<ArchiveCancelledList />} />
      )
}
