import React, { useEffect, useState } from "react";
import save_icon from "../../../assets/images/save_icon.svg";
import {
  Loading,
  useCreate,
  useGetList,
  useNotify,
  useRefresh,
  useStore,
  useUpdate,
  useListContext,
  useGetOne,
  useGetIdentity,
} from "react-admin";
import { Datagrid, FunctionField, NumberField } from "react-admin";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import EditIcon from "@mui/icons-material/Edit";

import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";

import ToggleButtons from "../../common/InputComponents/ToggleButtons";

import { JSONTree } from "react-json-tree";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { Box, Button } from "@mui/material";
const ApplicationSettingsApplicant = ({ tabClick, currentProfileTab }) => {
  const { id } = useParams();
  const threetoggleButton = [
    {
      value: "Hide",
      label: "Hide",
      icon: <VisibilityOffOutlinedIcon />,
      borderColor: "#FF0000",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#90909033",
      color: "#FF0000",
    },
    {
      value: "Optional",
      label: "Optional",
      icon: <RemoveRedEyeOutlinedIcon />,
      borderColor: "#8A3324",
      backgroundColor: "#FFF5EE",
      boxShadowColor: "#46a96f1a",
      color: "#8A3324",
    },
    {
      value: "Required",
      label: "Required",
      icon: <DoneAllIcon />,
      borderColor: "#46A96F",
      backgroundColor: "#EBFFF3",
      boxShadowColor: "#46a96f1a",
      color: "#46A96F",
    },
  ];

  const methods = useForm();
  const [docuverusAccordion, setDocuverusAccordion] = React.useState(false);
  const navigate = useNavigate();
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const { data: getquestionssectionwise, isLoading: loadingdocument } = useGetList(
    "getquestionssectionwise",
    {
      filter: {
        SubscriberID: switchSubscriberObj?.value,
        ApplicationProfileID: id,
        RoleName: tabClick,
        ProfileTab: `${currentProfileTab}`,
      },
    },
    {
      enabled: tabClick != "document",
    }
  );



  useEffect(() => {
    const formData = getquestionssectionwise?.reduce((acc, item) => {
      acc[item.ProfileTabFieldID] = item?.FinalValue; // Set the initial value from FinalValue
      return acc;
    }, {});

    methods.reset(formData);
  }, [getquestionssectionwise, methods]);


  const [create, { isLoading, error }] = useCreate();
  const notify = useNotify();

  const refresh = useRefresh();

  // form submit handler
  const onSubmit = (value, e) => {
  
    e.preventDefault();
    const addData = getquestionssectionwise?.map((item) => {
      if (value[item.ProfileTabFieldID]) {
        // Return a new object with the updated FinalValue
        return {
          ...item, // Spread the current item properties
          FinalValue: value[item.ProfileTabFieldID], // Update the FinalValue
        };
      }
      return item; // Return the original item if no update is needed
    });



    create(
      `updateprofilesettigs`,
      { data: { Questions: addData } },
      {
        onSuccess: (data) => {
          notify(
            `Profile settings updated successfully.`,

            {
              type: "success",
              anchorOrigin: { vertical: "top", horizontal: "right" },
            }
          );
          refresh();
          // methods?.reset();
          // navigate(-1);
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
  return (
    <>
    
      <div className="tab-pane fade show" id="tab-applicant">
        <div className="auth-form mr-t10">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <div className="info-toggle">
                <div
                  className="toggle-heads"
                  onClick={(event) => {
                    event.preventDefault();
                    setDocuverusAccordion((v) => !v);
                  }}
                >



                  <div
                    className="card card-body w-100"
                    style={{ paddingTop: 0 }}
                  >
                    <div className="row">
                      <div className="container">

                        <div className="row" key={`row`}>
                          {/* Create a row and fill it with the next 4 items */}
                          {getquestionssectionwise &&
                            getquestionssectionwise.length > 0 &&
                            getquestionssectionwise
                              .filter((item) => item?.CaptionName) // Ensure both ID and DisplayName exist
                              .map((doc, i) => {

                                if (i == 0)
                                  return <div key={doc?.id} className="col-12 my-2">
                                    <div className="card border">
                                      <div className="card-body">
                                        <p style={{ fontSize: "18px", fontWeight: "bold" }}>Standards Fields</p>
                                      </div>
                                    </div>
                                  </div>
                                if (doc?.ProfileTabFieldID == "0")
                                  return <div key={doc?.id} className="col-12">
                                    <div className="card border">
                                      <div className="card-body">
                                        <p style={{ fontSize: "18px", fontWeight: "bold" }}>{doc?.CaptionName}</p>
                                      </div>
                                    </div>
                                  </div>

                                return (
                                  <>
                                    <div className="col-md-4 col-sm-6 mr-b10 mr-t20" key={doc?.id}>
                                      <ToggleButtons
                                        disabled={doc.IsRequired}
                                        toggleButton={threetoggleButton}
                                        label={doc?.CaptionName} // Use the 'DisplayName' property
                                        labelClass={"lable-title"}
                                        source={doc?.ProfileTabFieldID}
                                        onClickHandler={(event) => event.stopPropagation()}
                                      />
                                    </div>

                                  </>
                                )
                              })}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Box
                sx={{
                  height: "60px",
                  background: "#F5FDFF",
                  boxShadow: "0px -1px 12px 0px rgba(0, 0, 0, 0.12)",
                }}
                display={"flex"}
                justifyContent={"end"}
                alignItems={"center"}
                className="sticky-bottom mt-4"
              >
                <Box>
                  <Button
                    sx={{ mx: 1 }}
                    onClick={() => {
                      methods?.reset();
                      navigate(-1);
                    }}
                    variant="outlined"
                    color="secondary"
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button
                    startIcon={<img src={save_icon} alt="save icon" />}
                    sx={{ mx: 1 }}
                    variant="contained"
                    type="submit"
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
};
export default ApplicationSettingsApplicant;
