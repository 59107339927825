import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import InputField2 from "../common/InputComponents/InputField2";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetOne, useCreate, useRefresh, useNotify, Loading, Title } from "react-admin";
import TextInputPhone from "../DocumentAnalysis/components/inputComponents/TextInputPhone";
import InputSelectField from "../common/InputComponents/InputSelectField";
import ErrorIcon from "@mui/icons-material/Report";

const ApplicationRegistration = () => {
  const [url, setUrl] = useState(null);
  const [splitData, setSplitData] = useState<string[]>([]);
  // get registration data
  const { data, error: registrationError, isLoading: registrationLoading} = useGetOne(
    "registrationpage",
    { id: url },
    // { refetchOnWindowFocus: false }
    { enabled: url ? true : false, refetchOnWindowFocus: false },
  );
  // form validation schema
  const schema = Yup.lazy((value) =>
    Yup.object().shape({
      first_name: Yup.string()
        .typeError("First name is required!")
        // .max(75, "maximum 75 characters is required")
        // .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid name")
        // .matches(/^$|^\S+.*/g, "Enter a valid value")
        .required("First name is required!")
        .min(2, "Minimum 2 characters is required"),

      last_name: Yup.string()
        .typeError("Last name is required!")
        // .max(75, "maximum 75 characters is required")
        // .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid name")
        // .matches(/^$|^\S+.*/g, "Enter a valid value")
        .required("Last name is required!")
        .min(2, "Minimum 2 characters is required"),
      mobile: Yup.string()
        .required("Mobile number is required!")
        .typeError("Mobile number is required!")
        .test("rent", "Min 10 digits are required", (value) => {
          let temp: any = value?.toString().replace(/\D/g, "");
          return temp?.length > 10 ? true : false;
        }),
      email: Yup.string()
        .email("Invalid email format")
        // .max(75, "maximum 75 characters is required")
        .required("Email is required!")
        .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
      confirm_email: Yup.string()
        .oneOf([Yup.ref("email"), ""], "Email must match!")
        // .max(75, "maximum 75 characters is required")
        .required("Confirm Email is required!")
        .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
      office:
        splitData &&
        splitData?.length > 0 &&
        splitData?.includes("Agent") &&
        Yup.object()
          .shape({
            value: Yup.string().required("Required!").nullable(),
            label: Yup.string().nullable(),
          })

          .nullable()
          // .max(75, "maximum 75 characters is required")
          .required("Required!"),
      agent:
        splitData &&
        splitData?.length > 0 &&
        splitData?.includes("Office") &&
        Yup.object()
          .shape({
            value: Yup.string().required("Required!").nullable(),
            label: Yup.string().nullable(),
          })
          .nullable()
          // .max(75, "maximum 75 characters is required")
          .required("Required!"),
    })
  );

  // Initial form values
  const defaultFormValues = {
    first_name: "",
    last_name: "",
    mobile_number: "",
    email: "",
    confirm_email: "",
    office: null,
    agent: null,
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const [isLoading1, setIsLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const [create, { isLoading, error }] = useCreate();

  // form onsubmit Handler
  const onSubmit = (value) => {
    console.log("value?.office",value?.office)
    console.log("data?.data?.records?.Office",data?.data?.records?.Office)
    const sendData = {
      invite_type: "Email",
      application_type:
        splitData && splitData?.length > 0 && splitData?.includes("Docupload")
          ? "Doc Upload"
          : "Guest Card",
      subscriberid: data?.data?.records?.subscriber_id,
      is_testapplication: 0,
      office:splitData && splitData?.length > 0 && splitData?.includes("Agent")
        ? value?.office
        : null,
      agent:
        data?.data?.records?.selected_agent?.FullName &&
        data?.data?.records?.selected_agent?.ASPNetUserID &&
        data?.data?.records?.selected_agent?.UserID
          ? {
              label: data?.data?.records?.selected_agent?.FullName,
              value: data?.data?.records?.selected_agent?.ASPNetUserID,
              id: data?.data?.records?.selected_agent?.UserID,
            }
          : splitData && splitData?.length > 0 && splitData?.includes("Office")
          ? value?.agent
          : null,
      floor: data?.data?.records?.select_floor
        ? {
            label: data?.data?.records?.select_floor?.FloorCode,
            value: data?.data?.records?.select_floor?.ID,
          }
        : null,
      unit: data?.data?.records?.selected_unit
        ? {
            label: data?.data?.records?.selected_unit?.UnitName,
            value: data?.data?.records?.selected_unit?.ID,
          }
        : null,
      property: data?.data?.records?.property
        ? {
            label: data?.data?.records?.property?.PropertyName,
            value: data?.data?.records?.property?.ID,
          }
        : null,
      applicant: [
        {
          first_name: value?.first_name,
          last_name: value?.last_name,
          email: value?.email,
          confirm_email: value?.confirm_email,
          username: null,
          mobile_number: value?.mobile,
          sentinvite_email:
            splitData &&
            splitData?.length > 0 &&
            splitData?.includes("GuestCard")
              ? 0
              : 1,
          sentinvite_sms: 0,
          role: "applicant",
          country_code: value?.country_code,
        },
      ],
      is_behalfofapplicant: null,
      createdby: null,
      desired_propertyaddress: null,
      desired_city: null,
      desired_state: null,
      desired_moveindate: null,
      desired_rent: null,
      desired_unitno: null,
      hearaboutus: null,
      other_lead_source: null,
    };
    create(
      "addnewapplication",
      { data: sendData },
      {
        onSuccess: (data) => {
          notify(`Request processed successfully.`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          window.open(data?.data, "_self");
          refresh();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };

  useEffect(() => {
    const url = window.location.href;
    const parts = url.split("/cp/");
    const extractedUrl: any = parts[1];
    setUrl(extractedUrl);
    const splitValue: any = url.split("/");
    setSplitData(splitValue);
  }, []);
  const handleButtonClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 6000);

    // You can replace the setTimeout above with your actual async operation
  };
  if(registrationLoading)  return <Loading/>
  if(registrationError) return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",

      gap: "20px", // Adjust the gap value as needed
      marginTop: "20px",
      textAlign: "center"
  }}>
        <h3>
            {splitData[5]} Not Available
        </h3>
        <ErrorIcon style={{ fontSize: 150 }}/>
        <div>
            {`${registrationError}`.split(":")[1].trim()}
        </div>
      </div>
);
  return (
    <>
      <section className="register-wrapper">
        <div className="register-page">
          <div className="register-block">
            <div className="register-header">
              <div className="text-center">
                <img
                  className="mb-3"
                  src={`${data?.data?.records?.subrciber_logo}`}
                  alt={data?.data?.records.subscriber_name}
                  title={data?.data?.records.subscriber_name}
                  style={{
                    maxHeight: "180px",
                    maxWidth: "50px",
                    height: "auto",
                    marginRight: "5px",
                    display: data?.data?.records.subscriber_name
                      ? "initial"
                      : "none", // Hide the entire image if the source is falsy
                  }}
                  onError={(e) => {
                    const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                    target.style.display = "none"; // Hide the broken image
                  }}
                />
                <p>
                  Thanks for your interest in applying with{" "}
                  {splitData?.includes("Agent") ||
                  splitData?.includes("Office") ? (
                    <br />
                  ) : (
                    ""
                  )}
                  {data?.data?.records?.property?.PropertyName ||
                    data?.data?.records?.subscriber_name}
                  <br />
                  {splitData?.includes("Unit") ? (
                    <b>{`Unit: ${data?.data?.records?.selected_unit?.UnitName} (Rent: $${data?.data?.records?.selected_unit?.MaximumRent})`}</b>
                  ) : splitData?.includes("Floor") ? (
                    <b>{`Floor Plan: ${data?.data?.records?.select_floor?.FloorCode} (Min Rent: $${data?.data?.records?.select_floor?.MinimumRent}, Max Rent: $${data?.data?.records?.select_floor?.MaximumRent})`}</b>
                  ) : (
                    ""
                  )}
                </p>
              </div>

              <div className="text-center mr-b30 mr-t10">
                <p className="mr-b10">
                  Please register to start your application
                </p>
                {/* <h6>
                  Already started application?{" "}
                  <NavLink to="/ats/login">Login Here</NavLink>
                </h6> */}
                <br />
              </div>
            </div>

            <div className="register-form mr-t30">
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 mr-b10">
                      <InputField2
                        label="First Name"
                        source={`first_name`}
                        placeholder="Enter First Name"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mr-b10">
                      <InputField2
                        label="Last Name"
                        source={`last_name`}
                        placeholder="Enter Last Name"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mr-b10">
                      <TextInputPhone
                        source={`mobile`}
                        label="Mobile"
                        isCountryCode={`country_code`}
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                        widthClass={"w-100"}
                        requiredClass={{
                          fontSize: "70%",
                        }}
                        // required={false}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6"></div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mr-b10">
                      <InputField2
                        label="Email"
                        source={`email`}
                        placeholder="Enter Email"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                        // handleOnChange={() => methods.trigger("confirm_email")}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 mr-b10">
                      <InputField2
                        label="Confirm Email"
                        source={`confirm_email`}
                        placeholder="Enter Confirm Email"
                        labelClass={"lable-title"}
                        additionalInputClass={"cus-control"}
                      />
                    </div>
                    {splitData &&
                      splitData?.length > 0 &&
                      splitData?.includes("Agent") && (
                        <div className="col-sm-12 col-md-6 col-lg-6 mr-b10">
                          <InputSelectField
                            label="Office"
                            source={`office`}
                            options={data?.data?.records?.Office?.map(
                              (item) => ({
                                label: item.OfficeName,
                                value: item.ID,
                              })
                            )}
                            labelAsteriskClass={"color-red"}
                            labelClass={"lable-title"}
                            requiredClass={{
                              fontSize: "70%",
                            }}
                          />
                        </div>
                      )}
                    {splitData &&
                      splitData?.length > 0 &&
                      splitData?.includes("Office") && (
                        <div className="col-sm-12 col-md-6 col-lg-6 mr-b10">
                          <InputSelectField
                            label="Agent"
                            source={`agent`}
                            options={data?.data?.records?.Agents?.map(
                              (item) => ({
                                label: item.FullName,
                                value: item.ASPNetUserID,
                                id: item.UserID,
                              })
                            )}
                            labelAsteriskClass={"color-red"}
                            labelClass={"lable-title"}
                            requiredClass={{
                              fontSize: "70%",
                            }}
                          />
                        </div>
                      )}
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    {/* <button
                      type="submit"
                      className="l-btn w-50"
                      disabled={isLoading}
                    >
                      Start Application
                    </button> */}
                    <button
                      type="submit"
                      className="l-btn w-50"
                      disabled={isLoading}
                      onClick={() => handleButtonClick()}
                    >
                      {isLoading1 ? "Loading..." : "Start Application"}
                    </button>
                  </div>
                </form>
              </FormProvider>
            </div>

            <div className="l-foot-links mr-t20">
              <ul className="mx-auto">
                <li>
                  <a href={"/atss/DataPolicy"} target="_blank">
                    Data Policy
                  </a>
                </li>

                <li>
                  <a href={"/atss/TermsOfUse"} target="_blank">
                    Terms of Use
                  </a>
                </li>
                {/* <li>Dev Version 1.0.0.9</li> */}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ApplicationRegistration;
