import { Rectangle } from "@mui/icons-material";
import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export default function TotalApplicationGraph({ records }) {
  // const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const data = records.map((item) => ({
    name: item?.Months.slice(0,3) + " " + item?.Months?.slice(-2),
    new_app: item?.Total_Applications || 0,
  }));
  // console.log(data,"month");

  return (
    <div className="n-app-graph">
      <ResponsiveContainer width="100%" height={150}>
        <BarChart data={data}>
          <XAxis dataKey="name" />
          <Tooltip />
          <Legend verticalAlign="top" height={25} />
          <Bar name="New Application" dataKey="new_app" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
