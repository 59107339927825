import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ErrorIcon from "@mui/icons-material/Report";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
const ErrorPage = ({ unauthacess = false }) => {


    return (
        <>


            <div className="home_content">

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "20px", // Adjust the gap value as needed
                    marginTop: "100px",
                    textAlign: "center",
                    
                }}>

                    <DoDisturbIcon style={{ fontSize: 150,color:"red", }} />

                    <div style={{fontSize:"30px",fontWeight:"bold"}}>
                        You Don't have access to this page.
                    </div>
                </div>
            </div>

        </>
    );
};

export default ErrorPage;