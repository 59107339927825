import { format } from "date-fns"
import { BooleanField, Datagrid, DateField, FunctionField, List, ListBase, ListGuesser, ListToolbar, NumberField, Pagination, Resource, TextField, useListContext, useNotify, useStore } from "react-admin"

import {
    Grid,
    Typography,
    TextField as MuiTextField,
    Box,
    Link,
    ToggleButton, 
    ToggleButtonGroup,
    Checkbox,
    Stack,
    Button as MuiButton
  } from "@mui/material";
  
  import PDF from "./../../../../assets/images/Frame (5).png";
  import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
  import InputAdornment from "@mui/material/InputAdornment";
  import SearchIcon from "@mui/icons-material/Search";

import { debounce } from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import React from "react";
import FilterChips from "../Ats/SuperAdminInbox/FilterChips";


export const GeographicalsettingsResource = () => {
    return <Resource name="getgeographicalrules" list={Geographicalsettings} />
}
const MyListToolBar = () => {
    const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
      useListContext();
      const [searchValue, setSearchValue] = useState(filterValues.searchcode || "");
    const [applicantType,setApplicantType] = useState("All")
    const navigate = useNavigate();
    const [q, setQ] = React.useState<null | string>(filterValues?.q);
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    // Debounce the search function
    const debouncedSearch = debounce((value) => {
      // Perform your search logic here
      setFilters({ ...filterValues, q: value }, []);
    }, 500); // 500ms debounce delay
  
    const handleSearchChange = (event) => {
      const value = event.target.value;
      debouncedSearch(value);
    };
  const notify=useNotify();
  
    
    return (
      <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
        {/* Left side with title */}
        <Grid item xs={12} md={6}>
        
        <p style={{font:"Open Sans",fontWeight:600,fontSize:'18px'}}>Geographic Rule Setting</p>
     
      
  
        </Grid>
  
        {/* Right side with search input, filter button, and add button */}
        <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent="flex-end"
          alignItems="center"
        >
          <div className="group-btn">
            {/* Search Input */}
            <MuiTextField
              placeholder="search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              defaultValue={filterValues.q}
              onChange={handleSearchChange}
              variant="outlined"
              size="small"
            />
            
            
           
  
    
          </div>
        </Grid>
      </Grid>
    );
  };
  
  const MyList = ({ children, toolBar, ...props }) => {
    return (
      <ListBase
        {...props}
        perPage={100} // Number of items per page
      >
        {toolBar}
        <FilterChips excludeKeys={['app_invitation_type','active']} />
        {children}
       
        <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
        <br/>
        <br/>
        <br/>
        <br/>
      </ListBase>
    );
  };

const Geographicalsettings=()=>{
return(
    <>
     <MyList
            toolBar={<MyListToolBar />}
            sort={{ field: "id", order: "DESC" }}   
    >
    <Datagrid bulkActionButtons={false} sx={{
        "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
        "& .RaDatagrid-expandIcon": { color: "#fff" },
        "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
        "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
            fontSize: 12,
        },
        "& .RaDatagrid-table": {
            fontSize: 12,
        },
        "& .MuiToggleButton-root.Mui-selected": {
            fontWeight: 700,
        },
    }}>
            <TextField source="id" />
            <TextField source="Name" />
            <TextField source="Ruletype" />
            <TextField source="City" />
            <TextField source="State" />
        </Datagrid>
        </MyList>
    </>
)
}
export default Geographicalsettings;