import React from 'react'
import moment from "moment";
const DateComponent = ({date}) => {
  return (
    <div>
      {date
        ? moment(date).format("MM/DD/YYYY h:mm A z")
        : "NA"}
    </div>
  )
}

export default DateComponent
