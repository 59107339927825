import { format } from "date-fns"

import { BooleanField, Datagrid, DateField, FunctionField, List, ListBase, ListGuesser, ListToolbar, NumberField, Pagination, Resource, TextField, useListContext, useStore,useNotify } from "react-admin"

import FilterChips from "../../../Ats/SuperAdminInbox/FilterChips";

import {

  Grid,

  Typography,

  TextField as MuiTextField,

  Box,

  Link,

  ToggleButton,

  ToggleButtonGroup,

  Checkbox,

  Stack

} from "@mui/material";

import PDF from "./../../../../assets/images/Frame (5).png";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import InputAdornment from "@mui/material/InputAdornment";

import SearchIcon from "@mui/icons-material/Search";

import IconButton from "@mui/material/IconButton";

import { ATS_API_URL } from "../../../../config";

import { debounce } from "lodash";

import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import FilterModal from "../../../manageSubscribers/FilterModal";

import MarkActiveModal from "../../../Settings/EmailSettings/MarkActiveModal";
import React from "react";

export const ConfidentialityLogsResource = () => {

  return <Resource name="getconfidentialitylogs" list={ConfidentialityLogs} />

}



const MyListToolBar = () => {

  const { displayedFilters, filterValues, setFilters, hideFilter, sort } =

    useListContext();

  // const [applicantType, setApplicantType] = useState("LANDLORD")

  const navigate = useNavigate();
  const [q, setQ] = React.useState<null | string>(filterValues?.q ?? ''); 

  const notify = useNotify();
  const [isLoadingExport, setIsLoading] = useState(false);

  const [switchSubscriberObj] = useStore("switchSubscriberName");

  // Debounce the search function

  const debouncedSearch = debounce((value) => {

    // Perform your search logic here

    setFilters({ ...filterValues, q: value }, []);

  }, 500); // 500ms debounce delay



  const handleSearchChange = (event) => {

    const value = event.target.value;

    debouncedSearch(value);

  };



  const handleExportData = () => {
    setIsLoading(true);

    const tokenString = localStorage.getItem('auth')

    let tokenSecret = ''

    if (tokenString) {

      try {

        const token = JSON.parse(tokenString);

        tokenSecret = token?.data?.tokendata || '';

      } catch (error) {

        console.error('Error parsing token:', error);

      }

    }





    const currentTimestamp = Date.now();



    const url = `${ATS_API_URL}/subscriber/exportconfidentialitylogs?subscriberid=${switchSubscriberObj?.value

      }&_sort=${sort?.field || ""}&_order=${sort?.order || ""}&Type=${filterValues.Type || ""}`;



    fetch(url, {

      method: 'GET',

      headers: {

        'Authorization': `${tokenSecret}`

      }

    }).then(response => response.blob())

      .then(blob => {

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');

        a.style.display = 'none';

        a.href = url;

        a.download = `confidentialitylog-${currentTimestamp}.xlsx`;

        document.body.appendChild(a);

        a.click();

        window.URL.revokeObjectURL(url);
        notify(`File exported successfully.`, {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
      });
  }).catch(error => {
      console.error('Error:', error);

      // Error notification
      notify(`Operation failed: ${error.message}`, {
          type: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
      });
  }) .finally(() => {
      setIsLoading(false); // Stop loading
  });
};

  

React.useEffect(() => {
  console.log("This is useEffect", filterValues.q);
  
  if (filterValues.q === undefined) {
      setQ(''); // Reset q to an empty string if filterValues.q is undefined
  } else {
      setQ(filterValues.q); // Otherwise, set q to the value in filterValues.q
  }

}, [filterValues.q]); 



  return (

    <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>

      {/* Left side with title */}

      <Grid item xs={12} md={6}>

        <div className="app-option-btn" id="a-oprtions">



          <label htmlFor="c2">

            <input

              type="radio"

              onChange={() => {

                // setApplicantType("Landlord");

                setFilters({ ...filterValues, Type: "Landlord" }, []);

              }}

              checked={

                filterValues.Type == "Landlord"

              }

              name="a-oprtions"

              id="c2"

            />

            <span>LANDLORD</span>

          </label>





          <label htmlFor="c1">

            <input

              type="radio"

              onChange={() => {

                setFilters({ ...filterValues, Type: "Third Party Agent" }, []);

              }}

              checked={

                filterValues.Type == "Third Party Agent"

              }

              name="a-oprtions"

              id="c1"

            />

            <span>THIRD PARTY</span>

          </label>











        </div>







      </Grid>



      {/* Right side with search input, filter button, and add button */}

      <Grid

        item

        xs={12}

        md={6}

        container

        justifyContent="flex-end"

        alignItems="center"

      >

        <div className="group-btn">

          {/* Search Input */}

          <MuiTextField

            placeholder="search"

            InputProps={{

              startAdornment: (

                <InputAdornment position="start">

                  <SearchIcon />

                </InputAdornment>

              ),

            }}

            value={q}

            onChange={handleSearchChange}

            variant="outlined"

            size="small"

          />

          {/* <FilterModal /> */}

          <button

            onClick={handleExportData}

            type="button"

            className="g-line-btn"

            title="Export all data"
            disabled={isLoadingExport}

          >
   {isLoadingExport ? (
                <svg
                width="16"
                height="16"
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
                className="spinner"
            >
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    stroke="#2DC3E8"
                    strokeWidth="4"
                    fill="none"
                    strokeLinecap="round"
                    strokeDasharray="31.4 31.4"
                    strokeDashoffset="0"
                    transform="rotate(-90 25 25)"
                    style={{ animation: "spin 1s linear infinite" }}
                />
            </svg>

        ) : (
            <svg

              width="16"

              height="17"

              viewBox="0 0 16 17"

              fill="none"

              xmlns="http://www.w3.org/2000/svg"

            >

              <g clip-path="url(#clip0_14375_6193)">

                <path

                  d="M8.66667 8.4987H10.6667L8 11.1654L5.33333 8.4987H7.33333V5.83203H8.66667V8.4987ZM10 3.16536H3.33333V13.832H12.6667V5.83203H10V3.16536ZM2 2.49336C2 2.12803 2.298 1.83203 2.666 1.83203H10.6667L14 5.16536V14.494C14.0006 14.5816 13.984 14.6684 13.951 14.7495C13.9181 14.8306 13.8695 14.9045 13.808 14.9668C13.7466 15.0291 13.6734 15.0788 13.5928 15.1128C13.5121 15.1469 13.4255 15.1648 13.338 15.1654H2.662C2.48692 15.1641 2.31934 15.0941 2.19548 14.9704C2.07161 14.8466 2.0014 14.6791 2 14.504V2.49336Z"

                  fill="#2DC3E8"

                />

              </g>

              <defs>

                <clipPath id="clip0_14375_6193">

                  <rect

                    width="16"

                    height="16"

                    fill="white"

                    transform="translate(0 0.5)"

                  />

                </clipPath>

              </defs>

            </svg>)}

          </button>











        </div>

      </Grid>

    </Grid>

  );

};



const MyList = ({ children, toolBar, ...props }) => {

  return (

    <ListBase

      {...props}

      perPage={100} // Number of items per page

    >

      {toolBar}

      <FilterChips excludeKeys={['app_invitation_type', 'Type']} />

      {children}

      <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />

      <br />

      <br />

      <br />

      <br />

    </ListBase>

  );

};

const ConfidentialityLogs = () => {

  const { displayedFilters, filterValues, setFilters, hideFilter, sort } =

    useListContext();

  const [switchSubscriberObj] = useStore("switchSubscriberName");


const defaultFilterValue={ Type : "Landlord"};




  return (

    <>



      <MyList

        toolBar={<MyListToolBar />}

        sort={{ field: "id", order: "DESC" }}

          // filter={{ subscriberID: switchSubscriberObj?.value }}

          filterDefaultValues={defaultFilterValue}
          

        // filter={{ subscriberID: 10145 }}
      
        // resource="getconfidentialitylogs"

      >

        <UserSetupDataGrid />



      </MyList>

    </>

  );

};

const UserSetupDataGrid = () => {

  const navigate = useNavigate();

  const {

    displayedFilters,

    filterValues,

    setFilters,

    hideFilter,

    sort,

  } = useListContext();
  const [isLoadingExport1, setIsLoading] = useState(false);

  const [selectedRecords, setSelectedRecords] = useState<number[]>([]);

  const [headerCheckbox, setHeaderCheckbox] = useState(false);

  const [switchSubscriberObj] = useStore("switchSubscriberName");

  const [selectedAllRecords, setSelectedAllRecords] = useState<number[]>([]);

  

  const [selectedThirdPartyRecords, setSelectedThirdPartyRecords] = useState<

    number[]

  >([]);



  const SelectAllHeader = ({ setSelectedRecords }) => {

    const { data } = useListContext();

    const [headerCheckboxAll, setHeaderCheckboxAll] = useState(false);

    const [headerCheckboxApplicant, setHeaderCheckboxApplicant] = useState(false);

    const [headerCheckboxSub, setHeaderCheckboxSub] = useState(false);

    const [headerCheckboxThird, setHeaderCheckboxThird] = useState(false);







    const handleHeaderCheckboxChange = (event) => {

      const isChecked = event.target.checked;





      // console.log("filterValues.templateApplicableFor",filterValues.templateApplicableFor)

      console.log("isChecked", isChecked)



      if (filterValues.Type === "Landlord") {

        // Active Tab

        setHeaderCheckboxAll(isChecked);

        const allRecordIds = data.map((record) => record.id);

        if (isChecked) {

          setSelectedAllRecords(allRecordIds);

        } else {

          setSelectedAllRecords([]);

        }

      } else if (filterValues.Type === "Third Party Agent") {

        // All Tab

        setHeaderCheckboxThird(isChecked);

        const allRecordIds = data.map((record) => record.id);

        if (isChecked) {

          setSelectedThirdPartyRecords(allRecordIds);

        } else {

          setSelectedThirdPartyRecords([]);

        }

      }

      // You can add similar logic for the Inactive tab if needed.

    };





    return (

      <Checkbox

        // checked={filterValues.Type == undefined ? headerCheckboxSub : headerCheckboxThird}
        checked={headerCheckbox}
        

        onChange={handleHeaderCheckboxChange}

      />

    );

  };



  const handleCheckboxChange = (recordId: any) => {

    if (filterValues.Type === "Landlord") {

      // Active Tab

      setSelectedAllRecords((prevSelectedRecords) =>

        prevSelectedRecords.includes(recordId)

          ? prevSelectedRecords.filter((id) => id !== recordId)

          : [...prevSelectedRecords, recordId]

      );

    } else if (filterValues.Type === "Third Party Agent") {

      // All Tab

      setSelectedThirdPartyRecords((prevSelectedRecords) =>

        prevSelectedRecords.includes(recordId)

          ? prevSelectedRecords.filter((id) => id !== recordId)

          : [...prevSelectedRecords, recordId]

      );

    }

    // You can add similar logic for the Inactive tab if needed.

  };



  const anyCheckboxChecked = selectedRecords.length > 0;


  const notify = useNotify();
  useEffect(() => {

    if (anyCheckboxChecked) {

      console.log("At least one checkbox is checked", selectedRecords);

    } else {

      console.log("No checkboxes are checked");

    }

  }, [selectedRecords.length]);

  const handleExportDataPDF = (record) => {
    setIsLoading(true);

    const tokenString = localStorage.getItem('auth');

    let tokenSecret = '';



    if (tokenString) {

      try {

        const token = JSON.parse(tokenString);

        tokenSecret = token?.data?.tokendata || '';

      } catch (error) {

        console.error('Error parsing token:', error);

      }

    }





    const url = `${ATS_API_URL}/subscriber/getconfidentialitylogpdf?LandlordID=${record.LandlordID}`;



    fetch(url, {

      method: 'GET',

      headers: {

        'Authorization': `${tokenSecret}`

      }

    })

      .then(response => response.blob())

      .then(blob => {

        const newTabUrl = window.URL.createObjectURL(blob);

        window.open(newTabUrl); // Open the PDF in a new tab

      })

      .catch(error => {
        console.error('Error:', error);
  
        // Error notification
        notify(`Operation failed: ${error.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
        });
    }) .finally(() => {
        setIsLoading(false); // Stop loading
    });

  };

  return (

    <>

      {/* <Box>

        {filterValues.Type === undefined &&

          selectedAllRecords.length > 0 && (

            <Box

              sx={{

                padding: 2,

                display: "flex",

                justifyContent: "space-between",

                alignItems: "center",

                marginBottom: 2,

              }}

            >

              <Stack direction="row" spacing={2}>

                <MarkActiveModal

                  status="Inactive"

                  selectedIds={selectedAllRecords}

                  setSelectedIds={setSelectedAllRecords}

                />

                <MarkActiveModal

                  status="Active"

                  selectedIds={selectedAllRecords}

                  setSelectedIds={setSelectedAllRecords}

                />

              </Stack>

            </Box>

          )}



        {filterValues.Type === "Third Party" &&

          selectedThirdPartyRecords.length > 0 && (

            <Box

              sx={{

                padding: 2,

                display: "flex",

                justifyContent: "space-between",

                alignItems: "center",

                marginBottom: 2,

              }}

            >

              <Stack direction="row" spacing={2}>

                <MarkActiveModal

                  status="Inactive"

                  selectedIds={selectedThirdPartyRecords}

                  setSelectedIds={setSelectedThirdPartyRecords}

                />

                <MarkActiveModal

                  status="Active"

                  selectedIds={selectedThirdPartyRecords}

                  setSelectedIds={setSelectedThirdPartyRecords}

                />

              </Stack>

            </Box>

          )} */}

        <Datagrid bulkActionButtons={false} sx={{

          "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },

          "& .RaDatagrid-expandIcon": { color: "#fff" },

          "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },

          "& .RaDatagrid-headerCell": {

            fontWeight: "bold",

            fontSize: 12,

          },

          "& .RaDatagrid-table": {

            fontSize: 12,

          },

          "& .MuiToggleButton-root.Mui-selected": {

            fontWeight: 700,

          },

        }}>

          {/* <FunctionField

            render={(record) => (

              <Checkbox

                // checked={selectedRecords.includes(record.id)}

                // onChange={() => handleCheckboxChange(record.id)}

                checked={

                  filterValues.Type === "Landlord"

                    ? selectedAllRecords.includes(record.id)



                    : filterValues.Type === "Third Party Agent"

                      ? selectedThirdPartyRecords.includes(record.id)

                      : false // Handle other tabs similarly

                }

                onChange={() => handleCheckboxChange(record.id)}

              />

            )}

            label={<SelectAllHeader setSelectedRecords={setSelectedRecords} />}

            sx={{ width: "100px" }}

          /> */}
          
          <TextField source="Name" label={"User Name"} />

          <TextField source="ApplicationNumber" label={"App No."} />

          <TextField source="Email" />

          {/* <DateField source="LogDate" label={"Date"}/> */}

          {/* <FunctionField

        label="Date & Time"

        render={record => record.LogDate ? format(new Date(record.LogDate), 'yyyy-MM-dd HH:mm:ss') : ''}

        source="LogDate"

    /> */}

          <FunctionField

            label="Date & Time"

            render={record =>

              record.LogDate ?

                format(new Date(record.LogDate), 'dd/MM/yyyy hh:mm a')

                : ''}

            source="LogDate"

          />

          <TextField source="IPAddress" label={"Ip Address"} />

          {/* <TextField source="LandlordType" />

           

            <TextField source="LandlordFirstName" />

            <TextField source="LandlordLastName" />

           

            <TextField source="LandlordID" />

            <TextField source="Terms" />

            <TextField source="IPAddress" />

            <BooleanField source="IsTermsAccept" />

            <BooleanField source="IsTermsResign" />

           

            <TextField source="TranscationID" /> */}

          <FunctionField

            label={""}

            render={(record) => (

              // <Box display={"flex"}>

              <Box display={"flex"} sx={{ width: "80px" }}>

                {/* <CopyLinkHoverDropdown record={record} /> */}

                <button

                  onClick={()=>handleExportDataPDF(record)}

                  style={{

                    background: 'none',

                    border: 'none',

                    padding: 0,

                    cursor: 'pointer',

                  }}

                  className="show_on_hover"

                  aria-label="view details"
                  disabled={isLoadingExport1}

                >
 {isLoadingExport1 ? (
                <svg
                width="16"
                height="16"
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
                className="spinner"
            >
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    stroke="#2DC3E8"
                    strokeWidth="4"
                    fill="none"
                    strokeLinecap="round"
                    strokeDasharray="31.4 31.4"
                    strokeDashoffset="0"
                    transform="rotate(-90 25 25)"
                    style={{ animation: "spin 1s linear infinite" }}
                />
            </svg>

        ) : (
                  <img

                    src={PDF} // Replace this with the path to your PDF image

                    alt="Export PDF"

                    style={{ width: '24px', height: '24px' }} // Adjust the size as needed

                  />)}

                </button>



              </Box>

            )}

          />

        </Datagrid>

     

    </>


  )

}



export default ConfidentialityLogs;