import React from 'react'
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

export default function DeleteContainedBtn({ onClick, disable = false }) {
    return (
        <div><button
            type="button"
            onClick={() =>
                onClick(true)
            }
            disabled={disable}
            className="btn btn-link print-hide print-hide"
            style={{color:'#dc3545',paddingTop:25}}
        >
            <DeleteIcon sx={{fontSize:16}} /> Delete
        </button>
        </div>
    )
}
