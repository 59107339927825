import React from "react";
import { CircularProgress, Dialog, styled, Paper } from "@mui/material/";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "background-color": "transparent",
  // "& .css-1t1j96h-MuiPaper-root-MuiDialog-paper": {
  //   "background-color": "transparent",
  //   "overflow-y": "inherit",
  //   "box-shadow": "inherit",
  //   color: "#ffffff",
  // },
  cursor: "progress",
}));

const StyledPaper = styled(Paper)`
  background-color: transparent;
  overflow-y: inherit !important;
  box-shadow: inherit !important;
  color: #ffffff;
`;


const LoadingSpinner = () => {
  return (
    <>
      <BootstrapDialog open={true} PaperComponent={StyledPaper}>
        <CircularProgress color="inherit" />
      </BootstrapDialog>
    </>
  );
};

export default LoadingSpinner;
