import React from "react";
import { JSONTree } from "react-json-tree";
import greenCircle from "../../../assets/images/greenCircle.svg";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { ListSubheader, Typography } from "@mui/material";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const Dark = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const formatDate = (year: string, month: string, day: string) => {
  return `${month.padStart(2, '0')}-${day.padStart(2, '0')}-${year.slice(-4)}`;
};

const formatTime = (time: string) => {
  const [hours, minutes, seconds] = time.split(':');
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
};

const checkCreateandModifiedDate = (dateString: string) => {
  if (dateString === 'Create Date should be Null') {
    return dateString;
  }
  if(dateString == 'Create Date should be Present'){
    return dateString
  }
  
  const createdDateMatch = dateString.match(/Create Date: (\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})/);
  const modifiedDateMatch = dateString.match(/Modified Date: (\d{4}-\d{2}-\d{2}) (\d{2}:\d{2}:\d{2})/);

  if (createdDateMatch && modifiedDateMatch) {
    const [, createdDate, createdTime] = createdDateMatch;
    const [, modifiedDate, modifiedTime] = modifiedDateMatch;

    const [createdYear, createdMonth, createdDay] = createdDate.split('-');
    const [modifiedYear, modifiedMonth, modifiedDay] = modifiedDate.split('-');

    const formattedCreatedDate = formatDate(createdYear, createdMonth, createdDay);
    const formattedCreatedTime = formatTime(createdTime);
    const formattedModifiedDate = formatDate(modifiedYear, modifiedMonth, modifiedDay);
    const formattedModifiedTime = formatTime(modifiedTime);

    return (
      <>
        <div className="d-flex justify-content-start">
          <div className="">CD</div>
          <div className="px-1">{formattedCreatedDate} {formattedCreatedTime}</div>
        </div>
        <div className="d-flex justify-content-start">
          <div className="">MD</div>
          <div className="px-1">{formattedModifiedDate} {formattedModifiedTime}</div>
        </div>
      </>
    );
  }

  return dateString;
};



const NewMetaDataRulesBank = ({
  metaDataCheck
}) => {
  return (<><div className="row" >
    {metaDataCheck.map((item, index) => {
      return (
        <div className="col-12 col-lg-12 col-md-12 mb-4" >
          <div className="">
            <div className="border-bottom small mb-2"  style={{ fontSize: '10px' }}><strong>{index + 1}) {item?.filename?.split('_')?.pop()}</strong></div >
            
            {item.metaDataCheck.map(item => {
              return (<div className="row justify-content-between py-1">
                <div className="col-12 col-lg-3 col-md-3" style={{ fontSize: '10px' }}>{item.rule.split(' - ').shift()}</div>
                {item.rule.split(' - ').shift()=='Font Check' && <div className="col-12 col-lg-6 col-md-6" style={{ fontSize: '10px' }}>{item.rule.split(' - ').pop().split(',').map(item=><Chip sx={{margin:'1px'}} size="small" variant="filled" label={item} />)}</div>}
                {item.rule.split(' - ').shift()!='Font Check' && <div className="col-12 col-lg-6 col-md-6" style={{ fontSize: '10px' }}>{item.rule.split(' - ').shift()=='Further Verification'?item.rule.split(' - ').pop():checkCreateandModifiedDate(item.rule.split(' - ').pop())}</div>}
                <div className="col-12 col-lg-2 col-md-2" style={{ fontSize: '8px' }}><strong>{item.status}</strong></div>
              </div>)
            })}
          </div>
        </div>
      );
    })}
  </div></>)
};

export default NewMetaDataRulesBank;
