import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useFormContext, useController } from "react-hook-form";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const InputFileUpload = ({ source, acceptedFiles, buttonText = "Upload" }) => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name: source,
    control,
  });

  return (
    <>
      <Button
        component="label"
        variant="contained"
        startIcon={<FileUploadIcon />}
      >
        {buttonText}
        <VisuallyHiddenInput
          type="file"
          onChange={(e) => {
            if(e.target.files[0])
            field.onChange(e.target.files[0]);
          }}
          accept={acceptedFiles}
        />
      </Button>
      <div
        className="invalid-feedback"
        style={{ display: "block", fontSize: "70%" }}
      >
        {error !== undefined && error.message}
      </div>
    </>
  );
};

export default InputFileUpload;
