import { Button, Datagrid, DateField, FunctionField, List, ListBase, ListToolbar, NumberField, Pagination, Resource, TextField, useGetList, useListContext, useStore } from 'react-admin';
import {
  Box, TextField as MuiTextField,
  Button as MuiButton, Grid
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import FilterChips from '../Ats/SuperAdminInbox/FilterChips';
import FilterReports from './FilterReports';
import Action from './Action';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';

export const LeadManagementReportResource = () => {
  return <Resource name="getleadmanagementreport" list={LeadManagement} />
}


const bgStyle = (condition: string) => {
  let style = {};
  switch (condition) {
    case "CompletedConditionallyApproved":
      style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
      break;
    case "Completed-Unacceptable":
      style = { backgroundColor: "#F8ECE2", color: "#FF4848" };
      break;
    case "Completed-Approved":
      style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
      break;
    case "Invited":
      style = { backgroundColor: "#E9E9E9", color: "#909090" };
      break;
    case " In Progress":
    case "Application In Progress":
      style = { backgroundColor: "#DDF8FF", color: "#1CA2C2" };
      break;
    case "Submitted":
    case "Review Pending":

      style = { backgroundColor: "#B0EFFF", color: "#127790" };
      break;
    // case "Screening":
    case "Completed":
    // case "Completed-Approved":
    case "Verified":
      // case "Screening":
      style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
      break;
    case "Cancelled":
    case "Archived":
    // case "Completed-Unacceptable":
    case "Unverifiable":
      style = { backgroundColor: "#FFEEEE", color: "#FF4848" };
      break;
    case "Completed-Conditionally Approved":
    case "CompletedConditionallyApproved":
    case "Further Documentation Required":
    case "Further Doc Req.":
      style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
      break;
    default:
      style = {};
  }
  return style;
};
const bgStyle1 = (condition: string) => {
  let style = {};
  switch (condition) {
    case "ConditionallyApproved":
    case "Conditionally Approved":
      style = { backgroundColor: "#FFF8E2", color: "#F9BB04" };
      break;
    case "Unacceptable":
      style = { backgroundColor: "#F8ECE2", color: "#FF4848" };
      break;
    case "NoAssessment":
    case "No Assessment":
      style = { backgroundColor: "#fff", color: "#000000" };
      break;
    case "Approved":
      style = { backgroundColor: "#E6FDF0", color: "#46A96F" };
      break;
    default:
      let abc = bgStyle;
      return abc;
  }
  return style;
};

const MyList = ({ children, filters, actions, ...props }) => {



  return (
    <ListBase
      {...props}
      perPage={100} // Number of items per page
    >
      <ListToolbar
        filters={filters}
        actions={actions}
      />

      <FilterChips excludeKeys={['app_invitation_type', 'subscriberID']} />
      {children}
      <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
      <br />
      <br />
      <br />
      <br />
    </ListBase>
  );
};
const LeadManagement = () => {



  return (
    <>
      <br />
      <br />
      <MyList
        filters={<FilterReports />}
        actions={<Action />}
        sort={{ field: "ApplicationNumber", order: "DESC" }}
        filterDefaultValues={{ subscriberID: 1 }}
        children={<DataGrid />}
      />
    </>
  )
}

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

const DataGrid = () => {

  const getDocUploadStatus = (status) => {
    if (status === "CompletedConditionallyApproved") {
      return "Further doc req.";
    } else if (status === "Application In Progress") {
      return "In Progress";
    } else if (status === "Further Documentation Required") {
      return "Further doc req.";
    }
    return status;
  };
  const getAtsStatus = (status) => {
    if (status === "CompletedConditionallyApproved") {
      return "Completed Conditionally Approved";
    } else if (status === "Application In Progress") {
      return "In Progress";
    } else if (status === "Further Documentation Required") {
      return "Further doc req.";
    }
    return status;
  };
  return (<Datagrid bulkActionButtons={false} sx={{
    "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
    "& .RaDatagrid-expandIcon": { color: "#fff" },
    "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
    "& .RaDatagrid-headerCell": {
      fontWeight: "bold",
      fontSize: 12,
    },
    "& .RaDatagrid-table": {
      fontSize: 12,
    },
    "& .MuiToggleButton-root.Mui-selected": {
      fontWeight: 700,
    },
  }}>
    {/* <TextField source="id" /> */}
    <TextField label={'App Id'} source="ApplicationNumber" />
    {/* <DateField source="AppDate" /> */}

    <FunctionField
      sortBy="AppDate"
      label="Date"
      render={(record) => {
        // Format the AppDate using moment.js
        const formattedDate = moment.utc(record.AppDate).format('MM/DD/YYYY');

        return (
          <>

            <p>{formattedDate}</p> {/* Display the formatted AppDate */}
            {/* <p>{record.LandLordPhone}</p> */} {/* Other data rendering if needed */}
          </>
        );
      }}
    />
    <TextField source="FirstName" />
    <TextField source="LastName" />
    <FunctionField
      label={"Status"}
      source="ApplicationStatus"
      render={(record) => {
        return (
          <>
            {/* <JSONTree data={record}/> */}
            <MuiButton
              sx={{ width: "100%" }}
              variant="contained"
              disableElevation={true}
              style={{
                ...bgStyle1(
                  record.Archived == true
                    ? "Archived"
                    : record?.ScreeningDetails
                ),
                ...bgStyle(
                  record.Archived == true
                    ? "Archived"
                    : record?.ApplicationStatus
                ),

              }}
            >
              {truncateText(record.Archived == true
                ? "Archived - " +
                (record.ApplicationType === "Doc Upload"
                  ? getDocUploadStatus(record?.ApplicationStatus)
                  : getAtsStatus(record?.ApplicationStatus))
                : record.ApplicationType === "Doc Upload"
                  ? getDocUploadStatus(record?.ApplicationStatus)
                  : getAtsStatus(record?.ApplicationStatus), 12)}
            </MuiButton></>

        );
      }}
    />
    <TextField source="Mobile" />
    <TextField label={'App Address'} source="Address" />
    <TextField source="City" />
    <TextField source="State" />
    <TextField source="ZipCode" />
    <FunctionField label="Current LandLord" render={(record) => {
      return (<>
        <p>{record.LandLordName || 'NA'}</p>
        <p>{record.LandLordPhone}</p>
      </>)
    }
    } />
     <DateField source="MoveInDate" />
    {/* <TextField label="Rent" source="DesiredRent" /> */}
    <FunctionField
      source="Rent"
      label="Rent"
      sortBy="Rent"
      render={(record) => {
        return !record.DesiredRent ? (
          "NA"
        ) : (
          <>
            <NumericFormat
              thousandSeparator=","
              displayType="text"
              thousandsGroupStyle="thousand"
              decimalScale={2}
              prefix="$"
              fixedDecimalScale
              value={record.DesiredRent}
            />

          </>
        );
      }}
    />
  
     {/* <TextField source="LeadSource" /> */}
  

   
    {/* <FunctionField
      sortBy="MoveInDate"
      label="MoveInDate"
      render={(record) => {
       
        const formattedDate = moment.utc(record.MoveInDate).format('MM/DD/YYYY');

        return (
          <>

            <p>{formattedDate}</p> 
          
          </>
        );
      }}
    /> */}

    {/* <NumberField source="MonthlyIncome" /> */}
    <FunctionField
      source="MonthlyIncome"
      label="Monthly Income"
      sortBy="MonthlyIncome"
      render={(record) => {
        return !record.MonthlyIncome ? (
          "NA"
        ) : (
          <>
            <NumericFormat
              thousandSeparator=","
              displayType="text"
              thousandsGroupStyle="thousand"
              decimalScale={2}
              prefix="$"
              fixedDecimalScale
              value={record.MonthlyIncome}
            />

          </>
        );
      }}
    />
    <FunctionField
      source={"Score"}
      label="Score"
      render={(record) => {
        return (
          <>
            {record.Score > 0 && (
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Avatar
                  sx={{
                    bgcolor:


                      record.ScreeningDetails ===
                        "ConditionallyApproved" ||
                        record.ScreeningDetails ===
                        "Conditionally Approved"
                        ? "#FFF8E2"
                        : record.ScreeningDetails === "Unacceptable"
                          ? "#F8ECE2"
                          : record.ScreeningDetails ===
                            "NoAssessment" ||
                            record.ScreeningDetails === "No Assessment"
                            ? "#fff"
                            : record.ScreeningDetails === ""
                              ? "inherit"
                              : "#E6FDF0",
                    color:
                      // record?.ApplicationStatus ===
                      //   "Completed-Approved" ||
                      // record?.ApplicationStatus ===
                      //   "CompletedConditionallyApproved" ||
                      // record?.ApplicationStatus ===
                      //   "Completed-Unacceptable"
                      //   ? record?.ApplicationStatus ===
                      //     "Completed-Approved"
                      //     ? "#46A96F"
                      //     : record?.ApplicationStatus ===
                      //       "CompletedConditionallyApproved"
                      //     ? "#F9BB04"
                      //     : record?.ApplicationStatus ===
                      //       "Completed-Unacceptable"
                      //     ? "#FF4848"
                      //     : "FF4848"
                      //   :
                      record.ScreeningDetails ===
                        "ConditionallyApproved" ||
                        record.ScreeningDetails ===
                        "Conditionally Approved"
                        ? "#F9BB04"
                        : record.ScreeningDetails === "Unacceptable"
                          ? "#FF4848"
                          : record.ScreeningDetails == "NoAssessment" ||
                            record.ScreeningDetails === "No Assessment"
                            ? "#000000"
                            : record.ScreeningDetails === ""
                              ? "inherit"
                              : "#46A96F",
                    border:
                      // record?.ApplicationStatus ===
                      //   "Completed-Approved" ||
                      // record?.ApplicationStatus ===
                      //   "CompletedConditionallyApproved" ||
                      // record?.ApplicationStatus ===
                      //   "Completed-Unacceptable"
                      //   ? record?.ApplicationStatus ===
                      //     "Completed-Approved"
                      //     ? "1px solid #46A96F"
                      //     : record?.ApplicationStatus ===
                      //       "CompletedConditionallyApproved"
                      //     ? "1px solid #F9BB04"
                      //     : record?.ApplicationStatus ===
                      //       "Completed-Unacceptable"
                      //     ? "1px solid #FF4848"
                      //     : ""
                      //   :
                      record.ScreeningDetails ===
                        "ConditionallyApproved" ||
                        record.ScreeningDetails ===
                        "Conditionally Approved"
                        ? "1px solid #F9BB04"
                        : record.ScreeningDetails === "Unacceptable"
                          ? "1px solid #FF4848"
                          : record.ScreeningDetails ===
                            "NoAssessment" ||
                            record.ScreeningDetails === "No Assessment"
                            ? "1px solid #000000"
                            : record.ScreeningDetails === ""
                              ? "inherit"
                              : "1px solid #46A96F",
                  }}
                >

                  {record?.Score}
                </Avatar>
              </Box>

            )}
          </>
        );
      }}
    />
   <TextField source="LeadSource" />
  </Datagrid>



  )

};


// Exporting component
export default LeadManagement;

