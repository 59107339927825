import React from "react";
import { Controller, useFormContext,useController } from "react-hook-form";
import { JSONTree } from "react-json-tree";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable'

const InputSelectField = ({ source, required = true,labelRequired = true, disable = false, creatable = false, options, label, multiple = false ,onChangeCallback=(selectedOption)=>{}, labelAsteriskClass = "red", labelClass = "form-label" , requiredClass = {} , fontsize = "14px", stopAutoFocus = false,isClearable=true }) => {
  const { control, getValues } = useFormContext();
  const { field, fieldState } = useController({ name: source });
  const isDisable = disable;
  const selectRef = React.useRef(null);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      border: "1px solid rgba(255, 133, 133, 0.37)",
      backgroundColor: "#FFF8F8",
      fontSize: fontsize
    }),
    menu: base => ({  
      ...base,
      fontSize: fontsize,
      zIndex:requiredClass?.zIndexValue?requiredClass?.zIndexValue:1
    })
  };
  const FontcustomStyles = {
    control: (base, state) => ({
      ...base,
      fontSize: fontsize
    }),
    menu: base => ({  
      ...base,
      fontSize: fontsize,
      zIndex:requiredClass?.zIndexValue?requiredClass?.zIndexValue:1
    })
  };

  //Applying a logic for stop auto focused element if needed to passing a stopAutoFocus props
  React.useEffect(() => {
    if (selectRef.current ) {
      if(stopAutoFocus){
        selectRef.current.blur(); //Remove focus from the element
      }else{
        selectRef.current.focus();
      }
    }
  }, [selectRef]);
  
  return (
    <>
      <Controller
        control={control}
        name={source}
        {...field}
        render={({ field, fieldState }) => {
          const error = fieldState.error !== undefined;
          return (
            <>
              {labelRequired && <label
              
                htmlFor={source}
                className={`${error && "text-danger"} ${labelClass}`}
              >
                {label} {required && <span className={labelAsteriskClass}>*</span>}
              </label>}
              {creatable ? <><CreatableSelect
                isDisabled={isDisable}
                styles={required && !field.value && customStyles}
                className={`${required && !field.value && "is-warning"} ${error && "is-invalid"
                  }`}
                {...field}
                value={{ value: field.value, label: field.value }}
                options={options}
              />
                <div id={source + 'btn'} className="form-text">Please select from the drop-down or type to create other option.</div>
              </>
                : <Select
                  isDisabled={isDisable}
                  styles={(required && !field.value) ? customStyles: FontcustomStyles}
                  className={`${required && !field.value && "is-warning"} ${error && "is-invalid"
                    }`}
                  {...field}
                  onChange={(selectedOption)=>{
                      field.onChange(selectedOption);
                      onChangeCallback(selectedOption);
                  }}
                 
                  // value={field?.value?.hasOwnProperty('label') ? field.value : field.value ? { value: field.value, label: field.value } : null}
                  options={options}
                  isMulti={multiple}
                  closeMenuOnSelect={!multiple}
                  maxMenuHeight={200}
                  isClearable={isClearable}
                  ref={selectRef}
                />
              }

              {/* <div className="invalid-feedback" style={{ display: "block" }}>
                {error && fieldState.error?.message}
              </div> */}
              <div className="invalid-feedback d-block" style={requiredClass}> 
                {error && fieldState.error?.message}
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default InputSelectField;
