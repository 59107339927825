import React from "react";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Button as MuiButton,
} from "@mui/material";
import { useNotify, useRefresh, useUpdate } from "react-admin";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import LoadingSpinner from "../common/loadingSpinner";

const ReAddApplicant = ({ record ,subsciberId=''}) => {
  const [update, { data, isLoading, error }] = useUpdate();
  const notify = useNotify();
  const refresh = useRefresh();
  // readd applicant call
  const readdApplicantHandler = () => {
    update(
      "readdapplicant",
      { id: record?.id,data:{role:record?.ApplicantType} },
      {
        onSuccess: (d) => {
          notify(`Application Number ${d.ID} has been Re-Added`, {
            type: "success",
            anchorOrigin: { vertical: 'top', horizontal: 'right' }
          });
          refresh();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }}),
      }
    );
  };

  return (
    <>
      <MuiButton
        variant="text"
        startIcon={<RestoreFromTrashIcon />}
        onClick={readdApplicantHandler}
        sx={{textTransform:"capitalize"}}
      >
        Restore
      </MuiButton>
      {isLoading ? <LoadingSpinner /> : ""}
    </>
  );
};

export default ReAddApplicant;
