import { Controller, useController } from "react-hook-form";
import { useId } from 'react';

import { JSONTree } from "react-json-tree";
interface options {
  value: string;
  name: string;
}
const NoDocReasonRadioButtons = ({
  source,
  options: options,
  section_id = 1,
  handleOnchange = () => {},
}) => {
  const { field, fieldState } = useController({ name: source });
  const id = useId();

  return (
    <>
      {options.map((option) => (
        <div
          className="radios-wrap  mr-t20"
          style={{
            borderBottom: `0px solid rgba(0, 0, 0, 0.08)`,
          }}
          key={option.name}
        >
          <div className="mr-b5 d-flex align-items-center">
            <input
              type="radio"
              id={option.value + section_id + id}
              {...field}
              name={option.value + section_id + id}
              value={option.value}
              checked={field.value == option.value ? true : false}
              onChange={(e) => {
                field.onChange(e);
                if (e?.target?.value?.toLowerCase() !== "other") handleOnchange();
              }}
            />
            <label className="radio" htmlFor={option.value + section_id + id}>
              {option.name}
            </label>
          </div>
        </div>
      ))}
      <div
        className="invalid-feedback"
        style={{ display: "block", fontSize: "70%" }}
      >
        {fieldState.error !== undefined && fieldState.error.message}
      </div>
    </>
  );
};

export default NoDocReasonRadioButtons;
