import { memo } from "react";
import InputDateField from "../common/InputComponents/InputDateFieldOld";
import InputField2 from "../common/InputComponents/InputField2";
import InputCurrencyField from "./components/inputComponents/InputCurrencyFiled";
import { JSONTree } from "react-json-tree";
import { getValue } from "@testing-library/user-event/dist/utils";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { NumericFormat } from "react-number-format";

const BankGptReadingData = ({ index, fileIndex }) => {
    const { control, getValues } = useFormContext();
    return <><div className="row">
        <div className="col-12 col-md-8 col-lg-8">
            <div className="row">
                <div className="col-12 col-md-3 col-lg-3">
                    <InputDateField
                        disabled={true}
                        label="Start Date"
                        source={`banking.${index}.files.${fileIndex}.start_date`}
                        notRequired={true}
                        placeholder=""
                    />
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                    <InputDateField
                        notRequired={true}
                        disabled={true}
                        label="End Date"
                        source={`banking.${index}.files.${fileIndex}.end_date`}
                        placeholder=""
                    />
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                    <InputField2
                        notRequired={true}
                        disable={true}
                        label="Bank Name"
                        source={`banking.${index}.files.${fileIndex}.read_bank_name`}
                        placeholder=""
                    />
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                    <InputField2
                        notRequired={true}
                        disable={true}
                        label=" Account Holder"
                        source={`banking.${index}.files.${fileIndex}.read_employee_name`}
                        placeholder=""
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-3 col-lg-3">
                    {/* <JSONTree data={getValues(`banking.${index}.files.${fileIndex}`)} />
                    <InputCurrencyField
                        notRequired={true}
                        disabled={true}
                        label="Current Balance"
                        source={`banking.${index}.files.${fileIndex}.read_current_balance`}
                        placeholder=""
                    /> */}
                    <div className="emp-inputs">
                        <label
                            htmlFor={"current_balance"}
                            className={`form-label`}
                        >
                            Current Balance
                        </label>
                        <NumericFormat id="current_balance" value={getValues(`banking.${index}.files.${fileIndex}.read_current_balance`)} className={`form-control cus-input-control`} disabled={true} prefix={'$'} allowNegative={true} decimalScale={2} fixedDecimalScale allowLeadingZeros thousandSeparator="," />
                    </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                    {/* <InputCurrencyField
                        notRequired={true}
                        disabled={true}
                        label="Total Withdrawal"
                        source={`banking.${index}.files.${fileIndex}.total_withdrawal`}
                        placeholder=""
                    /> */}
                    <div className="emp-inputs">
                        <label
                            htmlFor={"withdrawal_balance"}
                            className={`form-label`}
                        >
                            Total Withdrawal
                        </label>
                        <NumericFormat id="withdrawal_balance" value={getValues(`banking.${index}.files.${fileIndex}.total_withdrawal`)} className={`form-control cus-input-control`} disabled={true} prefix={'$'} allowNegative={true} decimalScale={2} fixedDecimalScale allowLeadingZeros thousandSeparator="," />
                    </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3">
                    {/* <InputCurrencyField
                        notRequired={true}
                        disabled={true}
                        label="Total Deposit"
                        source={`banking.${index}.files.${fileIndex}.total_deposit`}
                        placeholder=""
                    /> */}
                    <div className="emp-inputs">
                        <label
                            htmlFor={"deposit_balance"}
                            className={`form-label`}
                        >
                            Total Deposit
                        </label>
                        <NumericFormat id="deposit_balance" value={getValues(`banking.${index}.files.${fileIndex}.total_deposit`)} className={`form-control cus-input-control`} disabled={true} prefix={'$'} allowNegative={true} decimalScale={2} fixedDecimalScale allowLeadingZeros thousandSeparator="," />
                    </div>
                </div>
            </div>
        </div>
        {/* <div className="col-md-4 col-sm-12">
        <CustomPreviewUpload
          filePath={files.path_image}
        />
    </div> */}
    </div></>
}
export default memo(BankGptReadingData);