import { debounce } from "lodash";
import React from "react";
import { Button, Datagrid, DateField, FunctionField, List, ListBase, ListToolbar, NumberField, Pagination, Resource, TextField, useGetIdentity, useGetList, useGetOne, useListContext, useNotify, useStore } from 'react-admin';
import {
    Box, TextField as MuiTextField,
    Button as MuiButton, Grid
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { useState } from 'react';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FilterModal from '../manageSubscribers/FilterModal';
import { useForm, FormProvider } from "react-hook-form";
import InputDateField from '../common/InputComponents/InputDateFieldOld';
import { ATS_API_URL } from '../../config';
import InputSelectField from '../common/InputComponents/InputSelectField';
import StatsAndGraph from './DocuploadReportCount';
import { JSONTree } from 'react-json-tree';
import {
    CreateButton,
    DatagridConfigurable,
    ExportButton,
    FilterButton,
    SelectColumnsButton,
    TopToolbar,
    SearchInput,
} from 'react-admin';
import { position } from 'html2canvas/dist/types/css/property-descriptors/position';
const ExportSubRevenue = () => {
    const [switchSubscriberObj] = useStore("switchSubscriberName");
    const { displayedFilters, filterValues, setFilters, hideFilter, sort,total } =
        useListContext();
    const debouncedSearch = debounce((value) => {
        setFilters({
            ...filterValues,
            q: value,  // Update the search_by filter with the new search value
        }, []);
    }, 500);  // 500ms debounce delay
   
    const handleSearchChange = (event) => {
        const value = event.target.value;
        setQ(value);
        debouncedSearch(value);
       
    };
    const form = useForm({

    });
    const resetForm = () => {
        form.reset({
          office: '', // Reset office to its default value
        });
      };
      const resetForm1 = () => {
        form.reset({
            Properties: '', // Reset property to its default value
        });
      };
    const [q, setQ] = React.useState<null | string>(filterValues?.q ?? ''); 
    const notify = useNotify();
    const [isLoadingExport, setIsLoading] = useState(false);
    const handleExportRevenueManagementReport = () => {
        setIsLoading(true); // Start loading
        const tokenString = localStorage.getItem('auth');
        let tokenSecret = '';
    
        if (tokenString) {
            try {
                const token = JSON.parse(tokenString);
                tokenSecret = token?.data?.tokendata || '';
            } catch (error) {
                console.error('Error parsing token:', error);
            }
        }
    
        const currentTimestamp = Date.now();
    
        // Updated URL for exporting Revenue Management Report
        const url = `${ATS_API_URL}/subscriber/exportrevenuemanagementreport?_end=${total}&_start=0&subscriberid=${switchSubscriberObj?.value}&_sort=${sort?.field || ""}&_order=${sort?.order || ""}`;
    
        fetch(url, {
            method: 'GET',
            headers: {
                'Authorization': `${tokenSecret}`
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error('Failed to export the file');
            }
            return response.blob();
        }).then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `Subsciber Revenue Management Report-${currentTimestamp}.xlsx`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
    
            // Success notification
            notify(`File exported successfully.`, {
                type: "success",
                anchorOrigin: { vertical: "top", horizontal: "right" },
            });
        }).catch(error => {
            console.error('Error:', error);
    
            // Error notification
            notify(`Operation failed: ${error.message}`, {
                type: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
            });
        }).finally(() => {
            setIsLoading(false); // Stop loading
        });
    };
    React.useEffect(() => {
        console.log("This is useEffect", filterValues.q);
        
        if (filterValues.q === undefined) {
            setQ(''); // Reset q to an empty string if filterValues.q is undefined
        } else {
            setQ(filterValues.q); // Otherwise, set q to the value in filterValues.q
        }
    
    }, [filterValues.q]); 
    
    const { data: identity } = useGetIdentity();
    const { data, isLoading, error } = useGetOne("getfilterforinbox", {
        id: switchSubscriberObj?.value || identity?.subscriberid,
    });

    return (
        <div className="d-flex mb-2">
            <p style={{paddingTop:10}}>Office/Property:</p>
            <div style={{ zIndex: 1002,position:"relative" }} className="px-2">
                <FormProvider {...form}>
                   {data?.Offices.length>0 && <InputSelectField isClearable={false} onChangeCallback={()=>{
                         setFilters({
                            ...filterValues,
                           office: form.getValues('office').value,  // Update the search_by filter with the new search value
                        },[])
                        resetForm();
                     }} labelRequired={false} label={""} options={data?.Offices.map((item) => {
                        return {
                            value: item?.OfficeName,
                            label: item.OfficeName,
                        };
                    })} source={'office'}  stopAutoFocus={true} required={false} />}
                    {data?.Properties.length>0 && <InputSelectField onChangeCallback={()=>{
                         setFilters({
                            ...filterValues,
                           property: form.getValues('Properties').value,  // Update the search_by filter with the new search value
                        },[])
                        resetForm1();
                     }} labelRequired={false} label={""} options={data?.Properties.map((item) => {
                        return {
                            value: item?.PropertyName,
                            label: item.PropertyName,
                        };
                    })} source={'Properties'} isClearable={false} stopAutoFocus={true} required={false} />}
                </FormProvider>
            </div>
            <MuiTextField
                placeholder="search"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                value={q}
                onChange={handleSearchChange}
                variant="outlined"
                size="small"
                
            />
            <button
                onClick={handleExportRevenueManagementReport}
                type="button"
                className="g-line-btn mx-2"
                title="Export all data"
                disabled={isLoadingExport}
            >
                {isLoadingExport ? (
                <svg
                width="16"
                height="16"
                viewBox="0 0 50 50"
                xmlns="http://www.w3.org/2000/svg"
                className="spinner"
            >
                <circle
                    cx="25"
                    cy="25"
                    r="20"
                    stroke="#2DC3E8"
                    strokeWidth="4"
                    fill="none"
                    strokeLinecap="round"
                    strokeDasharray="31.4 31.4"
                    strokeDashoffset="0"
                    transform="rotate(-90 25 25)"
                    style={{ animation: "spin 1s linear infinite" }}
                />
            </svg>

        ) : (
                <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clip-path="url(#clip0_14375_6193)">
                        <path
                            d="M8.66667 8.4987H10.6667L8 11.1654L5.33333 8.4987H7.33333V5.83203H8.66667V8.4987ZM10 3.16536H3.33333V13.832H12.6667V5.83203H10V3.16536ZM2 2.49336C2 2.12803 2.298 1.83203 2.666 1.83203H10.6667L14 5.16536V14.494C14.0006 14.5816 13.984 14.6684 13.951 14.7495C13.9181 14.8306 13.8695 14.9045 13.808 14.9668C13.7466 15.0291 13.6734 15.0788 13.5928 15.1128C13.5121 15.1469 13.4255 15.1648 13.338 15.1654H2.662C2.48692 15.1641 2.31934 15.0941 2.19548 14.9704C2.07161 14.8466 2.0014 14.6791 2 14.504V2.49336Z"
                            fill="#2DC3E8"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_14375_6193">
                            <rect
                                width="16"
                                height="16"
                                fill="white"
                                transform="translate(0 0.5)"
                            />
                        </clipPath>
                    </defs>
                </svg>)}
            </button>
        </div>
    );
}

export default ExportSubRevenue;  