import React, { useState, useEffect, useRef, useContext } from "react";
import DialogContent from "@mui/material/DialogContent";
import { MdDriveFileMove } from "react-icons/md";
import IconButton from "@mui/material/IconButton";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import { Button } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useGetOne, useCreate, useNotify, useRefresh } from "react-admin";
import { useSelector } from "react-redux";
import { RootState } from "../../../Store/store";
import SyncIcon from "@mui/icons-material/Sync";
import InputSelectField from "../../common/InputComponents/InputSelectField";
import { JSONTree } from "react-json-tree";
import { MoveDocFileContext } from "../../Ats/ApplicantDetailEdit";
import { ApplicantContext } from "..";
import { FormatShapes, Refresh } from "@mui/icons-material";
import { method } from "lodash";

interface OptionType {
  label: string;
  value: string;
  checkValue:string
}

const MoveButton = () => {
  //{ open, handleClose, doc_id }
  const { moveFileState, setMoveFileState, } = useContext(MoveDocFileContext);
  console.log("moveFileState",moveFileState,sessionStorage.getItem('selectedDocumentId'))
  const source_index = moveFileState?.source_index;
  const source_name = moveFileState?.source_name;
  const [filteredOptions, setFilteredOptions] = useState<OptionType[]>([]);
  const [licenseOptions, setLicenseOptions] = useState([
    { label: "Front ID", value: "2" },
    { label: "Back ID", value: "13" },
  ]);


  const { id, appType } = useParams();
  // const trn_id = useSelector((state: RootState) => state.users.userTrnId);
  const notify = useNotify();
  const refresh = useRefresh();
  const formRef = useRef<HTMLFormElement>(null);

  const {
    data: sectionData,
    isLoading: loading,
    refetch,
  } = useGetOne(
    "getsectionavailable",
    {
      id: moveFileState?.applicantID,
    },
    { enabled: !!moveFileState?.applicantID }
  );
  const location = useLocation();
  const params = useParams();
  const getlocalStorageItem: any = localStorage.getItem(
    "stateRecord" + params?.applicationId
  );
  let locationState = JSON.parse(getlocalStorageItem);
  const data = location.state || locationState;
  console.log("Data",data)
  console.log("sectionData",sectionData)
  const [isLoadingMove, setIsLoading] = useState(false);

  const handleCloseMovedDocs = () => {
    setMoveFileState({ isOpen: false });
  };

  const validationSchema = Yup.object().shape({
    
    section: Yup.object()
      .required("Section is required")
      .typeError("Required!")
      .test(
        "fileCountValidation",
        "File limit has been exceeded.",
        function (value: any) {
          console.log("value 3",value)
          const section = value?.value;
          const fileCount = value?.rest?.[0]?.file_count;
          const idType = value?.rest?.[0]?.document_type;
          const IdentificationDocType = value?.rest?.[0]?.IdentificationDocType;
          const backFileCount = value?.rest?.[0]?.back_file_count;
          // if(section == "paystub"){
          //  var fileCountPaystub =value?.rest.filter((count)=>count.file_count == 0);
          //  console.log("fileCountForSource",fileCountPaystub)
          // }

          // if(section == "bank"){
          //   var fileCountBank =value?.rest.filter((count)=>count.file_count == 0);
          //   console.log("fileCountForSource",fileCountBank)
          //  }

          //  if(section == "other_income"){
          //   var fileCountOtherIncome =value?.rest.filter((count)=>count.file_count == 0);
          //   console.log("fileCountForSource",fileCountOtherIncome)
          //  }

          console.log("idType",idType)
          console.log("fileCount",fileCount)
          console.log("backFileCount",backFileCount)
          console.log("section",section)

          if (section === "id") {
            if (!value?.rest) return false;
            if (idType == "2" && fileCount == 1 && IdentificationDocType != "License") return false;
            if (idType == "2" && fileCount == 1 && backFileCount == 1)
              return false;
          } else if (section === "ssn") {
            // if (!value?.rest) return false;
            if (fileCount >= 1) return false;
          } else if (section === "paystub") {
            if (!value?.rest) return false;
           //if (fileCountPaystub == 0) return false;
          } else if (section === "bank") {
            if (!value?.rest) return false;
         // if (fileCountBank == 0) return false;
          } else if (section === "other_income") {
            if (!value?.rest) return false;
           //if (fileCountOtherIncome == 0) return false;
          }
          return true;
        }
      ),
    source: Yup.object()

      .when("section", {
        is: (value: any) => {
          const section = value?.value;
          const isTargetSection = (section === "paystub" || section === "other_income" || section === "bank");
         
          return isTargetSection && value?.rest?.length >= 1;
        },
        then: Yup.object()
          .required("Required!")
          .typeError("Required!!")
          .test(
            "The document can't be moved as there is no source or the file limit has been exceeded.",
            function (value: any) {
              if (value?.fileCount > 9) {
                return false;
              }

              return true;
            }
          ),
      })
      .nullable(),

      // license: Yup.object()
      // .when("idType", {
      //   is: (value: any) => value === "0", // License
      //   then: Yup.object()
      //     .required("License is required!")
      //     .test(
      //       "document-not-able-to-move",
      //       "The document can't be moved as the file limit has been exceeded.",
      //       function (value, obj) {
      //         const backFileCount = obj?.parent?.section?.rest?.[0]?.back_file_count;
      //         const Ffilecount = obj?.parent?.section?.rest?.[0]?.file_count;
      //         if (Ffilecount == 1 && value?.value == "2") return false;
      //         if (backFileCount == 1 && value?.value == "13") return false;
      //         if (Ffilecount === 1 && backFileCount === 1) {
      //           return false;
      //         }
      //         return true;
      //       }
      //     ),
      // })
      // .nullable(),
  //     license: Yup.object()
      
  // .nullable()
  // .when(["section", "idType"], {
  //   is: (section: any, idType: any) => section === "id" && idType?.value === "0",
  //   then: Yup.object()
  //     .required("Required!")
    
  //     .test(
  //       "document-not-able-to-move",
  //       "The document can't be moved as the file limit has been exceeded.",
  //       function (value, context) {
  //         const backFileCount =
  //           context?.parent?.section?.rest?.[0]?.back_file_count;
  //         const Ffilecount = context?.parent?.section?.rest?.[0]?.file_count;

  //         if (Ffilecount === 1 && value?.value === "2") return false;
  //         if (backFileCount === 1 && value?.value === "13") return false;
  //         if (Ffilecount === 1 && backFileCount === 1) {
  //           return false;
  //         }

  //         return true;
  //       }
  //     ),
  //   otherwise: Yup.object().nullable(),
  
  // }),

    // idType: Yup.object()
    //   .when("section", {
    //     is: (value: any) => value?.value == "id",
    //     then: Yup.object()
    //       .test(
    //         "Required when ID type is License",
    //         "ID type is required when section is ID",
    //         function (value, obj) {
    //           if (obj?.parent?.idType?.value === "0" && !value) {
    //             return false;
    //           }
    //           return true;
    //         }
    //       )
    //   })
    //   .nullable(),
      
    license: Yup.object().when("idType", {
      is: (idType) => (idType || {}).value === "0",
      then: Yup.object().required("Required!"),
      otherwise: Yup.object().nullable()
   
        .test(
          "document-not-able-to-move",
          "The document can't be moved as the file limit has been exceeded.",
          function (value, obj) {
            const backFileCount =
              obj?.parent?.section?.rest?.[0]?.back_file_count;
            const Ffilecount = obj?.parent?.section?.rest?.[0]?.file_count;
            if (Ffilecount == 1 && value?.value == "2") return false;
            if (backFileCount == 1 && value?.value == "13") return false;
            if (Ffilecount === 1 && backFileCount === 1) {
              return false;
            }

            return true;
          }
        )
    }),
    
      
    idType: Yup.object().when("section", {
      is: (value: any) => value?.value === "id" ,
      then: Yup.object().shape({
        value: Yup.string().required("Required!"),
      }).required("Required!").nullable(),
      otherwise: Yup.object().nullable(),
    }),
    
      // idType: Yup.object().nullable().transform((value, originalValue) =>
      //   originalValue === '' ? null : value
      // ).required('Required!'),
    
  });

  const form = useForm<any>({
    defaultValues: {
      section: "",
      source: "",
      idType: "",
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });
  
  const { watch, setValue } = form;
  const [create, result] = useCreate();

  // const handleDoc = async (data) => {
  //   let IdType =
  //     data?.idType?.value == 0 ? data?.license?.value : data?.idType?.value;
  //   //const docType = data?.section?.docType;
  //   const section = form.getValues("section");
  //   const source_id = data?.source?.value ;
  //   const docType = section.docType;
  const handleDoc = async (data) => {
    console.log("move 3",data)
    // let IdType =
    //   data?.idType?.value == 0 ? data?.license?.value : data?.idType?.value;
    let IdType =  data?.idType?.label == "License" ? data?.license?.value : data?.idType?.value
    const section = form.getValues("section");
    let source_id;
   
    if (section.value === "id" || section.value === "other" || section.value === "ssn") {
      source_id = data?.section?.rest?.[0]?.source_id;
    } else {
      source_id = data?.source?.value;
    }
    const docType = section.docType;

    create(
      "automovedocument",
      {
        data: {
          trn_id: moveFileState?.trnID,
          document_id: IdType ? IdType : docType,
          //source_id: data?.section?.rest?.[0]?.source_id,
          source_id: source_id,

          allowOfferLetter: false,
          application_document_id: moveFileState?.docId,
        },
      },
      {
        onSuccess: (data) => {
          notify(`Document moved successfully.`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          refresh();
          form.reset();
          handleCloseMovedDocs();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, {
            type: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          }),
      }
    );
  };
  const transformLabel = (val) => {
    switch (val) {
      case "id":
        return "ID";
      case "ssn":
        return "Social Security Number";
      case "bank":
        return "Bank Statements";
      case "other_income":
        return "Proof of Other Income";
      case "other":
        return "Other Documents";
      case "paystub":
        return "Employment Income";
      default:
        return val; // Default to val if no transformation needed
    }
  };

  const options: OptionType[] = [
    { label: "License", value: "2",checkValue:"License" },
    { label: "Passport", value: "1",checkValue:"Passport" },
    { label: "Visa", value: "11",checkValue:"Visa" },
    { label: "Perm ID", value: "10",checkValue:"Permanant Residence ID" },
  ];

  useEffect(() => {
    if (sectionData?.id && !loading) {
      // const filtered = options.filter(opt =>
      //   sectionData.id.some(data => data.IdentificationDocType == opt.checkValue)
      // );

      const filteredOptions = options.filter(opt => opt.value == sessionStorage.getItem('selectedDocumentId'));

       console.log("sectionData",sectionData.id)
       setFilteredOptions(filteredOptions);

      const idData = sectionData.id[0];
      if (idData.file_count == 0 && idData.back_file_count == 0) {
     // console.log("filtered 2",filtered)
        setLicenseOptions([{ label: "Front ID", value: "2" },{ label: "Back ID", value: "13" }])
      } else if (idData.back_file_count == 1 && idData.file_count == 0) {
      //console.log("filtered 3",filtered)
        setLicenseOptions([{ label: "Front ID", value: "2" }]);
      }else if(idData.back_file_count == 0 && idData.file_count == 1){
       setLicenseOptions([{ label: "Back ID", value: "13" }]);
      }
    }
  }, [sectionData, loading]);
  
  const transformedOptions = Object.keys(sectionData || {}).map((val) => {

    // Conditionally include "ssn" based on appType
    if (appType == "2" && val === "ssn" && !data.appInDAReviewQueue) {
      return null; // Skip "ssn" option if appType is 2
    }


    return {
      label: transformLabel(val),
      value: val,
      rest: sectionData[val],
      docType:
        val === "id"
          ? 2
          : val === "ssn"
            ? 4
            : val === "bank"
              ? 6
              : val === "other_income"
                ? 7
                : val === "other"
                  ? 5
                  : val === "paystub"
                    ? 3
                    : 2,
    };
  })
  // .filter(option => {

  //   return option !== null && option.rest !== null;
  // }

  // ); 
  .filter((option) => option && option !== null && option.rest !== null && option.rest !== undefined );


  return (
    
    <>
    
      <Dialog
        open={moveFileState.isOpen}
        onClose={handleCloseMovedDocs}
        fullWidth
        sx={{
          "& .MuiDialog-container.MuiDialog-scrollPaper .MuiPaper-root": {
            maxWidth: 700,
            maxHeight: "80vh",
            height: "80vh",

          },
        }}


      >
        <DialogTitle
          sx={{ m: 0, p: 1, backgroundColor: "Gainsboro" }}
          id="customized-dialog-title"
        >
          Move Document
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleCloseMovedDocs();
            form.reset();
          }}
          sx={{
            position: "absolute",
            right: 6,
            top: 1,
            color: (theme) => theme.palette.grey[700],
          }}
        >
            {/* <span style={{fontSize:"12px"}}>Dismiss</span> */}
          <CloseIcon style={{ fontSize: "12px" }} />
        </IconButton>
        <DialogContent>
          <FormProvider {...form}>
            <form onSubmit={form?.handleSubmit(handleDoc)}>
              <Box sx={{ width: "100%" }} component="span" mt={1}>
                <Grid
                  mt={1}
                  px={2}
                  container
                  spacing={2}
                  className="grid_padding"
                >
                 {/* <JSONTree data={form.formState.errors}/> */}
                  <Grid item xs={12} px={1}>
                    <InputSelectField
                      label="Select section"
                      source="section"
                      options={
                        transformedOptions

                      }
                      labelAsteriskClass={"color-red"}
                      labelClass={"lable-title"}
                      requiredClass={{ fontSize: "70%" }}
                    />
                  </Grid>

                  {sectionData != null &&
                    watch("section")?.value != "id" && watch("section")?.value != "other" && watch("section")?.value != "ssn" &&
                    Object?.keys(sectionData).length > 0 &&
                    sectionData[watch("section")?.value] && (
                      <>
                       {/* <JSONTree data={form.formState.errors}/> */}
                        <Grid item xs={12} px={1}>
                          <InputSelectField
                            label="Select source"
                            source="source"
                            options={

                              sectionData[watch("section")?.value]
                                .map((item, index) => ({
                                  index: index,
                                  item: item,
                                  source_name: watch("section").value,
                                }))
                                .filter((indexedItem) => {

                                  return indexedItem?.source_name === source_name ? indexedItem.index !== source_index : true
                                })
                                .map((filteredItem) => ({
                                  label: `Source ${filteredItem.index + 1}`,
                                  value: filteredItem.item.source_id,
                                  fileCount: filteredItem.item.file_count,
                                }))
                            }

                            labelAsteriskClass={"color-red"}
                            labelClass={"lable-title"}
                            requiredClass={{ fontSize: "70%" }}
                          />

                        </Grid>
                      </>
                    )}
                  {/* <JSONTree data={form.formState.errors}/> */}

                  {watch("section")?.value === "id" && (
                    <Grid item xs={12} px={1}>
                      <InputSelectField
                        label="Select IDs"
                        source="idType"
                        options={filteredOptions}
                        labelAsteriskClass={"color-red"}
                        labelClass={"lable-title"}
                        requiredClass={{ fontSize: "70%" }}
                      />
                    </Grid>
                  )}

                  {watch("section")?.value === "id" &&
                    watch("idType")?.value == "2" &&
                    sectionData != null &&
                    Object?.keys(sectionData).length > 0 && (
                      <Grid item xs={12} px={1}>
                        <InputSelectField
                          label="License"
                          source="license"
                          options={licenseOptions}
                          labelAsteriskClass={"color-red"}
                          labelClass={"lable-title"}
                          requiredClass={{ fontSize: "70%" }}
                        />
                      </Grid>
                    )}
                </Grid>
              </Box>
              <Box display={"flex"} marginLeft={"435px"}>
                <Button
                  startIcon={<MdDriveFileMove />}
                  sx={{ mx: 1, my: 5 }}
                  variant="contained"
                  type="submit"
                  disabled={result.isLoading}
                >
                  {result.isLoading ? "Moving..." : "Move"}
                 
                </Button>
               
                <Button
                  sx={{ mx: 1, my: 5 }}
                  onClick={() => {
                    handleCloseMovedDocs();
                    form.reset();
                  }}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>
              </Box>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MoveButton;
