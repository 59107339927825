import React from "react";
import { useFormContext, useController } from "react-hook-form";

const InputCheckBoxField = ({
  name,
  notRequired,
  disabled = false,
  checked = false,
  label = "",
  handleOnChange = () => {},
}) => {
  // const { name } = register;
  const required = notRequired === undefined ? true : !notRequired;
  const { control } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  // 

  return (
    <>
      <div className="form-check print-hide">
        <input
          disabled={disabled || false}
          type="checkbox"
          className={`form-check-input ${
            error !== undefined || (required && !field.value && "is-invalid")
          }`}
          {...field}
          id={name}
          onChange={(e) => {
            handleOnChange();
            field.onChange(e);
          }}
          checked={field.value}
        />
        <label
          htmlFor={name}
          style={{ fontSize: 12 }}
          className={`form-check-label ${
            error !== undefined || (required && !field.value && "text-danger")
          }`}
        >
          {label}
        </label>
        <div className="invalid-feedback">
          {error !== undefined && error.message}
        </div>
      </div>
    </>
  );
};

export default InputCheckBoxField;
