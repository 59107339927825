import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { useNotify } from "react-admin";
import copyTextToClipboard from "../../../Functions/copytoclipboard";
import { REACT_APPLICANT } from "../../../config";

const CopyLinkHoverDropdownUserSetup = ({ record, ...props }) => {
  console.log(record,"copy url")
    const [showMenu, setShowMenu] = useState(false);
  const [dropDownData, setDropDownData] = useState([
    {
      id: 1,
      name: "Application URL",
    },
    {
      id: 2,
      name: "Doc Upload URL",
    },
    {
      id: 3,
      name: "Guest Card URL",
    },
   
  ]);

  const handleMouseOver = () => {
    setShowMenu(true);
  };

  const handleMouseOut = () => {
    setShowMenu(false);
  };

  const notify = useNotify();
  const notifyUser = async (funcRes, url) => {
 
    if (await funcRes(url))
      notify(`Url copied to clipboard!`, {
        type: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    else
      notify(`Url fail copied to clipboard!`, {
        type: "warning",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
  };
  // const handleClick = () => {
  //   if (record?.URL) {
  //     notifyUser(copyTextToClipboard,record.URL);
  //   } else {
  //     notify(`No URL available to copy.`, {
  //       type: "warning",
  //       anchorOrigin: { vertical: "top", horizontal: "right" },
  //     });
  //   }
  // };
  // Filter data depending on conditions.
  useEffect(() => {
    const newDropDownData = dropDownData
      .filter((item) => {
        if (item?.id === 1) {
          return true;
        }
        if (
          // record?.SubscriberDocUpload && 
          item.id === 2) {
          return true;
        }
        if (
          // record?.SubscriberGuestCard  &&
           item?.id === 3
          ) {
          return true;
        }
        return false;
      })
      .map((item) => ({ ...item }));

    setDropDownData(newDropDownData);
  }, []);

  return (
    <div
      className="btn-group dropdown_dropup"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {dropDownData?.length > 0 ? (
        <IconButton
        // onClick={handleClick}
          aria-label="copy"
          color="primary"
          className={`show_on_hover`}
          sx={{
            py: 0,
          }}
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_14375_6178)">
              <path
                d="M18.364 15.6573L16.95 14.2413L18.364 12.8273C18.8316 12.3638 19.2031 11.8124 19.4571 11.2049C19.7111 10.5974 19.8425 9.94574 19.844 9.28729C19.8454 8.62884 19.7168 7.97659 19.4655 7.36798C19.2142 6.75938 18.8451 6.2064 18.3795 5.74081C17.9139 5.27521 17.361 4.90616 16.7524 4.65485C16.1437 4.40354 15.4915 4.27491 14.833 4.27635C14.1746 4.2778 13.5229 4.40928 12.9154 4.66325C12.3079 4.91723 11.7566 5.2887 11.293 5.75633L9.87901 7.17133L8.46401 5.75733L9.88001 4.34333C11.1928 3.0305 12.9734 2.29297 14.83 2.29297C16.6866 2.29297 18.4672 3.0305 19.78 4.34333C21.0928 5.65615 21.8304 7.43672 21.8304 9.29333C21.8304 11.1499 21.0928 12.9305 19.78 14.2433L18.365 15.6573H18.364ZM15.536 18.4853L14.121 19.8993C12.8082 21.2121 11.0276 21.9497 9.17101 21.9497C7.3144 21.9497 5.53383 21.2121 4.22101 19.8993C2.90819 18.5865 2.17065 16.8059 2.17065 14.9493C2.17065 13.0927 2.90819 11.3121 4.22101 9.99933L5.63601 8.58533L7.05001 10.0013L5.63601 11.4153C5.16838 11.8789 4.79692 12.4302 4.54294 13.0377C4.28896 13.6452 4.15748 14.2969 4.15604 14.9554C4.1546 15.6138 4.28323 16.2661 4.53454 16.8747C4.78585 17.4833 5.1549 18.0362 5.62049 18.5018C6.08609 18.9674 6.63906 19.3365 7.24767 19.5878C7.85628 19.8391 8.50853 19.9677 9.16698 19.9663C9.82543 19.9649 10.4771 19.8334 11.0846 19.5794C11.6921 19.3254 12.2435 18.954 12.707 18.4863L14.121 17.0723L15.536 18.4863V18.4853ZM14.828 7.87833L16.243 9.29333L9.17201 16.3633L7.75701 14.9493L14.828 7.87933V7.87833Z"
                fill="#2DC3E8"
              />
            </g>
            <defs>
              <clipPath id="clip0_14375_6178">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0 0.121094)"
                />
              </clipPath>
            </defs>
          </svg>
        </IconButton>
      ) : (
        <></>
      )}
      <ul
        className={`dropdown-menu ${showMenu ? "show" : ""}`}
        style={{
          position: "absolute",
          inset: "auto auto 0px 0px",
          margin: "0px",
          transform: "translate3d(-50px, -38.6667px, 0px)",
        }}
      >
        {dropDownData?.map((item) => (
          <li
            onClick={(event) => {
              let url;
              if (item?.id === 1) {
                url = record?.URL;
              }
              if (item?.id === 2) {
                url = record?.DocuploadURL;
                // record?.linkType === "unCompleted" ? `${window.location.protocol}//${window.location.host}/atss/cp/` + record?.DocUploadURL : record?.DocUploadURL;
              }
              if (item?.id === 3) {
                url = record?.GuestCardURL;
                // record?.linkType === "unCompleted" ? `${window.location.protocol}//${window.location.host}/atss/cp/` + record?.GuestCardURL : record?.DocUploadURL;
              }
              notifyUser(copyTextToClipboard, url);
            }}
            key={item?.id}
          >
            <span className="dropdown-item" style={{ cursor: "pointer" }} 
            //  onClick={(event) => {
            //   let url;
            //   if (item?.id === 1) {
            //     url = record?.URL;
            //   }
           
            //   notifyUser(copyTextToClipboard, url);
            // }}
            >
              {item?.name}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CopyLinkHoverDropdownUserSetup;
