import {
  Datagrid,
  List,
  WrapperField,
  NumberField,
  ImageField,
  DeleteWithConfirmButton,
  useGetList,
  ListContextProvider,
  TextField,
  useList,
  useListContext,
} from "react-admin";
import moment from "moment";
import { FunctionField } from "react-admin";
import { RaDatagrid_headerCell } from "../common/inlineStyle";
import { Grid, Stack } from "@mui/material";
import { JSONTree } from "react-json-tree";
import CustomUndoButton from "./CustomUndoButton";
import { useState } from "react";
import { RootState } from "../../Store/store";
import { useSelector } from "react-redux";
import { Button, Toolbar } from "@mui/material";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
const EmptyList = () => {
  return (
    <>
      <small className="text-muted">
        <i>No Document has been deleted</i>
      </small>
    </>
  );
};

const PostPagination = () => {
  const { page, hasPreviousPage, hasNextPage, setPage } = useListContext();
  if (!hasPreviousPage && !hasNextPage) return null;
  return (
    <>
 
    <>
      {hasPreviousPage && (
        <Button
          key="previous"
          onClick={() => setPage(page - 1)}
          startIcon={<ChevronLeft />}
        >
          {" "}
          Previous
        </Button>
      )}
      {hasNextPage && (
        <Button
          key="next"
          onClick={() => setPage(page + 1)}
          startIcon={<ChevronRight />}
        >
          {" "}
          Next
        </Button>
      )}
    </>
    </>
  );
};
export const GetdeleteddocumentlistList = ({ trn_id }) => {
  // const [filter, setFilter] = useState('');
  // const [page, setPage] = useState(1);
  // const perPage = 10;
  // const sort = { field: 'id', order: 'ASC' };
  // const  { data, total, isLoading } = useGetList('deletedDocument1', {
  //     filter: { trn_id },
  //     pagination: { page, perPage },
  //     sort,
  // });

  // const listContext = useList({ data, isLoading });
  // if (isLoading) {
  //     return <div>Loading...</div>;
  // }
  const userRole = useSelector((state: RootState) => state.users.role);
  const trn_id1 = useSelector((state: RootState) => state.users.userTrnId);
  return (
    // <ListContextProvider value={listContext}>
    <List
      perPage={5}
      pagination={<PostPagination/>}
      empty={<EmptyList />}
      filter={{ trn_id: trn_id1 }}
      exporter={false}
      disableSyncWithLocation
    >
      <div
        style={{ marginTop: "-50px", position: "relative", overflowX: "auto" }}
      >
        <Datagrid bulkActionButtons={false} sx={RaDatagrid_headerCell}>
          <NumberField sortable={false} source="id" />
          <ImageField
            sortable={false}
            sx={{
              "& img": { maxWidth: 90, maxHeight: 90, objectFit: "contain" },
            }}
            source="BlobPathfile"
            title="File preview"
            label="File Thumbnail"
          />
          <FunctionField
            label={"Document Type"}
            render={(record) => {
              if (record.DocumentTypeID == 2) return "DL Front";
              if (record.DocumentTypeID == 13) return "DL Back Barcode";
              if (record.DocumentTypeID == 1) return "Passport";
              if (record.DocumentTypeID == 10) return "PRID";
              if (record.DocumentTypeID == 11) return "Visa";
              if (record.DocumentTypeID == 6) return "Bank";
              if (record.DocumentTypeID == 3) return "Income";
              if (record.DocumentTypeID == 7) return "Other Income";
              if (record.DocumentTypeID == 5) return "Other Docs";
              if (record.DocumentTypeID == 4) return "SSN";
              if (record.DocumentTypeID == 14) return "Pet";

              return "NA";
            }}
          />
          <FunctionField
            label="Source Number"
            render={(record) => {
              if (record.OtherIncomeNumber) {
                return record.OtherIncomeNumber;
              }
              if (record.EmployerNumber) {
                return record.EmployerNumber;
              }
              if (record.BankStatementNumber) {
                return record.BankStatementNumber;
              }
              return "NA";
            }}
          />
          <FunctionField
            label="Date Uploaded"
            render={(record) => {
              if (record.CreatedOn !== undefined && record.CreatedOn !== null) {
                return moment
                  .tz(record.CreatedOn, "America/New_York")
                  .format("YYYY-MM-DD h:mm z");
              }
              return "NA";
            }}
          />

          <FunctionField
            label="Date Deleted"
            render={(record) => {
              if (
                record.ModifiedOn !== undefined &&
                record.ModifiedOn !== null
              ) {
                return moment
                  .tz(record.ModifiedOn, "America/New_York")
                  .format("YYYY-MM-DD h:mm z");
              }
              return "NA";
            }}
          />
          <WrapperField label="Action">
            <FunctionField
              label="File Name"
              render={(record) => {
                return (
                  <Stack direction="row" spacing={1}>
                    <CustomUndoButton
                      record={record}
                      resource="deletedDocument"
                      title={"Restore document"}
                    />
                    {userRole !== "Docuverus Super Admin" && (
                      <DeleteWithConfirmButton
                        redirect={false}
                        confirmTitle="Permanent Delete"
                        confirmContent="You will not be able to recover this record. Are you sure?"
                      />
                    )}
                  </Stack>
                );
              }}
            />
          </WrapperField>
        </Datagrid>
      </div>
    </List>
    // </ListContextProvider>
  );
};
