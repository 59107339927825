import React from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Box, Button, InputAdornment } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  TextInput,
  required,
  useUpdate,
  useNotify,
  useRefresh,
  useGetOne,
} from "react-admin";
import { useForm, FormProvider, get } from "react-hook-form";
import { Button as MuiButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import { JSONTree } from "react-json-tree";
import InputField2 from "../common/InputComponents/InputField2";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../common/loadingSpinner";

export default function ArchiveAplicant(props) {
  const notify = useNotify();
  const refresh = useRefresh();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { id } = useParams();
  const defaultFun=()=>{}
  // call get Ip address data
  const {data: getIPAddress} = useGetOne("getipaddress",{id : 1}, {
    enabled: open,
  });
  const ipAddress = getIPAddress?.IPv4;

  // form validation schema 
  const validationSchema = Yup.lazy((value) =>
  Yup.object().shape({
    Reason: Yup.string().trim("Reason is required!").required("Reason is required!").max(5000, "maximum 5000 characters is required"),
    })
  );

  // form initial states
  const form = useForm({
    defaultValues: {
      Reason: "",
      SubscriberID: id || props?.subsciberId,
      ApplicationNumber: props.record.AppID,
      IP: ipAddress,
      role:props?.record?.ApplicantType
    },
    resolver: yupResolver(validationSchema),
  });

  // Destructuring form
  const { register, reset } = form;

  const [update, { data, isLoading, error }] = useUpdate();
  // form submit handler
  const onSubmit = (value) => {
    update(
      "removeapplicant",
      { id: props.record.id, data: value },
      {
        onSuccess: (d) => {
          notify(`Application Number ${d.ID} has been Removed`, {
            type: "success",
            anchorOrigin: { vertical: 'top', horizontal: 'right' }
          });
          refresh();
          //  props.onRemove();
          handleClose();
          reset();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }}),
      }
    );
  };

  return (
    <>
      {props?.buttonRemove ? (
        <li key={props?.record.id} onClick={handleOpen}>
          {props?.record?.FirstName} {props?.record?.LastName}
        </li>
      ) : (
        <MuiButton
          color="error"
          onClick={handleOpen}
          startIcon={<DeleteIcon />}
          variant="text"
          sx={{textTransform:"capitalize"}}
        >
          Remove
        </MuiButton>
      )}
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle color="error" id="alert-dialog-title">
          <InfoIcon /> Are you sure?
        </DialogTitle>
        <DialogContent>
          <p>Are you sure you want to remove the applicant?</p>

          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="row">
                  <div className="col-12 mr-b20 mt-3">
                  <InputField2
                      label="Reason"
                      source={`Reason`}
                      placeholder="Enter Reason for Removing Application"
                      labelClass={"lable-title"}
                      additionalInputClass={"cus-control"}
                    />
                  </div>
              </div>
              <Box display={"flex"} justifyContent={"end"}>
                <Button
                  sx={{ mx: 1 }}
                  onClick={() => {handleClose(); reset();}}
                  variant="outlined"
                  color="secondary"
                  type="button"
                >
                  Cancel
                </Button>

                <Button
                  startIcon={<ArchiveIcon />}
                  sx={{ mx: 1 }}
                  variant="contained"
                  type="submit"
                >
                  Remove
                </Button>

              </Box>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
      {isLoading ? <LoadingSpinner /> : ""}
    </>
  );
}
