import * as React from "react";
import {
  useListContext,
  useGetIdentity,
  usePermissions,
} from "react-admin";
import { useForm } from "react-hook-form";
import moment from "moment";
import { checkModuleAccess, isSuperAdmin } from "../../../Functions/permissions";

export default function MainFilter(props) {
  const { data: identity } = useGetIdentity();
  const {permissions} =usePermissions();
   const { displayedFilters, filterValues, setFilters, hideFilter,resource } =
    useListContext();
    
  const form = useForm({
    defaultValues: filterValues,
  });

  // if (!displayedFilters.main) return null;
  const applyFilter = (values) => {
    if (Object.keys(values).length > 0) {
      const filter = {
        ...values,
        end_date: values.end_date
          ? moment(values.end_date).format("MM/DD/yyyy")
          : "",
        start_date: values.start_date
          ? moment(values.start_date).format("MM/DD/yyyy")
          : "",
      };
      setFilters(filter, displayedFilters);
    }
  };

  return (
    <>
      <div className="app-option-btn" id="a-oprtions">
        {props.applicationDetail?.ApplicationType?.ATS && isSuperAdmin(checkModuleAccess(permissions,"Inbox",""),identity?.userrole)&&(
          <label htmlFor="c1">
            <input
              type="radio"
              onChange={() => {
                form.setValue("app_invitation_type", "1");
                applyFilter({...filterValues, ...form.getValues(),});
                // unselectAll();
              }}
              checked={
                !filterValues.app_invitation_type ||
                filterValues.app_invitation_type == 1
              }
              name="a-oprtions"
              id="c1"
            />
            <span>Application</span>
          </label>
        )}
        {props.applicationDetail?.ApplicationType?.DocUpload && isSuperAdmin(checkModuleAccess(permissions,"Doc Upload App",""),identity?.userrole)&&(
          <label htmlFor="c2">
            <input
              type="radio"
              onChange={() => {
                form.setValue("app_invitation_type", "2");
                applyFilter({...filterValues, ...form.getValues(),});
                // unselectAll();
              }}
              checked={filterValues.app_invitation_type == 2}
              name="a-oprtions"
              id="c2"
            />
            <span>Doc Upload</span>
          </label>
        )}
        {props.applicationDetail?.ApplicationType?.GuestCard && isSuperAdmin(checkModuleAccess(permissions,"Guest Card App",""),identity?.userrole)&& (
          <label htmlFor="c3">
            <input
              type="radio"
              onChange={() => {
                form.setValue("app_invitation_type", "3");
                applyFilter({...filterValues, ...form.getValues(),});
                // unselectAll();
              }}
              checked={filterValues.app_invitation_type == 3}
              name="a-oprtions"
              id="c3"
            />
            <span>Guest Card</span>
          </label>
        )}
      </div>
    </>
  );
}
