import React, { useState, useEffect, useContext } from "react";
import "../../pages/Sidebar/sidebar.css";
import "../../assets/styles/application_det.css";
import "../../assets/styles/style.css";
import { useParams } from "react-router-dom";
import {
  useDataProvider,
  useGetIdentity,
  useGetOne,
  useNotify,
} from "react-admin";
import FilePreviewThumnail from "../common/FilePreviewThumnail";
import DragDropDocumentButton from "./components/DragDropDocumentButton";
import AlertDialog from "../common/AlertDialog";
import FormInstruction from "../common/FormInstruction";
import { JSONTree } from "react-json-tree";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/store";
import FilePreview from "../common/FilePreview";
import CustomPreviewUpload from "../common/CustomPreviewUpload";

const OtherDocuments = ({Trnid=null}) => {
  const [filePreview, setFilePreview] = useState({
    proof_of_income: "",
    proof_of_income_filePath: "",
  });
  const { id } = useParams();
  // fix the co-applicant issue
  const userTrnId = useSelector((state: RootState) => state.users.userTrnId);
  const trn_id=Trnid || userTrnId;
  const userRole = useSelector((state: RootState) => state.users.role);

  const { isLoading, data: otherDocData } = useGetOne(
    "otherDoc",
    { id: trn_id },
    { refetchOnWindowFocus: false, keepPreviousData: true, staleTime: 5 ,enabled:!!trn_id}
  );
  const [expandOtherSection, setExpandOtherSection] = useState(true);
  const { identity, isLoading: identityLoading } = useGetIdentity();
  useEffect(() => {
    if (identity?.super_user_role === "applicant") setExpandOtherSection(true);
  }, [identity?.super_user_role]);
  return (
    <div className="info-toggle" style={{ marginBottom: "40px" }}>
      <div className="toggle-heads d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-between align-items-center">
          <b className="mx-3">Other Documents</b>
          {identity?.userrole != "Applicant" && (
            <p className="prf-upload">Documents uploaded in this section cannot be assessed for authenticity.</p>
          )}
          <div className="subcontent text-center p-1 pb-t10" style={{ marginLeft: "6px" }}>
            <div className="upload-subwrap">
              {identity?.super_user_role == "applicant" && (
                <p className="prf-upload">
                  <strong style={{ color: "#FF8585" }}>Note: </strong>
                  if you want to upload any other document, you can do it here
                </p>
              )}

              {identity?.super_user_role != "applicant" &&
                otherDocData?.other_documents.length == 0 && (
                  <p className="prf-upload">
                    <strong style={{ color: "#FF8585" }}>Note: </strong>
                    No documents uploaded
                  </p>
                )}
            </div>
          </div>
        </div>
        {identity?.super_user_role !== "applicant" && (
          <IconButton
            className="m-1 print-hide"
            onClick={() => setExpandOtherSection((v) => !v)}
            color="primary"
          >
            {!expandOtherSection ? <AddIcon /> : <RemoveIcon />}
          </IconButton>
        )}
      </div>
      <div
        className={`${
          !expandOtherSection ? "d-none" : "d-display"
        } row mb-2  sb-blue m-3`}
      >
        <div
          className={
            filePreview.proof_of_income !== ""
              ? "col-md-8 col-sm-12 "
              : "col-md-12 col-sm-12 "
          }
        >
          <div className="sb-blue mx-3 m-1">
            <div className="blue-flex">
              <div className="row align-items-center px-2 print-hide">
                <div
                  className="col-sm-12 col-md-10"
                  style={{
                    fontSize: "11px",
                    color: "#606060",
                    paddingLeft: "16px",
                  }}
                >
                  <div className="d-flex align-items-center ">
                    <strong>{`${1}. ${"Other Documents:"}`} </strong>
                    <AlertDialog
                      message={`If necessary, you can upload any other documents here, including tax documents, proof of SSN, etc.  With the exception of SSN cards, please ensure that documents are PDF’s downloaded from the source.`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row"> </div>
            <div className="col-md-12 col-sm-12 row mt-2">
              <div className="d-flex flex-wrap">
                <div className="">
                  <DragDropDocumentButton
                    SourceId={1}
                    documentId={5}
                    fetchRecord={() => {}}
                    acceptedFiles={`image/*,application/pdf`}
                    invalidateQueries={"otherDoc"}
                    tag={`fileOprations_other_income`}
                    trn_id={trn_id}
                    fileError={false}
                  />
                </div>
                {/* <div className="d-flex"> */}
                  {otherDocData?.other_documents.map((doc) => (
                    <div 
                    // key={doc.application_document_id} 
                    className="px-1"
                    >
                      <FilePreview
                      
                        trn_id={trn_id}
                        doc_id={doc?.application_document_id}
                        img={`${doc?.path_image}`}
                        tag={"delete_document_other_document"}
                        is_Duplicate={doc?.is_Duplicate}
                        invalidateQueries={"otherDocData"}
                        file={doc.path}
                        handleClick={() =>
                          setFilePreview({
                            ...filePreview,
                            proof_of_income: doc.application_document_id,
                            proof_of_income_filePath: doc.path,
                          })
                        }
                        handleOnChange={() => {
                          const isCurrentFile =
                            filePreview.proof_of_income_filePath ==
                            doc?.path
                              ? true
                              : false;
                          // const isCurrentIndex =
                          //   filePreview.index == index ? true : false;

                          if (isCurrentFile) {
                            setFilePreview({
                              proof_of_income: "",
                              proof_of_income_filePath: "",

                              // index: null,
                            });
                          }
                        }}
                      />
                    </div>
                  ))}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        {filePreview.proof_of_income !== "" && (
          <div className="col-md-4 col-sm-12 mt-2 ">
            <CustomPreviewUpload
              filePath={filePreview.proof_of_income_filePath}
            />
          </div>
        )}
      </div>
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default OtherDocuments;
