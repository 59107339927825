import React from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import { useDataProvider, useNotify, Confirm } from "react-admin";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export default function DeleteUnsaveRecords({ removeFun }) {
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  return (
    <>
      <div className="print-hide" style={{ textAlign: "center" }}>
        <a
          type="button"
          href={"#"}
          onClick={(e) => {
            e.preventDefault();
            setConfirmDelete(true);
          }}
          className="btn btn-link"
          style={{ color: "red", textAlign: "center", fontSize: 10 }}
        >
          <DeleteIcon style={{ fontSize: 12 }} /> Delete
        </a>
      </div>
      <Confirm
        isOpen={confirmDelete}
        // loading={isLoading}
        sx={{
          "& .RaConfirm-iconPaddingStyle": { display: "none" },
        }}
        title="Confirm"
        content={"Are you sure you want to delete this document?"}
        onConfirm={() => {
          removeFun();
          setConfirmDelete(false);
        }}
        onClose={() => {
          // setOtherincomeNumber(0);
          setConfirmDelete(false);
        }}
      />
      {/* <Dialog
                open={confirmDelete}
                onClose={() => {
                    setConfirmDelete(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {`Delete Document`}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this document ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button
                        className="btn btn-info text-light"
                        onClick={() => {
                            setConfirmDelete(false);
                        }}
                    >
                        No
                    </button>
                    <button
                        className="btn btn-info text-light"
                        onClick={() => {
                            removeFun()
                        }}
                    >
                        Yes
                    </button>
                </DialogActions>
            </Dialog> */}
    </>
  );
}
