import { useController } from "react-hook-form";
import './input.css';

const TextInput = ({
  label,
  placeholder,
  source,
  type,
  className = "",
  fullWidth = true,
  required = true,
}) => {
  const { field, fieldState } = useController({ name: source });

  return (
    <div className={className}>
      <div className="emp-inputs">
        <label className={`form-label ${fieldState.invalid && "text-danger"} `} htmlFor="">
          {label}
          {required && (<span>&nbsp;&#42;</span>)}
        </label>
        <input
          type={type}
          {...field}
          placeholder={placeholder}
          className={`form-control cus-input-control ${required && !field.value && 'is-warning'} ${fullWidth && 'mw-100'} ${fieldState.invalid && "is-invalid"
            }`}
        />
        <div
          className="invalid-feedback"
        >
          {fieldState.invalid && fieldState?.error?.message}
        </div>
      </div>
    </div>
  );
}

export default TextInput;