import React, { useState } from "react";
import {
  required,
  SimpleForm,
  TextInput,
  Toolbar,
  useNotify,
} from "react-admin";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

function ShowRawData({ title, rawData }) {
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleCopy = async () => {
    
    await navigator.clipboard.writeText(rawData);
    notify("RAW data copied successfully", {anchorOrigin: { vertical: 'top', horizontal: 'right' }});
  };
  return (
    <>
      <div className='print-hide'>
        <div className="wrapper-tooltip new-wrp-tooltip">
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleClick}
          >
            <path
              d="M10.5857 20.363C5.06269 20.363 0.585693 15.886 0.585693 10.363C0.585693 4.84004 5.06269 0.363037 10.5857 0.363037C16.1087 0.363037 20.5857 4.84004 20.5857 10.363C20.5857 15.886 16.1087 20.363 10.5857 20.363ZM10.5857 18.363C12.7074 18.363 14.7423 17.5202 16.2425 16.0199C17.7428 14.5196 18.5857 12.4848 18.5857 10.363C18.5857 8.24131 17.7428 6.20647 16.2425 4.70618C14.7423 3.20589 12.7074 2.36304 10.5857 2.36304C8.46396 2.36304 6.42913 3.20589 4.92884 4.70618C3.42855 6.20647 2.58569 8.24131 2.58569 10.363C2.58569 12.4848 3.42855 14.5196 4.92884 16.0199C6.42913 17.5202 8.46396 18.363 10.5857 18.363ZM9.58569 5.36304H11.5857V7.36304H9.58569V5.36304ZM9.58569 9.36304H11.5857V15.363H9.58569V9.36304Z"
              fill="#F9BB04"
            />
          </svg>
        </div>
      </div>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
        // style={{borderRadius:20}}
        PaperProps={{
          style: { borderRadius: 20 },
        }}
      >
        {/* <DialogTitle> */}
        {/* {title} */}
        <div className="modal-header px-4 pt-4">
          <h5 className="modal-title" id="emailchange">
            {title}
          </h5>
          <button
            type="button"
            className="btn-close"
            // data-bs-dismiss="modal"
            // aria-label="Close"
            onClick={() => handleCloseClick()}
          ></button>
        </div>
        {/* </DialogTitle> */}
        <DialogContent>
          <SimpleForm
            record={{
              rawData: rawData,
            }}
            toolbar={
              <PostShowRawDataToolbar
                onCopy={handleCopy}
                onCancel={handleCloseClick}
              />
            }
          >
            <TextInput
              validate={[required()]}
              //   resettable
              source="rawData"
              label="PDF RAW data"
              fullWidth
              multiline
              //   maxRows={4}
              minRows={3}
              variant="outlined"
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ShowRawData;

function PostShowRawDataToolbar({ onCopy, onCancel, ...props }) {
  return (
    <Toolbar
      {...props}
      style={{
        backgroundColor: "white",
        justifyContent: "flex-end",
      }}
    >
      <CancelButton onClick={onCancel} />
      <CopyButton onClick={onCopy} />
    </Toolbar>
  );
}

function CopyButton(props) {
  return (
    <button className="modal-blue-btn" {...props}>
      <ContentCopyIcon
        style={{
          height: 15,
          width: 15,
        }}
      />
      <span>Copy</span>
    </button>
  );
}

function CancelButton(props) {
  return (
    <button
      type="button"
      className=" cancelbtn"
      {...props}
      // data-bs-dismiss="modal"
    >
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.99999 4.05734L8.29999 0.757339L9.24266 1.70001L5.94266 5.00001L9.24266 8.30001L8.29999 9.24267L4.99999 5.94267L1.69999 9.24267L0.757324 8.30001L4.05732 5.00001L0.757324 1.70001L1.69999 0.757339L4.99999 4.05734Z"
          fill="#2DC3E8"
        />
      </svg>

      <span>Cancel</span>
    </button>
  );
}
