import React, { createContext, useState, ReactNode } from 'react';

// Define the shape of the context state
interface FormsContextType {
  forms: any[];
  updateFormAtIndex: (index: number, formData: any) => void;
}

// Create the context with a default value
const FormsChildContext = createContext<FormsContextType | undefined>(undefined);

// Define the provider props
interface FormsProviderProps {
  children: ReactNode;
}

// Create a provider component
export const FormsChildProvider: React.FC<FormsProviderProps> = ({ children }) => {
  const [forms, setForms] = useState<string[]>([]);

  const updateFormAtIndex = (index: number, formData: any) => {
    // debugger;
    const newForms = forms;
    console.log("🚀 ~ updateFormAtIndex ~ newForms:", newForms)
    newForms[index] = formData;
    console.log("🚀 ~ updateFormAtIndex ~ formData:", formData)
    console.log("🚀 ~ updateFormAtIndex ~ newForms[index]:", newForms[index])
    setForms(newForms);
  };

  return (
    <FormsChildContext.Provider value={{ forms, updateFormAtIndex }}>
      {children}
    </FormsChildContext.Provider>
  );
};  

export default FormsChildContext;
