import React from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Button, IconButton } from "@mui/material";
import { useNotify } from "react-admin";
import copyTextToClipboard from "../../../Functions/copytoclipboard";

const CopyUrls = ({ heading, url }) => {
  const notify = useNotify();
  const notifyUser = async (funcRes, url) => {
    if (await funcRes(url))
      notify(`Url copied to clipboard!`, {
        type: "success",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
    else
      notify(`Url fail copied to clipboard!`, {
        type: "warning",
        anchorOrigin: { vertical: "top", horizontal: "right" },
      });
  };
  return (
    <div className="basic-subinfo px-2">
      <div className="info-block">
        <label htmlFor="">{heading}</label>
      </div>
      <span>
        {url}
        <IconButton
          aria-label="copy"
          color="primary"
          sx={{
            mx: 1,
          }}
          onClick={() => notifyUser(copyTextToClipboard, url)}
        >
          <ContentCopyIcon />
        </IconButton>
      </span>
    </div>
  );
};

export default CopyUrls;
