import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
// import CustomRadioButton from "../../../registration/Inputs/customRadioButton";
// import InputDateField from "../../../registration/Inputs/InputDateField";
// import InputField from "../../../registration/Inputs/InputField";
// import InputTextArea from "../../../registration/Inputs/InputTextArea";
// import Pets from "./pets/pets";
// import { TiDelete } from "react-icons/ti";
// import InputCurrencyField from "../../../registration/Inputs/InputCurrencyField";
import { useDispatch, useSelector } from "react-redux";
import { BsCheckCircleFill } from "react-icons/bs";
import {
  AccordionDetails,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import CustomRadioButton from "../../../common/InputComponents/CustomRadioButton";
// import { sideNavAction } from "../../../../store/identification/sideMenuSlice";
import { CardHeader } from "@mui/material";
import DeleteContainedBtn from "../../../common/DeleteContainedBtn";
import InputField from "../../../common/InputComponents/InputField";
import InputField2 from "../../../common/InputComponents/InputField2";
import InputDateField from "../../../common/InputComponents/InputDateFieldOld";
import InputCurrencyField from "../../components/inputComponents/InputCurrencyFiled";
import InputTextArea from "../../../common/InputComponents/InputTextArea";
import Pets from "./pets/pets";
import { setUploadPetImageId } from "../../../../Store/Slice/applicantDetailsSlice";

const OthersSection = (props) => {
  const dispatch = useDispatch();
  const [removeStudent, setRemoveStudent] = React.useState(false);
  const [removeVehcile, setRemoveVehcile] = React.useState(false);
  const [removePets, setRemovePets] = React.useState(false);
  const { register, watch, control, getValues, setValue, formState } =
    useFormContext();

  const is_student = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "is_student.fields", // unique name for your Field Array
    keyName: "arrId", //default to "id", you can change the key name
  });
  const have_vehicle = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "have_vehicle.fields", // unique name for your Field Array
    keyName: "arrId", //default to "id", you can change the key name
  });

  const checkSourceAvailableHandler = (section) => {
    if (section == "student" && watch("is_student.fields")?.length == 0) {
      setValue("is_student.response", "No");
    }
    if (section == "vehical" && watch("have_vehicle.fields")?.length == 0) {
      setValue("have_vehicle.response", "No");
    }
    if (section == "pet" && watch("have_pet.fields")?.length == 0) {
      setValue("have_pet.response", "No");
    }
  };

  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      // 
      if (type === "change" && name === `is_student.response`) {
        if (getValues(`is_student.response`) === "Yes")
          setValue("is_student.fields", [
            {
              id: 0,
              collegeuniversity_name: "",
              anticipatedgraduation_date: "",
              major: "",
              activities: "",
            },
          ]);
      } else if (type === "change" && name === `have_vehicle.response`) {
        if (getValues(`have_vehicle.response`) === "Yes")
          setValue("have_vehicle.fields", [
            {
              id: 0,
              vehicle_monthlypayment: null,
            },
          ]);
      } else if (type === "change" && name === `have_pet.response`) {
        //contingencies.fields
        if (getValues(`have_pet.response`) === "Yes")
          setValue("have_pet.fields", [
            {
              id: 0,
              pet_type: "",
              pet_breed: "",
              pet_weight: "",
              pet_name: "",
              pet_Spayedneutered: "YES",
              service_animal: false,
              file: "",
            },
          ]);
      }
    });
    return () => subscription.unsubscribe();
  }, [getValues, setValue, watch]);

  return (
    <AccordionDetails>
      <div className="others-body">
        <div className="row">
          {watch("is_student.display") === true && (
            <>
              <div className="col-sm-12">
                <div className="i-a-block mr-b15">
                  <CustomRadioButton
                    onClick={() => {
                      if (watch("is_student.fields")?.length > 0) {
                        setRemoveVehcile(false);
                        setRemovePets(false);
                        setRemoveStudent(true);
                      }
                    }}
                    notRequired={!getValues("is_student.required")}
                    disable={watch("is_student.fields")?.length > 0}
                    label={`${getValues(
                      `is_student.labelIndex`
                    )}. Are you a full time student?`}
                    register={register("is_student.response")}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                  />
                </div>
              </div>
              {watch("is_student.response") === "Yes" && (
                <div className="col-sm-12">
                  <div className="blue-box a-blue-box pd15 mr-b30">
                    {is_student.fields.map((field, index) => {
                      return (
                        <div key={field.arrId} className="row">
                          <div className="col-sm-12 mb-2">
                            {/* <span className="s-no float-left">
                                {index + 1}
                              </span>{" "} */}
                            {/* <button
                              type="button"
                              onClick={async () => {
                                is_student.remove(index);
                                checkSourceAvailableHandler("student");
                              }}
                              title="Delete this Employer"
                              className="float-right btn btn-sm btn-danger"
                            >
                              <TiDelete size={25} /> Delete
                            </button> */}
                            <CardHeader
                              sx={{ padding: 0 }}
                              // title={
                              //   <b className="s-no-index-number">{index + 1}</b>
                              // }
                              action={
                                <DeleteContainedBtn
                                  onClick={async () => {
                                    is_student.remove(index);
                                    checkSourceAvailableHandler("student");
                                    props?.handleAPIDebounced();
                                  }}
                                  // title="Delete this Employer"
                                />
                              }
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                            {/* <InputField
                              label={`College / University`}
                              register={register(
                                `is_student.fields.${index}.collegeuniversity_name`
                              )}
                            /> */}
                            <InputField2
                              label="College / University"
                              source={`is_student.fields.${index}.collegeuniversity_name`}
                              placeholder="College / University"
                              labelClass={"lable-title"}
                              additionalInputClass={"cus-control"}
                              handleOnChange={() => {
                                props?.handleAPIDebounced();
                              }}
                            />
                          </div>

                          <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                            {/* <InputDateField
                              minDate={new Date()}
                              type={"date"}
                              label="Anticipated Graduation Date"
                              placeholder="Enter Date"
                              register={register(
                                `is_student.fields.${index}.anticipatedgraduation_date`
                              )}
                            /> */}
                            <InputDateField
                              source={`is_student.fields.${index}.anticipatedgraduation_date`}
                              label="Anticipated Graduation Date"
                              labelClass={"lable-title"}
                              onChangeCallback={() => {
                                props?.handleAPIDebounced();
                              }}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                            {/* <InputField
                              label={`Major`}
                              register={register(
                                `is_student.fields.${index}.major`
                              )}
                              notRequired={true}
                            /> */}
                            <InputField2
                              label="Major"
                              source={`is_student.fields.${index}.major`}
                              placeholder="Major"
                              labelClass={"lable-title"}
                              additionalInputClass={"cus-control"}
                              handleOnChange={() => {
                                props?.handleAPIDebounced();
                              }}
                              notRequired={true}
                            />
                          </div>
                          <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                            {/* <InputField
                              label={`Activities / Clubs`}
                              register={register(
                                `is_student.fields.${index}.activities`
                              )}
                              notRequired={true}
                            /> */}
                            <InputField2
                              label="Activities / Clubs"
                              source={`is_student.fields.${index}.activities`}
                              placeholder="Activities / Clubs"
                              labelClass={"lable-title"}
                              additionalInputClass={"cus-control"}
                              handleOnChange={() => {
                                props?.handleAPIDebounced();
                              }}
                              notRequired={true}
                            />
                          </div>
                        </div>
                      );
                    })}
                    {!is_student.fields?.length && (
                      <div className="add-text-btn flex1 text-center ps-2 pt-3">
                        <button
                          type="button"
                          onClick={(event) =>
                            is_student.append({
                              id: 0,
                              collegeuniversity_name: "",
                              anticipatedgraduation_date: "",
                              major: "",
                              activities: "",
                            })
                          }
                        >
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                              fill="#2DC3E8"
                            />
                          </svg>
                          &nbsp; Add College / University detail
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          <div className="col-sm-12">
            {watch("have_vehicle.display") === true && (
              <>
                <div className="i-a-block mr-b15">
                  <CustomRadioButton
                    onClick={() => {
                      if (watch("have_vehicle.fields")?.length > 0) {
                        setRemovePets(false);
                        setRemoveStudent(false);
                        setRemoveVehcile(true);
                      }
                    }}
                    notRequired={!getValues("have_vehicle.required")}
                    disable={watch("have_vehicle.fields")?.length > 0}
                    label={`${getValues(
                      `have_vehicle.labelIndex`
                    )}. Do you have a vehicle?`}
                    register={register("have_vehicle.response")}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                  />
                </div>
                {watch("have_vehicle.response") === "Yes" && (
                  <div className="col-sm-12">
                    <div className="blue-box a-blue-box pd15 mr-b30">
                      {have_vehicle.fields.map((field, index) => {
                        return (
                          <div key={field.arrId} className="row">
                            <div className="col-sm-12 mb-2">
                              {/* <span className="s-no float-left">
                                  {index + 1}
                                </span>{" "} */}
                              {/* <button
                                type="button"
                                onClick={async () => {
                                  have_vehicle.remove(index);
                                  checkSourceAvailableHandler("vehical");
                                }}
                                title="Delete this Employer"
                                className="float-right btn btn-sm btn-danger"
                              >
                                <TiDelete size={25} /> Delete
                              </button> */}
                              <CardHeader
                                sx={{ padding: 0 }}
                                // title={
                                //   <b style={{ fontSize: 13 }}>{index + 1}</b>
                                // }
                                action={
                                  <DeleteContainedBtn
                                    onClick={async () => {
                                      have_vehicle.remove(index);
                                      checkSourceAvailableHandler("vehical");
                                      props?.handleAPIDebounced();
                                    }}
                                    // title="Delete this Employer"
                                  />
                                }
                              />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4">
                              {/* <InputField
                                type="tel"
                                maxLength={4}
                                label={`Year`}
                                register={register(
                                  `have_vehicle.fields.${index}.vehicle_year`
                                )}
                              /> */}
                              <InputField2
                                type="tel"
                                label="Year"
                                source={`have_vehicle.fields.${index}.vehicle_year`}
                                placeholder="Year"
                                labelClass={"lable-title"}
                                additionalInputClass={"cus-control"}
                                handleOnChange={() => {
                                  props?.handleAPIDebounced();
                                }}
                              />
                            </div>

                            <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                              {/* <InputField
                                label="Make "
                                placeholder="Enter Make"
                                register={register(
                                  `have_vehicle.fields.${index}.vehicle_make`
                                )}
                              /> */}
                              <InputField2
                                label="Make"
                                source={`have_vehicle.fields.${index}.vehicle_make`}
                                placeholder="Enter Make"
                                labelClass={"lable-title"}
                                additionalInputClass={"cus-control"}
                                handleOnChange={() => {
                                  props?.handleAPIDebounced();
                                }}
                              />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                              {/* <InputField
                                label={`Model`}
                                register={register(
                                  `have_vehicle.fields.${index}.vehicle_model`
                                )}
                              /> */}
                              <InputField2
                                label="Model"
                                source={`have_vehicle.fields.${index}.vehicle_model`}
                                placeholder="Model"
                                labelClass={"lable-title"}
                                additionalInputClass={"cus-control"}
                                handleOnChange={() => {
                                  props?.handleAPIDebounced();
                                }}
                              />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                              {/* <InputField
                                label={`Color`}
                                register={register(
                                  `have_vehicle.fields.${index}.vehicle_color`
                                )}
                              /> */}
                              <InputField2
                                label="Color"
                                source={`have_vehicle.fields.${index}.vehicle_color`}
                                placeholder="Color"
                                labelClass={"lable-title"}
                                additionalInputClass={"cus-control"}
                                handleOnChange={() => {
                                  props?.handleAPIDebounced();
                                }}
                              />
                            </div>

                            <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                              {/* <InputField
                                label={`Licence Plate`}
                                register={register(
                                  `have_vehicle.fields.${index}.vehicle_licenseplate`
                                )}
                              /> */}
                              <InputField2
                                label="Licence Plate"
                                source={`have_vehicle.fields.${index}.vehicle_licenseplate`}
                                placeholder="Licence Plate"
                                labelClass={"lable-title"}
                                additionalInputClass={"cus-control"}
                                handleOnChange={() => {
                                  props?.handleAPIDebounced();
                                }}
                              />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-4 mr-b10">
                              {/* <InputCurrencyField
                                notRequired={true}
                                label={`Monthly Payment`}
                                register={register(
                                  `have_vehicle.fields.${index}.vehicle_monthlypayment`
                                )}
                              /> */}
                              <InputCurrencyField
                                label="Monthly Payment"
                                source={`have_vehicle.fields.${index}.vehicle_monthlypayment`}
                                required={false}
                                labelClass={"lable-title"}
                                requiredClass={{
                                  fontSize: "70%",
                                }}
                                handleOnChange={() => {
                                  props?.handleAPIDebounced();
                                }}
                                // notRequired={true}
                              />
                            </div>
                          </div>
                        );
                      })}
                      {!have_vehicle.fields?.length && (
                        <div className="add-text-btn flex1 text-center ps-2 pt-3">
                          <button
                            type="button"
                            onClick={(event) =>
                              have_vehicle.append({
                                id: 0,
                              })
                            }
                          >
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 13 13"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.68652 5.44287V0.442871H7.35319V5.44287H12.3532V7.10954H7.35319V12.1095H5.68652V7.10954H0.686523V5.44287H5.68652Z"
                                fill="#2DC3E8"
                              />
                            </svg>
                            &nbsp; Add vehicle detail
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
          {watch("have_pet.display") === true && (
            <>
              <div className="col-sm-12">
                <div className="i-a-block mr-b15">
                  <CustomRadioButton
                    onClick={() => {
                      if (watch("have_pet.fields")?.length > 0) {
                        setRemoveStudent(false);
                        setRemoveVehcile(false);
                        setRemovePets(true);
                      }
                    }}
                    disable={watch("have_pet.fields")?.length > 0}
                    notRequired={!getValues("have_pet.required")}
                    label={`${getValues(
                      `have_pet.labelIndex`
                    )}. Do you have pets or service animal?`}
                    register={register("have_pet.response")}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                  />
                </div>
              </div>
              {watch("have_pet.response") === "Yes" && (
                <div className="col-sm-12">
                  <div className="blue-box a-blue-box pd15 mr-b30">
                    <Pets
                      register={register}
                      checkSourceAvailableHandler={checkSourceAvailableHandler}
                      data={props?.data}
                      handleAPIDebounced={props?.handleAPIDebounced}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          {watch("do_you_smoke.display") === true && (
            <>
              <div className="col-sm-12">
                <div className="i-a-block mr-b30">
                  <CustomRadioButton
                    notRequired={!getValues("do_you_smoke.required")}
                    label={`${getValues(
                      `do_you_smoke.labelIndex`
                    )}. Do you smoke?`}
                    register={register("do_you_smoke.response")}
                    handleOnChange={() => {
                      props?.handleAPIDebounced();
                    }}
                  />
                </div>
              </div>
            </>
          )}
          {watch("convicted_for_crime.display") === true && (
            <div className="col-sm-12">
              <div className="i-a-block mr-b15">
                <CustomRadioButton
                  label={`${getValues(
                    `convicted_for_crime.labelIndex`
                  )}. Have you been convicted of a crime?`}
                  register={register("convicted_for_crime.response")}
                  notRequired={!getValues(`convicted_for_crime.required`)}
                  handleOnChange={() => {
                    props?.handleAPIDebounced();
                  }}
                />
              </div>
            </div>
          )}

          {watch("convicted_for_crime.response") == "Yes" && (
            <div className="col-sm-12">
              <div className="blue-box a-blue-box pd15 mr-b30">
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    {/* <InputTextArea
                      label="Please Explain"
                      register={register("convicted_for_crime.explanation")}
                      placeholder="Please Explain"
                    /> */}
                    <div className="emp-inputs">
                      <InputTextArea
                        register={"convicted_for_crime.explanation"}
                        label="Please Explain"
                        placeholder="Please Explain"
                        labelClass={"lable-title"}
                        labelAsteriskClass={"color-red"}
                        additionalInputClass={"cus-control"}
                        styleProperty={{ height: "auto" }}
                        messageText={false}
                        requiredClass={{
                          fontSize: "70%",
                        }}
                        handleOnChange={() => {
                          props?.handleAPIDebounced();
                        }}
                      />
                      <em
                        className="d-block"
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          lineHeight: "19px",
                          textAlign: "right",
                          color: "#B4B4B4",
                        }}
                      >
                        Max 5000 Characters
                      </em>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={removePets || removeStudent || removeVehcile}
        onClose={() => {
          setRemovePets(false);
          setRemoveStudent(false);
          setRemoveVehcile(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete
           ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              setRemovePets(false);
              setRemoveStudent(false);
              setRemoveVehcile(false);
            }}
          >
            No
          </button>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              if (removePets) {
                setValue("have_pet.fields", []);
                setValue("have_pet.response", "No");
                dispatch(
                  setUploadPetImageId({
                    index: null,
                    id: null,
                    type: "DELETESOURCE",
                  })
                );
              }
              if (removeStudent) {
                setValue("is_student.fields", []);
                setValue("is_student.response", "No");
              }
              if (removeVehcile) {
                setValue("have_vehicle.fields", []);
                setValue("have_vehicle.response", "No");
              }
              setRemovePets(false);
              setRemoveStudent(false);
              setRemoveVehcile(false);
              props?.handleAPIDebounced();
            }}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>
    </AccordionDetails>
  );
};

export default OthersSection;
