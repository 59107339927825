import FilterReports from "../../componets/SubscriberManagementReport/FilterReports";
import { Datagrid, FunctionField, ListBase, ListToolbar, Pagination, Resource, TextField } from 'react-admin';
import ExportRevenueReport from "../../componets/SubscriberManagementReport/ExportRevenueReport";
import FilterChips from "../../componets/Ats/SuperAdminInbox/FilterChips";
import moment from "moment";
import { NumericFormat } from "react-number-format";
const AdminRevenueManagement = () => {
    return <>
        <Resource name="adminRevenueManagement" list={AdminRevenueManagementList} />
    </>
}

const MyList = ({ children, filters, actions, ...props }) => {
    return (
        <ListBase
            {...props}
            perPage={100} // Number of items per page
        >
            <ListToolbar
                filters={filters}
                actions={actions}
            />

            <FilterChips excludeKeys={['app_invitation_type']} />
            {children}
            <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
            <br />
            <br />
            <br />
            <br />
        </ListBase>
    );
};

export const AdminRevenueManagementList = () => {
    return (
        <>
            <br />
            <br />
            <MyList
                filters={<FilterReports />}
                actions={<ExportRevenueReport />}
                sort={{ field: "ApplicationNumber", order: "DESC" }}
                children={<DataGrid />}
            />
        </>
    )
}
const DataGrid = () => {
    return (<Datagrid bulkActionButtons={false} sx={{
        "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
        "& .RaDatagrid-expandIcon": { color: "#fff" },
        "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
        "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
            fontSize: 12,

        },
        "& .RaDatagrid-table": {
            fontSize: 12,
        },
        "& .MuiToggleButton-root.Mui-selected": {
            fontWeight: 700,
        },
        marginTop: '40px'
    }}>
        <TextField source="ApplicationNumber" label={"App Id"} />
        <FunctionField
            sortBy="AppDate"
            label="Date"
            render={(record) => {
                // Format the AppDate using moment.js
                const formattedDate = moment.utc(record.AppDate).format('MM/DD/YYYY');

                return (
                    <>

                        <p>{formattedDate}</p> {/* Display the formatted AppDate */}
                    </>
                );
            }}
        />
        <TextField source="SubscriberName" label={"Subscriber"} />
        <TextField source="PropertyName/OfficeName" label={"Property/Office"} />
        <TextField source="ApplicantName" label={"Applicant"} />
        {/* <TextField source="ApplicationFee" label={"Applicant Fee"}/> */}
        <FunctionField
            source="ApplicationFee"
            label="Applicant Fee"
            sortBy="ApplicationFee"
            render={(record) => {
                return !record.ApplicationFee ? (
                    ""
                ) : (
                    <>
                        <NumericFormat
                            thousandSeparator=","
                            displayType="text"
                            thousandsGroupStyle="thousand"
                            decimalScale={2}
                            prefix="$"
                            fixedDecimalScale
                            value={record.ApplicationFee}
                        />

                    </>
                );
            }}
        />

        {/* <TextField source="SubscriberFee" label={"Subscriber Fee"} /> */}
        <FunctionField
            source="SubscriberFee"
            label="Subscriber Fee"
            sortBy="SubscriberFee"
            render={(record) => {
                return !record.SubscriberFee ? (
                    ""
                ) : (
                    <>
                        <NumericFormat
                            thousandSeparator=","
                            displayType="text"
                            thousandsGroupStyle="thousand"
                            decimalScale={2}
                            prefix="$"
                            fixedDecimalScale
                            value={record.SubscriberFee}
                        />

                    </>
                );
            }}
        />
        {/* <TextField source="ScreeningFee" label={"Screening Fee"} /> */}
        <FunctionField
            source="ScreeningFee"
            label="Screening Fee"
            sortBy="ScreeningFee"
            render={(record) => {
                return !record.ScreeningFee ? (
                    ""
                ) : (
                    <>
                        <NumericFormat
                            thousandSeparator=","
                            displayType="text"
                            thousandsGroupStyle="thousand"
                            decimalScale={2}
                            prefix="$"
                            fixedDecimalScale
                            value={record.ScreeningFee}
                        />

                    </>
                );
            }}
        />
        {/* <TextField source="GrossDVRevenue" label={"GROSS DV REVENUE"} /> */}
        <FunctionField
            source="GrossDVRevenue"
            label="GROSS DV REVENUE"
            sortBy="GrossDVRevenue"
            render={(record) => {
                return !record.GrossDVRevenue ? (
                    ""
                ) : (
                    <>
                        <NumericFormat
                            thousandSeparator=","
                            displayType="text"
                            thousandsGroupStyle="thousand"
                            decimalScale={2}
                            prefix="$"
                            fixedDecimalScale
                            value={record.GrossDVRevenue}
                        />

                    </>
                );
            }}
        />
        {/* <TextField source="StripeFee" label={"Transaction Fee"} /> */}
        <FunctionField
            source="StripeFee"
            label="Transaction Fee"
            sortBy="StripeFee"
            render={(record) => {
                return !record.StripeFee ? (
                    ""
                ) : (
                    <>
                        <NumericFormat
                            thousandSeparator=","
                            displayType="text"
                            thousandsGroupStyle="thousand"
                            decimalScale={2}
                            prefix="$"
                            fixedDecimalScale
                            value={record.StripeFee}
                        />

                    </>
                );
            }}
        />
        {/* <TextField source="NetDVRevenue" label={" NET DV REVENUE "} /> */}
        <FunctionField
            source="NetDVRevenue"
            label="NET DV REVENUE"
            sortBy="NetDVRevenue"
            render={(record) => {
                return !record.NetDVRevenue ? (
                    ""
                ) : (
                    <>
                        <NumericFormat
                            thousandSeparator=","
                            displayType="text"
                            thousandsGroupStyle="thousand"
                            decimalScale={2}
                            prefix="$"
                            fixedDecimalScale
                            value={record.NetDVRevenue}
                        />

                    </>
                );
            }}
        />
        <TextField source="NameOnCard" label={"Card Holder Name"} />
        {/* <FunctionField label="Batch date Batch Id." render={(record) => {
                        return (<>
                            <p>{record.BatchDate}</p>
                            <p>{record.BatchNumber}</p>
                        </>)
                    }
                    } />  */}
        <TextField source="TransactionNumber" label={"Transaction No."} />
        <TextField source="AgentName" label={"Agent Name"} />


    </Datagrid>


    )
}
export default AdminRevenueManagement;